export interface TopicsRankedProps {
  list?: TopicListProps[];
  totalElements?: number;
  totalPages?: number;
  number?: number;
  size?: number;
}

export interface TopicListProps {
  id?: number;
  name?: string;
  entityType?: string;
  deltaYearPercent?: number;
  deltaMonthPercent?: number;
  deltaWeekPercent?: number;
  deltaYear?: number;
  deltaMonth?: number;
  deltaWeek?: number;
  newsAuthors?: number;
  newsAuthorsMonth?: number;
  newsAuthorsWeek?: number;
  longHistory?: number[];
  newThisMonth?: boolean;
  newThisWeek?: boolean;
  score?: number;
  percentage?: number;
  isSaving?: boolean;
  isSaved?: boolean;
  starIcon?: string;
  longTermChart?: any;
}

export class TopicsRankedResponse {
  public list?: TopicListProps[];
  public totalElements?: number;
  public totalPages?: number;
  public number?: number;
  public size?: number;

  constructor({
    list,
    totalElements,
    totalPages,
    number,
    size
  }: TopicsRankedProps) {
    this.list = list || [];
    this.totalElements = totalElements || 0;
    this.totalPages = totalPages || 0;
    this.number = number || 0;
    this.size = size || 0;
  }
}

export class TopicList {
  public id?: number;
  public name?: string;
  public entityType?: string;
  public deltaYearPercent?: number;
  public deltaMonthPercent?: number;
  public deltaWeekPercent?: number;
  public deltaYear?: number;
  public deltaMonth?: number;
  public deltaWeek?: number;
  public newsAuthors?: number;
  public newsAuthorsMonth?: number;
  public newsAuthorsWeek?: number;
  public longHistory?: number[];
  public newThisMonth?: boolean;
  public newThisWeek?: boolean;
  public percentage?: number;
  public score?: number;
  public isSaving?: boolean;
  public isSaved?: boolean;
  public starIcon?: string;
  public longTermChart?: any;

  constructor({
    id,
    name,
    entityType,
    deltaYearPercent,
    deltaMonthPercent,
    deltaWeekPercent,
    deltaYear,
    deltaMonth,
    deltaWeek,
    newsAuthors,
    newsAuthorsMonth,
    newsAuthorsWeek,
    longHistory,
    newThisMonth,
    newThisWeek,
    isSaving,
    isSaved,
    starIcon,
    longTermChart,
    percentage,
    score
  }: TopicListProps) {
    this.id = id;
    this.name = name;
    this.entityType = entityType;
    this.deltaYearPercent = deltaYearPercent;
    this.deltaMonthPercent = deltaMonthPercent;
    this.deltaWeekPercent = deltaWeekPercent;
    this.deltaYear = deltaYear;
    this.deltaYear = deltaYear;
    this.deltaMonth = deltaMonth;
    this.deltaWeek = deltaWeek;
    this.newsAuthors = newsAuthors;
    this.newsAuthorsMonth = newsAuthorsMonth;
    this.newsAuthorsWeek = newsAuthorsWeek;
    this.longHistory = longHistory;
    this.newThisMonth = newThisMonth;
    this.newThisWeek = newThisWeek;
    this.isSaving = isSaving || false;
    this.isSaved = isSaved || false;
    this.starIcon = starIcon;
    this.longTermChart = longTermChart;
    this.percentage = percentage || null;
    this.score = score || null;
  }
}
