import { createReducer, on } from '@ngrx/store';
import {
  showSignUpDialog,
  hideSignUpDialog,
  showPaymentSuccessDialog,
  hidePaymentSuccessDialog
} from 'redux/actions';

export interface SignUpDialogState {
  showDialog: boolean;
  showSuccessDialog: boolean;
}

const initialState: SignUpDialogState = {
  showDialog: false,
  showSuccessDialog: false
};

const _signUpDialogReducer = createReducer(
  initialState,

  on(showSignUpDialog, state => {
    return {
      ...state,
      showDialog: true
    };
  }),

  on(hideSignUpDialog, state => ({
    ...state,
    showDialog: false
  })),

  on(showPaymentSuccessDialog, state => {
    return {
      ...state,
      showSuccessDialog: true
    };
  }),

  on(hidePaymentSuccessDialog, state => ({
    ...state,
    showSuccessDialog: false
  }))
);

export function signUpDialogReducer(state, action) {
  return _signUpDialogReducer(state, action);
}
