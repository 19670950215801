<div class="right-container">
  <!-- <div class="row"> -->
  <div id="resultsSection" class="col-12">
    <div *ngIf="!showMyNewsFeed">
      <app-search (setKeywordsEmitter)="getKeywords($event)"></app-search>
    </div>

    <toast-notification></toast-notification>
    <div *ngIf="tabControl === 0; then topicsContent"></div>
    <div *ngIf="tabControl === 1; then coverageContent"></div>
    <div *ngIf="tabControl === 3; then collectionContent"></div>

    <ng-template #collectionContent>
      <app-toolbar-content></app-toolbar-content>
      <p-splitter
        [panelSizes]="collectionPanelSizes"
        [style]="{ height: '100%' }"
      >
        <ng-template pTemplate>
          <div class="p-mr-2">
            <topics-ranked-list
              [topicsList]="topicsSavedList"
            ></topics-ranked-list>
          </div>
        </ng-template>
        <ng-template pTemplate>
          <div class="p-ml-2">
            <app-collection-content></app-collection-content>
          </div>
        </ng-template>
      </p-splitter>
    </ng-template>

    <ng-template #topicsContent>
      <div *ngIf="showMyNewsFeed">
        <app-news-feed></app-news-feed>
      </div>
      <div *ngIf="showTopicsContent">
        <app-toolbar-content></app-toolbar-content>
        <topics-ranked-list [topicsList]="topicsSavedList"></topics-ranked-list>
      </div>
    </ng-template>

    <ng-template #coverageContent>
      <div *ngIf="showMiniTopics">
        <p-splitter
          [panelSizes]="cPanelSizes"
          [style]="{ height: 'calc(100vh - 145px)' }"
          (onResizeEnd)="changeResizeSplitterPanel($event)"
        >
          <ng-template pTemplate>
            <div class="col flex align-items-center justify-content-center">
              <app-facets-table></app-facets-table>
            </div>
          </ng-template>
          <ng-template pTemplate>
            <div class="col flex align-items-center justify-content-center">
              <app-coverage></app-coverage>
            </div>
          </ng-template>
          <ng-template pTemplate>
            <div class="col flex align-items-center justify-content-center">
              <app-mini-topics-table
                [topicsObj]="topics"
                [topicsList]="topicsSavedList"
              ></app-mini-topics-table>
            </div>
          </ng-template>
        </p-splitter>
      </div>
      <div *ngIf="!showMiniTopics">
        <p-splitter
          [panelSizes]="cPanelSizes"
          [style]="{ height: '100%' }"
          (onResizeEnd)="changeResizeSplitterPanel($event)"
        >
          <ng-template pTemplate>
            <div class="col flex align-items-center justify-content-center">
              <app-facets-table></app-facets-table>
            </div>
          </ng-template>
          <ng-template pTemplate>
            <div class="col flex align-items-center justify-content-center">
              <app-coverage></app-coverage>
            </div>
          </ng-template>
        </p-splitter>
      </div>
    </ng-template>
  </div>
  <!-- </div> -->
</div>
<p-confirmDialog
  key="unfollowConfirmation"
  [style]="{ width: '40vw' }"
  [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>

<app-off-canvas></app-off-canvas>
<app-payment-successful></app-payment-successful>
<app-sign-up></app-sign-up>
<app-author-manager></app-author-manager>
<app-outlet-manager></app-outlet-manager>
<app-company-manager></app-company-manager>
<app-topic-manager></app-topic-manager>
<app-select-project></app-select-project>
<app-alert-settings></app-alert-settings>
<app-categories-list></app-categories-list>
<app-category-manager></app-category-manager>
<app-report-missing-incorrect-data></app-report-missing-incorrect-data>
<app-welcome></app-welcome>
<app-my-account></app-my-account>
