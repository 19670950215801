import { createReducer, on } from '@ngrx/store';
import { filter, eq } from 'lodash';
import {
  loadProjectContentItems,
  loadProjectContentItemsSuccess,
  loadProjectContentItemsError,
  saveContentItemToProject,
  saveContentItemToProjectSuccess,
  saveContentItemToProjectError,
  deleteContentItemToProject,
  deleteContentItemToProjectSuccess,
  deleteContentItemsToProjectError
} from 'redux/actions';

export interface ContentItemsListState {
  contentItems: any[];
  loaded: boolean;
  loading: boolean;
  hasContentItemSaved: boolean;
  hasContentItemDeleted: boolean;
  isAddingContentItem: boolean;
  isDeletingContentItem: boolean;
  loadError: any;
  savedError: any;
  deletedError: any;
}

const initialState: ContentItemsListState = {
  contentItems: [],
  loaded: false,
  loading: false,
  hasContentItemSaved: false,
  hasContentItemDeleted: false,
  isAddingContentItem: false,
  isDeletingContentItem: false,
  loadError: null,
  savedError: null,
  deletedError: null
};

const _contentItemsListReducer = createReducer(
  initialState,

  on(loadProjectContentItems, state => ({
    ...state,
    loaded: false,
    loading: true,
    loadError: null
  })),

  on(loadProjectContentItemsSuccess, (state, { contentItems }) => ({
    ...state,
    contentItems,
    loading: false,
    loaded: true
  })),

  on(loadProjectContentItemsError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    loadError: 'Error loading project content items'
  })),

  on(saveContentItemToProject, state => ({
    ...state,
    hasContentItemSaved: false,
    isAddingContentItem: true,
    savedError: null
  })),

  on(saveContentItemToProjectSuccess, (state, { contentItem }) => {
    return {
      ...state,
      contentItems: [...state.contentItems, contentItem],
      hasContentItemSaved: true,
      isAddingContentItem: false
    };
  }),

  on(saveContentItemToProjectError, (state, { payload }) => ({
    ...state,
    hasContentItemSaved: true,
    isAddingContentItem: false,
    savedError: 'Error saving content item to project'
  })),

  on(deleteContentItemToProject, state => ({
    ...state,
    hasContentItemDeleted: false,
    isDeletingContentItem: true,
    deletedError: null
  })),

  on(deleteContentItemToProjectSuccess, (state, { contentItemId }) => ({
    ...state,
    contentItems: filter(
      state.contentItems,
      ({ id }) => !eq(id, contentItemId)
    ),
    hasContentItemDeleted: true,
    isDeletingContentItem: false
  })),

  on(deleteContentItemsToProjectError, (state, { payload }) => ({
    ...state,
    hasContentItemDeleted: true,
    isDeletingContentItem: false,
    deletedError: 'Error deleting content item from project'
  }))
);

export function contentItemsListReducer(state, action) {
  return _contentItemsListReducer(state, action);
}
