export class OffCanvasContent {
  public showContent: boolean;
  public contentKey: number;
  public contentType: string;
  public contentData: any;
  public topicId: number;

  constructor(obj: OffCanvasContentObject) {
    this.showContent = (obj && obj.showContent) || true;
    this.contentKey = (obj && obj.contentKey) || 0;
    this.contentType = (obj && obj.contentType) || null;
    this.contentData = (obj && obj.contentData) || null;
    this.topicId = (obj && obj.topicId) || null;
  }
}

interface OffCanvasContentObject {
  showContent: boolean;
  contentKey: number;
  contentType: string;
  contentData: any;
  topicId: number;
}
