import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { Notification } from 'app/models';
import * as actions from 'redux/actions';

export type NotificationType = 'Info' | 'Warn' | 'Error' | 'Success';

@Injectable({
  providedIn: 'root'
})
export class ToastNotificationService {
  constructor(private store: Store<AppState>) {}

  public showMessage(message: string, severity: NotificationType) {
    this.store.dispatch(
      actions.showNotification({
        notify: new Notification(message, severity, 'tl')
      })
    );
  }
}
