import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../authentication';
import { UserUpdate, ValidUser, UserCreate, UserEdit } from 'app/models';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private api: string;
  private product: string;
  private s3: string;
  private env: string;
  private version: string;
  private httpOptions: any;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
    this.env = environment.production
      ? '/itdatabase-media/'
      : '/itdatabase-media/Development/';
    this.product = environment.product;
    this.api = environment.api_url;
    this.s3 = `${environment.s3_url}${this.env}`;
    this.version = environment.images_version;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public login(userId): Observable<any> {
    return this.http.post(
      `${this.api}/user/${userId}/login?product=${this.product}`,
      this.httpOptions
    );
  }

  public getUserAccountProductsInfo(): Observable<any> {
    return this.http.get(`${this.api}/user/userData`, this.httpOptions);
  }

  public productTour(userId, steps): Observable<any> {
    return this.http.post(
      `${this.api}/user/${userId}/productTour?product=${this.product}&steps=${steps}`,
      this.httpOptions
    );
  }

  public getUserByEmail(email): Observable<any> {
    const p = encodeURIComponent(email);
    return this.http.get(
      `${this.api}/users/search/findByEmailAddress?email=${p}`,
      this.httpOptions
    );
  }

  public getUserPicture(userId): string {
    return `${this.s3}users/${
      this.version
    }/${userId}.png?${new Date().getTime()}`;
  }

  public signInSocialUser() {
    const jsonFileUrl = 'assets/user.json';
    return this.http.get(jsonFileUrl);
  }

  public techNewsLogin(username: string, password: string): Observable<any> {
    return this.http.post(
      `${this.api}/tech-news`,
      {
        username: username,
        password: password
      },
      this.httpOptions
    );
  }

  public validCurrentPassword(validUser: ValidUser): Observable<any> {
    const { userId, username, password } = validUser;
    return this.http.get(
      `${
        this.api
      }/user/validatePassword?userId=${userId}&username=${encodeURIComponent(
        username
      )}&password=${encodeURIComponent(password)}`,
      this.httpOptions
    );
  }

  public createNewUser(username: string, user: UserCreate): Observable<any> {
    return this.http.post(
      `${this.api}/user/create?username=${encodeURIComponent(username)}`,
      user,
      this.httpOptions
    );
  }

  public editUser(user: UserEdit): Observable<any> {
    return this.http.patch(`${this.api}/user/edit`, user, this.httpOptions);
  }

  public updateCurrentUserProfile(
    userId: number,
    userUpdate: UserUpdate
  ): Observable<any> {
    return this.http.patch(
      `${this.api}/user/update?userId=${userId}`,
      userUpdate,
      this.httpOptions
    );
  }

  public deleteUser(userId: number): Observable<any> {
    return this.http.delete(
      `${this.api}/user/delete/${userId}`,
      this.httpOptions
    );
  }

  public updloadUserImage(userId: any, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', userId);
    const options = {
      headers: {
        Authorization: `Bearer ${this.authService.getToken()}`
      }
    };

    return this.http.post(`${this.api}/user/avatar/upload`, formData, options);
  }

  public getUsersByAccountId(accountId: number): Observable<any> {
    return this.http.get(
      `${this.api}/user/getAccounts/${accountId}`,
      this.httpOptions
    );
  }
}
