import { createAction, props } from '@ngrx/store';

// Create Author

export const createAuthor = createAction(
  '[Author Manager] Create Author',
  props<{ payload: any }>()
);

export const createAuthorSuccess = createAction(
  '[Author Manager] Create Author Success',
  props<{ author: any }>()
);

export const createAuthorError = createAction(
  '[Author Manager] Create Author Error',
  props<{ payload: any }>()
);

// Update Author

export const updateAuthor = createAction(
  '[Author Manager] Update Author',
  props<{ payload: any }>()
);

export const updateAuthorSuccess = createAction(
  '[Author Manager] Update Author Success',
  props<{ author: any }>()
);

export const updateAuthorError = createAction(
  '[Author Manager] Update Author Error',
  props<{ payload: any }>()
);

// Delete Author

export const deleteAuthor = createAction(
  '[Author Manager] Delete Author',
  props<{ payload: any }>()
);

export const deleteAuthorSuccess = createAction(
  '[Author Manager] Delete Author Success',
  props<{ author: any }>()
);

export const deleteAuthorError = createAction(
  '[Author Manager] Delete Author Error',
  props<{ payload: any }>()
);

export const resetAuthorState = createAction(
  '[Author Manager] Reset Author State'
);

// Author Manager Dialog

export const showAuthorManagerDialog = createAction(
  '[Author Manager Dialog] showAuthorManagerDialog',
  props<{ author: any }>()
);

export const hideAuthorManagerDialog = createAction(
  '[Author Manager Dialog] hideAuthorManagerDialog'
);
