import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { Content } from 'app/constants';
import {
  deleteAuthor,
  setAuthorDialog,
  showAuthorManagerDialog,
  startLoading
} from 'app/redux/actions';
import { AuthorService } from 'app/services';
import { eq, orderBy, reverse } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { SidebarList } from 'app/models';

@Component({
  selector: 'app-sidebar-author-list',
  template: `
    <div *ngIf="!!authors.length">
      <app-sidebar-list
        [sidebarData]="authors"
        (openSelectDialogEmiter)="selectedItem($event)"
        (openEditDialogEmiter)="openEditAuthorManagerDialog($event)"
        (unfollowEmiter)="unfollow($event)"
      ></app-sidebar-list>
    </div>
    <!-- <span *ngIf="loading">Loading..</span>
    <span *ngIf="loaded">No results</span> -->
  `
})
export class SidebarAuthorListComponent implements OnInit {
  public authors: SidebarList[];
  public selectItem: any;
  public sort: any;
  public showScrollbar: boolean;
  public projectId: number;
  public loading: boolean;
  public loaded: boolean;
  public currentProject: any;
  public keywords: string;
  public isEdit: boolean;
  public isUnfollow: boolean;
  public parentName: string;

  @ViewChildren('sortNameIcon, sortCategoryIcon', {
    read: ElementRef
  })
  sortIcons: QueryList<ElementRef>;

  constructor(
    private store: Store<AppState>,
    private confirmationService: ConfirmationService,
    private authorService: AuthorService
  ) {
    this.isEdit = false;
    this.isUnfollow = false;
    this.keywords = null;
    this.showScrollbar = false;
    this.loading = false;
    this.loaded = false;
    this.authors = [];
    this.parentName = Content.Author;
  }

  ngOnInit() {
    this.store.select('search').subscribe(({ searchBody }) => {
      if (searchBody) {
        const { keywords } = searchBody;
        this.keywords = keywords;
      }
    });

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.currentProject = projectSelected;
      }
    });

    this.store
      .select('authorsOutletsList')
      .subscribe(({ authors, loading, loaded, errors }) => {
        this.loading = loading;
        this.loaded = loaded;
        if (authors && authors.length > 0 && loaded) {
          this.authors = authors.map(
            item =>
              new SidebarList({
                title: item.name,
                subTitle: item.label.name,
                picture: this.loadPicture(item.partyId),
                loadingIcon: false,
                hasPicture: true,
                parentObject: {
                  categoryName: item.label.name,
                  categoryId: item.label.id,
                  name: item.name,
                  id: item.id,
                  partyId: item.partyId
                },
                parentName: this.parentName
              })
          );
        } else {
          this.authors = [];
        }
      });

    this.store
      .select('authorDialog')
      .subscribe(({ authorId, loading, loaded }) => {
        if (authorId) {
          this.loadingAuthorDialog(authorId, loading, loaded);
        }
      });
  }

  public selectedItem(item) {
    this.store.dispatch(
      setAuthorDialog({
        authorId: item.partyId,
        projectSelected: this.currentProject,
        keywords: this.keywords,
        sort: 'recent'
      })
    );
  }

  loadingAuthorDialog(authorId, loading, loaded) {
    const index = this.authors.findIndex(({ parentObject }) =>
      eq(parentObject.partyId, authorId)
    );
    if (loading) {
      this.authors[index].loadingIcon = true;
    }
    if (loaded) {
      this.authors[index].loadingIcon = false;
    }
  }

  openCreateAuthorManagerDialog() {
    this.store.dispatch(showAuthorManagerDialog({ author: null }));
  }

  openEditAuthorManagerDialog(author) {
    this.store.dispatch(showAuthorManagerDialog({ author }));
  }

  handleUnfollow(value) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to unfollow this author?',
      header: 'Unfollow Confirmation',
      icon: 'pi pi-exclamation-triangle',
      key: 'unfollowConfirmation',
      accept: () => {
        this.unfollow(value);
      },
      reject: type => {
        // handle rejected option ConfirmEventType.CANCEL
      }
    });
  }

  public unfollow(value) {
    const payload = {
      id: value.partyId,
      name: value.name,
      userProject: this.currentProject
    };
    this.store.dispatch(
      deleteAuthor({
        payload
      })
    );
  }

  public loadPicture(id) {
    return this.authorService.getAuthorPicture(id);
  }
}
