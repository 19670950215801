<app-content-items
  [parentName]="parentName"
  [items]="contents"
  [loading]="loading"
  [pageSize]="pageSize"
  [page]="page"
  [total]="total"
  [keywords]="keywords"
></app-content-items>
<div class="p-d-flex p-jc-between collection-footer">
  <div>
    <p-paginator
      *ngIf="totalPages >= 2"
      #pp
      [pageLinkSize]="3"
      [showFirstLastIcon]="false"
      (onPageChange)="getPage($event)"
      [rows]="pageSize"
      [totalRecords]="total"
    >
    </p-paginator>
  </div>
</div>
