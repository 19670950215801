import { createAction, props } from '@ngrx/store';
import { ReportMissingData } from 'app/models';

// Create ReportMissingData

export const createReport = createAction(
  '[Report Missing Data] Create Report',
  props<{ payload: ReportMissingData }>()
);

export const createReportSuccess = createAction(
  '[Report Missing Data] Create Report Success',
  props<{ payload: any }>()
);

export const createReportError = createAction(
  '[Report Missing Data] Create Report Error',
  props<{ payload: any }>()
);

// Reset state
export const resetReportState = createAction(
  '[Report Missing Data] Reset Report State'
);

// Report Missing Dialog

export const showReportDialog = createAction(
  '[Report Missing Data] showReportDialog'
);

export const hideReportDialog = createAction(
  '[Report Missing Data] hideReportDialog'
);
