import { createReducer, on } from '@ngrx/store';
import { CategoryResponse, CategorySubmit } from 'app/models';
import {
  loadCategories,
  loadCategoriesSuccess,
  loadCategoriesError,
  createCategory,
  createCategorySuccess,
  createCategoryError,
  updateCategory,
  updateCategorySuccess,
  updateCategoryError,
  deleteCategory,
  deleteCategorySuccess,
  deleteCategoryError,
  showCategoriesListDialog,
  hideCategoriesListDialog,
  showCategoryManagerDialog,
  hideCategoryManagerDialog
} from 'redux/actions';

export interface CategoryState {
  categories: CategoryResponse[];
  category: CategoryResponse;
  showCategoryManager: boolean;
  showCategoriesList: boolean;
  loading: boolean;
  loaded: boolean;
  error: any;
  creationMode: boolean;
  editionMode: boolean;
  message: string;
  responseSuccess: any;
}

const initialState: CategoryState = {
  categories: [],
  category: null,
  showCategoryManager: false,
  showCategoriesList: false,
  loading: false,
  loaded: false,
  error: null,
  creationMode: false,
  editionMode: false,
  message: null,
  responseSuccess: null
};

const _categoryReducer = createReducer(
  initialState,

  on(loadCategories, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: null
  })),

  on(loadCategoriesSuccess, (state, { categories }) => ({
    ...state,
    categories,
    loading: false,
    loaded: true
  })),

  on(loadCategoriesError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Error loading categories: ${payload?.message}`
    }
  })),

  on(createCategory, state => ({
    ...state,
    category: null,
    error: null,
    creationMode: false
  })),

  on(createCategorySuccess, (state, { responseSuccess }) => ({
    ...state,
    responseSuccess,
    message: `Category has been added successfully`
  })),

  on(createCategoryError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Error creating category: ${payload?.message}`
    }
  })),

  on(updateCategory, state => ({
    ...state,
    error: null,
    editionMode: false
  })),

  on(updateCategorySuccess, (state, { responseSuccess }) => ({
    ...state,
    responseSuccess,
    showCategoryManager: false,
    message: `Category has been updated successfully`
  })),

  on(updateCategoryError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Error updating category: ${payload?.message}`
    }
  })),

  on(deleteCategory, state => ({
    ...state,
    category: null,
    error: null
  })),

  on(deleteCategorySuccess, (state, { responseSuccess }) => ({
    ...state,
    loading: false,
    loaded: true,
    responseSuccess,
    message: `Category has been deleted successfully`
  })),

  on(deleteCategoryError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Error deleting category: ${payload?.message}`
    }
  })),

  on(showCategoriesListDialog, state => ({
    ...state,
    showCategoriesList: true
  })),

  on(hideCategoriesListDialog, state => ({
    ...state,
    showCategoriesList: false
  })),

  on(showCategoryManagerDialog, (state, { category }) => {
    return {
      ...state,
      editionMode: category ? true : false,
      creationMode: !category ? true : false,
      showCategoryManager: true,
      category
    };
  }),

  on(hideCategoryManagerDialog, state => ({
    ...state,
    category: null,
    error: null,
    creationMode: false,
    editionMode: false,
    message: null,
    responseSuccess: null,
    showCategoryManager: false
  }))
);

export function categoryReducer(state, action) {
  return _categoryReducer(state, action);
}
