<div *ngIf="showSettingSection">
  <!--  <p>Edit Project</p>-->
  <form [formGroup]="form">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12" *ngIf="hasMainTopic">
        <p-message severity="error" text="Main topic not found!"></p-message>
      </div>
      <div class="p-field p-col-12">
        <label for="name">Name*</label>
        <input
          #projectNameInput
          id="name"
          formControlName="name"
          [class]="invalidField('name') ? 'ng-invalid ng-dirty' : ''"
          pInputText
          maxlength="250"
        />
        <small
          class="block"
          *ngIf="invalidField('name')"
          id="name-help"
          class="p-error"
          >Display Name is a required field.</small
        >
      </div>
      <div class="p-field p-col-12">
        <label for="name">Description</label>
        <input
          id="description"
          formControlName="description"
          pInputText
          maxlength="250"
        />
      </div>
    </div>
    <div class="box-button-right">
      <p-button
        [disabled]="!form.valid || isSaving"
        icon="pi pi-check"
        type="button"
        label="Save"
        class="p-mr-2 save"
        (click)="handleSubmit()"
      ></p-button>
      <p-button
        icon="pi pi-times"
        type="button"
        label="Close"
        styleClass="p-button-raised p-button-secondary p-button-text"
        (click)="handleCancel()"
      ></p-button>
    </div>
  </form>
</div>
