import { createAction, props } from '@ngrx/store';
import { Project, TopicResponse } from 'app/models';

export const setTopicDialog = createAction(
  '[Topic Dialog] setTopicDialog',
  props<{
    topicSelected: TopicResponse;
    projectSelected: Project;
    keywords: string;
    sort: string;
    isSidebarTopic: boolean;
  }>()
);

export const setTopicDialogSuccess = createAction(
  '[Topic Dialog] setTopicDialogSuccess',
  props<{ topic: any }>()
);

export const setTopicDialogError = createAction(
  '[Topic Dialog] setTopicDialogError',
  props<{ payload: any }>()
);
export const unSetTopicDialog = createAction('[Topic Dialog] unSetTopicDialog');
