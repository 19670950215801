import { createReducer, on } from '@ngrx/store';

import {
  setCompaniesRanked,
  setCompaniesRankedSuccess,
  setCompaniesRankedError,
  unSetTopicsRanked
} from 'redux/actions';

export interface CompaniesRankedState {
  list: any[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initialState: CompaniesRankedState = {
  list: [],
  loaded: false,
  loading: false,
  error: null
};

const _companiesRankedReducer = createReducer(
  initialState,
  on(setCompaniesRanked, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),

  on(unSetTopicsRanked, () => ({ ...initialState, list: [], error: null })),

  on(setCompaniesRankedSuccess, (state, { list }) => ({
    ...state,
    list,
    loading: false,
    loaded: true,
    error: null
  })),

  on(setCompaniesRankedError, (state, { payload }) => ({
    ...state,
    list: [],
    loading: false,
    loaded: false,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  }))
);

export function companiesRankedReducer(state, action) {
  return _companiesRankedReducer(state, action);
}
