import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-outlet-content',
  template: `
    <app-sidebar-outlet-list></app-sidebar-outlet-list>
  `
})
export class SidebarOutletContentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
