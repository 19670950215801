import { Component, Input, OnInit } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexStroke,
  ApexLegend,
  ApexGrid,
  ApexYAxis,
  ApexXAxis,
  ApexPlotOptions,
  ApexTooltip,
} from 'ng-apexcharts';

interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  legend: ApexLegend;
  colors: string[];
  markers: any;
  tooltip: ApexTooltip;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  title: ApexTitleSubtitle;
}

@Component({
  selector: 'trendbar-chart',
  template: `
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [dataLabels]="chartOptions.dataLabels"
      [colors]="chartOptions.colors"
      [title]="chartOptions.title"
      [grid]="chartOptions.grid"
      [plotOptions]="chartOptions.plotOptions"
      [yaxis]="chartOptions.yaxis"
      [xaxis]="chartOptions.xaxis"
      [tooltip]="chartOptions.tooltip"
      [legend]="chartOptions.legend"
    ></apx-chart>
  `,
  styles: [``],
})
export class TrendNegativebarChartComponent implements OnInit {
  public chartOptions: Partial<ChartOptions>;

  @Input() trend: number;

  constructor() {}

  ngOnInit() {
    const positive = this.trend > 0 ? this.trend : -this.trend;
    const negative = this.trend < 0 ? this.trend : -this.trend;
    this.chartOptions = {
      series: [
        {
          name: 'positive',
          data: [positive],
        },
        {
          name: 'negative',
          data: [negative],
        },
      ],
      chart: {
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
        },
        type: 'bar',
        height: 82,
        stacked: true,
      },
      colors: [
        this.trend > 0 ? '#00E396' : '#FFFFFF',
        this.trend < 0 ? '#FF4560' : '#FFFFFF',
      ],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '72%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0,
        colors: ['#fff'],
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        enabled: false, // with this doesn't show values on mouse hover
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return '';
            },
          },
        },
        marker: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        showAlways: false,
      },
    };
  }
}
