<p-dialog
  [dismissableMask]="true"
  header="Advanced Search Options"
  [(visible)]="showFilters"
  [style]="{ width: '90vw' }"
  [baseZIndex]="10000"
  [modal]="true"
  (onHide)="onClose()"
>
  <div class="p-grid">
    <div class="p-col-12">
      <br />
      <p-fieldset legend="Content" [toggleable]="true">
        <div class="p-grid">
          <div class="p-col-7">
            <h5>Timeframe</h5>
            <p-selectButton
              [options]="timeframes"
              [(ngModel)]="timeframeSelected"
              optionLabel="label"
              optionValue="value"
            >
            </p-selectButton>
          </div>
          <div class="p-col-5">
            <div *ngIf="timeframeSelected === 'CUSTOM'">
              <h5>Date range</h5>
              <p-calendar
                [showButtonBar]="true"
                [style]="{ width: '45%' }"
                [showIcon]="true"
                [(ngModel)]="fromDate"
                inputId="fromDate"
                placeholder="From date"
                [touchUI]="true"
              >
              </p-calendar>
              <span class="m-l-15"></span>
              <p-calendar
                [showButtonBar]="true"
                [style]="{ width: '45%' }"
                [showIcon]="true"
                [(ngModel)]="toDate"
                inputId="toDate"
                placeholder="To date"
                [touchUI]="true"
              >
              </p-calendar>
            </div>
          </div>
        </div>
      </p-fieldset>
    </div>
    <div class="p-col-12">
      <p-fieldset legend="Authors" [toggleable]="true">
        <div class="p-grid">
          <div class="p-col-4">
            <h5>Influence</h5>
            <p-selectButton
              [options]="authorInfluences"
              [(ngModel)]="authorInfluSelected"
              optionLabel="label"
              optionValue="value"
            ></p-selectButton>
          </div>
          <div class="p-col-4">
            <h5>Analysts</h5>
            <p-selectButton
              [options]="analysts"
              [(ngModel)]="analystSelected"
              optionLabel="label"
              optionValue="value"
            >
            </p-selectButton>
          </div>
          <div class="p-col-4">
            <h5>Location</h5>
            <p-selectButton
              [options]="locations"
              [(ngModel)]="locationSelected"
              optionLabel="label"
              optionValue="value"
            ></p-selectButton>
          </div>
          <div class="p-col-6">
            <h5>
              Include
              <span class="small-text"
                >(Choose authors that you want to include)</span
              >
            </h5>
            <span class="p-fluid">
              <p-autoComplete
                [forceSelection]="true"
                [(ngModel)]="selectedAuthorsInclude"
                [suggestions]="filteredAuthors"
                (completeMethod)="findAuthorsByName($event)"
                field="name"
                [multiple]="true"
                placeholder="Search authors by name.."
              >
              </p-autoComplete>
            </span>
          </div>
          <div class="p-col-6">
            <h5>
              Exclude
              <span class="small-text"
                >(Choose authors that you want to exclude)</span
              >
            </h5>
            <span class="p-fluid">
              <p-autoComplete
                [forceSelection]="true"
                [(ngModel)]="selectedAuthorsExclude"
                [suggestions]="filteredAuthors"
                (completeMethod)="findAuthorsByName($event)"
                field="name"
                [multiple]="true"
                placeholder="Search authors by name.."
              >
              </p-autoComplete>
            </span>
          </div>
        </div>
      </p-fieldset>
    </div>
    <div class="p-col-12">
      <p-fieldset legend="Outlets" [toggleable]="true">
        <div class="p-grid">
          <div class="p-col-6">
            <h5>Influence</h5>
            <p-selectButton
             [options]="outletInfluences"
             [(ngModel)]="outletInfluSelected"
             optionLabel="label"
             optionValue="value">
            </p-selectButton>
          </div>
          <div class="p-col-6"></div>
          <div class="p-col-6">
            <h5>
              Include
              <span class="small-text"
                >(Choose outlets that you want to include)</span
              >
            </h5>
            <span class="p-fluid">
              <p-autoComplete
                [forceSelection]="true"
                [(ngModel)]="selectedOutletsInclude"
                [suggestions]="filteredOutlets"
                (completeMethod)="findOutletsByName($event)"
                field="name"
                [multiple]="true"
                placeholder="Search outlets by name.."
              >
              </p-autoComplete>
            </span>
          </div>
          <div class="p-col-6">
            <h5>
              Exclude
              <span class="small-text"
                >(Choose outlets that you want to exclude)</span
              >
            </h5>
            <span class="p-fluid">
              <p-autoComplete
                [forceSelection]="true"
                [(ngModel)]="selectedOutletsExclude"
                [suggestions]="filteredOutlets"
                (completeMethod)="findOutletsByName($event)"
                field="name"
                [multiple]="true"
                placeholder="Search outlets by name.."
              >
              </p-autoComplete>
            </span>
          </div>
        </div>
      </p-fieldset>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      pRipple
      icon="pi pi-times"
      (click)="onClose()"
      label="Cancel"
      styleClass="p-button-raised p-button-secondary p-button-text"
    ></p-button>
    <p-button
      pRipple
      icon="pi pi-search"
      (click)="handleAdvanceSearch()"
      label="Search"
      class="search"
    >
    </p-button>
  </ng-template>
</p-dialog>
