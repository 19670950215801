import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FacetContent } from './facet-content.interface';
import { eq, isEmpty, includes } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import {
  loadAuthorContent,
  loadOutletContent,
  loadCompanyContent,
  loadProductContent,
  setTopicDialog,
  deleteTopicToProject,
  addTopicToProject,
  deleteCompanyToProject,
  addCompanyToProject,
  setCompanyDialog,
  addAuthorToProject,
  deleteAuthorToProject,
  addOutletToProject,
  deleteOutletToProject,
  showNotification,
  initAuthorOutletToProjectState,
  startLoading
} from 'redux/actions';
import { FacetSearch, Notification } from 'app/models';
import { CompanyService, TopicService } from 'app/services';
import { MAP_URL } from 'app/pages/topics/constants';
import {
  RECENT_SORT,
  RELEVANT_SORT,
  SPINNER_ICON,
  STAR_ICON,
  STAR_ICON_FILL
} from 'app/constants';

@Component({
  selector: 'facets-table',
  template: `
    <div class="card border" *ngIf="entity">
      <p-table
        [value]="tableItems"
        [scrollable]="true"
        scrollHeight="calc(100vh - 380px)"
        styleClass="p-datatable-sm"
      >
        <ng-template pTemplate="header">
          <tr class="p-table-tr">
            <th class="p-d-flex p-jc-between">
              <div>{{ entity.title }}</div>
              <!--              <div>{{ entity.items }}</div>-->
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-facet>
          <tr class="p-table-tr">
            <td class="p-d-flex p-jc-between">
              <div>
                <a
                  href="javascript:;"
                  role="button"
                  *ngIf="facet?.starIcon"
                  (click)="handleSaveFacetItemToProject(facet, entity.title)"
                >
                  <i [class]="facet.starIcon" style="font-size: 0.8rem"></i>
                </a>
                <a
                  href="javascript:;"
                  role="button"
                  (click)="showModalContent(entity.title, facet)"
                >
                  <span>
                    {{ facet.name }}
                  </span>
                </a>
              </div>

              <div class="total-inline">
                {{ facet.numItems | number }}
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <div class="p-d-flex p-jc-between">
            <div></div>
            <div class="p-m-2">
              <app-dashboard-facets-sort
                (sortEmitter)="sortFacetsTable($event)"
                [table]="entity"
              ></app-dashboard-facets-sort>
            </div>
            <div></div>
          </div>
        </ng-template>
      </p-table>
    </div>
  `,
  styleUrls: ['./dashboard-facets.component.scss']
})
export class DashboardFacetsTableComponent implements OnInit, OnChanges {
  @Input() entity: FacetContent;
  @Output() sortTableEmitter = new EventEmitter<any>();
  public keywords: string;
  public currentProject: any;
  public companiesList: any[];
  public outletsList: any[];
  public authorsList: any[];
  public topicsList: any[];
  public starSelected: string;
  public starUnSelected: string;
  public tableItems: any[];
  public project: any;
  public authorOutletOperation: any;
  public sort: any;
  constructor(
    private store: Store<AppState>,
    private companyService: CompanyService,
    private topicService: TopicService
  ) {
    this.keywords = '';
    this.starUnSelected = STAR_ICON;
    this.starSelected = STAR_ICON_FILL;
    this.tableItems = [];
    this.authorsList = [];
    this.outletsList = [];
    this.project = null;
    this.authorOutletOperation = {
      typeOperation: null,
      entity: null,
      itemIndex: null
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity.currentValue) {
      this.tableItems = this.entity.contents;
    }
  }

  ngOnInit() {
    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.project = projectSelected;
      }
    });

    this.store.select('topics').subscribe(({ topics }) => {
      this.topicsList = topics;
      if (!!this.entity?.contents.length) {
        if (eq(this.entity.title, 'Topics')) {
          this.tableItems = this.entity.contents.map(item => ({
            ...item,
            starIcon: topics.find(li => eq(li.name, item.name))
              ? this.starSelected
              : this.starUnSelected
          }));
        }
      }
    });

    this.store.select('companies').subscribe(({ companies }) => {
      this.companiesList = companies;
      if (!!this.entity?.contents.length) {
        if (eq(this.entity.title, 'Companies & Products')) {
          this.tableItems = this.entity.contents.map(item => ({
            ...item,
            starIcon: companies.find(li => eq(li.name, item.name))
              ? this.starSelected
              : this.starUnSelected
          }));
        }
      }
    });

    this.store
      .select('authorsOutletsList')
      .subscribe(({ authors, outlets, errors }) => {
        const { typeOperation, entity } = this.authorOutletOperation;
        if (!!this.entity?.contents.length) {
          this.authorsList = authors;
          this.outletsList = outlets;

          if (eq(this.entity.title, 'Authors')) {
            this.tableItems = this.entity.contents.map(item => ({
              ...item,
              starIcon: this.authorsList.find(li => eq(li.partyId, item.id))
                ? this.starSelected
                : this.starUnSelected
            }));
          }

          if (eq(this.entity.title, 'Outlets')) {
            this.tableItems = this.entity.contents.map(item => ({
              ...item,
              starIcon: this.outletsList.find(li => eq(li.partyId, item.id))
                ? this.starSelected
                : this.starUnSelected
            }));
          }
        }
        // handle
        if (errors?.message && typeOperation) {
          this.store.dispatch(
            showNotification({
              notify: new Notification(
                `Error ${typeOperation} ${entity} to project`,
                'error',
                'tl'
              )
            })
          );
          this.authorOutletOperation = {
            typeOperation: null,
            entity: null,
            tableItem: null
          };
          setTimeout(() => {
            this.store.dispatch(initAuthorOutletToProjectState());
          }, 500);
        }
      });

    this.store
      .select('search')
      .subscribe(({ searchBody }) => (this.keywords = searchBody?.keywords));

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.currentProject = projectSelected;
      }
    });
  }

  sortFacetsTable(value) {
    this.sortTableEmitter.emit(value);
  }

  showModalContent(entity, item) {
    if (eq(entity, 'Authors')) {
      this.getAuthorContent(item);
    }

    if (eq(entity, 'Outlets')) {
      this.getOutletContent(item);
    }

    if (eq(entity, 'Topics')) {
      this.getTopicContent(item);
    }

    if (eq(entity, 'Companies & Products')) {
      this.getCompanyContent(item);
    }

    if (eq(entity, 'Products')) {
      this.getProductContent(item);
    }

    if (eq(entity, 'People')) {
      this.getTopicContent(item);
    }

    if (eq(entity, 'Locations')) {
      this.getTopicContent(item);
    }
  }

  handleSaveFacetItemToProject(valueItem, entity) {
    if (eq(entity, 'Topics')) {
      const topicIndex = this.tableItems.findIndex(item =>
        eq(item.id, valueItem.id)
      );
      this.tableItems[topicIndex].starIcon = eq(
        valueItem.starIcon,
        this.starUnSelected
      )
        ? this.starSelected
        : this.starUnSelected;

      if (eq(this.tableItems[topicIndex].starIcon, this.starUnSelected)) {
        this.store.dispatch(
          deleteTopicToProject({
            topic: valueItem,
            userProject: this.project
          })
        );
      } else {
        this.store.dispatch(
          addTopicToProject({
            topic: valueItem,
            userProject: this.project
          })
        );
      }
    }

    if (eq(entity, 'Companies & Products')) {
      const topicIndex = this.tableItems.findIndex(item =>
        eq(item.id, valueItem.id)
      );
      this.tableItems[topicIndex].starIcon = eq(
        valueItem.starIcon,
        this.starUnSelected
      )
        ? this.starSelected
        : this.starUnSelected;

      if (eq(this.tableItems[topicIndex].starIcon, this.starUnSelected)) {
        this.store.dispatch(
          deleteCompanyToProject({
            companyId: valueItem.id,
            userProject: this.project
          })
        );
      } else {
        this.store.dispatch(
          addCompanyToProject({
            companyId: valueItem.id,
            userProject: this.project
          })
        );
      }
    }

    if (eq(entity, 'Authors')) {
      const itemIndex = this.tableItems.findIndex(item =>
        eq(item.id, valueItem.id)
      );
      this.tableItems[itemIndex].starIcon = eq(
        valueItem.starIcon,
        this.starUnSelected
      )
        ? this.starSelected
        : this.starUnSelected;

      if (eq(this.tableItems[itemIndex].starIcon, this.starUnSelected)) {
        this.authorOutletOperation = {
          typeOperation: 'deleting',
          entity: 'author',
          itemIndex: itemIndex
        };
        this.store.dispatch(
          deleteAuthorToProject({
            partyId: valueItem.id,
            userProject: this.project,
            author: valueItem
          })
        );
      } else {
        this.authorOutletOperation = {
          typeOperation: 'adding',
          entity: 'author',
          itemIndex: itemIndex
        };
        this.store.dispatch(
          addAuthorToProject({
            partyId: valueItem.id,
            userProject: this.project,
            author: valueItem
          })
        );
      }
    }

    if (eq(entity, 'Outlets')) {
      const itemIndex = this.tableItems.findIndex(item =>
        eq(item.id, valueItem.id)
      );
      this.tableItems[itemIndex].starIcon = eq(
        valueItem.starIcon,
        this.starUnSelected
      )
        ? this.starSelected
        : this.starUnSelected;

      if (eq(this.tableItems[itemIndex].starIcon, this.starUnSelected)) {
        this.authorOutletOperation = {
          typeOperation: 'deleting',
          entity: 'outlet',
          itemIndex: itemIndex
        };
        this.store.dispatch(
          deleteOutletToProject({
            partyId: valueItem.id,
            userProject: this.project,
            outlet: valueItem
          })
        );
      } else {
        this.authorOutletOperation = {
          typeOperation: 'adding',
          entity: 'outlet',
          itemIndex: itemIndex
        };
        this.store.dispatch(
          addOutletToProject({
            partyId: valueItem.id,
            userProject: this.project,
            outlet: valueItem
          })
        );
      }
    }
  }

  getAuthorContent(author) {
    const facetSearch = new FacetSearch(
      author.id,
      'author',
      this.keywords,
      'recent',
      0,
      50
    );

    this.store.dispatch(
      loadAuthorContent({
        facetSearch,
        author
      })
    );
  }

  getOutletContent(outlet) {
    const facetSearch = new FacetSearch(
      outlet.id,
      'outlet',
      this.keywords,
      'recent',
      0,
      50
    );

    this.store.dispatch(
      loadOutletContent({
        facetSearch,
        outlet
      })
    );
  }

  getCompanyContent(company) {
    const sort = /* this.keywords ? RELEVANT_SORT : */ RECENT_SORT;
    this.store.dispatch(
      setCompanyDialog({
        companyId: company.id,
        projectSelected: this.currentProject,
        keywords: this.keywords,
        sort
      })
    );
    // this.companyService.getCompanyById(company.id).subscribe(response => {
    //   const topic = {
    //     id: response.id,
    //     name: response.name,
    //     query: response?.query,
    //     implicitQuery: response?.implicitQuery,
    //     isSaved: this.companiesList.find(li => eq(li.name, response.name))
    //       ? true
    //       : false,
    //     isMyCompany: false,
    //     wikipediaUrl: response.contact?.wikipediaUrl,
    //     webSiteUrl: response.contact?.webSiteUrl,
    //     youTubeUrl: response.contact?.youTubeUrl,
    //     twitterUrl: response.contact?.twitterUrl,
    //     linkedInUrl: response.contact?.linkedInUrl,
    //     financial: response?.organizationDetail
    //       ? this.getYahooFinanceURL(response.organizationDetail?.stockTicker)
    //       : null,
    //     picture: response.contact?.imageUrl,
    //     locationUrl: response.contact?.streetAddress
    //       ? `${MAP_URL}/${response.contact.streetAddress}`
    //       : '',
    //     project: this.currentProject
    //   };
    //   let keywords = '';
    //   const { name, query, implicitQuery } = topic;
    //   if (query) {
    //     keywords = query;
    //   } else if (implicitQuery) {
    //     keywords = implicitQuery;
    //   } else {
    //     keywords = `\"${name}\"`;
    //   }

    //   const facetSearch = new FacetSearch(
    //     company.id,
    //     'company',
    //     keywords,
    //     'recent',
    //     0,
    //     50
    //   );

    //   this.store.dispatch(
    //     loadCompanyContent({
    //       facetSearch,
    //       company: topic
    //     })
    //   );
    // });
  }

  getTopicContent(topic) {
    const sort = /* this.keywords ? RELEVANT_SORT : */ RECENT_SORT;
    this.store.dispatch(startLoading());
    this.store.dispatch(
      setTopicDialog({
        topicSelected: topic,
        projectSelected: this.currentProject,
        keywords: this.keywords,
        sort,
        isSidebarTopic: false
      })
    );
  }

  getProductContent(topic) {
    this.topicService.getTopicWithContact(topic.id).subscribe(response => {
      const newTopic = {
        id: topic.id,
        name: topic.name,
        query: topic?.query,
        implicitQuery: topic?.implicitQuery,
        wikipediaUrl: response.contact?.wikipediaUrl,
        webSiteUrl: response.contact?.webSiteUrl,
        youTubeUrl: response.contact?.youTubeUrl,
        twitterUrl: response.contact?.twitterUrl,
        linkedInUrl: response.contact?.linkedInUrl,
        financial: response?.organizationDetail
          ? this.getYahooFinanceURL(response.organizationDetail?.stockTicker)
          : null,
        picture: response.contact?.imageUrl,
        locationUrl: response.contact?.streetAddress
          ? `${MAP_URL}/${response.contact.streetAddress}`
          : '',
        project: this.currentProject
      };

      const query = topic.query ? topic.query : topic.implicitQuery;
      const keywords = `(${query}) AND (${this.keywords})`;
      const facetSearch = new FacetSearch(
        topic.id,
        'topic',
        keywords,
        'recent',
        0,
        50
      );

      this.store.dispatch(
        loadProductContent({
          facetSearch,
          product: newTopic
        })
      );
    });
  }

  getYahooFinanceURL(value: any) {
    if (isEmpty(value)) {
      return null;
    } else if (includes(value, 'http')) {
      const q = value.substring(value.lastIndexOf('/') + 1, value.length);
      return `https://finance.yahoo.com/quote/${q}`;
    } else {
      return `https://finance.yahoo.com/quote/${value}`;
    }
  }
}
