import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent
} from 'ngx-perfect-scrollbar';

import {
  head,
  filter,
  includes,
  isUndefined,
  isNull,
  isEmpty,
  eq
} from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { Subscription } from 'rxjs/Subscription';
import * as actions from 'redux/actions';
import { CategoryService, AuthenticationService } from 'app/services';
import { UserSession, Project, Notification } from 'app/models';
import { RECENT_SORT } from 'app/constants';

@Component({
  selector: 'app-topics-sidebar',
  template: `
    <app-topics-list
      (createNewTopicOnclick)="createNewTopic()"
      (editTopicOnclick)="openTopicManagerModal($event)"
      (selectedTopicOnClick)="onSelectTopic($event)"
    ></app-topics-list>
  `
})
export class SidebarTopicsContentComponent implements OnInit, OnDestroy {
  public accountId: any;
  public projectId: any;
  public userId: any;
  public currentTopic: number;
  public projects: Project[] = [];
  public projectSelectedItem: Project;
  private projectsSub: Subscription;
  private projectSub: Subscription;
  private toggleStoreSub: Subscription;
  private topicsSub: Subscription;
  private topicSub: Subscription;
  private topicCardsSub: Subscription;
  private sessionSub: Subscription;
  public loadingProjects: boolean;
  public formProject: FormGroup;
  public currentProject: any;
  public sortTopicBy: string;
  public avoidSelectCard: boolean;
  public projectLabel: string;
  public userSession: UserSession;
  public numberOfProjects: number;
  public maxProjects: any;
  public saveSearchAsTopicData: any;
  public config: PerfectScrollbarConfigInterface = {};
  public keywords: string;
  public prevIconAfterLoading: string;

  @ViewChild('topicsSidebarPerfectScroll', { static: false })
  topicsSidebarPerfectScroll: PerfectScrollbarComponent;

  constructor(
    private authService: AuthenticationService,
    private categoryService: CategoryService,
    private store: Store<AppState>,
    private formBuilder: FormBuilder
  ) {
    this.saveSearchAsTopicData = null;
    this.avoidSelectCard = false;
    this.sortTopicBy = 'category';
    this.projectLabel = this.authService.getProjectLabel();
    this.maxProjects = this.authService.getMaxProjects();
    this.userId = this.authService.getUserId();
    this.accountId = this.authService.getAccountId();
    this.keywords = null;
    this.keywords = '';
  }

  ngOnInit() {
    this.createReactiveForm(null);
    this.getProjectsSelector();
    this.userSessionSelector();
    this.saveSearchAsTopic();
    this.projectSelector();
    this.store.select('search').subscribe(({ searchBody }) => {
      if (searchBody?.keywords) {
        this.keywords = searchBody.keywords;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.projectSub) {
      this.projectSub.unsubscribe();
    }
    if (this.projectsSub) {
      this.projectsSub.unsubscribe();
    }
    if (this.topicsSub) {
      this.topicsSub.unsubscribe();
    }
    if (this.toggleStoreSub) {
      this.toggleStoreSub.unsubscribe();
    }
    if (this.topicCardsSub) {
      this.topicCardsSub.unsubscribe();
    }
    if (this.sessionSub) {
      this.sessionSub.unsubscribe();
    }
    if (this.topicSub) {
      this.topicSub.unsubscribe();
    }
  }

  getProjectsSelector() {
    // [select] Load Projects
    this.projectsSub = this.store
      .select('projects')
      .subscribe(({ projects, action }) => {
        if (
          eq(action, 'setProjectsSuccess') ||
          eq(action, 'addProjectSuccess')
        ) {
          if (projects.length > 0) {
            this.numberOfProjects = this.projects.length;
            this.projects = projects;
            this.projectSelectedItem = head(this.projects);
            this.projectId = this.projectSelectedItem.value;
            this.store.dispatch(
              actions.setProject({
                id: this.projectId,
                name: this.projectSelectedItem?.name
              })
            );
            this.getTopics();
          } else {
            this.projects = [];
            this.projectId = null;
            this.store.dispatch(actions.unSetTopicCards());
            this.store.dispatch(actions.unSetProject());

            // this.onCreateProject();
          }
        }
      });
  }

  getTopics() {
    this.topicsSub = this.store
      .select('topics')
      .subscribe(({ topics, error }) => {
        if (topics.length > 0) {
          // Save topics for cards on redux on the store
          this.store.dispatch(
            actions.setTopicCards({
              topicCards: topics
            })
          );
        } else {
          this.store.dispatch(
            actions.setTopicCards({
              topicCards: []
            })
          );
        }
        if (error) {
          this.store.dispatch(
            actions.showNotification({
              notify: new Notification(
                `Error fetching topics: ${error.message}`,
                'success',
                'tl'
              )
            })
          );
        }
      });
  }

  saveSearchAsTopic() {
    this.store.select('search').subscribe(({ searchBody, saveAsTopic }) => {
      if (saveAsTopic) {
        this.saveSearchAsTopicData = {
          query: searchBody.keywords,
          isSaveAsTopic: saveAsTopic
        };
        this.createNewTopic();
      }
    });
  }

  toggleEmailAlertSelector() {
    this.toggleStoreSub = this.store
      .select('alertToggle')
      .subscribe(({ toggle }) => {
        this.formProject
          .get('emailAlerts')
          .setValue(toggle ? toggle.active : false);
      });
  }

  userSessionSelector() {
    this.sessionSub = this.store.select('session').subscribe(({ session }) => {
      this.userSession = session;
      if (session) {
        this.toggleEmailAlertSelector();
      }
    });
  }

  projectSelector() {
    this.topicSub = this.store
      .select('project')
      .subscribe(
        ({
          projectId,
          projectName,
          company,
          changeProject,
          createProject,
          projectSelected
        }) => {
          if (changeProject) {
            this.changeProject(projectSelected);
          }

          if (createProject) {
            this.handleCreateProject();
          }
        }
      );
  }

  createReactiveForm(value) {
    this.formProject = this.formBuilder.group({
      project: [value],
      emailAlerts: [false]
    });
  }

  isMaxProjects(): boolean {
    if (!isUndefined(this.maxProjects) && !isNull(this.maxProjects)) {
      if (this.numberOfProjects >= parseInt(this.maxProjects, 0)) {
        return true;
      }
    }
    return false;
  }

  changeEmailAlerts(e) {
    if (e) {
      this.categoryService.activeIncludeInEmailAlerts(this.projectId).subscribe(
        (response: any) => {
          console.log(response);
        },
        ({ error }) => {
          console.log(error);
        }
      );
    } else {
      this.categoryService.deleteIncludeInEmailAlerts(this.projectId).subscribe(
        (response: any) => {
          console.log(response);
        },
        ({ error }) => {
          console.log(error);
        }
      );
    }
  }

  public onSelectTopic(topic) {
    const sort = /* this.keywords ? RELEVANT_SORT : */ RECENT_SORT;
    this.store.dispatch(
      actions.setTopicDialog({
        topicSelected: topic,
        projectSelected: this.currentProject,
        keywords: null,
        sort,
        isSidebarTopic: true
      })
    );
  }

  getYahooFinanceURL(value: any) {
    if (isEmpty(value)) {
      return null;
    } else if (includes(value, 'http')) {
      const q = value.substring(value.lastIndexOf('/') + 1, value.length);
      return `https://finance.yahoo.com/quote/${q}`;
    } else {
      return `https://finance.yahoo.com/quote/${value}`;
    }
  }

  getProjectLabel(): string {
    return this.authService.getProjectLabel();
  }

  public handleCreateProject() {
    this.store.dispatch(actions.showProjectCreateSection());
  }

  changeProject(project: any) {
    this.currentProject = project;
    this.projectId = this.currentProject.id;
  }

  openTopicManagerModal(e) {
    this.store.dispatch(actions.showTopicDialog({ topic: e }));
  }

  public cancelCreateProject() {
    window.location.href = this.authService.getSearchUrl(null);
  }

  public createNewTopic() {
    this.store.dispatch(actions.showTopicDialog({ topic: null }));
  }
}
