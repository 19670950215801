import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, includes, lowerCase, eq, head } from 'lodash';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { ConfirmationService } from 'primeng/api';
import { ProjectService } from 'app/services';
import { Notification, Project } from 'app/models';

@Component({
  selector: 'app-select-project',
  templateUrl: './select-project.component.html',
  styles: [
    `
      :host ::ng-deep .p-dialog .p-dialog-header {
        border-bottom: 1px solid #e0dcdc !important;
      }

      :host ::ng-deep .p-dialog .p-dialog-content {
        padding-top: 10px;
      }

      .box-button-right {
        float: right;
        display: block;
      }

      .hidden {
        display: none;
      }

      .create {
        margin-top: 0.5rem !important;
      }

      :host ::ng-deep {
        .p-button:focus {
          box-shadow: none;
        }

        .p-inputtext:focus {
          box-shadow: none;
        }

        .p-button {
          background: #465675;
          border-color: transparent;
        }

        .p-button:hover {
          background: #4e5f80;
          border-color: #4e5f80;
        }
      }

      .openDw {
        width: 100%;
        height: 350px;
      }

      .closeDw {
        width: 100%;
        height: 100px;
      }
    `
  ],
  providers: [ConfirmationService]
})
export class SelectProjectComponent implements OnInit {
  public showModal: boolean;
  public isOpenProjectDw: boolean;
  public projects: Project[];
  public projectList: Project[];
  public projectSelected: Project;
  public showCreateProject: boolean;
  public showEditProject: boolean;
  public userId: any;
  public accountId: any;
  public title: string;
  public nextProjectSelected: Project;
  @ViewChild('searchProject') searchProject: ElementRef;
  @ViewChild('projectNameInput') projectNameInput: ElementRef;

  constructor(
    private store: Store<AppState>,
    private confirmationService: ConfirmationService,
    private projectService: ProjectService
  ) {
    this.projects = [];
    this.projectList = [];
    this.projectSelected = null;
    this.showCreateProject = false;
    this.showEditProject = false;
    this.userId = null;
    this.accountId = null;
    this.title = 'Project Settings';
    this.isOpenProjectDw = false;
  }

  ngOnInit(): void {
    this.store.select('userData').subscribe(({ data }) => {
      if (data) {
        this.userId = data.userId;
        this.accountId = data.accountId;
      }
    });

    this.store
      .select('ui')
      .subscribe(
        ({
          showSelectProjectModal,
          showProjectSettingstSection,
          showProjectCreateSection
        }) => {
          this.showModal = showSelectProjectModal;
          this.showEditProject = showProjectSettingstSection;
          this.showCreateProject = showProjectCreateSection;
          if (showSelectProjectModal) {
            this.title = 'Project Settings';
            this.projectList = this.projects;
          }

          if (showProjectSettingstSection) {
            this.title = 'Edit Project';
          }

          if (showProjectCreateSection) {
            this.title = 'Create Project';
          }
        }
      );

    this.store.select('projects').subscribe(({ projects, action }) => {
      if (!!projects.length) {
        this.projects = projects;
        this.projectSelected = projects.find(item => item.selected);
      }

      if (eq(action, 'deleteProjectSuccess')) {
        this.store.dispatch(
          actions.showNotification({
            notify: new Notification(
              'Project has been deleted',
              'success',
              'tl'
            )
          })
        );
        this.handleSelectNextProject();
      }

      if (eq(action, 'deleteProjectError')) {
        this.store.dispatch(
          actions.showNotification({
            notify: new Notification('Error deleting project', 'error', 'tl')
          })
        );
      }
    });
  }

  public handleCreateProject() {
    this.isOpenProjectDw = false;
    this.store.dispatch(actions.showProjectCreateSection());
  }

  public openProjectSettings() {
    this.isOpenProjectDw = false;
    this.store.dispatch(actions.showProjectSettingsSection());
  }

  public openAlertSettings() {
    this.isOpenProjectDw = false;
    this.store.dispatch(actions.showAlertSettingsModal());
  }

  public handleCancelProject() {
    this.showCreateProject = false;
  }

  public handleSelectNextProject() {
    if (!!this.nextProjectSelected) {
      this.store.dispatch(
        actions.projectChange({
          projectSelected: this.nextProjectSelected
        })
      );

      this.projectService.saveProjec({
        ...this.nextProjectSelected
      });
      this.store.dispatch(
        actions.projectChange({
          projectSelected: this.nextProjectSelected
        })
      );
    } else {
      this.handleCreateProject();
    }
  }

  showDwOptions() {
    this.isOpenProjectDw = true;
  }

  closeDwOptions() {
    this.isOpenProjectDw = false;
  }

  public selectOneItem({ value }) {
    const projectSelected = { ...value, selected: true };
    this.projectService.saveProjec(projectSelected);
    this.store.dispatch(
      actions.setProjectsSuccess({
        projectId: value.value,
        projects: this.projects.map(
          item =>
            new Project({
              ...item,
              selected: false
            })
        )
      })
    );
    this.store.dispatch(actions.startProjectChanged());
    this.store.dispatch(actions.projectChange({ projectSelected }));
    this.store.dispatch(actions.loadAuthorsOutlets({ projectId: value.value }));
    // this.onClose();
  }

  public handleSelecProject(project) {
    const projectSelected = { ...project, selected: true };
    this.projectService.saveProjec(projectSelected);
    this.store.dispatch(
      actions.setProjectsSuccess({
        projectId: project.value,
        projects: this.projects.map(
          item =>
            new Project({
              ...item,
              selected: false
            })
        )
      })
    );
    this.store.dispatch(actions.startProjectChanged());
    this.store.dispatch(actions.projectChange({ projectSelected }));
    this.store.dispatch(
      actions.loadAuthorsOutlets({ projectId: project.value })
    );
    this.onClose();
  }

  public handleSearch(value) {
    this.projects = filter(this.projectList, item =>
      includes(lowerCase(item.name), lowerCase(value))
    );
  }

  deleteProject() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      header: 'Delete project',
      icon: 'pi pi-exclamation-triangle',
      key: 'deleteProjectConfirmation',
      accept: () => {
        const nextProject = head(
          filter(
            this.projectList,
            item =>
              !eq(lowerCase(item.name), lowerCase(this.projectSelected.name))
          )
        );

        this.nextProjectSelected = { ...nextProject, selected: true };
        this.store.dispatch(
          actions.deleteProject({
            project: this.projectSelected,
            nextProject: this.nextProjectSelected
          })
        );
      },
      reject: type => {
        // handle rejected option ConfirmEventType.CANCEL
      }
    });
  }

  public clearInput() {
    this.projects = this.projectList;
    this.searchProject.nativeElement.value = '';
    this.searchProject.nativeElement.focus();
  }

  public onClose() {
    this.isOpenProjectDw = false;
    this.store.dispatch(actions.hideSelectProjectModal());
    this.store.dispatch(actions.hideProjectCreateSection());
    this.store.dispatch(actions.hideProjectSettingsSection());
  }
}
