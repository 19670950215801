import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { head, eq, find } from 'lodash';
import { AuthorService, SearchService } from 'services/index';
import { FacetSearch, Notification } from 'app/models';
import { Paginator } from 'primeng/paginator';
import { OutletService } from 'app/services';
import * as actions from 'redux/actions';
import { Router } from '@angular/router';
import { MAP_URL } from 'app/pages/topics/constants';
import {
  Content,
  SPINNER_ICON,
  STAR_ICON,
  STAR_ICON_FILL
} from 'app/constants';

@Component({
  selector: 'facet-author-content',
  templateUrl: './facet-author-content.component.html',
  styleUrls: ['./facet-author-content.component.scss']
})
export class FacetAuthorContentComponent implements OnInit, OnChanges {
  @Input() contentData: any;
  public search: FacetSearch;
  public authorContentPage: number;
  public authorContentTotalPages: number;
  public authorContentPageSize: number;
  public authorContentTotal: number;
  public currentPage: number;
  public loading: boolean;

  public author: any;
  public contentItems: any[] = [];
  public keywords: string;
  public sortSelected: string;
  public hideFollowing: boolean;
  public starToggle: string;
  public authorsOutletsList: any[];
  public project: any;
  public isAuthorSaved: boolean;
  public actionType: string;

  @ViewChild('pp', { static: false }) paginator: Paginator;
  public parentName: Content;

  constructor(
    private authorService: AuthorService,
    private searchService: SearchService,
    private outletService: OutletService,
    private router: Router,
    private store: Store<AppState>
  ) {
    this.parentName = Content.Author;
    this.sortSelected = 'recent';
    this.loading = false;
    this.keywords = null;
    this.starToggle = STAR_ICON;
    this.isAuthorSaved = false;
    this.authorsOutletsList = [];
    this.project = null;
    this.actionType = null;
  }

  ngOnInit() {
    this.hideFollowing = false;
    this.authorContentPage = 1;
    this.currentPage = 0;

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.project = projectSelected;
      }
    });

    this.store.select('search').subscribe(({ searchBody }) => {
      if (searchBody?.keywords) {
        this.keywords = searchBody.keywords;
      }
    });

    this.store
      .select('authorsOutletsList')
      .subscribe(
        ({
          authors,
          isAddingAuthorOutlet,
          isDeletingAuthorOutlet,
          hasAuthorOutletDeleted,
          hasAuthorOutletAdded,
          errors
        }) => {
          this.authorsOutletsList = authors;

          if (isAddingAuthorOutlet || isDeletingAuthorOutlet) {
            this.starToggle = SPINNER_ICON;
          }

          if (hasAuthorOutletAdded) {
            this.isAuthorSaved = true;
            this.starToggle = STAR_ICON_FILL;
          }

          if (hasAuthorOutletDeleted) {
            this.isAuthorSaved = false;
            this.starToggle = STAR_ICON;
          }

          if (errors?.message) {
            this.store.dispatch(
              actions.showNotification({
                notify: new Notification(
                  `Error ${this.actionType} author to project`,
                  'error',
                  'tl'
                )
              })
            );
            this.setAuthorSaved();
            setTimeout(() => {
              this.store.dispatch(actions.initAuthorOutletToProjectState());
            }, 500);
          }
        }
      );
  }

  ngOnChanges(changes: any) {
    const {
      author,
      contentItems,
      facetSearch
    } = changes.contentData?.currentValue;
    const outlet = head(contentItems.content)?.outlet;
    const outletId = head(contentItems.content)?.outletId;
    this.author = {
      ...author,
      outletId,
      outlet,
      picture: this.authorService.getAuthorPicture(author.id)
    };

    this.getAuthorContactDetails(author.id);
    this.search = facetSearch;
    this.authorContentTotalPages = contentItems.totalPages;
    this.authorContentTotal = contentItems.totalElements;
    this.contentItems = contentItems.content;
    this.authorContentPageSize = this.search.getSize;
    this.currentPage = this.search.getPage;
    this.setAuthorSaved();
    this.resetPaginator();
  }

  public goToExploreRelatedCoverage(outlet) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/author/${outlet.id}`])
    );
    window.open(url, '_blank');
  }

  public sortChange(e) {
    this.sortSelected = e;
    this.authorContentPage = 1;
    this.currentPage = 0;
    this.resetPaginator();
    this.getAuthorContent();
  }

  getPage(e: any) {
    this.authorContentPage = e.page + 1;
    this.currentPage = e.page;
    this.getAuthorContent();
  }

  getAuthorContent() {
    const facetSearch = new FacetSearch(
      this.search.getId,
      this.search.getSearchBy,
      this.search.getKeywords,
      this.sortSelected,
      this.currentPage,
      this.search.getSize
    );

    this.searchService.searchContent(facetSearch).subscribe(response => {
      this.authorContentTotalPages = response.totalPages;
      this.authorContentTotal = response.totalElements;
      this.contentItems = response.content;
      // this.scrollTop();
    });
  }

  getAuthorContactDetails(id) {
    this.authorService.getAuthorById(id).subscribe(({ contact }) => {
      this.author = {
        ...this.author,
        emailAddress: contact?.emailAddress,
        contactUrl: contact?.contactUrl,
        twitter: contact?.twitterUrl,
        linkedIn: contact?.linkedInUrl,
        location: `${contact?.city}, ${contact?.state}`,
        locationUrl: contact?.city
          ? `${MAP_URL}/${
              contact.streetAddress ? contact.streetAddress : ''
            }, ${contact.city}, ${contact.state}`
          : ''
      };
    });
  }

  onDisplayOutletContent(outletId) {
    this.outletService.getOutletById(outletId).subscribe(
      response => {
        const contact = response?.contact || null;
        const website = contact?.webSiteUrl;
        const twitter = contact?.twitterUrl;
        const outlet = {
          id: outletId,
          name: response.name,
          website: website ? (eq(website, 'cbf') ? null : website) : null,
          twitter: twitter ? (eq(twitter, 'cbf') ? null : twitter) : null,
          picture: this.outletService.getOutletPicture(outletId)
        };

        const facetSearch = new FacetSearch(
          outletId,
          'outlet',
          this.keywords,
          'recent',
          0,
          50
        );

        this.store.dispatch(
          actions.loadOutletContent({
            facetSearch,
            outlet
          })
        );
      },
      error => {
        this.store.dispatch(
          actions.showNotification({
            notify: new Notification('Outlet not found', 'info', 'tl')
          })
        );
      }
    );
  }

  resetPaginator() {
    if (this.paginator) {
      this.paginator.changePage(0);
    }
  }

  private setAuthorSaved() {
    setTimeout(() => {
      this.isAuthorSaved = !!find(this.authorsOutletsList, i =>
        eq(i.partyId, this.author.id)
      );
      this.starToggle = this.isAuthorSaved ? STAR_ICON_FILL : STAR_ICON;
    }, 500);
  }

  handleSaveAuthor(author) {
    if (this.isAuthorSaved) {
      this.actionType = 'deleting';
      this.store.dispatch(
        actions.deleteAuthorToProject({
          partyId: author.id,
          userProject: this.project,
          author
        })
      );
    } else {
      this.actionType = 'adding';
      this.store.dispatch(
        actions.addAuthorToProject({
          partyId: author.id,
          userProject: this.project,
          author
        })
      );
    }
  }

  openReportMissingDataModal() {
    this.store.dispatch(actions.showReportDialog());
  }
}
