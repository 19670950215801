<div id="chart" *ngIf="sovChartValues?.chartData">
  <label style="font-size: medium">
    Coverage of <strong>{{ sovChartValues.chartLabels[0] }}</strong>
  </label>
  <div *ngIf="sovChartValues?.query">
    <small [style]="sovChartValues.query.color">{{
      sovChartValues.query.value
    }}</small>
  </div>
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [labels]="chartOptions.labels"
    [legend]="chartOptions.legend"
    [title]=""
    [colors]="chartOptions.colors"
    [theme]="chartOptions.theme"
    [responsive]="chartOptions.responsive"
    [tooltip]="chartOptions.tooltip"
    [dataLabels]="chartOptions.dataLabels"
  ></apx-chart>
  <div>
    {{ sovChartValues.chartData[1] / sovChartValues.chartDataTotal | percent }}
    mentions <b>{{ sovChartValues.chartLabels[1] }}</b>
  </div>
  <div class="p-text-italic">
    <small>
      {{ sovChartValues.chartData[1] | number }} out of
      {{ sovChartValues.chartDataTotal | number }} articles in the past year
    </small>
  </div>
</div>
