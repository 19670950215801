import { createReducer, on } from '@ngrx/store';
import { RECENT_SORT } from 'app/constants';
import { TopicResponse } from 'app/models';
import {
  setTopicDialog,
  setTopicDialogSuccess,
  setTopicDialogError,
  unSetTopicDialog
} from 'redux/actions';

export interface TopicDialogState {
  topicSelected: TopicResponse;
  topic: TopicResponse;
  loaded: boolean;
  loading: boolean;
  error: any;
  sort: string;
  keywords: string;
  isSidebarTopic: boolean;
}

const initialState: TopicDialogState = {
  topicSelected: null,
  topic: null,
  loaded: false,
  loading: false,
  error: null,
  sort: RECENT_SORT,
  keywords: null,
  isSidebarTopic: false
};

const _topicDialogReducer = createReducer(
  initialState,

  on(
    setTopicDialog,
    (state, { topicSelected, sort, keywords, isSidebarTopic }) => ({
      ...state,
      topic: null,
      loading: true,
      loaded: false,
      topicSelected,
      sort,
      keywords,
      isSidebarTopic
    })
  ),

  on(setTopicDialogSuccess, (state, { topic }) => ({
    ...state,
    topic,
    loading: false,
    loaded: true
  })),

  on(setTopicDialogError, (state, { payload }) => ({
    ...state,
    topic: null,
    loading: false,
    loaded: false,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  })),

  on(unSetTopicDialog, () => ({
    ...initialState
  }))
);

export function topicDialogReducer(state, action) {
  return _topicDialogReducer(state, action);
}
