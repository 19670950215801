import { createReducer, on } from '@ngrx/store';
import {
  setProjects,
  unSetProjects,
  setProjectsSuccess,
  setProjectsError,
  addProject,
  addProjectSuccess,
  addProjectError,
  deleteProject,
  deleteProjectSuccess,
  deleteProjectError,
  editProject,
  editProjectSuccess,
  editProjectError,
  projectChange
} from 'redux/actions';
import { Project } from 'app/models';
import { orderBy, eq, reverse, isEmpty } from 'lodash';

export interface ProjectsState {
  projects: Project[];
  newProject: Project;
  loaded: boolean;
  loading: boolean;
  error: any;
  action: string;
}

const initialState: ProjectsState = {
  projects: [],
  newProject: null,
  loaded: false,
  loading: false,
  error: null,
  action: null
};

const _projectsReducer = createReducer(
  initialState,
  on(setProjects, (state, { userId }) => ({
    ...state,
    action: 'setProjects',
    userId,
    loading: true
  })),

  on(setProjectsSuccess, (state, { projectId, projects }) => {
    const sortedProjects = orderBy(projects, ['name'], ['asc']);
    const id = eq(projectId, 0) ? sortedProjects[0].value : projectId;
    const projectx = sortedProjects.map(
      item =>
        new Project({
          ...item,
          selected: eq(item.value, id)
        })
    );

    return {
      ...state,
      action: 'setProjectsSuccess',
      projects: reverse(
        orderBy(projectx, ['selected', 'name'], ['asc', 'desc'])
      ),
      loading: false,
      loaded: true
    };
  }),

  on(setProjectsError, (state, { payload }) => ({
    ...state,
    action: 'setProjectsError',
    loading: false,
    loaded: false,
    error: {
      ...payload
    }
  })),

  on(unSetProjects, state => ({
    ...state,
    action: 'unSetProjects',
    projects: []
  })),

  on(addProject, (state, { accountId, newProjectName }) => ({
    ...state,
    action: 'addProject',
    accountId,
    newProjectName,
    newProject: null
  })),

  on(addProjectSuccess, (state, { project }) => {
    const resetProjects = state.projects.map(item => {
      return new Project({
        ...item,
        selected: false
      });
    });

    const projects = [...resetProjects, project];

    return {
      ...state,
      action: 'addProjectSuccess',
      projects: reverse(
        orderBy(projects, ['selected', 'name'], ['asc', 'desc'])
      ),
      newProject: project
    };
  }),

  on(addProjectError, (state, { payload }) => ({
    ...state,
    action: 'addProjectError',
    newProject: null,
    error: {
      ...payload
    }
  })),

  on(editProject, state => ({
    ...state,
    action: 'editProject'
  })),

  on(editProjectSuccess, (state, { project }) => {
    const filterProjects = state.projects.filter(
      item => !eq(item.value, project.value)
    );
    const projects = [project, ...filterProjects];

    return {
      ...state,
      action: 'editProjectSuccess',
      projects: reverse(
        orderBy(projects, ['selected', 'name'], ['asc', 'desc'])
      )
    };
  }),

  on(editProjectError, (state, { payload }) => ({
    ...state,
    action: 'editProjectError',
    error: {
      ...payload
    }
  })),

  on(deleteProject, state => ({
    ...state,
    action: 'deleteProject'
  })),

  on(deleteProjectSuccess, (state, { project, nextProject }) => {
    const deleteProjects1 = state.projects.filter(
      item => !eq(item.value, project.value)
    );

    const deleteProjects2 = deleteProjects1.filter(
      item => !eq(item.value, nextProject.value)
    );
    const projects = [nextProject, ...deleteProjects2];
    return {
      ...state,
      action: 'deleteProjectSuccess',
      projects: reverse(
        orderBy(projects, ['selected', 'name'], ['asc', 'desc'])
      )
    };
  }),

  on(deleteProjectError, (state, { payload }) => ({
    ...state,
    action: 'deleteProjectError',
    error: {
      ...payload
    }
  }))
);

export function projectsReducer(state, action) {
  return _projectsReducer(state, action);
}
