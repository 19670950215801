<div *ngIf="showCreateSection">
  <div class="p-fluid">
    <div class="p-field">
      <label for="name">Project Name*</label>
      <input
        (keydown.enter)="handleSaveProject(projectNameInput.value)"
        #projectNameInput
        type="text"
        pInputText
        placeholder="Add project name"
        [disabled]="isSaving"
      />
    </div>
  </div>
  <div class="box-button-right">
    <p-button
      [disabled]="!projectNameInput.value || isSaving"
      [icon]="isSaving ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      type="button"
      [label]="isSaving ? 'Saving' : 'Save'"
      class="p-mr-2 create"
      (click)="handleSaveProject(projectNameInput.value)"
    ></p-button>
    <p-button
      icon="pi pi-times"
      type="button"
      label="Close"
      styleClass="p-button-raised p-button-secondary p-button-text"
      (click)="handleCancelProject()"
    ></p-button>
  </div>
</div>
