import { createAction, props } from '@ngrx/store';
import { TopicCard, TopicResponse } from 'app/models';

export const setTopicCards = createAction(
  '[Topic Card] setTopicCards',
  props<{ topicCards: TopicResponse[] }>()
);

export const addTopicCard = createAction(
  '[Topic Card] addTopicCard',
  props<{ topic: TopicCard }>()
);

export const updateTopicCard = createAction(
  '[Topic Card] updateTopicCard',
  props<{ topic: TopicCard }>()
);

export const removeTopicCard = createAction(
  '[Topic Card] removeTopicCard',
  props<{ id: number }>()
);

export const unSetTopicCards = createAction('[Topic Card] unSetTopicCards');

export const resetAllTopicSelected = createAction(
  '[Topic Card] resetAllTopicSelected'
);
