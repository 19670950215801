import { createAction, props } from '@ngrx/store';
import { Search } from 'app/models';

export const setCoverages = createAction(
  '[Coverages] setCoverages',
  props<{
    searchBody: Search;
    topicId: number;
    sort: string;
    page: number;
    size: number;
    isSearch: boolean;
  }>()
);

export const setCoveragesSuccess = createAction(
  '[Coverages] setCoveragesSuccess',
  props<{ contents: any }>()
);

export const setCoveragesError = createAction(
  '[Coverages] setCoveragesError',
  props<{ payload: any }>()
);

export const unSetCoverages = createAction('[Coverages] unSetCoverages');
