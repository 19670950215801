import { createAction, props } from '@ngrx/store';
import { Search } from 'app/models';

export const setFacetsSearch = createAction(
  '[Facets Search] setFacetsSearch',
  props<{
    searchBody: Search;
    page?: number;
    size?: number;
    isNewSearch: boolean;
  }>()
);

export const setFacetsSearchSuccess = createAction(
  '[Facets Search] setFacetsSearchSuccess',
  props<{ contents: any }>()
);

export const setFacetsSearchError = createAction(
  '[Facets Search] setFacetsSearchError',
  props<{ payload: any }>()
);
