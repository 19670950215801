<a
  ngbDropdownToggle
  class="nav-link waves-effect waves-dark"
  role="button"
  data-toggle="dropdown"
  aria-haspopup="false"
  aria-expanded="false"
  style="top: 10px"
>
  <i class="{{ icon }}"></i><span class="font-18">{{ navItem }}</span>
</a>
<div class="dropdown-menu dropdown-menu-right">
  <span class="">
    <span class="bg-custom"></span>
  </span>
  <ul class="notification-list">
    <li>
      <!-- Title -->
      <div class="drop-title bg-custom custom-padding text-white">
        <h4 class="m-b-0">{{ title }}</h4>
        <span class="font-light" [innerHTML]="subTitle"></span>
      </div>
    </li>
    <li>
      <div class="message-center notifications">
        <!-- Message -->
        <div class="message-item">
          <span class="mail-contnet">
            <span class="mail-desc" [innerHTML]="message"></span>
          </span>
        </div>
        <!-- Image -->
        <div class="text-center custom-padding">
          <img src="{{ imageUrl }}" />
        </div>
      </div>
    </li>
    <li>
      <!-- Footer -->
      <div class="line"></div>
      <div class="nav-link text-right p-r-10">
        <button
          pButton
          type="button"
          class="p-button-sm"
          label="Close"
        ></button>
      </div>
    </li>
  </ul>
</div>
