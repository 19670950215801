import { createAction, props } from '@ngrx/store';

// Create Topic

export const createTopic = createAction(
  '[Topic] Create Topic',
  props<{ topic: any; userId: number; userProject: any }>()
);

export const createTopicSuccess = createAction(
  '[Topic] Create Topic Success',
  props<{ topic: any }>()
);

export const createTopicFail = createAction(
  '[Topic] Create Topic Fail',
  props<{ payload: any }>()
);

// Update Topic

export const updateTopic = createAction(
  '[Topic] Update Topic',
  props<{ topic: any; userTopicId: number; userId: number; userProject: any }>()
);

export const updateTopicSuccess = createAction(
  '[Topic] Update Topic Success',
  props<{ topic: any }>()
);

export const updateTopicFail = createAction(
  '[Topic] Update Topic Fail',
  props<{ payload: any }>()
);

// Delete Topic

export const deleteTopic = createAction(
  '[Topic] Delete Topic',
  props<{ topicId: number; userId: number; userProject: any }>()
);

export const deleteTopicSuccess = createAction(
  '[Topic] Delete Topic Success',
  props<{ topic: any }>()
);

export const deleteTopicFail = createAction(
  '[Topic] Delete Topic Fail',
  props<{ payload: any }>()
);

export const resetTopicState = createAction('[Topic] Reset Topic State');

// Topic Manager Dialog

export const showTopicDialog = createAction(
  '[Topic] showTopicDialog',
  props<{ topic: any }>()
);

export const hideTopicDialog = createAction('[Topic] hideTopicDialog');
