<div class="media m-t-1">
  <div class="media-body">
    <div class="p-d-flex p-jc-between">
      <div>
        <!-- Header title -->
        <div class="p-d-flex">
          <div class="p-mr-2">
            <h2 id="outletTitle" class="mt-4">{{ outlet.name }}</h2>
          </div>
          <div class="p-mr-2 p-mt-4" *ngIf="!hideFollowing">
            <button
              (click)="handleSaveOutlet(outlet)"
              pButton
              pRipple
              type="button"
              [icon]="starToggle"
              class="p-button-rounded p-button-secondary p-button-text"
            ></button>
          </div>
        </div>
        <!-- Author associated (TODO TN-483) -->
        <!--        <ul-->
        <!--          class="nav customizer-tab"-->
        <!--          style="padding: 0px; background: rgb(255, 255, 255);"-->
        <!--        >-->
        <!--          <li>-->
        <!--            <div class="row">-->
        <!--              <div class="col">-->
        <!--                <h5>-->
        <!--                  <a-->
        <!--                    href="javascript:;"-->
        <!--                    role="button"-->
        <!--                    (click)="onDisplayAuthorContent(outlet.authorId)"-->
        <!--                    >{{ outlet.author }}</a-->
        <!--                  >-->
        <!--                </h5>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </li>-->
        <!--        </ul>-->
        <!-- Social media contacts -->
        <ul class="nav">
          <div class="row align-items-end">
            <!--
            <li *ngIf="outlet.contact?.emailAddress">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a
                    href="mailto:{{ outlet.contact?.emailAddress }}"
                    [target]="'_blank'"
                  >
                    <i class="pi pi-envelope font-17 m-r-3"></i>
                    {{ outlet.contact?.emailAddress }}
                  </a>
                </div>
              </div>
            </li>
            -->
            <li *ngIf="outlet.contact?.webSiteUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="outlet.contact?.webSiteUrl" [target]="'_blank'">
                    <i class="pi pi-globe font-17 m-r-3"></i>
                    Website
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="outlet.contact?.twitter">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="outlet.contact?.twitterUrl" [target]="'_blank'">
                    <i class="pi pi-twitter font-17 m-r-3"></i>
                    Twitter
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="outlet.contact?.linkedIn">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="outlet.contact?.linkedInUrl" [target]="'_blank'">
                    <i class="pi pi-linkedin font-17 m-r-3"></i>
                    LinkedIn
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="outlet.contact?.youTube">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="outlet.contact?.youTubeUrl" [target]="'_blank'">
                    <i class="pi pi-youtube font-17 m-r-3"></i>
                    YouTube
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="outlet.contact?.locationUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="outlet?.locationUrl" [target]="'_blank'">
                    <i class="pi pi-map-marker font-17 m-r-3"></i>
                    {{ outlet?.location }}
                  </a>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>
      <!-- Avatar image -->
      <div>
        <img
          class="mt-4 mr-5 photo"
          [src]="outlet.picture"
          onerror="this.src='assets/images/blank.jpg'"
        />
      </div>
    </div>
  </div>
</div>

<hr />
<div class="p-grid">
  <div class="p-col-8">
    <label style="font-size:large">Recent content by {{ outlet.name }}</label>
  </div>
  <div class="p-col-4">
    <p-button
      class="p-button-sm coverage"
      type="button"
      icon="pi pi-search"
      label="View all content by {{ outlet.name }}"
      (click)="goToExploreRelatedCoverage(outlet)"
    ></p-button>
  </div>
</div>

<app-content-items
  [parentName]="parentName"
  [items]="contentItems"
  [loading]="!loading"
  [pageSize]="outletContentPageSize"
  [page]="currentPage"
  [total]="outletContentTotal"
  [keywords]="keywords"
></app-content-items>

<div id="paginator" class="p-d-flex p-jc-between">
  <div *ngIf="outletContentTotalPages >= 2">
    <p-paginator
      #pp
      [showCurrentPageReport]="false"
      currentPageReportTemplate="Showing {{ outletContentPage }} of {{
        outletContentPageSize
      }} pages"
      [pageLinkSize]="3"
      [showFirstLastIcon]="false"
      (onPageChange)="getPage($event)"
      [rows]="outletContentPageSize"
      [totalRecords]="outletContentTotal"
    >
    </p-paginator>
  </div>
  <div class="p-mt-2">
    <p-button
      pTooltip="Report Missing Data"
      class="p-button-sm coverage"
      icon="pi pi-flag"
      label=""
      (click)="openReportMissingDataModal()"
    ></p-button>
  </div>
  <div id="sort">
    <app-sort-options
      (sortChangesEmiter)="sortChange($event)"
      [defaultSort]="sortSelected"
    ></app-sort-options>
  </div>
</div>
