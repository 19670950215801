import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { AuthenticationService, UserService } from 'app/services';
import { ToastNotificationService } from 'app/components';
import { UserProfile } from 'app/models';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {
  public picture: string;
  public name: string;
  public uploadImage: File;
  public isAttached: boolean;
  public filePath: string;
  public hasPassword: boolean;
  public profile: UserProfile;

  @Output() imageSelected: EventEmitter<File> = new EventEmitter<File>();

  constructor(
    private userService: UserService,
    private store: Store<AppState>,
    private notificationService: ToastNotificationService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.profile = this.authenticationService.getUserProfile();
    this.store.select('userData').subscribe(({ data }) => {
      if (data) {
        this.hasPassword = data.hasPassword;
        this.picture = this.hasPassword
          ? this.getUserPicture(data.userId)
          : this.profile.picture;
        this.name = data.name;
        this.resetImageSelected();
      }
    });
  }

  getUserPicture(userId) {
    return this.userService.getUserPicture(userId);
  }

  public chooseImage(e) {
    const file = (e.target as HTMLInputElement).files[0];
    if (file) {
      const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (parseInt(sizeInMB, 10) >= 1) {
        this.notificationService.showMessage(
          'File size exceeds the maximum allowed size (1MB)',
          'Error'
        );
      } else {
        this.uploadImage = file;
        this.isAttached = true;

        const reader = new FileReader();
        reader.onload = () => {
          this.filePath = reader.result as string;
        };
        reader.readAsDataURL(file);

        this.imageSelected.emit(file); // Emit the selected image file
      }
    }
  }

  public resetImageSelected() {
    this.uploadImage = null;
    this.isAttached = false;
    this.filePath = this.picture;
    this.imageSelected.emit(null);
  }
}
