import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AuthenticationService } from 'app/services';
import { ToastNotificationService } from 'app/components';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnChanges {
  public showDialog: boolean;
  public isSending: boolean;
  public forgotPasswordForm: FormGroup;

  @ViewChild('emailInput')
  emailInput: ElementRef;

  @Input() visibleDialog: boolean;
  @Output() closeForgotPassword: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private notificationService: ToastNotificationService
  ) {
    this.isSending = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showDialog = changes.visibleDialog.currentValue;
    setTimeout(() => {
      this.emailInput.nativeElement.focus();
    }, 1000);
  }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  invalidField(field: string) {
    return (
      this.forgotPasswordForm.controls[field].errors &&
      this.forgotPasswordForm.controls[field].touched
    );
  }

  onClose() {
    this.showDialog = false;
    this.forgotPasswordForm.get('email').setValue(null);
    this.isSending = false;
    this.closeForgotPassword.emit(false);
  }

  onShow() {
    this.showDialog = true;
  }

  handleSubmit() {
    if (this.forgotPasswordForm.invalid) {
      this.forgotPasswordForm.markAllAsTouched();
      return;
    }
    this.isSending = true;
    const email = this.forgotPasswordForm.get('email').value;

    this.authService.forgotPassword(email).subscribe(
      response => {
        this.notificationService.showMessage(
          'Please check your email.',
          'Success'
        );
        this.onClose();
      },
      error => {
        this.notificationService.showMessage(
          'Sorry, that didn\'t work',
          'Error'
        );
        this.onClose();
      }
    );
  }
}
