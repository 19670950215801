import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { includes } from 'lodash';
import * as actions from '../actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import {eq} from 'lodash';
import { tap, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import {
  TopicService,
  AuthenticationService,
  ProjectService
} from 'app/services';
import { of } from 'rxjs';
import { TopicCard, TopicResponse } from 'app/models';

@Injectable()
export class TopicsEffects {
  private accountId: any;
  constructor(
    private actions$: Actions,
    private topictService: TopicService,
    private authenticationService: AuthenticationService,
    private store: Store<AppState>,
    private projectService: ProjectService
  ) {
    this.accountId = this.authenticationService.getAccountId();
  }

  loadTopics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.projectChange),
      tap(() => actions.startLoading()),
      mergeMap(action =>
        this.topictService
          .getTopicsSaved(action.projectSelected.value)
          .pipe(
            tap(response => console.log('Load topics saved list', action.projectSelected)),
            switchMap(response => [
              actions.setTopicsSuccess({
                topics: response
                  ? response.map(
                      ({custom, id, label, name, topicId, query, implicitQuery, numContentItems, numAuthors, numOutlets}) =>
                        new TopicResponse({
                          id: topicId || null,
                          name: name,
                          query: query && !eq(query, 'null') ? query : null,
                          implicitQuery: implicitQuery || null,
                          categoryId: label ? label.id : null,
                          categoryName: label ? label.name : null,
                          coverage: numContentItems || null,
                          authors: numAuthors || null,
                          outlets: numOutlets || null,
                          selected: false,
                          custom: custom,
                          userTopicId: id || null,
                          isSaved: true,
                          loadingIcon: false
                        })
                    )
                  : []
              }),
              // TODO: Remove this if not necessary reset collection after the project has changed
              // actions.unSetTopicMenuItemSelected(),
              // actions.tabControl({ index: 0 })
            ]),
            catchError(err => of(actions.setTopicsError({ payload: err })))
          )
      )
    )
  );

  setTopics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setTopics),
      mergeMap(action =>
        this.topictService.getTopicsSaved(action.projectId).pipe(
          tap(() => console.log('Load project topics list')),
          switchMap(response => [
            actions.setTopicsSuccess({
              topics: response
                ? response.map(
                  ({custom, id, label, name, topicId, query, implicitQuery, numContentItems, numAuthors, numOutlets}) =>
                  new TopicResponse({
                    id: topicId || null,
                    name: name,
                    query: query && !eq(query, 'null') ? query : null,
                    implicitQuery: implicitQuery || null,
                    categoryId: label ? label.id : null,
                    categoryName: label ? label.name : null,
                    coverage: numContentItems || null,
                    authors: numAuthors || null,
                    outlets: numOutlets || null,
                    selected: false,
                    custom: custom,
                    userTopicId: id || null,
                    isSaved: true,
                    loadingIcon: false
                  })
              )
            : []
            })
          ]),
          catchError(err => of(actions.setTopicsError({ payload: err })))
        )
      )
    )
  );

  loadTopicsMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadTopicsMenu),
      mergeMap(action =>
        this.topictService.getTopicsMenu().pipe(
          tap(() => console.log('Load topics menu')),
          switchMap(response => [
            actions.loadTopicsMenuSuccess({
              payload: response
            })
          ]),
          catchError(err => of(actions.loadTopicsMenuError({ payload: err })))
        )
      )
    )
  );

  addTopicToProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addTopicToProject),
      mergeMap(action =>
        this.topictService
          .addTopicToProject(action.topic.id, action.userProject.value)
          .pipe(
            tap(),
            switchMap(response => [
              actions.setTopics({ projectId: action.userProject.value }),
              actions.addTopicToProjectSuccess({ topic: action.topic })
              // actions.projectChange({ projectSelected: action.userProject })
            ]),
            catchError(err =>
              of(
                actions.setAddTopicToProjectError({
                  payload: err,
                  topicId: action.topic.id
                })
              )
            )
          )
      )
    )
  );

  deleteTopicToProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteTopicToProject),
      mergeMap(action =>
        this.topictService
          .deleteTopicToProject(action.topic.id, action.userProject.value)
          .pipe(
            tap(),
            switchMap(response => [
              actions.setTopics({ projectId: action.userProject.value }),
              actions.deleteTopicToProjectSuccess({ topic: action.topic })
              // actions.projectChange({ projectSelected: action.userProject })
            ]),
            catchError(err =>
              of(
                actions.setDeleteTopicToProjectError({
                  payload: err,
                  topicId: action.topic.id
                })
              )
            )
          )
      )
    )
  );

  updateTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateTopic),
      mergeMap(action =>
        this.topictService
          .update(action.topic, action.userTopicId, action.userId)
          .pipe(
            tap(() => console.log('Update topic')),
            switchMap(response => [
              actions.updateTopicSuccess({
                topic: {
                  name: response.name,
                  query: response.query,
                  categoryId: response?.label?.id
                }
              })
            ]),
            catchError(err => of(actions.updateTopicFail({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (includes(response?.type, 'Update Topic Success')) {
                this.refreshProjectTopicsList(action);
              }
            })
          )
      )
    )
  );

  createTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createTopic),
      mergeMap(action =>
        this.topictService
          .save(action.topic, action.userProject.value, action.userId)
          .pipe(
            tap(() => console.log('Create topic')),
            switchMap(response => [
              actions.createTopicSuccess({ topic: response })
            ]),
            catchError(err => of(actions.createTopicFail({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (includes(response?.type, 'Create Topic Success')) {
                this.refreshProjectTopicsList(action);
              }
            })
          )
      )
    )
  );

  deleteTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteTopic),
      mergeMap(action =>
        this.topictService
          .delete(action.topicId, action.userId)
          .pipe(
            tap(() => console.log('Delete topic')),
            switchMap(response => [
              actions.deleteTopicSuccess({ topic: action }),
              actions.deleteTopicToProjectSuccess({ topic: action })
            ]),
            catchError(err => of(actions.createTopicFail({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (includes(response?.type, 'Delete Topic Success')) {
                this.refreshProjectTopicsList(action);
              }
            })
          )
      )
    )
  );

  refreshProjectTopicsList(action) {
    this.store.dispatch(
      actions.setTopics({ projectId: action.userProject.value })
    );
  }
}
