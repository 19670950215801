export interface UserUpdateProps {
  accountId: number;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  newPassword?: string;
  role?: string;
}

export interface ValidUserProps {
  userId?: number;
  username?: string;
  password?: string;
}

export interface CreateUserProps {
  accountId?: number;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  role?: string;
}

export interface EditUserProps {
  userId?: number;
  firstName?: string;
  lastName?: string;
  userName?: string;
  role?: string;
}

export class UserEdit {
  public userId: number;
  public firstName: string;
  public lastName: string;
  public userName: string;
  public role: string;

  constructor({ userId, firstName, lastName, userName, role }: EditUserProps) {
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.userName = userName;
    this.role = role;
  }
}

export class UserCreate {
  public accountId: number;
  public firstName: string;
  public lastName: string;
  public emailAddress: string;
  public role: string;

  constructor({
    accountId,
    firstName,
    lastName,
    emailAddress,
    role
  }: CreateUserProps) {
    this.accountId = accountId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.emailAddress = emailAddress;
    this.role = role;
  }
}

export class UserUpdate {
  public userId: number;
  public accountId: number;
  public role?: string;
  public firstName: string;
  public lastName: string;
  public emailAddress: string;
  public newPassword: string;

  constructor({
    accountId,
    role,
    firstName,
    lastName,
    emailAddress,
    newPassword
  }: UserUpdateProps) {
    this.accountId = accountId;
    this.role = role;
    this.firstName = firstName;
    this.lastName = lastName;
    this.emailAddress = emailAddress;
    this.newPassword = newPassword;
  }
}

export class ValidUser {
  public userId: number;
  public username: string;
  public password: string;

  constructor({ userId, username, password }: ValidUserProps) {
    this.userId = userId;
    this.username = username;
    this.password = password;
  }
}
