import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { includes, eq } from 'lodash';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  addToken(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authenticationService.getToken()}`
      }
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (includes(req.url, '/secure/token')) {
      return next.handle(req);
    }

    if (includes(req.url, '/oauth')) {
      return next.handle(req);
    }

    if (includes(req.url, '/reset')) {
      return next.handle(req);
    }

    if (includes(req.url, '/password')) {
      return next.handle(req);
    }

    if (includes(req.url, '/forgetPassword')) {
      return next.handle(req);
    }

    if (this.authenticationService.isAuthenticated()) {
      return next.handle(this.addToken(req)).pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.authenticationService.goAuth0Login(null, 'logout');
            return null;
          } else {
            return throwError(err);
          }
        })
      );
    } else {
      this.authenticationService.goAuth0Login(null, 'logout');
      return null;
    }
  }
}
