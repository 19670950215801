import { createReducer, on } from '@ngrx/store';
import { addContent, removeContent, loadContentError } from 'redux/actions';
import { OffCanvasContent } from 'models/index';

export interface OffCanvasContentState {
  OffCanvasContents: OffCanvasContent[];
  error: any;
  isOpen: boolean;
}

const initialState: OffCanvasContentState = {
  OffCanvasContents: [],
  error: null,
  isOpen: false
};

const _offCanvasReducer = createReducer(
  initialState,
  on(addContent, (state, { contentType, contentData, topicId }) => {
    let contentKey = 0;

    if (!!state.OffCanvasContents.length) {
      contentKey =
        state.OffCanvasContents[state.OffCanvasContents.length - 1].contentKey +
        1;
    }
    const item = {
      showContent: true,
      contentKey,
      contentType,
      contentData,
      topicId
    };

    let newContent = [...state.OffCanvasContents];
    const findBlogContent = newContent.find(c => c.contentType === 'blogContent');
    if (!findBlogContent) {
      newContent = [
        ...state.OffCanvasContents,
        new OffCanvasContent(item)
      ];
    }
    return {
      OffCanvasContents: newContent,
      isOpen: true,
      error: null
    };
  }),
  on(removeContent, (state, { key }) => {
    return {
      ...state,
      isOpen: false,
      OffCanvasContents: state.OffCanvasContents.filter(
        content => content.contentKey !== key
      )
    };
  }),
  on(loadContentError, (state, { payload }) => ({
    ...state,
    error: {
      entity: payload.content,
      url: payload.error?.url,
      status: payload.error?.status,
      message: payload.error?.message
    }
  }))
);

export function offCanvasReducer(state, action) {
  return _offCanvasReducer(state, action);
}
