import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { switchMap, mergeMap, catchError } from 'rxjs/operators';
import { TypeaheadResponse } from 'app/models';
import { SearchService } from 'app/services';
import { of } from 'rxjs';

@Injectable()
export class TypeaheadSearchEffects {
  constructor(
    private actions$: Actions,
    private searchService: SearchService
  ) {}

  typeaheadSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadTypeaheadSearch),
      mergeMap(({ keywords }) =>
        this.searchService.searchByTypeahead(keywords).pipe(
          switchMap((response: any) => [
            actions.loadTypeaheadSearchSuccess({
              content: response.map(item => new TypeaheadResponse(item))
            })
          ]),
          catchError(err =>
            of(actions.loadTypeaheadSearchError({ error: err }))
          )
        )
      )
    )
  );
}
