<p-dialog
  header="Forgot Password"
  [style]="{ width: '28vw' }"
  [(visible)]="showDialog"
  [closable]="true"
  [dismissableMask]="true"
  [modal]="true"
  (onHide)="onClose()"
  [baseZIndex]="2003"
>
  <form [formGroup]="forgotPasswordForm">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <div class="space font-info">
          <span
            >Enter your login address and we'll send you an e-mail to help you
            reset your password.</span
          >
        </div>
        <span class="p-float-label">
          <input
            #emailInput
            id="email"
            formControlName="email"
            [class]="
              invalidField('email')
                ? 'ng-invalid ng-dirty p-inputtext-lg'
                : 'p-inputtext-lg'
            "
            pInputText
            maxlength="250"
            (keydown.enter)="handleSubmit()"
          />
          <label htmlFor="email" class="text-900 font-xl">Email</label>
        </span>
        <small
          class="block"
          *ngIf="invalidField('email')"
          id="name-help"
          class="p-error"
          >Enter a valid email address</small
        >
        <div class="space font-info">
          <span>Can't remember which email address you used? Contact us at</span
          ><a
            href="mailto:support@technews.io"
            class="font-medium no-underline ml-2 text-grey-500 cursor-pointer"
            >support@technews.io</a
          >
        </div>
      </div>
    </div>
  </form>
  <div class="box-button-right">
    <button
      pButton
      label="Cancel"
      class="p-button-lg p-button-raised p-button-secondary p-button-text p-mr-2"
      (click)="onClose()"
    ></button>
    <button
      [disabled]="isSending"
      icon="pi pi-send"
      pButton
      class="p-button-lg"
      label="Retrieve My Password"
      (click)="handleSubmit()"
    ></button>
  </div>
</p-dialog>
