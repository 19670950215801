import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { AuthenticationService } from 'app/services';
import * as actions from 'redux/actions';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  public showDialog: boolean;
  public isStepOne: boolean;
  public isStepTwo: boolean;
  public position: string;
  public closeTourByCollectionItem;

  constructor(
    private store: Store<AppState>,
    private authService: AuthenticationService
  ) {
    this.isStepOne = true;
    this.isStepTwo = false;
    this.position = 'center';
  }

  ngOnInit() {
    this.store
      .select('session')
      .subscribe(({ visibleWelcomeDialog, closeTourByCollectionItem }) => {
        this.showDialog = visibleWelcomeDialog;
        this.closeTourByCollectionItem = closeTourByCollectionItem;
      });
  }

  closeDialog() {
    this.store.dispatch(actions.doneWelcomeDialog());
    this.store.dispatch(actions.hideWelcomeDialog());
    this.store.dispatch(actions.setIsUserFirstTime({ value: false }));
    this.authService.saveFirstLogin(false);
    if (!this.closeTourByCollectionItem) {
      this.openBlogDialog();
    }
  }

  next() {
    if (this.isStepOne) {
      this.isStepOne = false;
      this.isStepTwo = true;
      this.store.dispatch(actions.nextWelcomeDialog());
      this.position = 'bottom';
    } else if (this.isStepTwo) {
      this.closeDialog();
      this.position = 'center';
    }
  }

  openBlogDialog() {
    this.store.dispatch(
      actions.addContent({
        contentType: 'blogContent',
        contentData: {},
        topicId: null
      })
    );
  }
}
