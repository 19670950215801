import { createAction, props } from '@ngrx/store';
import { TypeaheadResponse } from 'app/models';

export const loadTypeaheadSearch = createAction(
  '[TypeaheadSearch] loadTypeaheadSearch',
  props<{ keywords: string }>()
);

export const loadTypeaheadSearchSuccess = createAction(
  '[TypeaheadSearch] loadTypeaheadSearchSuccess',
  props<{ content: TypeaheadResponse[] }>()
);

export const loadTypeaheadSearchError = createAction(
  '[TypeaheadSearch] loadTypeaheadSearchError',
  props<{ error: any }>()
);

export const typeaheadItemSelected = createAction(
  '[TypeaheadSearch] typeaheadItemSelected',
  props<{ itemSelected: string; facet: string }>()
);
