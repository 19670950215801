import { createReducer, on } from '@ngrx/store';
import {
  setAuthors,
  unSetAuthors,
  setAuthorsSuccess,
  setAuthorsError
} from 'redux/actions';
import { Author } from 'app/models';

export interface AuthorsState {
  contents: any;
  isSearch: boolean;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initialState: AuthorsState = {
  contents: null,
  isSearch: false,
  loaded: false,
  loading: false,
  error: null
};

const _authorsReducer = createReducer(
  initialState,

  on(setAuthors, (state, { isSearch }) => ({
    ...state,
    loading: true,
    loaded: false,
    isSearch
  })),

  on(setAuthorsSuccess, (state, { contents }) => ({
    ...state,
    contents: { ...contents },
    loading: false,
    loaded: true
  })),

  on(setAuthorsError, (state, { payload }) => ({
    ...state,
    contents: null,
    loading: false,
    loaded: false,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  })),

  on(unSetAuthors, state => ({
    ...state,
    contents: null
  }))
);

export function authorsReducer(state, action) {
  return _authorsReducer(state, action);
}
