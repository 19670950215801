<app-forgot-password
  [visibleDialog]="showforgotPasswordDialog"
  (closeForgotPassword)="changeVisibleForgotPasswordDialog($event)"
></app-forgot-password>
<div class="surface-ground surface-card py-2">
  <form
    [formGroup]="loginForm"
    [style]="{
      'padding-bottom': paddingB
    }"
  >
    <div id="loginTitle" class="text-center font-title">
      Sign in
    </div>
    <div class="text-center">
      No account yet? Take a
      <a
        (click)="goToTrial()"
        class="font-medium no-underline text-grey-500 cursor-pointer"
        >free trial</a
      >
    </div>
    <div
      class="mt-2"
      [style]="{
        'padding-left': paddingRL,
        'padding-right': paddingRL
      }"
    >
      <span class="p-float-label">
        <input
          id="username"
          type="text"
          pInputText
          autocomplete="off"
          [class]="
            invalidField('username')
              ? 'ng-invalid ng-dirty w-full p-inputtext-lg'
              : 'w-full p-inputtext-lg'
          "
          formControlName="username"
        />
        <label htmlFor="username" class="text-900 font-xl">Email</label>
      </span>
      <span
        class="block"
        *ngIf="
          invalidField('username') &&
          !loginForm.get('username').hasError('pattern')
        "
        id="username-help"
        class="p-error"
        >Email is a required field.</span
      >
      <span
        class="p-error block"
        *ngIf="loginForm.get('username').hasError('pattern')"
      >
        Enter a valid email address
      </span>
      <div class="space"></div>
      <span class="p-float-label">
        <input
          id="password"
          type="password"
          autocomplete="off"
          pInputText
          [class]="
            invalidField('password')
              ? 'ng-invalid ng-dirty w-full p-inputtext-lg'
              : 'w-full p-inputtext-lg'
          "
          formControlName="password"
          (keydown.enter)="enterKeyPress($event)"
        />
        <label htmlFor="password" class="text-900 font-xl">Password</label>
      </span>
      <span
        class="block"
        *ngIf="invalidField('password')"
        id="password-help"
        class="p-error"
        >Password is a required field.</span
      >

      <div class="flex w-full justify-content-center mt-4">
        <button
          id="loginBtn"
          [disabled]="isLogging"
          pButton
          pRipple
          label="Sign in"
          class="p-button-lg px-4 w-medium"
          (click)="loginWithTechnews()"
        ></button>
      </div>
      <div class="space-xl"></div>
      <div class="flex justify-content-between flex-wrap mb-3 mt-3">
        <div class="flex align-items-center">
          <a
            (click)="showForgotPasswordDialog()"
            class="font-medium no-underline ml-2 text-grey-500 text-right cursor-pointer"
            >Forgot password?</a
          >
        </div>

        <div class="flex align-items-center">
          <a
            href="mailto:support@technews.io"
            class="font-medium no-underline ml-2 text-grey-500 cursor-pointer"
            >support@technews.io</a
          >
        </div>
      </div>
    </div>
    <hr class="hr-text gradient p-mt-4 p-mb-4" data-content="Or" />

    <div class="flex w-full justify-content-center mb-2">
      <button
        icon="pi pi-google"
        pButton
        pRipple
        label="Sign in with Google"
        class="p-button-lg px-4 w-medium"
        (click)="loginWithGoogle()"
      ></button>
    </div>
    <!--    <div class="flex w-full justify-content-center mb-2">-->
    <!--      <button-->
    <!--        icon="pi pi-microsoft"-->
    <!--        pButton-->
    <!--        pRipple-->
    <!--        label="Login with Microsoft"-->
    <!--        class="p-button-lg px-4 w-medium"-->
    <!--        (click)="loginWithMicrosoft()"-->
    <!--      ></button>-->
    <!--    </div>-->
    <div class="flex w-full justify-content-center mb-2">
      <button
        icon="pi pi-linkedin"
        pButton
        pRipple
        label="Sign in with LinkedIn"
        class="p-button-lg px-4 w-medium"
        (click)="loginWithLinkedIn()"
      ></button>
    </div>
  </form>
</div>
