import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { eq } from 'lodash';

@Component({
  selector: 'app-companies-list',
  template: `
    <div>
      <p-table
        selectionMode="single"
        [value]="companies"
        [scrollable]="showScrollbar"
        styleClass="p-datatable-sm"
        scrollHeight="460px"
        dataKey="name"
        [(selection)]="selectCompany"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="size-col-action"></th>
            <th>
              <a href="javascript:;" (click)="sortChange(sortNameIcon)"
                >Name
                <i id="sortNameIcon" class="pi pi-sort" #sortNameIcon></i>
              </a>
            </th>
            <th>
              <a href="javascript:;" (click)="sortChange(sortCategoryIcon)"
                >Category
                <i
                  id="sortCategoryIcon"
                  class="pi pi-sort-up"
                  #sortCategoryIcon
                ></i>
              </a>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-company let-rowIndex="rowIndex">
          <tr (click)="selectedCompany(company)" [pSelectableRow]="company">
            <td class="size-col-action">
              <button
                pTooltip="Edit company"
                tooltipPosition="top"
                (click)="handleEditCompany(company)"
                pButton
                pRipple
                type="button"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-button-text"
              ></button>
            </td>
            <td>{{ company?.name }}</td>
            <td>{{ company?.category }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <div class="p-d-flex p-ai-center p-jc-between">
            <p-button
              type="button"
              icon="pi pi-plus"
              styleClass="p-button-text"
              label="New Company"
              (click)="handleCreateNewCompany()"
            ></p-button>
          </div>
        </ng-template>
      </p-table>
    </div>
  `,
  styles: [
    `
      :host ::ng-deep .p-datatable .p-datatable-footer {
        padding: 0.5rem 0.5rem;
      }

      :host ::ng-deep .p-datatable .p-datatable-tbody > tr {
        cursor: pointer;
      }

      .size-col-action {
        width: 15%;
      }

      .size-col-center {
        text-align: center !important;
        width: 80px;
      }

      .size-col-right {
        text-align: right !important;
        width: 75px;
      }
    `
  ]
})
export class SidebarCompaniesListComponent implements OnInit, OnChanges {
  @Input() companies: any[];
  @Output() createNewCompanyOnclick: EventEmitter<any> = new EventEmitter();
  @Output() editCompanyOnclick: EventEmitter<any> = new EventEmitter();
  @Output() selectedCompanyOnClick: EventEmitter<any> = new EventEmitter();
  @Output() sortCompanyOnClick: EventEmitter<any> = new EventEmitter();
  public selectCompany: any;
  public sort: any;
  public showScrollbar: boolean;
  public projectId: number;
  public isEdit: boolean;

  @ViewChildren('sortNameIcon, sortCategoryIcon', {
    read: ElementRef
  })
  sortIcons: QueryList<ElementRef>;

  constructor() {
    this.showScrollbar = false;
    this.isEdit = false;
    this.sort = {
      orientation: 'ASC',
      order: 'name'
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companies.currentValue) {
      this.showScrollbar = this.companies.length > 10;
    }
  }

  ngOnInit() {}

  public handleCreateNewCompany() {
    this.createNewCompanyOnclick.emit(null);
  }

  public handleEditCompany(company) {
    this.isEdit = !this.isEdit;
    this.editCompanyOnclick.emit(company);
  }

  public selectedCompany(company) {
    if (!this.isEdit) {
      this.selectedCompanyOnClick.emit(company);
    } else {
      this.isEdit = false;
    }
  }

  public sortChange(icon: any) {
    this.sortIcons.forEach(({ nativeElement }) => {
      if (nativeElement === icon) {
        icon.className =
          icon.className === 'pi pi-sort'
            ? 'pi pi-sort-up'
            : icon.className === 'pi pi-sort-down'
            ? 'pi pi-sort-up'
            : 'pi pi-sort-down';
      } else {
        nativeElement.className = 'pi pi-sort';
      }
    });
    switch (icon.id) {
      case 'sortNameIcon':
        this.sort = {
          orientation: eq(icon.className, 'pi pi-sort-up') ? 'ASC' : 'DESC',
          order: 'name'
        };
        break;

      case 'sortCategoryIcon':
        this.sort = {
          orientation: eq(icon.className, 'pi pi-sort-up') ? 'ASC' : 'DESC',
          order: 'category'
        };
        break;

      default:
        this.sort = {
          orientation: 'ASC',
          order: 'category'
        };
        break;
    }
    this.sortCompanyOnClick.emit(this.sort);
  }
}
