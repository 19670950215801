import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import * as actions from 'redux/actions';

@Component({
  selector: 'app-block-ui',
  template: `
    <ng-template #blockuiTemplate>
      <div class="blockui-class">
        <i class="pi pi-lock" style="font-size: 3rem"></i>
        <span class="expire-msg">{{ expireMessage }}</span>
        &nbsp;
        <a (click)="showSignMeUpDialog()" class="button signUp sign-up-button"
          >Sign me up!</a
        >
      </div>
    </ng-template>
    <ngx-loading
      [show]="isSubscriptionExpired"
      [config]="config"
      [template]="blockuiTemplate"
    ></ngx-loading>
  `,
  styles: [
    `
      .sign-up-button {
        cursor: pointer;
        color: white;
        font-size: 17px;
      }
      .expire-msg {
        margin-top: 10px;
        font-size: 15px;
        font-style: italic;
        color: white;
      }

      .blockui-class {
        top: 0;
        z-index: 2002;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 3px;
      }

      .blockui-class p {
        color: white;
        margin-top: 10rem;
      }
    `
  ]
})
export class BlockUIComponent implements OnInit {
  public isSubscriptionExpired: boolean;
  public message: string;
  public timeoutMessages: number;
  public messages: any[];
  public counter$: Observable<any>;
  public counterSub: Subscription;
  public expireMessage: string;

  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    backdropBackgroundColour: 'rgba(0,0,0,0.5)',
    backdropBorderRadius: '3px',
    primaryColour: '#ff9800',
    secondaryColour: '#ffffff',
    tertiaryColour: '#ffffff',
    fullScreenBackdrop: false
  };

  constructor(private store: Store<AppState>) {
    this.isSubscriptionExpired = true;
    this.timeoutMessages = 1000;
    this.messages = [];
    this.expireMessage = null;
  }

  ngOnInit() {
    this.store
      .select('ui')
      .subscribe(({ isSubscriptionExpired, expireMessage }) => {
        this.isSubscriptionExpired = isSubscriptionExpired;
        this.expireMessage = expireMessage;
        if (isSubscriptionExpired) {
          this.store.dispatch(actions.showSignUpDialog());
        }
      });
  }

  showSignMeUpDialog() {
    this.store.dispatch(actions.showSignUpDialog());
  }
}
