import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from '../actions';
import { tap, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CategoryService } from 'app/services';
import { CategoryResponse } from 'app/models';
import { includes } from 'lodash';

@Injectable()
export class CategoryDialogEffects {
  constructor(
    private actions$: Actions,
    private categoryService: CategoryService,
    private store: Store<AppState>
  ) {}

  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadCategories),
      mergeMap(action =>
        this.getCategories$(action).pipe(
          tap(() => console.log('load categories')),
          switchMap(response => [
            actions.loadCategoriesSuccess({
              categories: response._embedded.userLabels.map(
                item =>
                  new CategoryResponse({
                    id: item.id,
                    name: item.name,
                    description: item.description
                  })
              )
            })
          ]),
          catchError(err => of(actions.loadCategoriesError({ payload: err })))
        )
      )
    )
  );

  createCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createCategory),
      mergeMap(({ payload }) =>
        this.categoryService
          .save(payload.name, payload.description, payload.projectId)
          .pipe(
            tap(() => console.log('Create category')),
            switchMap(response => [
              actions.createCategorySuccess({ responseSuccess: payload })
            ]),
            catchError(err => of(actions.createCategoryError({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (includes(response?.type, 'Create Category Success')) {
                this.refreshCategories(payload);
              }
            })
          )
      )
    )
  );

  updateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateCategory),
      mergeMap(({ payload }) =>
        this.categoryService
          .update(payload.name, payload.description, payload.id)
          .pipe(
            tap(() => console.log('Update category')),
            switchMap(response => [
              actions.updateCategorySuccess({ responseSuccess: payload })
            ]),
            catchError(err => of(actions.updateCategoryError({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (includes(response?.type, 'Update Category Success')) {
                this.refreshCategories(payload);
              }
            })
          )
      )
    )
  );

  deleteCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteCategory),
      mergeMap(({ payload }) =>
        this.categoryService
          .delete(payload.id)
          .pipe(
            tap(() => console.log('Delete category')),
            switchMap(response => [
              actions.deleteCategorySuccess({ responseSuccess: payload })
            ]),
            catchError(err => of(actions.updateCategoryError({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (includes(response?.type, 'Delete Category Success')) {
                this.refreshCategories(payload);
              }
            })
          )
      )
    )
  );

  getCategories$(action): Observable<any> {
    return this.categoryService.getCategoriesOfProjects(action.projectId);
  }

  refreshCategories(action) {
    this.store.dispatch(
      actions.loadCategories({ projectId: action.projectId })
    );
  }
}
