<div
  id="main-wrapper"
  [ngClass]="{ 'show-sidebar': showMobileMenu }"
  dir="ltr"
  [attr.data-theme]="options.theme"
  [attr.data-layout]="options.layout"
  [attr.data-sidebartype]="options.sidebartype"
  [attr.data-sidebar-position]="options.sidebarpos"
  [attr.data-header-position]="options.headerpos"
  [attr.data-boxed-layout]="options.boxed"
>
  <get-screen-size></get-screen-size>
  <header class="topbar" [attr.data-navbarbg]="options.navbarbg">
    <nav class="d-flex top-navbar navbar-expand-md navbar-dark">
      <div
        class="navbar-header"
        [ngClass]="expandLogo ? 'expand-logo' : ''"
        [attr.data-logobg]="options.logobg"
      >
        <a class="navbar-brand" href="/">
          <span class="logo-text">
            <img
              id="technew-logo"
              src="../../../assets/images/scalableVectorGraphics/logo-full.svg"
              class="light-logo"
              width="170"
              alt="homepage"
            />
          </span>
        </a>
        <a
          class="topbartoggler d-block d-md-none waves-effect waves-light"
          href="javascript:void(0)"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
        >
          <i class="ti-more"></i>
        </a>
      </div>

      <div
        class="navbar-collapse collapse"
        id="navbarSupportedContent"
        [attr.data-navbarbg]="options.navbarbg"
      >
        <app-header
          (toggleSidebar)="toggleSidebarType()"
          class="w-100"
          style="margin-left: 4em"
        ></app-header>
      </div>
    </nav>
  </header>
  <app-block-ui></app-block-ui>
  <aside
    class="left-sidebar sidebar-menu"
    [attr.data-sidebarbg]="options.sidebarbg"
  >
    <app-sidebar></app-sidebar>
  </aside>

  <div [ngClass]="hideFollowing ? 'page-without-side' : 'page-wrapper'">
    <ng-content></ng-content>
    <!-- <footer class="footer text-center"></footer> -->
  </div>
</div>
