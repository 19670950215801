import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent
} from 'ngx-perfect-scrollbar';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { get, maxBy, capitalize, isEmpty, eq } from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { FACETS } from 'app/constants';
import { SelectOption } from '../../../interfaces';
import { Search } from 'app/models';
import { FacetContent } from './facet-content.interface';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 'dashboard-facets',
  templateUrl: './dashboard-facets.component.html',
  styleUrls: ['./dashboard-facets.component.scss']
})
export class DashboardFacetsComponent implements OnInit, OnDestroy {
  public summaries: string[];
  public facetsOptions: SelectOption[];
  public selectedFacets: any[];
  public facets: FacetContent[];
  public selectOptions: any[];
  public authors: any;
  public outlets: any;
  public companies: any;
  public topics: any;
  public products: any;
  public people: any;
  public locations: any;
  public countries: any;
  public cities: any;
  private facetsSearchSub: Subscription;
  public filterSearch: any;
  public facetsLoading: boolean;
  public facetsLoaded: boolean;
  public sortAuthorFacets: string;
  public sortOutletFacets: string;
  public sortTopicFacets: string;
  public sortCompanyFacets: string;
  public currentPage: number;
  public page: number;
  public totalPages: number;
  public totalItems: number;
  public pageSize: number;
  public showLabels: number;
  public allContentsAreEmpty: boolean;
  public facetsFromSearch: any;
  public isNewSearch: boolean;
  public project: any;

  public config: PerfectScrollbarConfigInterface = {};

  @ViewChild('dashboardPerfectScroll', { static: false })
  dashboardPerfectScroll: PerfectScrollbarComponent;

  @ViewChild('p', { static: false }) paginator: Paginator;

  constructor(private store: Store<AppState>) {
    this.facetsOptions = FACETS;
    this.totalPages = 0;
    this.totalItems = 0;
    this.page = 1;
    this.currentPage = 0;
    this.pageSize = 50;
    this.facets = [];
    this.selectedFacets = [];
    this.showLabels = 1;
    this.selectOptions = FACETS;
    this.allContentsAreEmpty = false;
    this.facetsFromSearch = null;
    this.isNewSearch = false;
    this.project = null;
  }

  ngOnInit() {
    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.project = projectSelected;
      }
    });

    this.facetsSearchSub = this.store
      .select('facetsSearch')
      .subscribe(
        ({ searchBody, contents, loading, loaded, isNewSearch, facets }) => {
          this.selectedFacets = facets;
          this.facetsLoading = loading;
          this.facetsLoaded = loaded;
          this.isNewSearch = isNewSearch;
          if (loaded) {
            this.filterSearch = searchBody;
            this.facetsFromSearch = facets;
            this.setContents(contents);
            this.scrollTop();
          }
        }
      );
  }

  ngOnDestroy(): void {
    if (this.facetsSearchSub) {
      this.facetsSearchSub.unsubscribe();
    }
  }

  setContents(contents) {
    if (!isEmpty(contents)) {
      this.facets = [];
      const author = get(contents, 'authors', null);
      const outlet = get(contents, 'outlets', null);
      const topic = get(contents, 'topics', null);
      const company = get(contents, 'companies', null);
      const product = get(contents, 'products', null);
      const person = get(contents, 'people', null);
      const location = get(contents, 'locations', null);
      // const country = get(contents, 'countries', null);
      // const city = get(contents, 'cities', null);
      if (author) {
        this.facets.push({
          contents: author.content,
          title: 'Authors',
          facets: this.facetsFromSearch,
          pages: author.totalPages,
          items: author.totalElements,
          pageNumber: author.number
        });
      }
      if (outlet) {
        this.facets.push({
          contents: outlet.content,
          title: 'Outlets',
          facets: this.facetsFromSearch,
          pages: outlet.totalPages,
          items: outlet.totalElements,
          pageNumber: outlet.number
        });
      }
      if (topic) {
        this.facets.push({
          contents: topic.content,
          title: 'Topics',
          facets: this.facetsFromSearch,
          pages: topic.totalPages,
          items: topic.totalElements,
          pageNumber: topic.number
        });
      }
      if (company) {
        this.facets.push({
          contents: company.content,
          title: 'Companies & Products',
          facets: this.facetsFromSearch,
          pages: company.totalPages,
          items: company.totalElements,
          pageNumber: company.number
        });
      }
      if (product) {
        this.facets.push({
          contents: product.content,
          title: 'Products',
          facets: this.facetsFromSearch,
          pages: product.totalPages,
          items: product.totalElements,
          pageNumber: product.number
        });
      }
      if (person) {
        this.facets.push({
          contents: person.content,
          title: 'People',
          facets: this.facetsFromSearch,
          pages: person.totalPages,
          items: person.totalElements,
          pageNumber: person.number
        });
      }
      if (location) {
        this.facets.push({
          contents: location.content,
          title: 'Locations',
          facets: this.facetsFromSearch,
          pages: location.totalPages,
          items: location.totalElements,
          pageNumber: location.number
        });
      }
      // if (country) {
      //   this.facets.push({
      //     contents: country.content,
      //     title: 'Countries',
      //     facets: this.facetsFromSearch,
      //     pages: country.totalPages,
      //     items: country.totalElements
      //   });
      // }
      // if (city) {
      //   this.facets.push({
      //     contents: city.content,
      //     title: 'Cities',
      //     facets: this.facetsFromSearch,
      //     pages: city.totalPages,
      //     items: city.totalElements
      //   });
      // }
      const pageNumber = maxBy(this.facets, 'pageNumber').pageNumber;
      this.resetPaginatorPage(pageNumber);
      this.totalPages = maxBy(this.facets, 'pages').pages;
      this.totalItems = maxBy(this.facets, 'items').items;
      this.allContentsAreEmpty = this.isEmptyAllContents(this.facets);
    } else {
      this.facets = [];
    }
  }
  resetPaginatorPage(pageNumber: any) {
    if (this.isNewSearch && this.currentPage > 0 && eq(pageNumber, 0)) {
      this.paginator.changePage(0);
    }
  }

  isEmptyAllContents(facets) {
    if (!!facets.length) {
      for (const facet of facets) {
        if (facet.items > 0) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  goToPageOne() {
    if (this.currentPage > 0) {
      this.paginator.changePage(0);
    } else {
      this.handleFacetsOption();
    }
  }

  getPage(e: any) {
    this.currentPage = e.page;
    this.page = e.page + 1;
    this.handleFacetsOption();
  }

  handleFacetsOption() {
    this.store.dispatch(
      actions.setFacetsSearch({
        searchBody: this.filterSearch,
        page: this.currentPage,
        isNewSearch: false
      })
    );
  }

  handleMulipleSelect() {
    this.handleFacetsOption();
  }

  handleSortTables(sort) {
    const { table, value } = sort;
    if (eq(table, 'author')) {
      const newFacets = {
        ...this.selectedFacets,
        AUTHORS: eq(value, 'influence') ? 'influenceScore desc' : null
      };
      this.setFacetes(newFacets);
    }

    if (eq(table, 'outlet')) {
      const newFacets = {
        ...this.selectedFacets,
        OUTLETS: eq(value, 'influence') ? 'influenceScore desc' : null
      };
      this.setFacetes(newFacets);
    }

    if (eq(table, 'topics')) {
      const newFacets = {
        ...this.selectedFacets,
        TOPICS: eq(value, 'growth') ? 'deltaYearPercent desc' : null
      };
      this.setFacetes(newFacets);
    }

    if (eq(table, 'companies')) {
      const newFacets = {
        ...this.selectedFacets,
        COMPANIES: eq(value, 'growth') ? 'deltaYearPercent desc' : null
      };
      this.setFacetes(newFacets);
    }
    this.goToPageOne();
  }

  setFacetes(newFacets) {
    const searchBody = { ...this.filterSearch, facets: newFacets };
    this.filterSearch = searchBody;
    this.selectedFacets = newFacets;
  }

  private scrollTop() {
    if (this.dashboardPerfectScroll) {
      this.dashboardPerfectScroll.directiveRef.scrollTo(0, 1);
    }
  }
}
