import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private cdn: string;
  private version: string;
  private api: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.api = environment.api_url;
    this.cdn = environment.cdn_url;
    this.version = environment.images_version;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public getCompanyPicture(id): string {
    return `${this.cdn}/images/organizations/${this.version}/${id}.png`;
  }

  public getCompanyById(id): Observable<any> {
    return this.http.get(`${this.api}/topics/${id}`, this.httpOptions);
  }

  public rankedCompanies(
    sort = 'stats.trend,desc',
    page = 0,
    size = 50
  ): Observable<any> {
    return this.http.get(
      `${this.api}/companies?sort=${sort}&page=${page}&size=${size}`,
      this.httpOptions
    );
  }

  public getCompanies(projectId): Observable<any> {
    return this.http.get(
      `${this.api}/project/${projectId}/companies`,
      this.httpOptions
    );
  }

  public getCompanyRelated(id): Observable<any> {
    return this.http.get(`${this.api}/companies/${id}`, this.httpOptions);
  }

  public createCompany(
    projectId: number,
    name: string,
    query: string,
    categoryId: number
  ): Observable<any> {
    return this.http.post(
      `${this.api}/userTopics/addToProjectCustom?userProjectId=${projectId}&name=${name}&query=${query}&userLabelId=${categoryId}&company=true`,
      this.httpOptions
    );
  }

  public editCompany(
    companyId: number,
    topicName: string,
    query: string,
    categoryId: number
  ): Observable<any> {
    const name = encodeURIComponent(topicName);
    return this.http.put(
      `${this.api}/userTopics/updateCustom?userTopicId=${companyId}&name=${name}&query=${query}&userLabelId=${categoryId}`,
      null,
      this.httpOptions
    );
  }

  public removeCompany(companyId: number): Observable<any> {
    return this.http.delete(
      `${this.api}/userTopics/deleteFromProjectCustom?userTopicId=${companyId}`
    );
  }

  public addCompanyToProject(
    topicId: number,
    userProjectId: number
  ): Observable<any> {
    const path = `${this.api}/userTopics/addToProject?userProjectId=${userProjectId}&topicId=${topicId}&company=true`;
    return this.http.post(path, null, this.httpOptions);
  }

  public deleteCompanyToProject(
    topicId: number,
    userProjectId: number
  ): Observable<any> {
    const path = `${this.api}/userTopics/deleteFromProject?userProjectId=${userProjectId}&topicId=${topicId}&company=true`;
    return this.http.delete(path, this.httpOptions);
  }
}
