<div class="p-mt-2"></div>
<div class="scroll-container">
  <div class="card border">
    <app-loading-topic></app-loading-topic>
    <p-table
      #topicsTable
      [value]="topics"
      styleClass="p-datatable-sm"
      selectionMode="single"
      [scrollable]="true"
      scrollHeight="calc(100vh - 300px)"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>
            <button
              *ngIf="!hideFollowing"
              (click)="goTopicSearch()"
              pButton
              type="button"
              icon="pi pi-arrow-left"
              class="p-button-secondary p-button-text"
              [style]="{ 'font-size': '1.2rem' }"
            ></button>
          </th>
          <th [style]="{ width: '13%' }">
            <span *ngIf="!visibleEllipses">Related</span> Topics
            <!-- <a href="javascript:;" (click)="sortChange(sortNameIcon)"
              >Topic
              <i id="sortNameIcon" class="pi pi-sort" #sortNameIcon></i>
            </a> -->
          </th>
          <th
            [style]="relevaceLabel ? { width: '7.9%' } : { width: '0%' }"
            pTooltip="How frequently this topic gets mentioned together with the search topic"
          >
            <span
              role="button"
              *ngIf="relevaceLabel"
              href="javascript:;"
              (click)="sortChange(sortRelevanceIcon)"
            >
              {{ relevaceLabel }}
              <i
                id="sortRelevanceIcon"
                class="pi pi-sort"
                #sortRelevanceIcon
              ></i>
            </span>
          </th>
          <th *ngIf="visibleEllipses">
            <span
              role="button"
              href="javascript:;"
              (click)="sortChange(sortAuthorsWeekIcon)"
              ><span *ngIf="!visibleEllipses">Authors</span> Past Week
              <i
                id="sortAuthorsWeekIcon"
                class="pi pi-sort"
                #sortAuthorsWeekIcon
              ></i>
            </span>
          </th>
          <th *ngIf="visibleEllipses">
            <span
              role="button"
              href="javascript:;"
              (click)="sortChange(sortAuthorsMonthIcon)"
              ><span *ngIf="!visibleEllipses">Authors</span> Past Month
              <i
                id="sortAuthorsMonthIcon"
                class="pi pi-sort"
                #sortAuthorsMonthIcon
              ></i>
            </span>
          </th>
          <th *ngIf="visibleEllipses">
            <span
              role="button"
              href="javascript:;"
              (click)="sortChange(sortAuthorsYearIcon)"
              ><span *ngIf="!visibleEllipses">Authors</span> Past Year
              <i
                id="sortAuthorsYearIcon"
                class="pi pi-sort"
                #sortAuthorsYearIcon
              ></i>
            </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <app-not-records-found
          [visible]="topics.length === 0"
        ></app-not-records-found>
      </ng-template>
      <ng-template pTemplate="body" let-topic>
        <tr *ngIf="loading">
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
        </tr>
        <tr (click)="showTopicDialog(topic)" *ngIf="!loading">
          <td>
            <button
              *ngIf="!hideFollowing"
              (click)="handleStarCheckOnClick(topic)"
              pButton
              type="button"
              [icon]="topic?.starIcon"
              class="p-button-secondary p-button-text"
            ></button>
          </td>
          <td
            [class]="sortColumn === 'name' ? 'sort-color' : ''"
            [style]="{ width: '11.7%' }"
          >
            {{ topic.name }}
          </td>
          <td
            [class]="sortColumn === 'relatedScore' ? 'sort-color' : ''"
            [style]="{ width: '7.1%' }"
          >
            <p-progressBar
              [style]="{ background: 'transparent' }"
              [showValue]="false"
              [value]="topic.percentage"
            ></p-progressBar>
          </td>
          <td
            *ngIf="visibleEllipses"
            [class]="
              sortColumn === 'newsAuthorsWeek'
                ? 'col-numarticle sort-color'
                : 'col-numarticle'
            "
          >
            <span>{{ topic.newsAuthorsWeek | number }}</span>
          </td>
          <td
            *ngIf="visibleEllipses"
            [class]="
              sortColumn === 'newsAuthorsMonth'
                ? 'col-numarticle sort-color'
                : 'col-numarticle'
            "
          >
            <span>{{ topic.newsAuthorsMonth | number }}</span>
          </td>
          <td
            *ngIf="visibleEllipses"
            [class]="
              sortColumn === 'newsAuthorsYear'
                ? 'col-numarticle sort-color'
                : 'col-numarticle'
            "
          >
            <span>{{ topic.newsAuthors | number }}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-d-flex p-jc-between mini-topic-footer">
      <div *ngIf="totalPages >= 2">
        <p-paginator
          #p
          [showCurrentPageReport]="false"
          currentPageReportTemplate="Showing {{ page }} of {{
            totalPages
          }} pages"
          [pageLinkSize]="3"
          [showFirstLastIcon]="false"
          (onPageChange)="getPage($event)"
          [rows]="pageSize"
          [totalRecords]="totalItems"
          [showPageLinks]="showPageButton"
        >
        </p-paginator>
      </div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
