import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { get } from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { Search } from 'app/models';
import { Paginator } from 'primeng/paginator';
import { Content } from 'app/constants';

@Component({
  selector: 'app-coverage',
  templateUrl: './coverage.component.html',
  styleUrls: ['./coverage.component.scss']
})
export class CoverageComponent implements OnInit, OnDestroy {
  private topicStoreSub: Subscription;
  private coveragesSub: Subscription;
  private searchSub: Subscription;
  private uiSub: Subscription;
  public topicId: number;
  public currentPage: number;
  public page: number;
  public pageSize: number;
  public feeds: any[] = [];
  public totalPages: number;
  public total: number;
  public totalItems: number;
  public isSearch: boolean;
  public filterSearch: Search;
  public searchLoaded: boolean;
  public loading: boolean;
  public keywords: string;
  public saveAsTopicLabel: string;
  public reportMissingLabel: string;
  public recentSortLabel: string;
  public relevantSortLabel: string;
  public sortSelected: string;
  public parentName: string;
  public hideCoverageTitle: boolean;
  public headerPadding: string;
  public isLoadingContent: boolean;
  public showPageButton: boolean;
  public screenSize: number;
  public showSort: boolean;

  @Output() reportMissingDataEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild('pp', { static: false }) paginator: Paginator;

  constructor(private store: Store<AppState>) {
    this.parentName = Content.Coverage;
    this.currentPage = 0;
    this.sortSelected = 'recent';
    this.page = 1;
    this.pageSize = 50;
    this.isSearch = false;
    this.searchLoaded = false;
    this.keywords = null;
    this.loading = false;
    this.saveAsTopicLabel = 'Save as topic';
    this.reportMissingLabel = 'Report Missing Data';
    this.recentSortLabel = 'Recent';
    this.relevantSortLabel = 'Relevant';
    this.hideCoverageTitle = false;
    this.showPageButton = true;
    this.showSort = true;
  }

  ngOnDestroy(): void {
    if (this.topicStoreSub) {
      this.topicStoreSub.unsubscribe();
    }
    if (this.coveragesSub) {
      this.coveragesSub.unsubscribe();
    }
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
    if (this.uiSub) {
      this.uiSub.unsubscribe();
    }
  }

  ngOnInit() {
    this.uiSub = this.store
      .select('ui')
      .subscribe(({ contemItemPanelSize, screenSizeValues }) => {
        this.screenSize = screenSizeValues.scrWidth;
        if (this.screenSize < 1386) {
          this.saveAsTopicLabel = '..';
          this.reportMissingLabel = '..';
          this.hideCoverageTitle = true;
          this.headerPadding = 'padding: 2rem 0 2rem 0;';
        } else {
          this.saveAsTopicLabel = 'Save as topic';
          this.reportMissingLabel = 'Report Missing Data';

          this.hideCoverageTitle = false;
          this.headerPadding = 'padding: 0;';
        }

        if (this.screenSize >= 1500) {
          this.showPageButton = true;
          this.recentSortLabel = 'Rec..';
          this.relevantSortLabel = 'Rel..';
        } else {
          this.showPageButton = false;
          this.recentSortLabel = 'Recent';
          this.relevantSortLabel = 'Relevant';
        }

        if (this.screenSize <= 1200) {
          this.showSort = false;
        } else {
          this.showSort = true;
        }
      });

    this.searchSub = this.store
      .select('search')
      .subscribe(({ searchBody, loaded }) => {
        this.filterSearch = searchBody;
        this.searchLoaded = loaded;
        this.keywords = searchBody?.keywords;
      });

    this.topicStoreSub = this.store.select('topic').subscribe(({ topic }) => {
      this.topicId = get(topic, 'id', null);
    });

    this.coveragesSub = this.store
      .select('coverages')
      .subscribe(({ contents, isSearch, loaded, loading }) => {
        this.isLoadingContent = loading;
        this.loading = loaded;
        this.feeds = [];
        const contentItems = get(contents, 'content', []);
        this.isSearch = isSearch;
        if (loaded) {
          if (contentItems.length > 0) {
            this.feeds = contentItems;
            this.currentPage = contents.number;
            this.page = contents.number + 1;
            this.totalPages = contents.totalPages;
            this.total = contents.totalElements;
          } else {
            this.feeds = [];
            this.currentPage = 0;
            this.page = 0;
            this.totalPages = 0;
            this.total = 0;
          }
        }
        // this.scrollTop();
      });
  }

  public handleSaveSearchAsTopic() {
    this.store.dispatch(actions.setSaveAsTopic());
    this.store.dispatch(actions.showSidebar());
  }

  openReportMissingDataModal() {
    // this.reportMissingDataEmitter.emit('coverage');
    this.store.dispatch(actions.showReportDialog());
  }

  private getContentItems() {
    this.store.dispatch(
      actions.setCoverages({
        searchBody: this.filterSearch,
        topicId: this.topicId,
        sort: this.sortSelected,
        page: this.currentPage,
        size: this.pageSize,
        isSearch: this.isSearch
      })
    );
  }

  public sortChange(e) {
    this.sortSelected = e;
    this.currentPage = 0;
    this.page = 1;
    this.resetPaginator();
    this.getContentItems();
  }

  getPage({ page }) {
    this.currentPage = page;
    this.page = page + 1;
    this.getContentItems();
  }

  resetPaginator() {
    if (this.paginator) {
      this.paginator.changePage(0);
    }
  }
}
