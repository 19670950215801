import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import {
  CompanyService,
  SearchService,
  AuthorService,
  OutletService,
} from 'services/index';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { get, eq, isEmpty, includes } from 'lodash';
import * as actions from 'redux/actions';
import { Notification, FacetSearch } from 'app/models';
import { Paginator } from 'primeng/paginator';
import { MAP_URL } from 'app/pages/topics/constants';
import { TopicService } from 'app/services';
import { Content, RECENT_SORT, RELEVANT_SORT } from 'app/constants';

@Component({
  selector: 'facet-product-content',
  templateUrl: './facet-product-content.component.html',
})
export class FacetProductContentComponent implements OnInit, OnChanges {
  @Input() contentData: any;
  public search: FacetSearch;
  public sortModel: string;
  private sort: string;
  public productContentPage: number;
  public productContentTotalPages: number;
  public productContentPageSize: number;
  public productContentTotal: number;
  public currentPage: number;
  public topicId: number;
  public isTopicSaved: boolean;
  public keywords: string;
  public sortSelected: string;

  public product: any;
  public contentItems: any[] = [];
  public relatedTopics: any[];
  public relatedCompanies: any[];
  public relatedProducts: any[];
  public topicsList: any[];
  public companiesList: any[];
  public currentProject: any;

  @ViewChild('pp', { static: false }) paginator: Paginator;
  public parentName: Content;
  public loading: boolean;

  constructor(
    private companyService: CompanyService,
    private searchService: SearchService,
    private authorService: AuthorService,
    private outletService: OutletService,
    private topicService: TopicService,
    private store: Store<AppState>
  ) {
    this.parentName = Content.Company;
    this.sort = 'relevant';
    this.sortModel = 'relevant';
    this.isTopicSaved = false;
    this.keywords = null;
    this.relatedCompanies = null;
    this.relatedTopics = null;
    this.relatedProducts = null;
    this.loading = false;
  }

  ngOnInit() {
    this.productContentPage = 1;
    this.currentPage = 0;

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.currentProject = projectSelected;
      }
    });

    this.store.select('topic').subscribe(({ topic }) => {
      this.topicId = get(topic, 'id', null);
    });
    this.store.select('search').subscribe(({ searchBody }) => {
      this.keywords = searchBody.keywords;
    });
    this.store.select('topics').subscribe(({ topics }) => {
      this.topicsList = topics;
    });

    this.store.select('companies').subscribe(({ companies }) => {
      this.companiesList = companies;
    });
  }

  ngOnChanges(changes: any) {
    const { product, contentItems, facetSearch } =
      changes.contentData?.currentValue;

    this.product = {
      ...product,
      contact: {},
      picture: this.companyService.getCompanyPicture(product.id),
    };
    this.getRelatedInformation(product.id);
    this.search = facetSearch;
    this.productContentTotalPages = contentItems.totalPages;
    this.productContentTotal = contentItems.totalElements;
    this.contentItems = contentItems.content;
    this.productContentPageSize = this.search.getSize;
    this.currentPage = this.search.getPage;
    this.resetPaginator();
  }

  private getRelatedInformation(id) {
    this.topicService.getTopicRelated(id).subscribe(
      (response) => {
        const { related } = response;
        if (related) {
          this.relatedCompanies = related?.companies
            ? related.companies.content
            : null;
          this.relatedTopics = related?.topics ? related.topics.content : null;
          this.relatedProducts = related?.products
            ? related.products.content
            : null;
        } else {
          this.relatedCompanies = null;
          this.relatedTopics = null;
          this.relatedProducts = null;
        }
      },
      (error) => {
        this.relatedCompanies = null;
        this.relatedTopics = null;
        this.relatedProducts = null;
      }
    );
  }

  onDisplayOutletContent(outletId) {
    this.outletService.getOutletById(outletId).subscribe(
      (response) => {
        const contact = response?.contact || null;
        const website = contact?.webSiteUrl;
        const twitter = contact?.twitterUrl;
        const outlet = {
          id: outletId,
          name: response.name,
          website: website ? (eq(website, 'cbf') ? null : website) : null,
          twitter: twitter ? (eq(twitter, 'cbf') ? null : twitter) : null,
          picture: this.outletService.getOutletPicture(outletId),
        };

        const facetSearch = new FacetSearch(
          outletId,
          'outlet',
          this.keywords,
          'recent',
          0,
          50
        );

        this.store.dispatch(
          actions.loadOutletContent({
            facetSearch,
            outlet,
          })
        );
      },
      (error) => {
        this.store.dispatch(
          actions.showNotification({
            notify: new Notification('Outlet not found', 'info', 'tl'),
          })
        );
      }
    );
  }

  onDisplayRelatedTopicContent(topic) {
    const sort = /* this.keywords ? RELEVANT_SORT : */ RECENT_SORT;
    this.store.dispatch(actions.startLoading());
    this.store.dispatch(
      actions.setTopicDialog({
        topicSelected: topic,
        projectSelected: this.currentProject,
        keywords: null,
        sort,
        isSidebarTopic: true,
      })
    );
  }

  public sortChange(e) {
    this.sort = e;
    this.productContentPage = 1;
    this.currentPage = 0;
    this.resetPaginator();
    this.getCompanyContent();
  }

  getPage(e: any) {
    this.productContentPage = e.page + 1;
    this.currentPage = e.page;
    this.getCompanyContent();
  }

  getCompanyContent() {
    const facetSearch = new FacetSearch(
      this.search.getId,
      this.search.getSearchBy,
      this.search.getKeywords,
      this.sort,
      this.currentPage,
      this.search.getSize
    );

    this.searchService.searchContent(facetSearch).subscribe((response) => {
      this.productContentTotalPages = response.totalPages;
      this.productContentTotal = response.totalElements;
      this.contentItems = response.content;
      // this.scrollTop();
    });
  }

  resetPaginator() {
    if (this.paginator) {
      this.paginator.changePage(0);
    }
  }

  onDisplayAuthorContent(authorId) {
    this.authorService.getAuthorById(authorId).subscribe(
      (response) => {
        const contact = response?.contact || null;
        const email = contact?.emailAddress;
        const facebook = contact?.facebookUrl;
        const linkedIn = contact?.linkedInUrl;
        const twitter = contact?.twitterUrl;
        const author = {
          id: authorId,
          name: response.name,
          influenceScore: response.influenceScore,
          emailAddress: email ? (eq(email, 'cbf') ? null : email) : null,
          facebook: facebook ? (eq(facebook, 'cbf') ? null : facebook) : null,
          linkedIn: linkedIn ? (eq(linkedIn, 'cbf') ? null : linkedIn) : null,
          twitter: twitter ? (eq(twitter, 'cbf') ? null : twitter) : null,
          location: `${contact?.city}, ${contact?.state}`,
          locationUrl: contact?.city
            ? `${MAP_URL}/${
                contact.streetAddress ? contact.streetAddress : ''
              }, ${contact.city}, ${contact.state}`
            : '',
          picture: this.authorService.getAuthorPicture(authorId),
        };
        const facetSearch = new FacetSearch(
          authorId,
          'author',
          this.keywords,
          'recent',
          0,
          50
        );

        this.store.dispatch(
          actions.loadAuthorContent({
            facetSearch,
            author,
          })
        );
      },
      (error) => {
        this.store.dispatch(
          actions.showNotification({
            notify: new Notification('Author not found', 'info', 'tl'),
          })
        );
      }
    );
  }

  onDisplayRelatedCompanyContent(company) {
    this.companyService.getCompanyById(company.id).subscribe((response) => {
      const topic = {
        id: company.id,
        name: company.name,
        query: company?.query,
        implicitQuery: company?.implicitQuery,
        isSaved: this.companiesList.find((li) => eq(li.name, company.name))
          ? true
          : false,
        isMyCompany: false,
        wikipediaUrl: response.contact?.wikipediaUrl,
        webSiteUrl: response.contact?.webSiteUrl,
        youTubeUrl: response.contact?.youTubeUrl,
        twitterUrl: response.contact?.twitterUrl,
        linkedInUrl: response.contact?.linkedInUrl,
        financial: response?.organizationDetail
          ? this.getYahooFinanceURL(response.organizationDetail?.stockTicker)
          : null,
        picture: response.contact?.imageUrl,
        locationUrl: response.contact?.streetAddress
          ? `${MAP_URL}/${response.contact.streetAddress}`
          : '',
        project: this.currentProject,
      };

      const keywords = `(${
        company.query ? company.query : company.implicitQuery
      })`;
      const facetSearch = new FacetSearch(
        company.id,
        'company',
        keywords,
        'recent',
        0,
        50
      );

      this.store.dispatch(
        actions.loadCompanyContent({
          facetSearch,
          company: topic,
        })
      );
    });
  }

  onDisplayRelatedProductContent(product) {
    this.topicService.getTopicWithContact(product.id).subscribe((response) => {
      const newProduct = {
        id: product.id,
        name: product.name,
        query: product?.query,
        implicitQuery: product?.implicitQuery,
        wikipediaUrl: response.contact?.wikipediaUrl,
        webSiteUrl: response.contact?.webSiteUrl,
        youTubeUrl: response.contact?.youTubeUrl,
        twitterUrl: response.contact?.twitterUrl,
        linkedInUrl: response.contact?.linkedInUrl,
        financial: response?.organizationDetail
          ? this.getYahooFinanceURL(response.organizationDetail?.stockTicker)
          : null,
        picture: response.contact?.imageUrl,
        locationUrl: response.contact?.streetAddress
          ? `${MAP_URL}/${response.contact.streetAddress}`
          : '',
        project: this.currentProject,
      };

      const keywords = `(${
        product.query ? product.query : product.implicitQuery
      })`;
      const facetSearch = new FacetSearch(
        product.id,
        'topic',
        keywords,
        'recent',
        0,
        50
      );

      this.store.dispatch(
        actions.loadProductContent({
          facetSearch,
          product: newProduct,
        })
      );
    });
  }

  getYahooFinanceURL(value: any) {
    if (isEmpty(value)) {
      return null;
    } else if (includes(value, 'http')) {
      const q = value.substring(value.lastIndexOf('/') + 1, value.length);
      return `https://finance.yahoo.com/quote/${q}`;
    } else {
      return `https://finance.yahoo.com/quote/${value}`;
    }
  }
}
