import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';

@Component({
  selector: 'app-payment-successful',
  templateUrl: './payment-successful.component.html',
  styleUrls: ['./payment-successful.component.scss']
})
export class PaymetSuccessfulComponent implements OnInit {
  public showDialog: boolean;
  public price: any;

  constructor(private store: Store<AppState>, private location: Location) {}

  ngOnInit() {
    this.store.select('signUpDialog').subscribe(({ showSuccessDialog }) => {
      this.showDialog = showSuccessDialog;
    });
  }

  hideSuccessDialog() {
    this.store.dispatch(actions.setUserData());
    this.location.replaceState('/');
    this.store.dispatch(actions.hidePaymentSuccessDialog());
  }
}
