import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  private api: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.api = `${environment.api_url}`;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public getPosts(size): Observable<any> {
    return this.http.get(
      `${this.api}/public/blog/recent?size=${size}`,
      this.httpOptions
    );
  }
}
