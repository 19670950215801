<div class="media m-t-1">
  <div class="media-body">
    <div class="p-d-flex p-jc-between">
      <div>
        <!-- Header title -->
        <div class="p-d-flex">
          <div class="p-mr-2">
            <h2 id="companyTitle" class="mt-4">{{ company.name }}</h2>
          </div>
          <div class="p-mr-2 p-mt-4">
            <button
              (click)="handleSaveCompany(company)"
              pButton
              pRipple
              type="button"
              [icon]="starToggle"
              class="p-button-rounded p-button-secondary p-button-text"
            ></button>
          </div>
        </div>
        <!-- Social media contacts -->
        <ul class="nav p-mb-4">
          <div class="row align-items-end">
            <li *ngIf="company?.webSiteUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="company?.webSiteUrl" [target]="'_blank'">
                    <i class="pi pi-globe font-17 m-r-3"></i>
                    Website
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="company?.twitterUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="company?.twitterUrl" [target]="'_blank'">
                    <i class="pi pi-twitter font-17 m-r-3"></i>
                    Twitter
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="company?.linkedInUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="company?.linkedInUrl" [target]="'_blank'">
                    <i class="pi pi-linkedin font-17 m-r-3"></i>
                    LinkedIn
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="company?.youTubeUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="company?.youTubeUrl" [target]="'_blank'">
                    <i class="pi pi-youtube font-17 m-r-3"></i>
                    YouTube
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="company?.financial">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="company?.financial" [target]="'_blank'">
                    <i class="pi pi-money-bill font-17 m-r-3"></i>
                    Financial
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="company?.streetAddress">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="company?.locationUrl" [target]="'_blank'">
                    <i class="pi pi-map-marker font-17 m-r-3"></i>
                    {{ company?.city }}
                  </a>
                </div>
              </div>
            </li>
          </div>
        </ul>
        <!-- Related topics & companies -->
        <div class="p-grid">
          <div class="col-12">
            <b>Related Topics:</b>
            <span *ngFor="let rTopic of relatedTopics">
              <a
                href="javascript:;"
                role="button"
                (click)="onDisplayRelatedTopicContent(rTopic)"
                class="a-button-link"
                >{{ rTopic?.name }}</a
              >
            </span>
          </div>
          <div class="col-12">
            <b>Related Companies & Products:</b>
            <span *ngFor="let rCompany of relatedCompanies">
              <a
                href="javascript:;"
                role="button"
                (click)="onDisplayRelatedCompanyContent(rCompany)"
                class="a-button-link"
                >{{ rCompany?.name }}</a
              >
            </span>
          </div>
        </div>
      </div>
      <!-- Avatar image -->
      <div>
        <img
          class="mt-4 mr-5 photo"
          [src]="company.picture"
          onerror="this.src='assets/images/blank.jpg'"
        />
      </div>
    </div>

    <!-- Coverage chart section -->
    <div class="p-d-flex p-mt-4">
      <div class="pointer" (click)="goToExploreRelatedCoverage(company)">
        <span *ngIf="coveragePastYearText">
          <h6>Coverage of {{ company.name }} in the past year</h6>
          <div id="chart-2" *ngIf="longTermChart">
            <app-long-term-chart [topic]="longTermChart"></app-long-term-chart>
          </div>
          <p>{{ coveragePastYearText }}</p>
        </span>
      </div>
      <div class="p-ml-4">
        <p-button
          class="p-button-sm coverage"
          type="button"
          icon="pi pi-search"
          label="View all coverage for {{ company.name }}"
          (click)="goToExploreRelatedCoverage(company)"
        ></p-button>
      </div>
    </div>
  </div>
</div>

<hr />
<h4>Recent content matching {{ search.getKeywords }}</h4>
<app-content-items
  [parentName]="parentName"
  [items]="contentItems"
  [loading]="!loading"
  [pageSize]="companyContentPageSize"
  [page]="currentPage"
  [total]="companyContentTotal"
  [keywords]="keywords"
></app-content-items>

<div id="paginator" class="p-d-flex p-jc-between">
  <div>
    <p-paginator
      #pp
      [showCurrentPageReport]="false"
      currentPageReportTemplate="Showing {{ companyContentPage }} of {{
        companyContentPageSize
      }} pages"
      [pageLinkSize]="3"
      [showFirstLastIcon]="false"
      (onPageChange)="getPage($event)"
      [rows]="companyContentPageSize"
      [totalRecords]="companyContentTotal"
    >
    </p-paginator>
  </div>
  <div></div>
  <div id="sort">
    <app-sort-options
      (sortChangesEmiter)="sortChange($event)"
      [defaultSort]="sortSelected"
    ></app-sort-options>
  </div>
</div>
