import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OutletService {
  private api: string;
  private cdn: string;
  private version: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.api = environment.api_url;
    this.cdn = environment.cdn_url;
    this.version = environment.images_version;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public getOutletsSaved(projectId): Observable<any> {
    return this.http.get(
      `${this.api}/project/${projectId}/outlets/v2`,
      this.httpOptions
    );
  }

  public getOutletForTopic(topicId, sort, p, s): Observable<any> {
    return this.http.post(
      `${this.api}/content/userTopic/${topicId}/outlets/${sort}?page=${p}&size=${s}`,
      '{}',
      this.httpOptions
    );
  }

  public updateAuthorOutletToProject(
    partyId: number,
    userLabelId: number
  ): Observable<any> {
    const path = `${this.api}/userParties/updateCustom?userPartyId=${partyId}&userLabelId=${userLabelId}`;
    return this.http.put(path, null, this.httpOptions);
  }

  public unfollowOutletToProject(
    projectId: number,
    partyId: number
  ): Observable<any> {
    const path = `${this.api}/userParties/deleteFromProject?userProjectId=${projectId}&partyId=${partyId}`;
    return this.http.delete(path, this.httpOptions);
  }

  public getOutletPicture(id): string {
    return `${this.cdn}/images/organizations/${this.version}/${id}.png`;
  }

  public getContentItems(topicId, outletId, sort, p, s = 10): Observable<any> {
    return this.http.get(
      `${this.api}/content/userTopic/${topicId}/outlet/${outletId}/${sort}?page=${p}&size=${s}`,
      this.httpOptions
    );
  }

  public getOutletById(outletId): Observable<any> {
    return this.http.get(`${this.api}/outlets/${outletId}`, this.httpOptions);
  }

  public searchOutletsByName(name): Observable<any> {
    return this.http.get(
      `${this.api}/search/outlets/${name}`,
      this.httpOptions
    );
  }

  public getOutletWithAuthors(outletId, topicId): Observable<any> {
    return this.http.get(
      `${this.api}/content/userTopic/${topicId}/outlet/${outletId}`,
      this.httpOptions
    );
  }

  public createOutlet(outlet: any): Observable<any> {
    const path = `${this.api}/outlets/create`;
    return this.http.post(path, outlet, this.httpOptions);
  }

  public updateOutlet(outlet: any): Observable<any> {
    const path = `${this.api}/outlets/${outlet.id}`;
    return this.http.post(path, outlet, this.httpOptions);
  }

  public removeOutlet(outlet: any): Observable<any> {
    const path = `${this.api}/outlets/${outlet.id}`;
    return this.http.delete(path, this.httpOptions);
  }
}
