import {
  Component,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  map as rxMap
} from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { Search } from 'app/models';
import { AuthorService, OutletService } from 'app/services';
import { map, head } from 'lodash/fp';
import {
  TIMEFRAMES,
  OUTLET_INFLUENCES,
  AUTHOR_INFLUENCES,
  ANALYSTS,
  LOCATIONS
} from 'app/constants';
import { SelectOption } from '../../interfaces';
import { eq } from 'lodash';
import { resetAdvancedSearchFilters } from 'app/redux/actions';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit, OnChanges {
  public showFilters: boolean;
  public timeframes: SelectOption[];
  public authorInfluences: SelectOption[];
  public outletInfluences: SelectOption[];
  public analysts: SelectOption[];
  public locations: SelectOption[];
  public timeframeSelected: string;
  public authorInfluSelected: boolean;
  public outletInfluSelected: boolean;
  public analystSelected: string;
  public locationSelected: string;
  public fromDate: Date;
  public toDate: Date;
  public selectedAuthorsInclude: any[];
  public selectedAuthorsExclude: any[];
  public selectedOutletsInclude: any[];
  public selectedOutletsExclude: any[];
  public filteredAuthors: any[];
  public authors: any[];
  public filteredOutlets: any[];
  public outlets: any[];
  public searching: boolean;
  private authorSubjectKeyUp = new Subject<any>();
  private outletSubjectKeyUp = new Subject<any>();

  @ViewChild('customTimeframe', { static: false }) private customTimeframe: any;
  @Input() reset: boolean;
  @Input() setSearchFromTypeahead: Search;
  @Input() visible: boolean;
  @Input() keywords: string;
  @Output() toggle: EventEmitter<any> = new EventEmitter();
  @Output() advanceSearchOptions: EventEmitter<Search> = new EventEmitter();

  constructor(
    private store: Store<AppState>,
    private primengConfig: PrimeNGConfig,
    private authorService: AuthorService,
    private outletService: OutletService
  ) {
    this.primengConfig.ripple = true;
    this.showFilters = false;
    this.timeframes = TIMEFRAMES;
    this.outletInfluences = OUTLET_INFLUENCES;
    this.authorInfluences = AUTHOR_INFLUENCES;
    this.analysts = ANALYSTS;
    this.locations = LOCATIONS;
    this.timeframeSelected = 'ONE_YEAR';
    this.authorInfluSelected = false;
    this.outletInfluSelected = false;
    this.analystSelected = null;
    this.locationSelected = null;
    this.searching = false;
  }
  ngOnChanges(changes): void {
    if (changes.keywords) {
    }

    if (changes.visible) {
      this.showFilters = changes.visible.currentValue;
    }

    if (changes.reset) {
      this.onResetFilter();
    }

    if (changes.setSearchFromTypeahead?.currentValue) {
      const { authors, outlets } = changes.setSearchFromTypeahead.currentValue;
      if (authors.length > 0) {
        this.getAuthorById(head(authors));
      }

      if (outlets.length > 0) {
        this.getOutletById(head(outlets));
      }
    }
  }

  ngOnInit(): void {
    this.store.select('advancedSearch').subscribe(({ onResetFilters }) => {
      if (onResetFilters) {
        this.onResetFilter();
        this.handleAdvanceSearch();
      }
    });

    this.authorSubjectKeyUp
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(name => {
        this.getAuthors(name);
      });

    this.outletSubjectKeyUp
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(name => {
        this.getOutlets(name);
      });
  }

  onResetFilter() {
    this.timeframeSelected = 'ONE_YEAR';
    this.authorInfluSelected = false;
    this.outletInfluSelected = false;
    this.analystSelected = null;
    this.locationSelected = null;
    this.toDate = null;
    this.fromDate = null;
    this.selectedAuthorsInclude = null;
    this.selectedAuthorsExclude = null;
    this.selectedOutletsExclude = null;
    this.selectedOutletsInclude = null;
    this.store.dispatch(resetAdvancedSearchFilters({ onResetFilters: false }));
  }

  getAuthors(name) {
    const filtered: any[] = [];
    this.authorService.searchAuthorsByName(name).subscribe(
      (response: any) => {
        filtered.push(response);
        this.filteredAuthors = filtered[0];
      },
      ({ error }) => {
        console.log(error);
      }
    );
  }

  getOutlets(name) {
    const filtered: any[] = [];
    this.outletService.searchOutletsByName(name).subscribe(
      (response: any) => {
        filtered.push(response);
        this.filteredOutlets = filtered[0];
      },
      ({ error }) => {
        console.log(error);
      }
    );
  }

  getOutletById(id) {
    this.outletService.getOutletById(id).subscribe(response => {
      this.selectedOutletsInclude = [response];
    });
  }

  getAuthorById(id) {
    this.authorService.getAuthorById(id).subscribe(response => {
      this.selectedAuthorsInclude = [response];
    });
  }

  public handleAdvanceSearch() {
    const search = new Search({
      keywords: this.keywords,
      timeframe: this.timeframeSelected,
      analysts: this.analystSelected,
      countries: this.locationSelected,
      topOutletsOnly: this.outletInfluSelected,
      topAuthorsOnly: this.authorInfluSelected,
      from: eq(this.timeframeSelected, 'CUSTOM') ? this.fromDate : null,
      to: eq(this.timeframeSelected, 'CUSTOM') ? this.toDate : null,
      authors: map('id', this.selectedAuthorsInclude),
      excludeAuthors: map('id', this.selectedAuthorsExclude),
      excludeOutlets: map('id', this.selectedOutletsExclude),
      outlets: map('id', this.selectedOutletsInclude)
    });
    this.advanceSearchOptions.emit(search);
    this.onClose();
  }

  public onClose() {
    this.toggle.emit(false);
  }

  public findAuthorsByName(event) {
    this.authorSubjectKeyUp.next(event.query);
  }

  public findOutletsByName(event) {
    this.outletSubjectKeyUp.next(event.query);
  }
}
