import { createReducer, on } from '@ngrx/store';
import { Search, TopicsRankedResponse } from 'app/models';

import {
  setTopicsRanked,
  setTopicsRankedSuccess,
  setTopicsRankedError,
  unSetTopicsRanked,
  setTopicCoveragePreview,
  setTopicCoveragePreviewSuccess,
  setTopicCoveragePreviewError,
  unSetTopicCoveragePreview,
  setTopicSearchPreviewSuccess,
  setTopicSearchPreviewError
} from 'redux/actions';

export interface TopicsRankedState {
  topicsRankedList: TopicsRankedResponse;
  previews: any[];
  loaded: boolean;
  loading: boolean;
  error: any;
  errorPreviews: any;
  searchBody: Search;
  isNewSearch: boolean;
  coveragePreview: any;
  coveragePreviewLoading: boolean;
  coveragePreviewError: any;
}

const initialState: TopicsRankedState = {
  topicsRankedList: null,
  previews: [],
  loaded: false,
  loading: false,
  error: null,
  errorPreviews: null,
  searchBody: new Search({}),
  isNewSearch: false,
  coveragePreview: null,
  coveragePreviewLoading: false,
  coveragePreviewError: null
};

const _topicsRankedReducer = createReducer(
  initialState,
  on(setTopicsRanked, (state, { searchBody, page, isNewSearch }) => {
    return {
      ...state,
      loaded: false,
      loading: true,
      searchBody: searchBody,
      error: null,
      errorPreviews: null,
      previews: [],
      page,
      isNewSearch
    };
  }),

  on(unSetTopicsRanked, () => ({ ...initialState, list: [] })),

  on(setTopicsRankedSuccess, (state, { topicsRankedList }) => ({
    ...state,
    topicsRankedList: topicsRankedList || null,
    loading: false,
    loaded: true
  })),

  on(setTopicsRankedError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: { ...payload }
  })),

  on(setTopicSearchPreviewSuccess, (state, { previews }) => ({
    ...state,
    errorPreviews: null,
    previews
  })),

  on(setTopicSearchPreviewError, (state, { payload }) => ({
    ...state,
    errorPreviews: { ...payload }
  })),

  on(setTopicCoveragePreview, state => {
    return {
      ...state,
      coveragePreview: null,
      coveragePreviewLoading: true,
      coveragePreviewError: null
    };
  }),

  on(unSetTopicCoveragePreview, state => ({
    ...state,
    coveragePreview: null,
    coveragePreviewLoading: false,
    coveragePreviewError: null
  })),

  on(setTopicCoveragePreviewSuccess, (state, { coveragePreview }) => ({
    ...state,
    coveragePreview,
    coveragePreviewLoading: false,
    coveragePreviewError: null
  })),

  on(setTopicCoveragePreviewError, (state, { payload }) => ({
    ...state,
    coveragePreviewLoading: false,
    coveragePreviewError: { ...payload }
  }))
);

export function topicsRankedReducer(state, action) {
  return _topicsRankedReducer(state, action);
}
