import { Component } from '@angular/core';

@Component({
  selector: 'app-step-one',
  template: `
    <div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
      <div class="mb-3 font-bold text-2xl">
        <span class="text-900">Welcome to </span>
        <span class="text-orange-600">TechNews</span>
      </div>
      <div class="text-700 text-sm mb-6">
        TechNews is a tech industry intelligence engine that makes sure you
        always see the big picture clearly. During your trial, make sure to
        check out:
      </div>
      <div class="grid">
        <div class="col-12 md:col-4 mb-4 px-5">
          <span
            class="p-3 shadow-2 mb-3 inline-block surface-card"
            style="border-radius: 10px"
          >
            <i class="pi pi-chart-line text-4xl text-orange-600"></i>
          </span>
          <div class="text-900 mb-3 font-medium">What's Trending?</div>
          <span class="text-700 text-sm line-height-3"
            >Explore the topics / companies / products that are driving the most
            media interest across the entire tech industry.</span
          >
        </div>
        <div class="md:col-4 mb-4 px-5">
          <span
            class="p-3 shadow-2 mb-3 inline-block surface-card"
            style="border-radius: 10px"
          >
            <i class="pi pi-search text-4xl text-orange-600"></i>
          </span>
          <div class="text-900 mb-3 font-medium">
            What's Happening Inside a Conversation?
          </div>
          <span class="text-700 text-sm line-height-3"
            >Search for any specific product, topic or company in the search box
            above; never miss a new development.</span
          >
        </div>
        <div class="col-12 md:col-4 mb-4 px-5">
          <span
            class="p-3 shadow-2 mb-3 inline-block surface-card"
            style="border-radius: 10px"
          >
            <i class="pi pi-star text-4xl text-orange-600"></i>
          </span>
          <div class="text-900 mb-3 font-medium">
            Follow the Authors and Topics That Matter to You
          </div>
          <span class="text-700 text-sm line-height-3"
            >Anywhere you see a star icon, you can follow the people and
            conversations you need to track.</span
          >
        </div>
      </div>
      <div class="mb-3">
        <span class="text-900 font-sm">Questions? </span>
        <a
          href="mailto:support@technews.io"
          class="font-sm no-underline text-orange-600 cursor-pointer"
          >support@technews.io</a
        >
      </div>
    </div>
  `,
  styleUrls: ['../welcome.component.scss']
})
export class StepOneComponent {
  // Component logic goes here
}
