import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PAGES_ROUTE } from './pages.routing';
import { SharedModule } from '../shared/shared.module';
import { ComponentsModule } from '../components/components.module';
import { LayoutModule } from '../layouts/layout.module';

import {
  PagesComponent,
  TopicsComponent,
  CoverageComponent,
  SidebarTopicManagerComponent,
  OffCanvasComponent,
  DashboardFacetsComponent,
  DashboardFacetsTableComponent,
  FacetAuthorContentComponent,
  FacetOutletContentComponent,
  FacetCompanyContentComponent,
  FacetTopicContentComponent,
  DashboardFacetsSortComponent,
  DashboardFacetsSkeletonComponent,
  TopicsRankedListComponent,
  TopicsRankedSkeletonComponent,
  MiniTopicsTableComponent,
  FacetProductContentComponent,
  ShortTermChartComponent,
  RelevancebarChartComponent,
  TrendNegativebarChartComponent,
  ComapniesRankedListComponent,
  SelectProjectComponent,
  CreateProjectComponent,
  SidebarCompanyManagerComponent,
  SidebarAuthorManagerComponent,
  OutletManagerComponent,
  CategoriesListComponent,
  CategoryFormComponent,
  ReportMissingIncorrectDataComponent,
  SignUpComponent,
  PaymetSuccessfulComponent,
  FacetsTableComponent,
  ContentTableComponent,
  FacetsSortComponent,
  FacetsSkeletonComponent,
  NewsFeedComponent,
  WelcomeComponent,
  StepOneComponent,
  StepTwoComponent,
  MyAccountComponent,
  MyOrganizationComponent,
  UsersListComponent,
  UserAvatarComponent,
  UserFormComponent,
  NewUserComponent,
  EditUserComponent,
  CollectionContentComponent,
  BlogComponent,
  SidebarContentItemsManagerComponent
} from './index';
import { AlertSettingsComponent } from './topics/project/alert-settings/alert-settings.component';
import { EditProjectComponent } from './topics/project/edit-project/edit-project.component';

@NgModule({
  exports: [PagesComponent],
  imports: [
    LayoutModule,
    SharedModule,
    PAGES_ROUTE,
    BrowserModule,
    BrowserAnimationsModule,
    ComponentsModule
  ],
  declarations: [
    PagesComponent,
    TopicsComponent,
    SidebarTopicManagerComponent,
    CoverageComponent,
    OffCanvasComponent,
    DashboardFacetsComponent,
    DashboardFacetsTableComponent,
    FacetAuthorContentComponent,
    FacetOutletContentComponent,
    FacetCompanyContentComponent,
    FacetTopicContentComponent,
    DashboardFacetsSkeletonComponent,
    DashboardFacetsSortComponent,
    TopicsRankedListComponent,
    TopicsRankedSkeletonComponent,
    MiniTopicsTableComponent,
    ShortTermChartComponent,
    RelevancebarChartComponent,
    TrendNegativebarChartComponent,
    SidebarCompanyManagerComponent,
    SidebarAuthorManagerComponent,
    OutletManagerComponent,
    ComapniesRankedListComponent,
    FacetProductContentComponent,
    SelectProjectComponent,
    CreateProjectComponent,
    AlertSettingsComponent,
    EditProjectComponent,
    CategoriesListComponent,
    CategoryFormComponent,
    ReportMissingIncorrectDataComponent,
    SignUpComponent,
    PaymetSuccessfulComponent,
    FacetsTableComponent,
    ContentTableComponent,
    FacetsSortComponent,
    FacetsSkeletonComponent,
    NewsFeedComponent,
    WelcomeComponent,
    StepOneComponent,
    StepTwoComponent,
    MyAccountComponent,
    MyOrganizationComponent,
    UsersListComponent,
    UserAvatarComponent,
    UserFormComponent,
    NewUserComponent,
    EditUserComponent,
    CollectionContentComponent,
    BlogComponent,

    SidebarContentItemsManagerComponent
  ]
})
export class PagesModule {}
