import { createAction, props } from '@ngrx/store';
import { FacetSearch } from 'app/models';

export const addContent = createAction(
  '[OffCanvasContent] Add Content',
  props<{ contentType: string; contentData: any; topicId: number }>()
);

export const removeContent = createAction(
  '[OffCanvasContent] Remove Content',
  props<{ key: number }>()
);

export const loadAuthorContent = createAction(
  '[OffCanvasContent] loadAuthorContent',
  props<{ facetSearch: FacetSearch; author: any }>()
);

export const loadOutletContent = createAction(
  '[OffCanvasContent] loadOutletContent',
  props<{ facetSearch: FacetSearch; outlet: any }>()
);

export const loadCompanyContent = createAction(
  '[OffCanvasContent] loadCompanyContent',
  props<{ facetSearch: FacetSearch; company: any }>()
);

export const loadProductContent = createAction(
  '[OffCanvasContent] loadProductContent',
  props<{ facetSearch: FacetSearch; product: any }>()
);

export const loadTopicContent = createAction(
  '[OffCanvasContent] loadTopicContent',
  props<{ facetSearch: FacetSearch; topic: any }>()
);

export const loadContentError = createAction(
  '[OffCanvasContent] loadContentError',
  props<{ payload: any }>()
);
