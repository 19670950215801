import { createReducer, on } from '@ngrx/store';
import {
  showReportDialog,
  hideReportDialog,
  createReport,
  createReportSuccess,
  createReportError,
  resetReportState
} from 'redux/actions';

export interface ReportMissingState {
  successResponse: any;
  loading: boolean;
  error: any;
  showReportDialog: boolean;
  message: string;
}

const initialState: ReportMissingState = {
  successResponse: null,
  loading: false,
  error: null,
  showReportDialog: false,
  message: null
};

const _reportMissingDataReducer = createReducer(
  initialState,

  on(showReportDialog, state => ({
    ...state,
    showReportDialog: true,
    error: null,
    loading: false
  })),
  on(hideReportDialog, state => ({
    ...state,
    error: null,
    successResponse: null,
    showReportDialog: false
  })),

  on(resetReportState, state => ({
    ...state,
    error: null,
    successResponse: null,
    showReportDialog: false,
    loading: false
  })),

  on(createReport, state => ({
    ...state,
    successResponse: null,
    loading: true,
    error: null,
    message: null
  })),

  on(createReportSuccess, (state, { payload }) => ({
    ...state,
    loading: false,
    success: true,
    message: `Report has been sent successfully`,
    successResponse: payload,
    showReportDialog: false
  })),

  on(createReportError, (state, { payload }) => ({
    ...state,
    loading: false,
    successResponse: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Error sending report ${payload?.message}`
    }
  }))
);

export function reportMissingDataReducer(state, action) {
  return _reportMissingDataReducer(state, action);
}
