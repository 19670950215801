<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left">
  <li
    class="nav-item"
    *ngIf="hasTechNewsJsAccess()"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <a
      class="nav-link waves-effect waves-dark"
      href="javascript:;"
      (click)="storeResetLvLGod()"
      pTooltip="Home"
      tooltipPosition="bottom"
    >
      <i class="pi pi-home font-18 mr-2"></i>
      <span class="font-18" *ngIf="visibleLabels">Home</span>
    </a>
  </li>
  <li class="nav-item" *ngIf="hasTechNewsJsAccess()">
    <a
      class="nav-link waves-effect waves-dark"
      [href]="onClickTab('search')"
      pTooltip="Advanced Search"
      tooltipPosition="bottom"
    >
      <i class="pi pi-search font-18 mr-2"></i
      ><span class="font-18" *ngIf="visibleLabels">Advanced Search</span>
    </a>
  </li>

  <li class="nav-item" *ngIf="hasTechNewsJsAccess()">
    <a
      class="nav-link waves-effect waves-dark"
      [href]="onClickTab('lists')"
      pTooltip="List Builder"
      tooltipPosition="bottom"
    >
      <i class="pi pi-list font-18 mr-2"></i
      ><span class="font-18" *ngIf="visibleLabels">List Builder</span>
    </a>
  </li>

  <li class="nav-item" *ngIf="hasTechNewsJsAccess()">
    <a
      class="nav-link waves-effect waves-dark"
      [href]="onClickTab('charts')"
      pTooltip="Charts"
      tooltipPosition="bottom"
    >
      <i class="pi pi-chart-pie font-18 mr-2"></i
      ><span class="font-18" *ngIf="visibleLabels">Charts</span>
    </a>
  </li>

  <li class="nav-item" *ngIf="hasTechNewsJsAccess()">
    <a
      class="nav-link waves-effect waves-dark"
      [href]="onClickTab('events')"
      pTooltip="Events"
      tooltipPosition="bottom"
    >
      <i class="pi pi-calendar-times font-18 mr-2"></i
      ><span class="font-18" *ngIf="visibleLabels">Events</span>
    </a>
  </li>

  <!-- ============================================================== -->
  <!-- Help menu -->
  <!-- ============================================================== -->
  <p-overlayPanel p-overlaypanel-help #opHelp>
    <ng-template pTemplate>
      <div class="dropdown-left">
        <a [href]="helpUrl()" target="_blank" class="dropdown-item">
          <i class="pi pi-question-circle mr-3"></i><b>Help Guide</b>
        </a>
        <button
          (click)="openReportMissingDataModal()"
          class="dropdown-item"
          style="color: #5f676f"
        >
          <i class="pi pi-flag mr-3"></i><b>Report Missing Data</b>
        </button>
        <a
          href="mailto:support@technews.io"
          target="_blank"
          class="dropdown-item"
        >
          <i class="pi pi-envelope mr-3"></i><b>support@technews.io</b>
        </a>
      </div>
    </ng-template>
  </p-overlayPanel>
  <li class="nav-item">
    <a
      (click)="opHelp.toggle($event)"
      class="nav-link waves-effect waves-dark"
      pTooltip="Help Guide"
      tooltipPosition="bottom"
      ><i class="pi pi-question-circle font-18 mr-2"></i>
      <span class="font-18" *ngIf="visibleLabels">Help</span>
    </a>
  </li>
  <li class="nav-item" *ngIf="expireMessage">
    <div class="nav-link waves-effect waves-dark m-l-20">
      <span class="font-15 font-italic">{{
        visibleMassageSub ? expireMessage : ''
      }}</span>
      &nbsp;
      <a (click)="showSignMeUpDialog()" class="button signUp text-white"
        >Pricing / Sign up</a
      >
    </div>
  </li>
</ul>

<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="d-none d-lg-block navbar-nav float-right">
  <!-- ============================================================== -->
  <!-- User profile -->
  <!-- ============================================================== -->
  <p-overlayPanel #opProfile (onHide)="hideOverlayPanel()">
    <ng-template pTemplate>
      <div>
        <a
          href="javascript:;"
          (click)="myAccount()"
          class="dropdown-item"
          id="myAccountOpt"
        >
          <i class="pi pi-users mr-3"></i> <b>My Account</b>
        </a>
        <!-- Show this if they're already a customer in Stripe -->
        <a
          id="billingOpt"
          *ngIf="paymentsUrl()"
          [href]="paymentsUrl()"
          (click)="hideOverlayPanel()"
          class="dropdown-item"
        >
          <i class="pi pi-money-bill mr-3"></i><b>Billing</b>
        </a>
        <!-- Show this if they're not yet a customer in Stripe -->
        <a
          id="subscriptionOpt"
          href="javascript:;"
          *ngIf="!paymentsUrl()"
          (click)="showSignMeUpDialog()"
          class="dropdown-item"
        >
          <i class="pi pi-money-bill mr-3"></i><b>Subscription</b>
        </a>
        <a
          href="javascript:;"
          (click)="logout()"
          class="dropdown-item"
          id="logoutOpt"
        >
          <i class="pi pi-power-off mr-3"></i><b>Logout</b>
        </a>
      </div>
    </ng-template>
  </p-overlayPanel>
  <li class="nav-item p-pt-1">
    <div
      id="avatarBtn"
      (click)="toggleProfilePanel($event)"
      class="dropdowmnuser"
    >
      <a class="pr-3" id="dropdownUser">
        <div class="float-right">
          <img
            [src]="picture"
            onerror="this.src='assets/images/profile.png'"
            alt="user"
            class="rounded-circle"
            width="31"
          />
        </div>
        <div class="float-right" style="margin-right: 1em">
          <span *ngIf="visibleLabels">
            {{ name }}
            <span *ngIf="account !== name">
              <br />
              {{ account }}
            </span>
          </span>
        </div>
      </a>
    </div>
  </li>
</ul>
<ul class="navbar-nav float-right p-pt-2">
  <li class="nav-item p-pl-2" *ngIf="projects">
    <button
      id="projectBtn"
      [pTooltip]="selectedProject?.tooltip"
      tooltipPosition="bottom"
      (click)="handleEditProject()"
      pButton
      pRipple
      [label]="visibleLabels ? selectedProject?.label : ''"
      type="button"
      icon="pi pi-cog"
      iconPos="right"
      class="p-button-rounded p-button-secondary p-button-text"
    ></button>
  </li>
</ul>
