export class Author {
  constructor(
    public id: number,
    public name: string,
    public topicOutlets: any[],
    public topicId: number,
    public influenceScore: number,
    public emailAddress: string,
    public linkedIn: string,
    public twitter: string,
    public location: string,
    public numItems: number,
    public myCompanyItems: number,
    public picture: string
  ) {}
}
