import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty, eq } from 'lodash';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Subscription';
import { AuthenticationService, UserService } from 'services/index';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { UserData, UserProfile } from 'app/models';
import { ToastNotificationService } from 'app/components';
@Component({
  selector: 'app-login',
  template: `
    <app-spinner alwaysVisible="true"></app-spinner>
  `
})
export class LoginComponent implements OnInit, OnDestroy {
  private userSessionSub: Subscription;
  private loginFrom: string;
  private redirectTo: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private userService: UserService,
    private store: Store<AppState>,
    private notificationService: ToastNotificationService
  ) {}

  ngOnDestroy(): void {
    if (this.userSessionSub) {
      this.userSessionSub.unsubscribe();
    }
  }

  ngOnInit() {
    this.redirectTo = this.authService.getRedirectUrl();
    this.store.select('userData').subscribe(({ data, loginLoadedMessage }) => {
      if (eq(loginLoadedMessage, 'Success')) {
        this.userLogin(data);
      }
      if (eq(loginLoadedMessage, 'Error')) {
        this.notificationService.showMessage(
          'Unable to load user information. Please try again later',
          'Error'
        );
      }
    });

    const { jwtt, type } = this.route.snapshot.queryParams;
    this.loginFrom = type;
    if (!isEmpty(jwtt)) {
      this.authService.saveToken(jwtt);
      this.store.dispatch(actions.setUserData());
    } else {
      this.authService.initializeLogin();
    }
  }

  userLogin(userData: UserData) {
    this.saveUserProfile(userData);
    this.authService.setLoginRender(true);
    this.store.dispatch(
      actions.setIsUserFirstTime({ value: userData.firstLogin })
    );
    this.authService.saveBlogAfterLogin(true);
    this.authService.saveFirstLogin(userData.firstLogin);
    this.router.navigateByUrl(this.redirectTo);
  }

  saveUserProfile(userData: UserData) {
    if (eq(this.loginFrom, 'technews')) {
      const userProfile = new UserProfile({
        firstName: userData.firstName,
        lastName: userData.lastName,
        emailAddress: userData.userName,
        picture: this.userService.getUserPicture(userData.userId),
        login: this.loginFrom
      });
      this.authService.saveUserProfile(userProfile);
    }
  }
}
