import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/services';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { ToastNotificationService } from 'app/components';
import { ConfirmationService } from 'primeng/api';
import { UserEdit } from 'app/models';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  providers: [ConfirmationService]
})
export class UsersListComponent implements OnInit {
  public userList: any[];
  public accountId: number;
  public username: string;
  constructor(
    private userService: UserService,
    private store: Store<AppState>,
    private notificationService: ToastNotificationService,
    private confirmationService: ConfirmationService
  ) {
    this.userList = [];
  }

  ngOnInit(): void {
    this.store.select('myAccount').subscribe(({ isEdited, isCreated }) => {
      if (isEdited || isCreated) {
        this.getUsersList();
      }
    });

    this.store.select('userData').subscribe(({ data }) => {
      if (data) {
        this.accountId = data.accountId;
        this.username = data.userName;
        this.getUsersList();
      }
    });
  }

  public getUsersList(): void {
    this.userService.getUsersByAccountId(this.accountId).subscribe(response => {
      if (response) {
        this.userList = response.map(item => {
          return {
            ...item,
            avatar: this.getAvatar(item.userId)
          };
        });
      }
    });
  }

  public getAvatar(userId) {
    return this.userService.getUserPicture(userId);
  }

  public editUser(user) {
    const userEdit = new UserEdit({ ...user });
    this.store.dispatch(actions.showEditUserDialog({ user: userEdit }));
  }

  public deleteConfirmation(event: Event, user) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.userService.deleteUser(user.userId).subscribe(
          response => {
            this.notificationService.showMessage(
              'User has been deleted successfuly.',
              'Success'
            );
            this.getUsersList();
          },
          error => {
            this.notificationService.showMessage(
              'Error deleting user',
              'Error'
            );
          }
        );
      },
      reject: () => {
        return;
      }
    });
  }

  public newUser() {
    this.store.dispatch(actions.showNewUserDialog());
  }
}
