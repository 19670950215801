export interface UserDataProps {
  accountId?: number;
  accountName?: string;
  firstName?: string;
  lastName?: string;
  products?: ProductProps[];
  firstLogin?: boolean;
  name?: string;
  organizationType?: string;
  role?: string;
  userId?: number;
  userName?: string;
  paymentsUrl?: string;
  trialActivityExceeded?: boolean;
  hasPassword?: boolean;
}

export interface ProductProps {
  product?: string;
  subscriber?: boolean;
  trial?: boolean;
  expiration?: Date;
}

export class UserData {
  public accountId: number;
  public accountName: string;
  public firstName: string;
  public lastName: string;
  public products: any;
  public firstLogin: boolean;
  public name: string;
  public organizationType: string;
  public role: string;
  public userId: number;
  public userName: string;
  public paymentsUrl: string;
  public trialActivityExceeded: boolean;
  public hasPassword: boolean;

  constructor({
    accountId,
    accountName,
    firstName,
    lastName,
    products,
    firstLogin,
    name,
    organizationType,
    role,
    userId,
    userName,
    paymentsUrl,
    trialActivityExceeded,
    hasPassword
  }: UserDataProps) {
    this.accountId = accountId || null;
    this.accountName = accountName || null;
    this.firstName = firstName || null;
    this.lastName = lastName || null;
    this.products = products || [];
    this.firstLogin = firstLogin || false;
    this.name = name || null;
    this.organizationType = organizationType || null;
    this.role = role || null;
    this.userId = userId || null;
    this.userName = userName || null;
    this.paymentsUrl = paymentsUrl || null;
    this.trialActivityExceeded = trialActivityExceeded || null;
    this.hasPassword = hasPassword || false;
  }
}
