import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { distinctUntilKeyChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { eq } from 'lodash';

@Component({
  selector: 'app-facets-sort',
  template: `
    <p-selectButton
      [options]="sortOptions"
      [(ngModel)]="sortSelected"
      optionLabel="label"
      optionValue="value"
      (onOptionClick)="handleSortOptionClick($event)"
    ></p-selectButton>
  `
})
export class FacetsSortComponent implements OnInit {
  @Output() sortEmitter = new EventEmitter<any>();
  public sortSelected: string;
  public sortOptions: any[];
  constructor(private store: Store<AppState>) {
    this.sortSelected = 'results';
    this.sortOptions = [
      { label: 'Articles', value: 'results' },
      { label: 'Popular', value: 'influence' }
    ];
  }

  ngOnInit() {
    this.store
      .select('ui')
      .pipe(distinctUntilKeyChanged('screenSizeValues'))
      .subscribe(({ screenSizeValues }) => {
        if (screenSizeValues) {
          const { scrWidth } = screenSizeValues;
          if (scrWidth <= 1565) {
            this.sortOptions = this.sortOptions.map(item => {
              return {
                ...item,
                label: eq(item.value, 'results') ? 'Art..' : 'Pop..'
              };
            });
          } else {
            this.sortOptions = this.sortOptions.map(item => {
              return {
                ...item,
                label: eq(item.value, 'results') ? 'Articles' : 'Popular'
              };
            });
          }
        }
      });
  }

  public handleSortOptionClick({ option }) {
    this.sortEmitter.emit(option);
  }
}
