import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilKeyChanged } from 'rxjs/operators';
import { get } from 'lodash';
import { AppState } from 'app/app.reducer';
import { Content } from 'app/constants';
import { Paginator } from 'primeng/paginator';
import * as actions from 'redux/actions';

@Component({
  selector: 'app-news-feed',
  template: `
    <div
      class="p-d-flex p-jc-between"
      style="border-bottom: 'solid 0.5px #f0f0f0'; padding-top: 20px"
    >
      <div></div>
      <div>
        <h4 class="p-d-flex p-jc-center">News Feed</h4>
        <p>
          Recent coverage related to topics, authors and outlets you follow.
        </p>
      </div>
      <div></div>
    </div>
    <app-loading [isLoading]="isLoadingContent"></app-loading>
    <div class="p-p-2">
      <app-content-items
        [parentName]="parentName"
        [items]="feeds"
        [loading]="loading"
        [pageSize]="pageSize"
        [page]="page"
        [total]="total"
      ></app-content-items>
      <div
        class="p-d-flex p-jc-between"
        style="border-top: 'solid 0.5px #f0f0f0'"
      >
        <div>
          <p-paginator
          *ngIf="totalPages >= 2"
            #pp
            [pageLinkSize]="3"
            [showFirstLastIcon]="false"
            (onPageChange)="getPage($event)"
            [rows]="pageSize"
            [totalRecords]="total"
          >
          </p-paginator>
        </div>
        <div class="p-mt-2" *ngIf="hideSort">
          <app-sort-options
            (sortChangesEmiter)="sortChange($event)"
            [defaultSort]="sortSelected"
          ></app-sort-options>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./news-feed.component.scss']
})
export class NewsFeedComponent implements OnInit {
  public parentName: string;
  public feeds: any[] = [];
  public loading: boolean;
  public pageSize: number;
  public page: number;
  public total: number;
  public totalPages: number;
  public currentPage: number;
  public projectId: number;
  public sortSelected: string;
  public hideSort: boolean;
  public isLoadingContent: boolean;

  @ViewChild('pp', { static: false }) paginator: Paginator;

  constructor(private store: Store<AppState>) {
    this.parentName = Content.NewsFeed;
    this.feeds = [];
    this.loading = false;
    this.pageSize = 50;
    this.page = 1;
    this.projectId = null;
    this.currentPage = 0;
    this.sortSelected = 'recent';
    this.hideSort = false;
  }

  ngOnInit() {
    this.store
      .select('project')
      .pipe(distinctUntilKeyChanged('projectSelected'))
      .subscribe(({ projectSelected }) => {
        if (projectSelected) {
          this.projectId = projectSelected?.value;
          this.getContentItems();
        }
      });

    this.store
      .select('newsFeed')
      .subscribe(({ projectId: number, data, loaded, loading }) => {
        this.isLoadingContent = loading;
        this.loading = loaded;
        this.feeds = [];
        const contentItems = get(data, 'content', []);
        if (contentItems.length > 0) {
          this.feeds = contentItems;
          this.currentPage = data.number;
          this.page = data.number + 1;
          this.totalPages = data.totalPages;
          this.total = data.totalElements;
        } else {
          this.feeds = [];
          this.currentPage = 0;
          this.page = 0;
          this.totalPages = 0;
          this.total = 0;
        }
        if (loaded) {
          setTimeout(() => {
            this.store.dispatch(actions.stopLoading());
          }, 1000);
        }
        // this.scrollTop();
      });
  }

  private getContentItems() {
    this.store.dispatch(actions.startLoading());
    this.store.dispatch(
      actions.setNewsFeed({
        projectId: this.projectId,
        sort: this.sortSelected,
        page: this.currentPage,
        size: this.pageSize
      })
    );
  }

  public sortChange(e) {
    this.sortSelected = e;
    this.currentPage = 0;
    this.page = 1;
    this.resetPaginator();
    this.getContentItems();
  }

  getPage({ page }) {
    this.currentPage = page;
    this.page = page + 1;
    this.getContentItems();
  }

  resetPaginator() {
    if (this.paginator) {
      this.paginator.changePage(0);
    }
  }
}
