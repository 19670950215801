import { createAction, props } from '@ngrx/store';
import { UserSession } from 'app/models';

export const setIsUserFirstTime = createAction(
  '[Session] setIsUserFirstTime',
  props<{ value: boolean }>()
);

export const hideTourByCollectionSelected = createAction(
  '[Welcome Dialog] hideTourByCollectionSelected',
  props<{ value: boolean }>()
);

export const showWelcomeDialog = createAction(
  '[Welcome Dialog] showWelcomeDialog'
);

export const hideWelcomeDialog = createAction(
  '[Welcome Dialog] hideWelcomeDialog'
);

export const nextWelcomeDialog = createAction(
  '[Welcome Dialog] nextWelcomeDialog'
);

export const doneWelcomeDialog = createAction(
  '[Welcome Dialog] doneWelcomeDialog'
);
export const unSetUserSession = createAction('[Session] unSetUserSession');
