import { createReducer, on } from '@ngrx/store';
import {
  showAuthorManagerDialog,
  hideAuthorManagerDialog,
  resetAuthorState,
  createAuthor,
  createAuthorSuccess,
  createAuthorError,
  updateAuthor,
  updateAuthorSuccess,
  updateAuthorError,
  deleteAuthor,
  deleteAuthorSuccess,
  deleteAuthorError
} from 'redux/actions';

export interface AuthorManagerState {
  author: any;
  success: boolean;
  loading: boolean;
  creationMode: boolean;
  editionMode: boolean;
  message: string;
  error: any;
  showAuthorManager: boolean;
  successResponse: any;
}

const initialState: AuthorManagerState = {
  author: null,
  success: false,
  loading: false,
  editionMode: false,
  creationMode: false,
  message: null,
  error: null,
  showAuthorManager: false,
  successResponse: null
};

const _authorManagerReducer = createReducer(
  initialState,

  on(showAuthorManagerDialog, (state, { author }) => ({
    ...state,
    showAuthorManager: true,
    editionMode: author ? true : false,
    creationMode: !author ? true : false,
    author,
    successResponse: null
  })),
  on(hideAuthorManagerDialog, () => ({
    editionMode: false,
    creationMode: false,
    author: null,
    message: null,
    loading: false,
    success: false,
    error: null,
    successResponse: null,
    showAuthorManager: false
  })),

  on(resetAuthorState, state => ({
    ...state,
    creationMode: false,
    editionMode: false,
    message: null,
    loading: false,
    success: false,
    error: null,
    author: null,
    successResponse: null
  })),

  on(createAuthor, (state, { payload }) => ({
    ...state,
    author: payload,
    creationMode: false,
    message: null,
    loading: true,
    success: false,
    error: null,
    successResponse: null
  })),

  on(createAuthorSuccess, (state, { author }) => ({
    ...state,
    loading: false,
    success: true,
    message: `Author ${author.name} has been added successfully`,
    successResponse: author,
    showAuthorManager: false
  })),

  on(createAuthorError, (state, { payload }) => ({
    ...state,
    loading: false,
    success: false,
    successResponse: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Error adding author: ${payload?.message}`
    }
  })),

  on(updateAuthor, (state, { payload }) => ({
    ...state,
    author: payload,
    editionMode: false,
    message: null,
    loading: true,
    success: false,
    error: null,
    successResponse: null
  })),

  on(updateAuthorSuccess, (state, { author }) => ({
    ...state,
    loading: false,
    success: true,
    message: `Author ${author.name} has been updated successfully`,
    author,
    successResponse: author,
    showAuthorManager: false
  })),

  on(updateAuthorError, (state, { payload }) => ({
    ...state,
    loading: false,
    success: false,
    successResponse: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Error updating author: ${payload?.message}`
    }
  })),

  on(deleteAuthor, state => ({
    ...state,
    message: null,
    loading: true,
    success: false,
    error: null,
    successResponse: null
  })),

  on(deleteAuthorSuccess, (state, { author }) => ({
    ...state,
    loading: false,
    success: true,
    message: `Author ${author.name} has been unfollowed successfully`,
    successResponse: author,
    showAuthorManager: false,
    author: null
  })),

  on(deleteAuthorError, (state, { payload }) => ({
    ...state,
    loading: false,
    success: false,
    successResponse: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Error deleting author: ${payload?.message}`
    }
  }))
);

export function authorManagerReducer(state, action) {
  return _authorManagerReducer(state, action);
}
