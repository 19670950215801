<app-user-avatar (imageSelected)="setImageFile($event)"></app-user-avatar>
<form [formGroup]="myUserForm">
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-6">
      <label htmlFor="firstName">First Name*</label>
      <input
        id="firstName"
        formControlName="firstName"
        [class]="invalidField('firstName') ? 'ng-invalid ng-dirty' : ''"
        pInputText
        maxlength="250"
      />
      <small
        class="block"
        *ngIf="invalidField('firstName')"
        id="name-help"
        class="p-error"
        >First Name is a required field.</small
      >
    </div>
    <div class="p-field p-col-12 p-md-6">
      <label htmlFor="lastName">Last Name*</label>
      <input
        id="lastName"
        formControlName="lastName"
        [class]="invalidField('lastName') ? 'ng-invalid ng-dirty' : ''"
        pInputText
        maxlength="250"
      />
      <small
        class="block"
        *ngIf="invalidField('lastName')"
        id="name-help"
        class="p-error"
        >Last Name is a required field.</small
      >
    </div>

    <div class="p-field p-col-12 p-md-6">
      <label htmlFor="email">Email Address*</label>
      <input
        id="email"
        formControlName="emailAddress"
        [class]="invalidField('emailAddress') ? 'ng-invalid ng-dirty' : ''"
        pInputText
        maxlength="250"
      />

      <small
        class="block"
        *ngIf="invalidField('emailAddress')"
        id="name-help"
        class="p-error"
        >Enter a valid email address.</small
      >
    </div>

    <div class="p-field p-col-12 p-md-6" *ngIf="hasPassword">
      <label htmlFor="currentPassword">Current Password*</label>
      <input
        id="currentPassword"
        type="password"
        formControlName="currentPassword"
        [class]="invalidField('currentPassword') ? 'ng-invalid ng-dirty' : ''"
        pInputText
        maxlength="250"
      />

      <small
        class="block"
        *ngIf="invalidField('currentPassword')"
        id="name-help"
        class="p-error"
        >Current Password is a required field.</small
      >
    </div>

    <div class="p-field p-col-12 p-md-6" *ngIf="hasPassword">
      <label htmlFor="newPassword">New Password</label>
      <input
        id="newPassword"
        type="password"
        formControlName="newPassword"
        pInputText
        maxlength="250"
      />
    </div>

    <div class="p-field p-col-12 p-md-6" *ngIf="hasPassword">
      <label htmlFor="confirmPassword">Confirm New Password</label>
      <input
        id="confirmPassword"
        type="password"
        formControlName="confirmPassword"
        pInputText
        maxlength="250"
        (keydown.enter)="handleSubmit()"
      />
    </div>
  </div>
</form>
<div class="box-button-right">
  <button
    pButton
    label="Cancel"
    class="p-button-raised p-button-secondary p-button-text p-mr-2"
    (click)="onClose()"
  ></button>
  <button
    [disabled]="isSaving"
    icon="pi pi-check"
    pButton
    [label]="isSaving ? 'Saving' : 'Save'"
    class="p-save"
    (click)="handleSubmit()"
  ></button>
</div>
