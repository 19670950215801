import { SearchProps } from 'app/interfaces';

export class Search {
  public keywords: string;
  public facets: any;
  public timeframe: string;
  public analysts: string;
  public countries: string;
  public topOutletsOnly: boolean;
  public topAuthorsOnly: boolean;
  public from: Date;
  public to: Date;
  public authors: number[];
  public excludeAuthors: number[];
  public excludeOutlets: number[];
  public outlets: number[];
  // Filters for topics
  public topicTypes: string;
  public topicsFilter: string;
  public topicsCollection: number;
  public topics: number[];

  constructor({
    keywords,
    facets,
    timeframe,
    analysts,
    countries,
    topOutletsOnly,
    topAuthorsOnly,
    from,
    to,
    authors,
    excludeAuthors,
    excludeOutlets,
    outlets,
    topicTypes,
    topicsFilter,
    topicsCollection
  }: SearchProps) {
    this.keywords = keywords || null;
    this.facets = facets || null;
    this.timeframe = timeframe || null;
    this.analysts = analysts || null;
    this.countries = countries || null;
    this.topOutletsOnly = topOutletsOnly || false;
    this.topAuthorsOnly = topAuthorsOnly || false;
    this.from = from || null;
    this.to = to || null;
    this.authors = authors || [];
    this.excludeAuthors = excludeAuthors || [];
    this.excludeOutlets = excludeOutlets || [];
    this.outlets = outlets || [];
    this.topicTypes = topicTypes || null;
    this.topicsFilter = topicsFilter || null;
    this.topicsCollection = topicsCollection || null;
  }
}
