import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { MenuItem } from 'primeng/api';
import * as actions from 'redux/actions';
import { eq, isEmpty } from 'lodash';
import { Search } from 'app/models';

@Component({
  selector: 'app-topics-menubar',
  templateUrl: './topics-menu.component.html',
  styleUrls: ['./topics-menubar.component.scss']
})
export class TopicsMenubarComponent implements OnInit {
  public items: MenuItem[];
  public topicsMenuValues: any;
  public search: Search;
  public isTopicsSearch: boolean;
  public isKeywordsNull: boolean;

  constructor(private store: Store<AppState>) {
    this.items = [];
    this.isKeywordsNull = true;
  }

  ngOnInit() {
    this.store.select('search').subscribe(({ searchBody }) => {
      this.search = searchBody;
      if (searchBody) {
        const { keywords } = searchBody;
        this.isKeywordsNull = isEmpty(keywords);
        if (!this.isKeywordsNull) {
          this.resetMenuColorizer();
        }
      }
    });

    this.store
      .select('topics')
      .subscribe(({ topicsMenu, topicMenuItemSelected }) => {
        if (topicsMenu) {
          const topicsMenuValues = topicsMenu.filter(
            item => !eq(item.label, 'Collections')
          );
          this.topicsMenuValues = [...topicsMenuValues];
          this.createTopicsMenubar(this.topicsMenuValues);
        }

        /* Colorize menu item selected */
        if (topicMenuItemSelected) {
          const itemSelected = {
            ...topicMenuItemSelected,
            styleClass: 'menucus'
          };

          const headerItem = this.items.find(i =>
            eq(i.label, topicMenuItemSelected.header)
          );

          const headerColor = { ...headerItem, styleClass: 'menucus' };

          this.items = this.items.map(i => {
            const newItem = eq(i.label, headerColor.label) ? headerColor : i;

            return {
              ...newItem
            };
          });

          this.items = this.items.map(menu => {
            let items = menu.items ? menu.items : null;
            if (items) {
              items = items.map(value => {
                const item = eq(value.label, itemSelected.label)
                  ? itemSelected
                  : value;
                return {
                  ...item
                };
              });
            }
            const menuItem = eq(menu.label, itemSelected.label)
              ? itemSelected
              : menu;
            return {
              ...menuItem,
              items
            };
          });
        } else {
          if (this.isKeywordsNull) {
            this.items = this.items.map(i => {
              return {
                ...i,
                styleClass: eq(i.label, 'All Topics') ? 'menucus' : null
              };
            });
          }
        }
      });
  }

  resetMenuColorizer() {
    this.items = this.items.map(i => {
      return {
        ...i,
        styleClass: null
      };
    });
  }

  handleOnClickMenu({ item, topicsFilter, topicsCollection }) {
    // this.store.dispatch(actions.resetSearch({ onReset: true }));

    this.store.dispatch(actions.startLoading());
    let newSearch = null;
    if (this.search.keywords) {
      newSearch = new Search({
        ...this.search,
        facets: { TOPICS: 'relatedScore desc' },
        topicsFilter,
        topicsCollection
      });
    } else {
      newSearch = new Search({
        facets: { TOPICS: 'newsAuthorsWeek desc' },
        topicsFilter,
        topicsCollection
      });
    }

    this.store.dispatch(
      actions.setSearch({
        search: newSearch
      })
    );
    this.store.dispatch(
      actions.setTopicsRanked({
        searchBody: newSearch,
        page: 0,
        isNewSearch: true
      })
    );
    this.store.dispatch(actions.setTopicMenuItemSelected({ item }));
    if (this.isKeywordsNull) {
      this.store.dispatch(actions.tabControl({ index: 0 }));
    }
  }

  createTopicsMenubar(menuList) {
    const fxCommand = ({ item }) => {
      const filter = {
        item,
        topicsFilter: item.topicsFilter || null,
        topicsCollection: item.topicsCollection || null
      };
      this.handleOnClickMenu(filter);
    };

    this.items = menuList.map(menuItem => {
      const icon = null; // find(MENU_ICONS, i => eq(menuItem.label, i.key))?.icon;
      let items = !!menuItem.items.length ? menuItem.items : null;
      if (items) {
        items = items.map(value => {
          const subitems = !!value.items.length ? value.items : null;
          if (subitems) {
            items = subitems.map(subItem => {
              return {
                ...subItem,
                header: value.label,
                command: fxCommand
              };
            });
          }
          return {
            ...value,
            header: menuItem.label,
            items,
            command: !subitems ? fxCommand : null
          };
        });
      }
      return {
        ...menuItem,
        icon,
        items,
        command: !items ? fxCommand : null
      };
    });
  }
}
