<toast-notification></toast-notification>
<div
  class="surface-ground px-4 py-8 flex align-items-center justify-content-center mt-3"
>
  <div class="surface-card shadow-2 border-round w-full-wr">
    <div class="surface-img text-center mb-5 w-medium">
      <img
        src="../../../assets/images/scalableVectorGraphics/logo-full.svg"
        alt="Image"
        class="m-5"
      />
    </div>
    <form [formGroup]="resetPasswordForm">
      <div class="p-4">
        <div class="text-center font-title">
          Use this form to reset your password.
        </div>
        <div class="space"></div>
        <span class="p-float-label">
          <input
            id="newPassword"
            type="password"
            pInputText
            [class]="
              invalidField('newPassword')
                ? 'ng-invalid ng-dirty w-full p-inputtext-lg'
                : 'w-full p-inputtext-lg'
            "
            formControlName="newPassword"
            (keydown.enter)="updatePassword()"
          />
          <label htmlFor="newPassword" class="text-900 font-xl"
            >New Password</label
          >
        </span>
        <span
          class="block"
          *ngIf="invalidField('newPassword')"
          id="newPassword-help"
          class="p-error"
          >New Password is a required field.</span
        >
        <div class="space"></div>

        <span class="p-float-label">
          <input
            id="confirmPassword"
            type="password"
            pInputText
            [class]="
              invalidField('confirmPassword')
                ? 'ng-invalid ng-dirty w-full p-inputtext-lg'
                : 'w-full p-inputtext-lg'
            "
            formControlName="confirmPassword"
            (keydown.enter)="updatePassword()"
          />
          <label htmlFor="confirmPassword" class="text-900 font-xl"
            >Confirm New Password</label
          >
        </span>
        <span
          class="block"
          *ngIf="invalidField('confirmPassword')"
          id="confirmPassword-help"
          class="p-error"
          >Confirm Password is a required field.</span
        >

        <div class="flex w-full justify-content-end mt-3">
          <button
            [disabled]="isUpdating"
            pButton
            label="Cancel"
            class="p-button-lg p-mr-2"
            (click)="cancel()"
          ></button>
          <button
            [disabled]="isUpdating"
            pButton
            pRipple
            label="Update"
            class="p-button-lg"
            (click)="updatePassword()"
          ></button>
        </div>

        <div class="text-center mt-3">
          Having trouble? Contact us at
          <a
            href="mailto:support@technews.io"
            class="font-medium no-underline ml-2 text-grey-500 cursor-pointer"
            >support@technews.io</a
          >
        </div>
      </div>
    </form>
  </div>
</div>
