import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { ConfirmationService } from 'primeng/api';
import { findIndex } from 'lodash';
import { update } from 'lodash/fp';
import { deleteTopic } from 'app/redux/actions';
import { TopicResponse } from 'app/models';
import { Content } from 'app/constants';
import { SidebarList } from 'app/models';

@Component({
  selector: 'app-topics-list',
  template: `
    <app-sidebar-list
      [sidebarData]="topics"
      (openNewDialogEmiter)="handleCreateNewTopic()"
      (openSelectDialogEmiter)="selectedTopic($event)"
      (openEditDialogEmiter)="handleEditTopic($event)"
      (unfollowEmiter)="unfollow($event)"
    ></app-sidebar-list>
  `
})
export class SidebarTopicsListComponent implements OnInit {
  @Output() createNewTopicOnclick: EventEmitter<any> = new EventEmitter();
  @Output() editTopicOnclick: EventEmitter<any> = new EventEmitter();
  @Output() selectedTopicOnClick: EventEmitter<any> = new EventEmitter();
  public selectTopic: any;
  public topics: SidebarList[];
  public sort: any;
  public showScrollbar: boolean;
  public isEdit: boolean;
  public isUnfollow: boolean;
  public currentProject: any;
  public userId: any;
  public parentName: string;

  @ViewChildren('sortNameIcon, sortCategoryIcon', {
    read: ElementRef
  })
  sortIcons: QueryList<ElementRef>;

  constructor(
    private store: Store<AppState>,
    private confirmationService: ConfirmationService
  ) {
    this.topics = [];
    this.showScrollbar = false;
    this.isEdit = false;
    this.isUnfollow = false;
    this.userId = null;
    this.parentName = Content.Topic;
  }

  ngOnInit() {
    this.store.select('userData').subscribe(({ data }) => {
      if (data) {
        this.userId = data.userId;
      }
    });

    this.store.select('topics').subscribe(({ topics }) => {
      if (topics && topics.length > 0) {
        this.topics = topics.map(
          item =>
            new SidebarList({
              title: item.name,
              subTitle: this.parentName,
              loadingIcon: false,
              picture: null,
              hasPicture: false,
              parentObject: item,
              parentName: this.parentName
            })
        );
      } else {
        this.topics = [];
      }
      this.showScrollbar = this.topics.length > 10;
    });

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.currentProject = projectSelected;
      }
    });

    this.store
      .select('topicDialog')
      .subscribe(({ topicSelected, loading, loaded, isSidebarTopic }) => {
        if (isSidebarTopic) {
          if (topicSelected) {
            this.loadingTopicDialog(topicSelected.userTopicId, loading, loaded);
          }
        }
      });
  }

  public handleCreateNewTopic() {
    this.createNewTopicOnclick.emit(null);
  }

  public handleEditTopic(index) {
    this.editTopicOnclick.emit(index);
  }

  public selectedTopic(topic) {
    this.selectedTopicOnClick.emit(topic);
  }

  loadingTopicDialog(topicId, loading, loaded) {
    const index = findIndex(this.topics, { userTopicId: topicId });
    let updateTopics: SidebarList[];

    if (loading) {
      updateTopics = update(
        index,
        topic => ({ ...topic, loadingIcon: true }),
        this.topics
      );
    }
    if (loaded) {
      updateTopics = update(
        index,
        topic => ({ ...topic, loadingIcon: false }),
        this.topics
      );
    }
    this.topics = updateTopics;
  }

  handleUnfollow(value) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to unfollow this topic?',
      header: 'Unfollow Confirmation',
      icon: 'pi pi-exclamation-triangle',
      key: 'unfollowConfirmation',
      accept: () => {
        this.unfollow(value);
      },
      reject: type => {
        // handle rejected option ConfirmEventType.CANCEL
      }
    });
  }

  public unfollow(value) {
    const payload = {
      id: value.id,
      name: value.name,
      topicId: value.userTopicId,
      userId: this.userId,
      userProject: this.currentProject
    };
    this.store.dispatch(deleteTopic(payload));
  }
}
