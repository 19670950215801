import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { tap, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { OutletService } from 'app/services';
import { FacetSearch } from 'app/models';

@Injectable()
export class OutletDialogEffects {
  constructor(
    private actions$: Actions,
    private outletService: OutletService
  ) {}

  loadOutletDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setOutletDialog),
      mergeMap(action =>
        this.getOutlet$(action).pipe(
          switchMap(response => [
            actions.setOutletDialogSuccess({
              outlet: response
            }),
            actions.loadOutletContent({
              facetSearch: new FacetSearch(
                action.outletId,
                'outlet',
                action.keywords,
                action.sort,
                0,
                50
              ),
              outlet: response
            })
          ]),
          catchError(err => of(actions.setOutletDialogError({ payload: err })))
        )
      )
    )
  );

  getOutlet$(action): Observable<any> {
    return this.outletService.getOutletById(action.outletId);
  }
}
