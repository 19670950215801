<perfect-scrollbar
  class="scroll-container"
  [config]="config"
  #rankedListPerfectScroll
>
  <div
    *ngIf="companies.length === 0"
    class="d-flex justify-content-center text-muted"
  >
    No records to display
  </div>
  <div class="card border" *ngIf="!!companies.length">
    <p-table
      [value]="companies"
      styleClass="p-datatable-sm"
      selectionMode="single"
    >
      <ng-template pTemplate="header">
        <tr>
          <th class="col-star"></th>
          <th class="col-name">
            <a href="javascript:;" (click)="sortChange(sortNameIcon)"
              >Name
              <i id="sortNameIcon" class="pi pi-sort" #sortNameIcon></i>
            </a>
          </th>
          <th class="col-relevance">
            <a href="javascript:;" (click)="sortChange(sortRelevantIcon)"
              >Mentions
              <i
                id="sortRelevantIcon"
                class="pi pi-sort-down"
                #sortRelevantIcon
              ></i>
            </a>
          </th>
          <th class="col-shortterm">
            <a href="javascript:;" (click)="sortChange(sortTrendIcon)"
              >Trending past week
              <i id="sortTrendIcon" class="pi pi-sort" #sortTrendIcon></i>
            </a>
          </th>
          <th class="col-numarticle">
            <a href="javascript:;" (click)="sortChange(sortMonthDeltaIcon)"
              >New authors past month
              <i
                id="sortMonthDeltaIcon"
                class="pi pi-sort"
                #sortMonthDeltaIcon
              ></i>
            </a>
          </th>
          <th class="col-numarticle">
            <a href="javascript:;" (click)="sortChange(sortYearDeltaIcon)"
              >New authors past year
              <i
                id="sortYearDeltaIcon"
                class="pi pi-sort"
                #sortYearDeltaIcon
              ></i>
            </a>
          </th>
          <th class="col-longterm">
            <a href="javascript:;" (click)="sortChange(sortLongTrendIcon)"
              >Growth rate past year
              <i
                id="sortLongTrendIcon"
                class="pi pi-sort"
                #sortLongTrendIcon
              ></i>
            </a>
          </th>
          <th class="col-longterm">Coverage past year</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-company>
        <tr (click)="showCompanyDialog(company)">
          <td class="col-star">
            <button
              (click)="handleStarCheckOnClick(company)"
              pButton
              pRipple
              type="button"
              [icon]="!company.isSaved ? 'pi pi-star-o' : 'pi pi-star'"
              class="p-button-rounded p-button-secondary p-button-text"
            ></button>
          </td>
          <td class="col-name">
            {{ company.name }}
          </td>
          <td class="col-relevance">
            <p-progressBar
              [style]="{ background: 'white' }"
              [showValue]="false"
              [value]="company.stats?.totalRelevance"
            ></p-progressBar>
          </td>
          <td class="col-shortterm">
            <p-progressBar
              [style]="{ background: 'white' }"
              [showValue]="false"
              [value]="company.stats?.trend"
            ></p-progressBar>
          </td>
          <td class="col-numarticle">
            <div>
              {{ company.stats?.deltaMonth > 0 ? '+' : ''
              }}{{ company.stats?.deltaMonth | number }}
            </div>
            <div class="font-italic small">
              {{ company.stats?.deltaMonthPercent > 0 ? '+' : ''
              }}{{ company.stats?.deltaMonthPercent | percent }}
            </div>
          </td>
          <td class="col-numarticle">
            <div>
              {{ company.stats?.deltaYear > 0 ? '+' : ''
              }}{{ company.stats?.deltaYear | number }}
            </div>
            <div class="font-italic small">
              {{ company.stats?.deltaYearPercent > 0 ? '+' : ''
              }}{{ company.stats?.deltaYearPercent | percent }}
            </div>
          </td>
          <td class="col-shortterm">
            <p-progressBar
              [style]="{ background: 'white' }"
              [showValue]="false"
              [value]="company.stats?.longTrend"
            ></p-progressBar>
          </td>
          <td class="col-longterm">
            <div id="chart-2" *ngIf="company?.longTermChart">
              <app-long-term-chart
                [topic]="company.longTermChart"
              ></app-long-term-chart>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</perfect-scrollbar>
<div class="row" *ngIf="!!companies.length">
  <div class="col-12 m-b-5">
    <div class="float-left">
      <p-paginator
        [showCurrentPageReport]="false"
        currentPageReportTemplate="Showing {{ page }} of {{ totalPages }} pages"
        [pageLinkSize]="3"
        [showFirstLastIcon]="false"
        (onPageChange)="getPage($event)"
        [rows]="pageSize"
        [totalRecords]="totalItems"
      >
      </p-paginator>
    </div>
  </div>
</div>
