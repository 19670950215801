import { Component, OnInit } from '@angular/core';
import { eq, last } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { removeContent, showNotification, addContent } from 'redux/actions';
import { OffCanvasContent, Notification } from 'models/index';

@Component({
  selector: 'app-off-canvas',
  templateUrl: './off-canvas.component.html',
  styleUrls: ['./off-canvas.component.scss']
})
export class OffCanvasComponent implements OnInit {
  public showOffCanvas: boolean;
  public showAuthorApp: boolean;
  public showOutletApp: boolean;
  public showAuthorFacet: boolean;
  public showOutletFacet: boolean;
  public showCompanyFacet: boolean;
  public showTopicFacet: boolean;
  public showProductFacet: boolean;
  public showBlogContent: boolean;
  public showBlogButton: boolean;
  public authorDetails: any;
  public outletDetails: any;
  public authorFacetsDetails: any;
  public outletFacetsDetails: any;
  public companyFacetsDetails: any;
  public productFacetsDetails: any;
  public topicFacetsDetails: any;
  public content: OffCanvasContent;
  public topicId: number;
  public key: number;
  public isLoadingContent: boolean;

  constructor(private store: Store<AppState>) {
    this.showOffCanvas = false;
    this.showAuthorApp = false;
    this.showOutletApp = false;
    this.showAuthorFacet = false;
    this.showOutletFacet = false;
    this.showCompanyFacet = false;
    this.showTopicFacet = false;
    this.showProductFacet = false;
    this.showBlogContent = false;
    this.showBlogButton = true;
    this.authorFacetsDetails = null;
    this.outletFacetsDetails = null;
    this.companyFacetsDetails = null;
    this.topicFacetsDetails = null;
    this.productFacetsDetails = null;
    this.isLoadingContent = true;
  }

  ngOnInit() {
    this.offCanvasStore();
  }

  offCanvasStore() {
    this.store.select('offCanvas').subscribe(({ OffCanvasContents, error }) => {
      if (error) {
        this.store.dispatch(
          showNotification({
            notify: new Notification(`${error.entity} not found.`, 'info', 'tl')
          })
        );
      }

      this.showOffCanvas = false;
      if (OffCanvasContents.length > 0) {
        this.content = last(OffCanvasContents);
        if (this.content) {
          this.showOffCanvas = this.content.showContent;
          this.key = this.content.contentKey;

          if (eq(this.content.contentType, 'facetAuthor')) {
            this.authorFacetsDetails = this.content.contentData;
            this.showCompanyFacet = false;
            this.showTopicFacet = false;
            this.showOutletFacet = false;
            this.showOutletApp = false;
            this.showAuthorApp = false;
            this.showProductFacet = false;
            this.showBlogContent = false;
            this.showAuthorFacet = true;
            this.showBlogButton = false;
          }

          if (eq(this.content.contentType, 'facetOutlet')) {
            this.outletFacetsDetails = this.content.contentData;
            this.showCompanyFacet = false;
            this.showTopicFacet = false;
            this.showOutletApp = false;
            this.showAuthorApp = false;
            this.showProductFacet = false;
            this.showAuthorFacet = false;
            this.showBlogContent = false;
            this.showOutletFacet = true;
            this.showBlogButton = false;
          }

          if (eq(this.content.contentType, 'facetCompany')) {
            this.companyFacetsDetails = this.content.contentData;
            this.showTopicFacet = false;
            this.showOutletApp = false;
            this.showAuthorApp = false;
            this.showAuthorFacet = false;
            this.showProductFacet = false;
            this.showOutletFacet = false;
            this.showBlogContent = false;
            this.showCompanyFacet = true;
            this.showBlogButton = false;
          }

          if (eq(this.content.contentType, 'facetProduct')) {
            this.productFacetsDetails = this.content.contentData;
            this.showProductFacet = true;
            this.showOutletApp = false;
            this.showAuthorApp = false;
            this.showAuthorFacet = false;
            this.showOutletFacet = false;
            this.showCompanyFacet = false;
            this.showTopicFacet = false;
            this.showBlogButton = false;
            this.showBlogContent = false;
          }

          if (eq(this.content.contentType, 'author')) {
            this.authorDetails = this.content.contentData;
            this.topicId = this.content.topicId;
            this.showTopicFacet = false;
            this.showCompanyFacet = false;
            this.showOutletFacet = false;
            this.showOutletApp = false;
            this.showProductFacet = false;
            this.showAuthorFacet = false;
            this.showBlogContent = false;
            this.showBlogButton = false;
            this.showAuthorApp = true;
          }

          if (eq(this.content.contentType, 'outlet')) {
            this.outletDetails = this.content.contentData;
            this.topicId = this.content.topicId;
            this.showTopicFacet = false;
            this.showCompanyFacet = false;
            this.showOutletFacet = false;
            this.showAuthorApp = false;
            this.showProductFacet = false;
            this.showAuthorFacet = false;
            this.showBlogContent = false;
            this.showBlogButton = false;
            this.showBlogButton = false;
            this.showOutletApp = true;
          }

          if (eq(this.content.contentType, 'facetTopic')) {
            this.topicFacetsDetails = this.content.contentData;
            this.topicId = this.content.topicId;
            this.showCompanyFacet = false;
            this.showOutletFacet = false;
            this.showAuthorApp = false;
            this.showAuthorFacet = false;
            this.showProductFacet = false;
            this.showOutletApp = false;
            this.showBlogContent = false;
            this.showBlogButton = false;
            this.showTopicFacet = true;
          }

          if (eq(this.content.contentType, 'blogContent')) {
            this.showCompanyFacet = false;
            this.showOutletFacet = false;
            this.showAuthorApp = false;
            this.showAuthorFacet = false;
            this.showProductFacet = false;
            this.showOutletApp = false;
            this.showTopicFacet = false;
            this.showBlogContent = true;
            this.showBlogButton = true;
          }
        } else {
          this.showOffCanvas = false;
        }
      }
    });
  }

  onClose() {
    this.store.dispatch(removeContent({ key: this.key }));
    setTimeout(() => {
      this.resetShowContent();
    }, 500);
  }

  showBlog() {
    if (!this.showBlogContent) {
      this.store.dispatch(
        addContent({
          contentType: 'blogContent',
          contentData: {},
          topicId: null
        })
      );
    } else {
      this.onClose();
    }
  }

  resetShowContent() {
    this.showAuthorApp = false;
    this.showOutletApp = false;
    this.showAuthorFacet = false;
    this.showOutletFacet = false;
    this.showCompanyFacet = false;
    this.showTopicFacet = false;
    this.showProductFacet = false;
    this.showBlogContent = false;
    this.showBlogButton = true;
  }
}
