import { createAction, props } from '@ngrx/store';
import { CategoryResponse, CategorySubmit } from 'app/models';

// Load Categories
export const loadCategories = createAction(
  '[Category] Load Categories',
  props<{ projectId: number }>()
);

export const loadCategoriesSuccess = createAction(
  '[Category] Load Categories Success',
  props<{ categories: CategoryResponse[] }>()
);

export const loadCategoriesError = createAction(
  '[Category] Load Categories Error',
  props<{ payload: any }>()
);

// Create Category

export const createCategory = createAction(
  '[Category] Create Category',
  props<{ payload: CategorySubmit }>()
);

export const createCategorySuccess = createAction(
  '[Category] Create Category Success',
  props<{ responseSuccess: any }>()
);

export const createCategoryError = createAction(
  '[Category] Create Category Error',
  props<{ payload: any }>()
);

// Update Category

export const updateCategory = createAction(
  '[Category] Update Category',
  props<{ payload: CategorySubmit }>()
);

export const updateCategorySuccess = createAction(
  '[Category] Update Category Success',
  props<{ responseSuccess: any }>()
);

export const updateCategoryError = createAction(
  '[Category] Update Category Error',
  props<{ payload: any }>()
);

// Delete Category

export const deleteCategory = createAction(
  '[Category] Delete Category',
  props<{ payload: CategorySubmit }>()
);

export const deleteCategorySuccess = createAction(
  '[Category] Delete Category Success',
  props<{ responseSuccess: any }>()
);

export const deleteCategoryError = createAction(
  '[Category] Delete Category Error',
  props<{ payload: any }>()
);

// Category Dialogs

export const showCategoriesListDialog = createAction(
  '[Category Dialog] showCategoriesListDialog'
);

export const hideCategoriesListDialog = createAction(
  '[Category Dialog] hideCategoriesListDialog'
);

export const showCategoryManagerDialog = createAction(
  '[Category Dialog] showCategoryManagerDialog',
  props<{ category: CategorySubmit }>()
);

export const hideCategoryManagerDialog = createAction(
  '[Category Dialog] hideCategoryManagerDialog'
);
