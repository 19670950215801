import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { isEmpty, eq } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, UserService } from 'app/services';
import { AppState } from 'app/app.reducer';
import { ScreenSize } from 'app/constants';
import * as actions from 'redux/actions';
import { UserData, UserProfile } from 'app/models';
import { ToastNotificationService } from 'app/components';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  public screenSize: string;
  private loginFrom: string;
  private redirectTo: string;
  public isLoading: boolean;
  public bodySize: any;

  constructor(
    private store: Store<AppState>,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private notificationService: ToastNotificationService
  ) {
    this.screenSize = ScreenSize.Full;
    this.isLoading = false;
    this.bodySize = { width: '75%' };
  }

  ngOnInit() {
    this.store.select('userData').subscribe(({ data, loginLoadedMessage }) => {
      if (eq(loginLoadedMessage, 'Success')) {
        this.userLogin(data);
      }
      if (eq(loginLoadedMessage, 'Error')) {
        this.notificationService.showMessage(
          'Unable to load user information. Please try again later',
          'Error'
        );
        this.isLoading = false;
      }
    });

    this.store.select('ui').subscribe(({ screenSizeValues }) => {
      const { scrWidth } = screenSizeValues;
      if (scrWidth >= 0 && scrWidth <= 1000) {
        this.screenSize = ScreenSize.Small;
        this.bodySize = { width: '100%' };
      }
      if (scrWidth >= 1001 && scrWidth <= 1300) {
        this.screenSize = ScreenSize.Medium;
        this.bodySize = { width: '50%' };
      }
      if (scrWidth >= 1301) {
        this.screenSize = ScreenSize.Full;
        this.bodySize = { width: '50%' };
      }
    });
  }

  public login({ jwtt, type }) {
    this.redirectTo = this.authService.getRedirectUrl();
    this.isLoading = true;
    if (!isEmpty(jwtt)) {
      this.loginFrom = type;
      this.authService.saveToken(jwtt);
      this.store.dispatch(actions.setUserData());
    } else {
      this.authService.initializeLogin();
      this.isLoading = false;
    }
  }

  userLogin(userData: UserData) {
    this.authService.setLoginRender(true);
    this.store.dispatch(
      actions.setIsUserFirstTime({ value: userData.firstLogin })
    );
    this.authService.saveFirstLogin(userData.firstLogin);
    this.authService.saveBlogAfterLogin(true);
    this.saveUserProfile(userData);
    this.router.navigateByUrl(this.redirectTo);
    this.isLoading = false;
  }

  saveUserProfile(userData: UserData) {
    if (eq(this.loginFrom, 'technews')) {
      const userProfile = new UserProfile({
        firstName: userData.firstName,
        lastName: userData.lastName,
        emailAddress: userData.userName,
        picture: this.userService.getUserPicture(userData.userId),
        login: this.loginFrom
      });
      this.authService.saveUserProfile(userProfile);
    }
  }
}
