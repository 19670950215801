import { createReducer, on } from '@ngrx/store';
import {
  setOutlets,
  unSetOutlets,
  setOutletsSuccess,
  setOutletsError
} from 'redux/actions';

export interface OutletsState {
  contents: any;
  isSearch: boolean;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initialState: OutletsState = {
  contents: null,
  isSearch: false,
  loaded: false,
  loading: false,
  error: null
};

const _outletsReducer = createReducer(
  initialState,

  on(setOutlets, (state, { isSearch }) => ({
    ...state,
    loading: true,
    loaded: false,
    isSearch
  })),

  on(setOutletsSuccess, (state, { contents }) => ({
    ...state,
    contents: { ...contents },
    loading: false,
    loaded: true
  })),

  on(setOutletsError, (state, { payload }) => ({
    ...state,
    contents: null,
    loading: false,
    loaded: false,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  })),

  on(unSetOutlets, state => ({
    ...state,
    contents: null
  }))
);

export function outletsReducer(state, action) {
  return _outletsReducer(state, action);
}
