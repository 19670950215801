import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import * as reducers from './redux/reducers';

import {
  Params,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}

export interface AppState {
  offCanvas: reducers.OffCanvasContentState;
  ui: reducers.UIState;
  session: reducers.SessionState;
  project: reducers.ProjectState;
  topic: reducers.TopicState;
  projects: reducers.ProjectsState;
  alertToggle: reducers.ToggleState;
  authors: reducers.AuthorsState;
  outlets: reducers.OutletsState;
  topicCards: reducers.TopicCardsState;
  topics: reducers.TopicsState;
  search: reducers.SearchState;
  facetsSearch: reducers.FacetsSearchState;
  coverages: reducers.CoveragesState;
  topicsRanked: reducers.TopicsRankedState;
  companies: reducers.CompaniesState;
  company: reducers.CompanyState;
  companiesRanked: reducers.CompaniesRankedState;
  userData: reducers.UserDataState;
  topicDialog: reducers.TopicDialogState;
  companyDialog: reducers.CompanyDialogState;
  authorDialog: reducers.AuthorDialogState;
  outletDialog: reducers.OutletDialogState;
  routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
  typeaheadSearch: reducers.TypeaheadSearchState;
  authorsOutletsList: reducers.AuthorsOutletsState;
  authorManager: reducers.AuthorManagerState;
  outletManager: reducers.OutletManagerState;
  category: reducers.CategoryState;
  reportMissingData: reducers.ReportMissingState;
  advancedSearch: reducers.AdvancedSearchState;
  signUpDialog: reducers.SignUpDialogState;
  newsFeed: reducers.NewsFeedState;
  myAccount: reducers.MyAccountState;
  collectionContent: reducers.CollectionContentState;
  blog: reducers.BlogState;
  contentItemsList: reducers.ContentItemsListState;
}

export const appReducers: ActionReducerMap<AppState> = {
  offCanvas: reducers.offCanvasReducer,
  ui: reducers.uiReducer,
  session: reducers.sessionReducer,
  project: reducers.projectReducer,
  topic: reducers.topicReducer,
  projects: reducers.projectsReducer,
  alertToggle: reducers.projectAlertToggleReducer,
  authors: reducers.authorsReducer,
  outlets: reducers.outletsReducer,
  topicCards: reducers.topicCardsReducer,
  topics: reducers.topicsReducer,
  search: reducers.searchReducer,
  facetsSearch: reducers.facetsSearchReducer,
  coverages: reducers.coveragesReducer,
  topicsRanked: reducers.topicsRankedReducer,
  companies: reducers.companiesReducer,
  company: reducers.companyReducer,
  companiesRanked: reducers.companiesRankedReducer,
  userData: reducers.userDataReducer,
  topicDialog: reducers.topicDialogReducer,
  companyDialog: reducers.companyDialogReducer,
  authorDialog: reducers.authorDialogReducer,
  outletDialog: reducers.outletDialogReducer,
  routerReducer: fromRouter.routerReducer,
  typeaheadSearch: reducers.typeaheadSearchReducer,
  authorsOutletsList: reducers.authorsOutletsListReducer,
  authorManager: reducers.authorManagerReducer,
  outletManager: reducers.outletManagerReducer,
  category: reducers.categoryReducer,
  reportMissingData: reducers.reportMissingDataReducer,
  advancedSearch: reducers.advancedSearchReducer,
  signUpDialog: reducers.signUpDialogReducer,
  newsFeed: reducers.newsFeedReducer,
  myAccount: reducers.myAccountReducer,
  collectionContent: reducers.collectionContentReducer,
  blog: reducers.blogReducer,
  contentItemsList: reducers.contentItemsListReducer
};

export const getRouterState = createFeatureSelector<
  fromRouter.RouterReducerState<RouterStateUrl>
>('routerReducer');

export class CustomSerializer
  implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;
    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params } = state;
    return { url, queryParams, params };
  }
}
