import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/',
    title: 'Growth Page',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: []
  }
];
