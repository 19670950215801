import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { Notification } from 'app/models';
import { eq, toLower } from 'lodash';

@Component({
  selector: 'toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
  providers: [MessageService]
})
export class ToastNotificationComponent implements OnInit {
  public summaries: string[];

  constructor(
    private messageService: MessageService,
    private store: Store<AppState>
  ) {
    this.summaries = ['Info', 'Warn', 'Error', 'Success'];
  }

  ngOnInit() {
    this.store.select('ui').subscribe(({ notification }) => {
      if (notification) {
        const pos = notification.position ? notification.position : '';
        this.showMessage(
          new Notification(notification.message, notification.severity, pos)
        );
      }
    });
  }

  public showMessage(notification: Notification) {
    this.messageService.add({
      life: eq(notification.severity, 'Error') ? 9000 : 5000,
      key: notification.position,
      severity: toLower(notification.severity),
      // summary: capitalize(notification.severity),
      detail: notification.message
    });

    // reset notification store
    this.store.dispatch(
      actions.showNotification({
        notify: null
      })
    );
  }
}
