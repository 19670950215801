import { Component, OnInit } from '@angular/core';
import { formatPercent } from '@angular/common';
import * as actions from 'redux/actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { eq, isEmpty } from 'lodash';
import { ToastNotificationService } from '../toast-notification/toast-notification.service';
import { LongTermChart } from 'app/interfaces';
import { Search } from 'app/models';

@Component({
  selector: 'app-coverage-preview',
  template: `
    <div *ngIf="!readyToShowGraph">
      <div width="18rem" height="3rem" class="fix-height"></div>
      <div width="20rem" styleClass="mt-2 mb-2" height="0.3rem"></div>
    </div>
    <div *ngIf="readyToShowGraph && hasFilterText">
      <div class="p-d-flex">
        <div (click)="goToExploreRelatedCoverage()">
          <!-- <h6>Coverage in the past year</h6> -->
          <div *ngIf="longTermChart">
            <small>{{ coveragePastYearText }}</small>
            <app-long-term-chart [topic]="longTermChart"></app-long-term-chart>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .fix-height {
        height: 3.9rem;
      }
    `
  ]
})
export class CoveragePreviewComponent implements OnInit {
  public longTermChart: LongTermChart;
  public coveragePastYearText: string;
  public loading: boolean;
  public search: Search;
  public facets: any;
  public readyToShowGraph: boolean;
  public hasFilterText: boolean;

  constructor(
    private store: Store<AppState>,
    private notificationService: ToastNotificationService
  ) {
    this.longTermChart = null;
    this.loading = true;
    this.search = null;
    this.facets = null;
    this.readyToShowGraph = false;
    this.hasFilterText = false;
  }

  ngOnInit() {
    this.store.select('advancedSearch').subscribe(({ filterText }) => {
      this.hasFilterText = isEmpty(filterText);
    });

    this.store.select('facetsSearch').subscribe(({ searchBy }) => {
      this.facets = searchBy;
    });

    this.store.select('search').subscribe(({ searchBody }) => {
      this.search = searchBody;
    });

    this.store
      .select('topicsRanked')
      .subscribe(
        ({
          coveragePreviewLoading,
          coveragePreview,
          coveragePreviewError,
          loaded
        }) => {
          this.loading = coveragePreviewLoading;
          this.readyToShowGraph = false;
          if (coveragePreview) {
            const stats = coveragePreview;
            this.coveragePastYearText = `${stats?.newsAuthors.toLocaleString()} authors / ${stats?.newsItems.toLocaleString()} articles in the past year`;
            //  (${
            //   eq(stats?.deltaYearPercent, 0.000001)
            //     ? '&infin;'
            //     : formatPercent(Math.abs(stats?.deltaYearPercent), 'en-US')
            // } ${
            //   stats?.deltaYearPercent > 0.000001 ? ' more ' : ' less '
            // } than previous year)`;
            this.longTermChart = {
              longTermSeries: [
                {
                  name: 'longterm-history',
                  data: coveragePreview.longHistory
                }
              ],
              longTermColor: [
                coveragePreview.longTrend > 0 ? 'lightgray' : 'lightgray'
              ]
            };
            if (loaded && this.search.keywords) {
              this.readyToShowGraph = true;
            }
          }

          if (coveragePreviewError) {
            this.notificationService.showMessage(
              'Error fetching coverage preview information',
              'Error'
            );
          }
        }
      );
  }

  public goToExploreRelatedCoverage() {
    this.dispatchSearchDashboard(this.search);
  }

  private dispatchSearchDashboard(search: Search) {
    this.resetTopicMenuBar();
    if (search.keywords) {
      const newSearch = new Search({
        ...search,
        facets: this.facets,
        topicsCollection: null,
        topicsFilter: null
      });
      this.store.dispatch(
        actions.setFacetsSearch({
          searchBody: newSearch,
          page: 0,
          isNewSearch: true
        })
      );

      this.store.dispatch(
        actions.setCoverages({
          searchBody: newSearch,
          topicId: null,
          sort: 'recent',
          page: 0,
          size: 50,
          isSearch: true
        })
      );
    }
    this.store.dispatch(actions.tabControl({ index: 1 }));
  }

  public resetTopicMenuBar() {
    const item = {
      label: 'All',
      items: null
    };
    this.store.dispatch(actions.setTopicMenuItemSelected({ item }));
  }
}
