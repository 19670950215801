import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { isEmpty } from 'lodash';
import { ProjectEdit } from 'app/models';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private api: string;
  private product: string;
  private httpOptions: any;
  private storage = localStorage;

  constructor(private http: HttpClient) {
    this.api = environment.api_url;
    this.product = environment.product;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public getProjects(userId, projectType): Observable<any> {
    return this.http.get(
      `${this.api}/projects/list?userId=${userId}&product=${this.product}&type=${projectType}`,
      this.httpOptions
    );
  }

  public getTopicsOfProjects(projectId): Observable<any> {
    return this.http.get(
      `${this.api}/project/${projectId}/topicsAndCompanies`,
      this.httpOptions
    );
  }

  public save(
    newProjectName: string,
    accountId: number,
    projectType: string
  ): Observable<any> {
    const name = encodeURIComponent(newProjectName);
    return this.http.post(
      `${this.api}/projects/create?accountId=${accountId}&product=${this.product}&type=${projectType}&newProjectName=${name}`,
      null,
      this.httpOptions
    );
  }

  public update(project: ProjectEdit): Observable<any> {
    return this.http.patch(
      `${this.api}/projects/${project.projectId}`,
      project,
      this.httpOptions
    );
  }

  public delete(projectId: number): Observable<any> {
    return this.http.delete(
      `${this.api}/projects/${projectId}`,
      this.httpOptions
    );
  }

  public getProject() {
    const project = this.storage.getItem('project');
    return isEmpty(project) ? null : JSON.parse(project);
  }

  public saveProjec(project) {
    this.storage.setItem('project', JSON.stringify(project));
  }
}
