import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { Observable } from 'rxjs/Observable';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-loading-topic',
  template: `
    <ng-template #customLoadingTopicTemplate>
      <div class="custom-class">
        <p [innerHTML]="message"></p>
      </div>
    </ng-template>
    <ngx-loading
      [show]="isLoading"
      [config]="config"
      [template]="customLoadingTopicTemplate"
    ></ngx-loading>
  `,
  styles: [
    `
      .custom-class {
        top: 0;
        z-index: 2002;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }

      .custom-class p {
        color: white;
        margin-top: 10rem;
      }
    `
  ]
})
export class LoadingTopicComponent implements OnInit {
  public isLoading: boolean;
  public message: string;
  public timeoutMessages: number;
  public messages: any[];
  public counter$: Observable<any>;
  public counterSub: Subscription;

  public config = {
    animationType: ngxLoadingAnimationTypes.circle,
    backdropBackgroundColour: 'rgba(0,0,0,0.1)',
    backdropBorderRadius: '3px',
    primaryColour: '#ff9800',
    secondaryColour: '#ffffff',
    tertiaryColour: '#ffffff',
    fullScreenBackdrop: false
  };

  constructor(private store: Store<AppState>) {
    this.isLoading = true;
    this.timeoutMessages = 1000;
    this.messages = [];
  }

  ngOnInit() {
    this.store
      .select('topicsRanked')
      .subscribe(({ previews, loaded, searchBody }) => {
        const { keywords } = searchBody || null;
        this.message = '';
        if (!!previews.length) {
          this.showPreviews(
            previews.map(item => {
              return keywords
                ? `Processing results for your search <em>${keywords}</em> and related topic <em>${item.name}</em>`
                : `Generating data for topic: ${item.name}...`;
            }),
            loaded
          );
        }
      });

    this.store.select('ui').subscribe(({ isLoading }) => {
      this.isLoading = isLoading;
    });
  }

  showPreviews(arrPreviews, loaded) {
    const topics: string[] = arrPreviews;
    if (!loaded) {
      this.destroyCounter();
      this.counter$ = interval(this.timeoutMessages);
      const complete = topics.length;
      this.counterSub = this.counter$.pipe(take(complete)).subscribe({
        next: val => (this.message = arrPreviews[val])
      });
    } else {
      this.destroyCounter();
    }
  }

  destroyCounter() {
    if (this.counterSub) {
      this.counterSub.unsubscribe();
    }
  }
}
