import { CategoryResponseProps } from 'app/interfaces';

export class CategoryResponse {
  public id: number;
  public name: string;
  public description: string;

  constructor({ id, name, description }: CategoryResponseProps) {
    this.id = id || null;
    this.name = name || null;
    this.description = description || null;
  }
}
