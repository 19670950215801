import { CategoryProps } from 'app/interfaces';

export class CategorySubmit {
  public id: number;
  public projectId: number;
  public name: string;
  public description: string;

  constructor({ id, projectId, name, description }: CategoryProps) {
    this.id = id || null;
    this.projectId = projectId || null;
    this.name = name || null;
    this.description = description || null;
  }
}
