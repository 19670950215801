import { createAction, props } from '@ngrx/store';

export const setCompanies = createAction(
  '[Companies] Load Companies',
  props<{ projectId: number }>()
);

export const setCompaniesSuccess = createAction(
  '[Companies] Load Companies Success',
  props<{ companies: any[] }>()
);

export const setCompaniesError = createAction(
  '[Companies] Load Companies Error',
  props<{ payload: any }>()
);

export const addCompanyToProject = createAction(
  '[Companies] Add Company to Project',
  props<{ companyId: number; userProject: any }>()
);

export const deleteCompanyToProject = createAction(
  '[Companies] Delete Company to Project',
  props<{ companyId: number; userProject: any }>()
);

export const addCompanyFromDialog = createAction(
  '[Companies] Add Company from Topic Dialog',
  props<{ company: any }>()
);

export const deleteCompanyFromManager = createAction(
  '[Companies] Delete Company from Manager',
  props<{ company: any }>()
);

export const setAddCompanyToProjectError = createAction(
  '[Companies] Add Company to Project Error',
  props<{ payload: any }>()
);

export const setDeleteCompanyToProjectError = createAction(
  '[Companies] Delete Company to Project Error',
  props<{ payload: any }>()
);

export const setEditCompany = createAction(
  '[Companies] Set Edit Company',
  props<{ company: any }>()
);

export const unSetCompanies = createAction('[Companies] unSetCompanies');
