import { TypeaheadSearchGroupedProps } from 'app/interfaces';
import { TypeaheadResponse } from './typeaheadResponse.model';

export class TypeaheadSearchGrouped {
  public id: number;
  public label: string;
  public items: TypeaheadResponse[];

  constructor({ id, label, items }: TypeaheadSearchGroupedProps) {
    this.id = id || null;
    this.label = label || null;
    this.items = items || [];
  }
}
