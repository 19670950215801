import { createAction, props } from '@ngrx/store';
import { Project } from 'app/models';

export const setProject = createAction(
  '[Project] setProject',
  props<{ id: number; name: string }>()
);
export const unSetProject = createAction('[Project] unSetProject');

export const openProjectSettings = createAction(
  '[Project] openProjectSettings',
  props<{ company: any }>()
);

export const projectChange = createAction(
  '[Project] projectChange',
  props<{ projectSelected: Project }>()
);

export const editProjectSelected = createAction(
  '[Project] editProjectSelected',
  props<{ projectSelected: Project }>()
);

export const createProject = createAction('[Project] createProject');

export const startProjectChanged = createAction(
  '[Project] startProjectChanged'
);
export const stopProjectChanged = createAction('[Project] stopProjectChanged');

export const resetProjectState = createAction('[Project] resetProjectState');

export const hideSelectProjectModal = createAction(
  '[Project] hideSelectProjectModal'
);

export const showSelectProjectModal = createAction(
  '[Project] showSelectProjectModal',
  props<{ projectId: number }>()
);

export const hideAlertSettingstModal = createAction(
  '[Project] hideAlertSettingstModal'
);

export const showAlertSettingsModal = createAction(
  '[Project] showAlertSettingsModal'
);

export const hideProjectSettingsSection = createAction(
  '[Project] hideProjectSettingsSection'
);

export const showProjectSettingsSection = createAction(
  '[Project] showProjectSettingsSection'
);

export const hideProjectCreateSection = createAction(
  '[Project] hideProjectCreateSection'
);

export const showProjectCreateSection = createAction(
  '[Project] showProjectCreateSection'
);
