export const TIMEFRAMES = [
  { label: 'Past 1 month', value: 'ONE_MONTH' },
  { label: 'Past 3 months', value: 'THREE_MONTHS' },
  { label: 'Past 1 year', value: 'ONE_YEAR' },
  { label: 'Past 5 years', value: 'FIVE_YEARS' },
  { label: 'Custom', value: 'CUSTOM' }
];

export const OUTLET_INFLUENCES = [
  { label: 'Top outlets only', value: true },
  { label: 'All', value: false }
];

export const AUTHOR_INFLUENCES = [
  { label: 'Top authors only', value: true },
  { label: 'All', value: false }
];

export const ANALYSTS = [
  { label: 'Analysts only', value: 'ANALYSTS_ONLY' },
  { label: 'Exclude analysts', value: 'EXCLUDE_ANALYSTS' },
  { label: 'All', value: null }
];

export const LOCATIONS = [
  { label: 'USA only', value: 'USA_ONLY' },
  { label: 'Exclude USA', value: 'EXCLUDE_USA' },
  { label: 'All', value: null }
];

export const FACETS = [
  { label: 'Authors', value: 'AUTHORS' },
  { label: 'Outlets', value: 'OUTLETS' },
  { label: 'Topics', value: 'TOPICS' },
  { label: 'Companies', value: 'COMPANIES' },
  { label: 'Products', value: 'PRODUCTS' },
  { label: 'People', value: 'PEOPLE' },
  { label: 'Locations', value: 'LOCATIONS' }
  // { label: 'Countries', value: 'COUNTRIES' },
  // { label: 'Cities', value: 'CITIES' }
];

export const STAR_ICON_FILL = 'pi pi-star-fill';
export const STAR_ICON = 'pi pi-star';
export const SPINNER_ICON = 'pi pi-spin pi-spinner';

export const FILTERS = [
  { key: 'analysts', filters: ANALYSTS },
  { key: 'countries', filters: LOCATIONS },
  { key: 'timeframe', filters: TIMEFRAMES },
  { key: 'topAuthorsOnly', filters: AUTHOR_INFLUENCES },
  { key: 'topOutletsOnly', filters: OUTLET_INFLUENCES },
  { key: 'authors', filters: [] },
  { key: 'outlets', filters: [] },
  { key: 'excludeAuthors', filters: [] },
  { key: 'excludeOutlets', filters: [] }
];

export const SORT_OPTIONS = [
  { label: 'Recent', value: 'recent' },
  { label: 'Relevant', value: 'relevant' }
];

export const SORT_OPTIONS_ELIP = [
  { label: 'Rec..', value: 'recent' },
  { label: 'Rel..', value: 'relevant' }
];

export const RELEVANT_SORT = 'relevant';
export const RECENT_SORT = 'recent';

export enum Content {
  Collection = 'collection',
  Coverage = 'coverage',
  NewsFeed = 'feed',
  Outlet = 'outlet',
  Author = 'author',
  Topic = 'topic',
  TopicSidebar = 'topicSidebar',
  ContentItem = 'contentItem',
  Company = 'company'
}

export enum ScreenSize {
  Full = 'full',
  Medium = 'medium',
  Small = 'small'
}

const TOPIC_ITEM = {
  id: 129313,
  name: 'SoC',
  entityType: 'TO',
  query: '"SoC" OR "system on a chip" OR Socs',
  deltaYearPercent: 0.04670558798999166,
  deltaMonthPercent: 0.022488755622188907,
  deltaWeekPercent: 0.15384615384615385,
  deltaYear: 112,
  deltaMonth: 15,
  deltaWeek: 32,
  newsAuthors: 2510,
  newsAuthorsMonth: 682,
  newsAuthorsWeek: 240,
  longHistory: [
    0.020089031859758574,
    0.02688248971561438,
    0.02144805646529151,
    0.022886892770522756,
    0.02149538749858636,
    0.022172400587221548,
    0.02141121524819338,
    0.021387867873794313,
    0.020477761813789727,
    0.020876023518981626,
    0.022065186039803866,
    0.021030750367429133,
    0.020682641135572565
  ],
  newThisMonth: false,
  newThisWeek: false
};

const topicItems = new Array(50);

export const TOPIC_ITEMS = topicItems.fill(0).map(() => TOPIC_ITEM);
