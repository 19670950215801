import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { distinctUntilKeyChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { FacetContent } from './facet-content.interface';
import { eq } from 'lodash';

@Component({
  selector: 'app-dashboard-facets-sort',
  template: `
    <p-selectButton
      [options]="sortOptions"
      [(ngModel)]="sortSelected"
      optionLabel="label"
      optionValue="value"
      (onOptionClick)="handleSortOptionClick($event)"
    ></p-selectButton>
  `
})
export class DashboardFacetsSortComponent implements OnInit, OnChanges {
  @Input() table: FacetContent;
  @Output() sortEmitter = new EventEmitter<any>();
  public sortSelected: string;
  public sortOptions: any[];
  constructor(private store: Store<AppState>) {
    this.sortOptions = [];
  }

  ngOnInit() {
    this.store
      .select('ui')
      .pipe(distinctUntilKeyChanged('screenSizeValues'))
      .subscribe(({ screenSizeValues }) => {
        if (screenSizeValues) {
          const { scrWidth } = screenSizeValues;
          if (scrWidth <= 1565) {
            this.sortOptions = this.sortOptions.map(item => {
              return {
                ...item,
                label: eq(item.value, 'results') ? 'Art..' : 'Pop..'
              };
            });
          } else {
            this.sortOptions = this.sortOptions.map(item => {
              return {
                ...item,
                label: eq(item.value, 'results') ? 'Articles' : 'Popular'
              };
            });
          }
        }
      });
  }

  public handleSortOptionClick({ option }) {
    this.sortEmitter.emit(option);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.table.currentValue) {
      const { title, facets } = changes.table.currentValue;
      if (eq(title, 'Authors')) {
        const { AUTHORS } = facets;
        this.sortOptions = [
          { label: 'Articles', value: 'results', table: 'author' },
          { label: 'Popular', value: 'influence', table: 'author' }
        ];
        this.sortSelected = eq(AUTHORS, null) ? 'results' : 'influence';
      }

      if (eq(title, 'Outlets')) {
        const { OUTLETS } = facets;
        this.sortOptions = [
          { label: 'Articles', value: 'results', table: 'outlet' },
          { label: 'Popular', value: 'influence', table: 'outlet' }
        ];
        this.sortSelected = eq(OUTLETS, null) ? 'results' : 'influence';
      }

      if (eq(title, 'Topics')) {
        const { TOPICS } = facets;
        this.sortOptions = [
          { label: 'Relevance', value: 'results', table: 'topics' },
          { label: 'Growth', value: 'growth', table: 'topics' }
        ];
        this.sortSelected = eq(TOPICS, null) ? 'results' : 'growth';
      }

      if (eq(title, 'Companies & Products')) {
        const { COMPANIES } = facets;
        this.sortOptions = [
          { label: 'Relevance', value: 'results', table: 'companies' },
          { label: 'Growth', value: 'growth', table: 'companies' }
        ];
        this.sortSelected = eq(COMPANIES, null) ? 'results' : 'growth';
      }
    }
  }
}
