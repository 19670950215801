import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Search } from 'app/models';

@Injectable({
  providedIn: 'root'
})
export class TopicService {
  private api: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.api = environment.api_url;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public rankedTopicsSearch(
    search: Search,
    page = 0,
    size = 50
  ): Observable<any> {
    return this.http.post(
      `${this.api}/topics/search?page=${page}&size=${size}`,
      search,
      this.httpOptions
    );
  }

  public topicsSearchPreview(search: Search): Observable<any> {
    return this.http.post(
      `${this.api}/topics/search/preview`,
      search,
      this.httpOptions
    );
  }

  public coveragePreviewSearch(search: Search): Observable<any> {
    const url = `${this.api}/search/content/stats`;
    return this.http.post(url, search, this.httpOptions);
  }

  public getTopicRelated(id): Observable<any> {
    return this.http.get(`${this.api}/topics/${id}`, this.httpOptions);
  }

  public getTopicsMenu(): Observable<any> {
    return this.http.get(`${this.api}/topics/menu`, this.httpOptions);
  }

  public getTopicsSaved(projectId): Observable<any> {
    return this.http.get(
      `${this.api}/project/${projectId}/topicsAndCompanies`,
      this.httpOptions
    );
  }

  public getTopicWithContact(topicId): Observable<any> {
    return this.http.get(`${this.api}/topics/${topicId}`, this.httpOptions);
  }

  public getUserTopic(topicId): Observable<any> {
    return this.http.get(`${this.api}/userTopics/${topicId}`, this.httpOptions);
  }

  public save(topic, projectId: number, userId: number): Observable<any> {
    const name = encodeURIComponent(topic.name);
    const query = encodeURIComponent(topic.query);
    const catId = topic.categoryId;
    const path = `${this.api}/userTopics/addToProjectCustom?userProjectId=`;
    return this.http.post(
      `${path}${projectId}&name=${name}&query=${query}&userLabelId=${catId}&userId=${userId}`,
      null,
      this.httpOptions
    );
  }

  public update(topic, userTopicId: number, userId: number): Observable<any> {
    const name = encodeURIComponent(topic.name);
    const query = encodeURIComponent(topic.query);
    const catId = topic.categoryId;
    const path = `${this.api}/userTopics/updateCustom?userTopicId=`;
    return this.http.put(
      `${path}${userTopicId}&name=${name}&query=${query}&userLabelId=${catId}&userId=${userId}`,
      null,
      this.httpOptions
    );
  }

  public delete(topicId: number, userId: number): Observable<any> {
    return this.http.delete(
      `${this.api}/userTopics/deleteFromProjectCustom?userTopicId=${topicId}&userId=${userId}`,
      this.httpOptions
    );
  }

  public addTopicToProject(
    topicId: number,
    userProjectId: number
  ): Observable<any> {
    const path = `${this.api}/userTopics/addToProject?userProjectId=${userProjectId}&topicId=${topicId}`;
    return this.http.post(path, null, this.httpOptions);
  }

  public deleteTopicToProject(
    topicId: number,
    userProjectId: number
  ): Observable<any> {
    const path = `${this.api}/userTopics/deleteFromProject?userProjectId=${userProjectId}&topicId=${topicId}`;
    return this.http.delete(path, this.httpOptions);
  }

  public feedTopic(topicId, sort, p, s): Observable<any> {
    return this.http.post(
      `${this.api}/content/userTopic/${topicId}/${sort}?page=${p}&size=${s}`,
      '{}',
      this.httpOptions
    );
  }

  public async asyncFeedTopic(topicId, sort, p, s) {
    const feeds = await this.http
      .post(
        `${this.api}/content/userTopic/${topicId}/${sort}?page=${p}&size=${s}`,
        '{}',
        this.httpOptions
      )
      .toPromise();
    return feeds;
  }

  public getIntervalInMills(): number {
    return 5 * 1000;
  }
}
