import { Component, OnInit, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';

@Component({
  selector: 'get-screen-size',
  template: ``
})
export class ScreenSizeComponent implements OnInit {
  public scrWidth: any;
  public isDesktop: boolean;
  public isTablet: boolean;

  constructor(private store: Store<AppState>) {
    this.getScreenSize();
  }

  ngOnInit() {}

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    const scrHeight = window.innerHeight;
    const scrWidth = window.innerWidth;
    const isTablet = scrWidth <= 1024;
    const isDesktop = scrWidth > 1194;

    this.store.dispatch(
      actions.screenSize({
        screenSizeValues: { scrHeight, scrWidth, isTablet, isDesktop }
      })
    );
  }
}
