import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { tap, mergeMap, catchError, switchMap } from 'rxjs/operators';
import { CompanyService } from '../../services';
import { of } from 'rxjs';
import { includes, map } from 'lodash';

@Injectable()
export class CompaniesEffects {
  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
    private store: Store<AppState>
  ) {}

  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setCompanies),
      mergeMap(action =>
        this.companyService.getCompanies(action.projectId).pipe(
          tap(() => console.log('Load project companies list')),
          switchMap(response => [
            actions.setCompaniesSuccess({
              companies: map(response, item => ({
                ...item,
                categoryId: item?.label?.id,
                categoryName: item?.label?.name
              }))
            })
          ]),
          catchError(err => of(actions.setCompaniesError({ payload: err })))
        )
      )
    )
  );

  companiesRankedList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setCompaniesRanked),
      tap(() => console.log('Load companies ranked list')),
      mergeMap(action =>
        this.companyService
          .rankedCompanies(action.sort, action.page)
          .pipe(
            switchMap(response => [
              actions.setCompaniesRankedSuccess({
                list: response
              })
              // actions.stopLoading()
            ]),
            catchError(err =>
              of(actions.setCompaniesRankedError({ payload: err }))
            )
          )
          .pipe(
            tap((response: any) => {
              this.store.dispatch(actions.stopLoading());
            })
          )
      )
    )
  );

  addCompanyToProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addCompanyToProject),
      mergeMap(action =>
        this.companyService
          .addCompanyToProject(action.companyId, action.userProject.value)
          .pipe(
            tap(),
            switchMap(response => [
              actions.projectChange({ projectSelected: action.userProject })
            ]),
            catchError(err =>
              of(actions.setAddCompanyToProjectError({ payload: err }))
            )
          )
      )
    )
  );

  deleteCompanyToProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteCompanyToProject),
      mergeMap(action =>
        this.companyService
          .deleteCompanyToProject(action.companyId, action.userProject.value)
          .pipe(
            tap(),
            switchMap(() => [
              actions.projectChange({ projectSelected: action.userProject })
            ]),
            catchError(err =>
              of(actions.setAddCompanyToProjectError({ payload: err }))
            )
          )
      )
    )
  );

  updateCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateCompany),
      mergeMap(({ payload }) =>
        this.companyService
          .editCompany(
            payload.companyId,
            payload.name,
            payload.query,
            payload.categoryId
          )
          .pipe(
            tap(() => console.log('Update Company')),
            switchMap(response => [
              actions.updateCompanySuccess({
                company: {
                  name: response.name,
                  query: response.query,
                  categoryId: response?.label?.id
                }
              })
            ]),
            catchError(err => of(actions.updateCompanyFail({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (includes(response?.type, 'Update Company Success')) {
                this.refreshProjectCompanyList(payload);
              }
            })
          )
      )
    )
  );

  createCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createCompany),
      mergeMap(({ payload }) =>
        this.companyService
          .createCompany(
            payload.userProject.value,
            payload.name,
            payload.query,
            payload.categoryId
          )
          .pipe(
            tap(() => console.log('Create Company')),
            switchMap(response => [
              actions.createCompanySuccess({ company: response })
            ]),
            catchError(err => of(actions.createCompanyFail({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (includes(response?.type, 'Create Company Success')) {
                this.refreshProjectCompanyList(payload);
              }
            })
          )
      )
    )
  );

  deleteCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteCompany),
      mergeMap(({ payload }) =>
        this.companyService
          .removeCompany(payload.companySelected)
          .pipe(
            tap(() => console.log('Delete company')),
            switchMap(response => [
              actions.deleteCompanySuccess({ company: response })
            ]),
            catchError(err => of(actions.createCompanyFail({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (includes(response?.type, 'Delete Company Success')) {
                this.refreshProjectCompanyList(payload);
              }
            })
          )
      )
    )
  );

  refreshProjectCompanyList(action) {
    this.store.dispatch(
      actions.setCompanies({ projectId: action.userProject.value })
    );
  }
}
