import { createReducer, on } from '@ngrx/store';
import {
  setAuthorDialog,
  setAuthorDialogSuccess,
  setAuthorDialogError,
  unSetAuthorDialog
} from 'redux/actions';

export interface AuthorDialogState {
  authorId: number;
  author: any;
  loaded: boolean;
  loading: boolean;
  error: any;
  sort: string;
}

const initialState: AuthorDialogState = {
  authorId: null,
  author: null,
  loaded: true,
  loading: false,
  error: null,
  sort: null
};

const _authorDialogReducer = createReducer(
  initialState,

  on(setAuthorDialog, (state, { authorId, sort }) => {
    return {
      ...state,
      author: null,
      loading: true,
      loaded: false,
      authorId,
      sort
    };
  }),

  on(setAuthorDialogSuccess, (state, { author }) => ({
    ...state,
    author,
    loading: false,
    loaded: true
  })),

  on(setAuthorDialogError, (state, { payload }) => ({
    ...state,
    author: null,
    loading: false,
    loaded: false,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  })),

  on(unSetAuthorDialog, () => ({
    ...initialState
  }))
);

export function authorDialogReducer(state, action) {
  return _authorDialogReducer(state, action);
}
