import { NgModule } from '@angular/core';
import { AuthModule } from '@auth0/auth0-angular';
import { SharedModule } from '../shared/shared.module';
import { ComponentsModule } from '../components/components.module';
import {
  LoginComponent,
  LogoutComponent,
  Auth0Component,
  SignupComponent,
  ResetPasswordComponent,
  CallbackComponent,
  ForgotPasswordComponent,
  SubscriberUserComponent,
  TrialUserComponent
} from './';
import { environment } from 'environments/environment';

@NgModule({
  imports: [
    SharedModule,
    ComponentsModule,
    AuthModule.forRoot({ ...environment.auth0 })
  ],
  declarations: [
    LoginComponent,
    LogoutComponent,
    Auth0Component,
    SignupComponent,
    ResetPasswordComponent,
    CallbackComponent,
    ForgotPasswordComponent,
    SubscriberUserComponent,
    TrialUserComponent
  ],
  exports: [
    LoginComponent,
    LogoutComponent,
    Auth0Component,
    SignupComponent,
    CallbackComponent,
    ResetPasswordComponent
  ]
})
export class AuthenticationModule {}
