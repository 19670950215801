import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { Notification, Project, ProjectEdit, Topic } from 'app/models';
import {
  CategoryService,
  ProjectService,
  TopicService,
  CompanyService
} from 'app/services';
import { includes, filter, eq, find } from 'lodash';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styles: [
    `
      .box-button-right {
        float: right;
        display: block;
      }

      :host ::ng-deep .save .p-button {
        background: #465675 !important;
        border-color: transparent !important;
        box-shadow: none;
      }
    `
  ]
})
export class EditProjectComponent implements OnInit {
  public form: FormGroup;
  public isSaving: boolean;
  public projectId: number;
  public userId: number;
  public showSettingSection: boolean;
  public project: any;
  public hasMainTopic: boolean;
  public accountId: number;

  constructor(
    private projectService: ProjectService,
    private store: Store<AppState>,
    private formBuilder: FormBuilder
  ) {
    this.isSaving = false;
    this.showSettingSection = false;
    this.project = null;
    this.hasMainTopic = false;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      description: [null]
    });

    this.store.select('ui').subscribe(({ showProjectSettingstSection }) => {
      this.showSettingSection = showProjectSettingstSection;
      if (showProjectSettingstSection) {
        this.form.get('name').setValue(this.project.name);
        this.form.get('description').setValue(this.project.description);
      }
    });

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.projectId = projectSelected.value;
        this.project = projectSelected;
      }
    });

    this.store.select('projects').subscribe(({ action }) => {
      if (eq(action, 'editProjectSuccess')) {
        actions.showNotification({
          notify: new Notification(
            'Project name has been renamed',
            'success',
            'tl'
          )
        });
        this.handleCancel();
      }

      if (eq(action, 'editProjectError')) {
        this.store.dispatch(
          actions.showNotification({
            notify: new Notification(
              'Error renaming project name',
              'error',
              'tl'
            )
          })
        );
      }
    });

    this.store.select('userData').subscribe(({ data }) => {
      if (data) {
        this.userId = data.userId;
        this.accountId = data.accountId;
      }
    });
  }

  invalidField(field: string) {
    return (
      this.form.controls[field].errors && this.form.controls[field].touched
    );
  }

  handleSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isSaving = true;

    const projectEdit = new ProjectEdit({
      projectId: this.projectId,
      name: this.form.get('name').value,
      description: this.form.get('description').value
    });

    this.store.dispatch(actions.editProject({ projectEdit }));
  }

  handleCancel() {
    this.isSaving = false;
    this.store.dispatch(actions.hideProjectSettingsSection());
  }
}
