import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'services/authentication/authentication.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private api: string;
  private httpOptions: any;
  private userId: any;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
    this.api = environment.api_url;
    this.userId = this.authService.getUserId();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public getCategoriesOfProjects(projectId): Observable<any> {
    return this.http.get(
      `${this.api}/userLabels/search/findByProject?projectId=${projectId}`,
      this.httpOptions
    );
  }

  public save(
    categoryName: string,
    description: string,
    projectId: number
  ): Observable<any> {
    const name = encodeURIComponent(categoryName);
    return this.http.post(
      `${this.api}/userLabels/add?projectId=${projectId}&userId=${this.userId}&name=${name}&description=${description}`,
      null,
      this.httpOptions
    );
  }

  public update(
    categoryName: string,
    description: string,
    categoryId: number
  ): Observable<any> {
    const name = encodeURIComponent(categoryName);
    return this.http.put(
      `${this.api}/userLabels/update` +
        `?userLabelId=${categoryId}&userId=${this.userId}&name=${name}&description=${description}`,
      this.httpOptions
    );
  }

  public delete(categoryId: number): Observable<any> {
    return this.http.delete(
      `${this.api}/userLabels/delete?userLabelId=${categoryId}&userId=${this.userId}`,
      this.httpOptions
    );
  }

  // { "value": true }
  public getIncludeInEmailAlerts(projectId): Observable<any> {
    return this.http.get(
      `${this.api}/user/${this.userId}/notifications/project/${projectId}`,
      this.httpOptions
    );
  }

  public activeIncludeInEmailAlerts(projectId): Observable<any> {
    return this.http.post(
      `${this.api}/user/${this.userId}/notifications/project/${projectId}`,
      null,
      this.httpOptions
    );
  }

  public deleteIncludeInEmailAlerts(projectId): Observable<any> {
    return this.http.delete(
      `${this.api}/user/${this.userId}/notifications/project/${projectId}`,
      this.httpOptions
    );
  }

  public deleteAllUserEmailAlerts(): Observable<any> {
    return this.http.delete(
      `${this.api}/user/${this.userId}/notifications`,
      this.httpOptions
    );
  }

  public getFrequency(): Observable<any> {
    return this.http.get(
      `${this.api}/user/${this.userId}/notifications`,
      this.httpOptions
    );
  }

  public setFrequency(frecuency): Observable<any> {
    return this.http.patch(
      `${this.api}/user/${this.userId}/notifications/${frecuency}`,
      this.httpOptions
    );
  }
}
