<div class="p-mt-2" *ngIf="loading">
  <p-toolbar>
    <div [style]="headerPadding" class="p-toolbar-group-left coverage-header">
      Coverage
    </div>
    <div class="p-toolbar-group-right" *ngIf="!hideCoverageTitle">
      <app-coverage-preview></app-coverage-preview>
    </div>
  </p-toolbar>
</div>
<app-loading [isLoading]="isLoadingContent"></app-loading>
<app-content-items
  [parentName]="parentName"
  [items]="feeds"
  [loading]="loading"
  [pageSize]="pageSize"
  [page]="page"
  [total]="total"
  [keywords]="keywords"
></app-content-items>
<div class="p-d-flex p-jc-between coverage-footer">
  <div *ngIf="totalPages >= 2">
    <p-paginator
      #pp
      [pageLinkSize]="3"
      [showFirstLastIcon]="false"
      (onPageChange)="getPage($event)"
      [rows]="pageSize"
      [totalRecords]="total"
      [showPageLinks]="showPageButton"
    >
    </p-paginator>
  </div>
  <div class="p-pl-2 footerContent" *ngIf="showSort">
    <button
      pButton
      class="p-button-outlined p-button-secondary p-button-sm"
      icon="pi pi-bookmark"
      [label]="saveAsTopicLabel"
      *ngIf="searchLoaded"
      (click)="handleSaveSearchAsTopic()"
    ></button>
    <button
      [pTooltip]="reportMissingLabel"
      pButton
      class="p-button-outlined p-button-secondary p-button-sm p-mr-2"
      icon="pi pi-flag"
      label=""
      (click)="openReportMissingDataModal()"
    ></button>
  </div>
  <div class="p-3" *ngIf="showSort">
    <app-sort-options
      (sortChangesEmiter)="sortChange($event)"
      [defaultSort]="sortSelected"
    ></app-sort-options>
  </div>
</div>
