import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { eq, head } from 'lodash';

@Component({
  selector: 'app-collections-panel',
  templateUrl: './collections-panel.component.html',
  styleUrls: ['./collections-panel.component.scss']
})
export class CollectionsPanelComponent implements OnInit {
  public collections: any;
  public isPanelShowed: boolean;

  @ViewChild('opCollections') opCollections: OverlayPanel;
  @ViewChild('btnCollections') btnCollections: ElementRef;
  @ViewChild('opCollections') op: OverlayPanel;

  constructor(private store: Store<AppState>) {
    this.isPanelShowed = false;
  }

  ngOnInit(): void {
    this.store.select('session').subscribe(({ visiblePanelCollection }) => {
      if (visiblePanelCollection) {
        this.op.show(
          new MouseEvent('click'),
          this.btnCollections.nativeElement
        );
      }
    });

    this.store.select('topics').subscribe(({ topicsMenu }) => {
      if (topicsMenu) {
        const collections = topicsMenu.filter(item =>
          eq(item.label, 'Collections')
        );
        const { items } = head(collections);
        this.collections = items.map(item => {
          return {
            ...item,
            image: 'assets/images/collections/artificial-intelligence.svg'
          };
        });
      }
    });
  }

  public showOverlayPanel(e) {
    this.op.show(e);
    this.isPanelShowed = true;
  }

  public hideOverlayPanel() {
    this.op.hide();
    this.isPanelShowed = false;
  }

  public toggleOpCollections(event) {
    if (this.isPanelShowed) {
      this.hideOverlayPanel();
    } else {
      this.showOverlayPanel(event);
    }
  }
}
