interface SocialUserProps {
  name?: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  picture?: string;
  connection?: string;
}

export class SocialUser {
  public name: string;
  public emailAddress: string;
  public firstName: string;
  public lastName: string;
  public role: string;
  public picture: string;
  public connection: string;

  constructor({
    name,
    emailAddress,
    firstName,
    lastName,
    role,
    picture,
    connection
  }: SocialUserProps) {
    this.name = name || null;
    this.emailAddress = emailAddress || null;
    this.firstName = firstName || null;
    this.lastName = lastName || null;
    this.role = role || null;
    this.picture = picture || null;
    this.connection = connection || null;
  }
}
