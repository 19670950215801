import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Search } from 'models/index';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsFeedService {
  private api: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.api = `${environment.api_url}`;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public myNewsFeed(
    projectId: number,
    sort: string,
    page,
    size
  ): Observable<any> {
    return this.http.get(
      `${this.api}/project/${projectId}/feed?page=${page}&size=${size}`,
      this.httpOptions
    );
  }
}
