import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { tap, mergeMap, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ReportMissingDataService } from 'app/services';

@Injectable()
export class ReportMisssingDataEffects {
  constructor(
    private actions$: Actions,
    private reportMissingDataService: ReportMissingDataService
  ) {}

  createReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createReport),
      mergeMap(({ payload }) =>
        this.reportMissingDataService.sendReport(payload).pipe(
          tap(() => console.log('Create Report Missing Data')),
          switchMap(response => [
            actions.createReportSuccess({ payload: response })
          ]),
          catchError(err => of(actions.createReportError({ payload: err })))
        )
      )
    )
  );
}
