import { createAction, props } from '@ngrx/store';

export const setNewsFeed = createAction(
  '[News Feed] setNewsFeed',
  props<{
    projectId: number;
    sort: string;
    page: number;
    size: number;
  }>()
);

export const setNewsFeedSuccess = createAction(
  '[News Feed] setNewsFeedSuccess',
  props<{ data: any }>()
);

export const setNewsFeedError = createAction(
  '[News Feed] setNewsFeedError',
  props<{ payload: any }>()
);

export const unSetNewsFeed = createAction('[NewsFeed] unSetNewsFeed');
