import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { PublicTopicsComponent } from './topics/public-topics.component';

@NgModule({
  imports: [SharedModule],
  declarations: [PublicTopicsComponent],
  exports: [PublicTopicsComponent]
})
export class PublicPagesModule {}
