import { createReducer, on } from '@ngrx/store';
import { Project } from 'app/models';
import {
  setProject,
  unSetProject,
  resetProjectState,
  projectChange,
  createProject,
  editProjectSelected,
  startProjectChanged,
  stopProjectChanged
} from 'redux/actions';

export interface ProjectState {
  projectId: number;
  projectName: string;
  changeProject: boolean;
  createProject: boolean;
  company: any;
  projectSelected: Project;
  hasProjectChanged: boolean;
}

const initialState: ProjectState = {
  projectId: null,
  projectName: null,
  changeProject: false,
  createProject: false,
  company: null,
  projectSelected: null,
  hasProjectChanged: false
};

const _projectReducer = createReducer(
  initialState,

  on(setProject, (state, { id, name }) => {
    return {
      ...state,
      projectId: id,
      projectName: name
    };
  }),
  on(startProjectChanged, state => {
    return {
      ...state,
      hasProjectChanged: true
    };
  }),
  on(stopProjectChanged, state => {
    return {
      ...state,
      hasProjectChanged: false
    };
  }),
  on(unSetProject, state => ({ ...state, projectId: null, projectName: null })),

  on(editProjectSelected, (state, { projectSelected }) => {
    return {
      ...state,
      projectSelected
    };
  }),

  on(projectChange, (state, { projectSelected }) => ({
    ...state,
    changeProject: true,
    projectSelected
  })),
  on(createProject, state => ({
    ...state,
    createProject: true
  })),
  on(resetProjectState, state => ({
    ...state,
    changeProject: false,
    createProject: false,
    selectedItem: null,
    company: null
  }))
);

export function projectReducer(state, action) {
  return _projectReducer(state, action);
}
