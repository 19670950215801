import { Component } from '@angular/core';

@Component({
  selector: 'app-step-two',
  template: `
    <div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
      <div class="font-bold text-2xl">
        <div>
          <i class="pi pi-arrow-up text-gray-500" style="font-size: 2.5rem"></i>
        </div>
        <span class="text-900">Welcome to </span>
        <span class="text-orange-600">TechNews</span>
      </div>
      <div class="text-700 mb-2">
        Try browsing one of our curated topic collections to get started!
      </div>
    </div>
  `,
  styleUrls: ['../welcome.component.scss']
})
export class StepTwoComponent {}
