import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { ToastNotificationService } from 'app/components/toast-notification/toast-notification.service';
import { IssueReport, ReportMissingData } from 'app/models';
import { ReportMissingDataService } from 'app/services';
import * as actions from 'redux/actions';

@Component({
  selector: 'app-report-missing-incorrect-data',
  templateUrl: 'report-missing-incorrect-data.component.html',
  styleUrls: ['report-missing-incorrect-data.component.scss']
})
export class ReportMissingIncorrectDataComponent implements OnInit {
  public isSaving: boolean;
  public issues: IssueReport[];
  public form: FormGroup;
  public showModal: boolean;

  constructor(
    private rmdService: ReportMissingDataService,
    private notificationService: ToastNotificationService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>
  ) {
    this.showModal = true;
  }

  ngOnInit() {
    this.rmdService.getIssues().then(issues => (this.issues = issues));
    this.reportForm();

    this.store
      .select('reportMissingData')
      .subscribe(
        ({ loading, error, message, showReportDialog, successResponse }) => {
          this.isSaving = loading;
          this.showModal = showReportDialog;
          if (this.showModal) {
            this.resetDialog();
          }
          if (message) {
            this.notificationService.showMessage(message, 'Success');
          } else if (error) {
            this.notificationService.showMessage(error?.message, 'Error');
          }
        }
      );
  }

  reportForm() {
    this.form = this.formBuilder.group({
      name: [null],
      url: [null],
      notes: [null],
      problem: [null, Validators.required]
    });
  }

  invalidField(field: string) {
    return (
      this.form.controls[field].errors && this.form.controls[field].touched
    );
  }

  closeModalDialog() {
    this.resetDialog();
    this.store.dispatch(actions.hideReportDialog());
  }

  resetDialog() {
    this.reportForm();
  }

  handleSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.newReport(
      new ReportMissingData(
        this.form.get('problem').value,
        this.form.get('name').value,
        this.form.get('url').value,
        this.form.get('notes').value
      )
    );
  }

  private newReport(newReportMissingData: ReportMissingData) {
    this.store.dispatch(
      actions.createReport({ payload: newReportMissingData })
    );
  }
}
