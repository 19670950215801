import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { AuthenticationService } from 'app/services';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  public showDialog: boolean;
  public price: any;
  public numUsers: number;
  public minUser: number;
  public maxUser: number;

  constructor(
    private store: Store<AppState>,
    private authService: AuthenticationService
  ) {
    this.minUser = 1;
    this.maxUser = 100;
  }

  ngOnInit() {
    this.numUsers = 1;
    this.store.select('signUpDialog').subscribe(({ showDialog }) => {
      this.showDialog = showDialog;
      if (this.showDialog) {
        this.getPricing();
      }
    });
  }

  hideSignUpDialog() {
    this.store.dispatch(actions.hideSignUpDialog());
  }

  handleSubscription(annualSubscription) {
    this.authService
      .getCheckoutUrl(annualSubscription, this.numUsers)
      .subscribe(
        ({ value }) => {
          // Redirect to the checkout page
          window.location = value;
        },
        ({ error }) => {
          console.log(error);
        }
      );
  }

  getPricing() {
    this.price = null;
    this.authService.getPricing().subscribe(response => {
      this.price = response;
    });
  }
}
