import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-organization',
  template: `
    <app-users-list></app-users-list>
  `
})
export class MyOrganizationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
