import { createReducer, on } from '@ngrx/store';
import {
  updateTopic,
  updateTopicSuccess,
  updateTopicFail,
  resetTopicState,
  createTopic,
  createTopicSuccess,
  createTopicFail,
  deleteTopic,
  deleteTopicFail,
  deleteTopicSuccess,
  showTopicDialog,
  hideTopicDialog
} from 'redux/actions';

export interface TopicState {
  topic: any;
  success: boolean;
  creationMode: boolean;
  editionMode: boolean;
  loading: boolean;
  message: string;
  error: any;
  showTopicManager: boolean;
  successResponse: any;
}

const initialState: TopicState = {
  topic: null,
  success: false,
  loading: false,
  editionMode: false,
  creationMode: false,
  message: null,
  error: null,
  showTopicManager: false,
  successResponse: null
};

const _topicReducer = createReducer(
  initialState,

  on(showTopicDialog, (state, { topic }) => ({
    ...state,
    showTopicManager: true,
    editionMode: topic ? true : false,
    creationMode: !topic ? true : false,
    topic,
    successResponse: null
  })),

  on(hideTopicDialog, state => ({
    ...state,
    editionMode: false,
    creationMode: false,
    topic: null,
    message: null,
    loading: false,
    success: false,
    error: null,
    showTopicManager: false,
    successResponse: null
  })),

  on(resetTopicState, state => ({
    ...state,
    creationMode: false,
    editionMode: false,
    message: null,
    loading: false,
    success: false,
    error: null,
    topic: null,
    successResponse: null
  })),

  on(createTopic, (state, { topic }) => ({
    ...state,
    topic: {
      name: topic.name,
      query: topic.query,
      categoryId: topic.categoryId
    },
    creationMode: false,
    message: null,
    loading: true,
    success: false,
    error: null,
    successResponse: null
  })),

  on(createTopicSuccess, (state, { topic }) => ({
    ...state,
    loading: false,
    success: true,
    message: `Topic ${topic.name} has been added successfully`,
    successResponse: topic,
    showTopicManager: false
  })),

  on(createTopicFail, (state, { payload }) => ({
    ...state,
    loading: false,
    success: false,
    successResponse: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Add topic failed: ${payload?.message}`
    }
  })),

  on(updateTopic, (state, { topic }) => ({
    ...state,
    topic: {
      name: topic.name,
      query: topic.query,
      categoryId: topic.categoryId
    },
    editionMode: false,
    message: null,
    loading: true,
    success: false,
    error: null,
    successResponse: null
  })),

  on(updateTopicSuccess, (state, { topic }) => ({
    ...state,
    loading: false,
    success: true,
    message: `Topic ${topic.name} has been updated successfully`,
    successResponse: topic,
    topic,
    showTopicManager: false
  })),

  on(updateTopicFail, (state, { payload }) => ({
    ...state,
    loading: false,
    success: false,
    successResponse: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Update topic failed: ${payload?.message}`
    }
  })),

  on(deleteTopic, state => ({
    ...state,
    message: null,
    loading: true,
    success: false,
    error: null,
    successResponse: null
  })),

  on(deleteTopicSuccess, (state, { topic }) => ({
    ...state,
    loading: false,
    success: true,
    message: `Topic ${topic.name} has been unfollowed successfully`,
    successResponse: topic,
    showTopicManager: false,
    topic: null
  })),

  on(deleteTopicFail, (state, { payload }) => ({
    ...state,
    loading: false,
    success: false,
    successResponse: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Delete topic failed: ${payload?.message}`
    }
  }))
);

export function topicReducer(state, action) {
  return _topicReducer(state, action);
}
