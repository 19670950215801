import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge',
  template: `
    <div *ngIf="total > 0">
      <p-badge [value]="total.toString()"></p-badge>
    </div>
  `,
  styles: [
    `
      :host ::ng-deep .p-badge {
        position: absolute;
        background-color: #ff9800 !important ;
        color: #4e5f80 !important;
        font-size: 0.7rem;
        margin-left: -3px;
        top: 4.5px;
      }
    `,
  ],
})
export class BadgeComponent implements OnInit {
  @Input() total: number;
  constructor() {}

  ngOnInit() {}
}
