<p-dialog
  [dismissableMask]="true"
  [(visible)]="showModal"
  [style]="{ width: '60vw' }"
  [modal]="true"
  position="center"
  (onHide)="closeModalDialog()"
>
  <ng-template pTemplate="header">
    <h4>{{ title }}</h4>
  </ng-template>
  <div class="p-mt-3">
    <p-table
      #dt
      [globalFilterFields]="['name', 'description']"
      [rowHover]="true"
      selectionMode="single"
      [value]="categories"
      styleClass="p-datatable-sm"
      scrollHeight="460px"
      dataKey="name"
      [rows]="6"
      [paginator]="true"
      [showCurrentPageReport]="false"
    >
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="dt.filterGlobal(dt.value, 'contains')"
              placeholder="Search..."
            />
          </span>

          <div class="p-m-0">
            <button
              pButton
              pRipple
              label="New Category"
              icon="pi pi-plus"
              class="p-mr-2"
              (click)="showCategoryManager(null)"
            ></button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="size-col-action"></th>
          <th>
            <a href="javascript:;" (click)="sortChange(sortNameIcon)"
              >Name
              <i id="sortNameIcon" class="pi pi-sort" #sortNameIcon></i>
            </a>
          </th>
          <th>Description</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <span *ngIf="loaded">No results</span>
      </ng-template>
      <ng-template pTemplate="body" let-category let-rowIndex="rowIndex">
        <tr>
          <td class="size-col-action">
            <button
              pTooltip="Edit category"
              tooltipPosition="top"
              (click)="showCategoryManager(category)"
              pButton
              pRipple
              type="button"
              icon="pi pi-pencil"
              class="p-button-rounded p-button-primary p-button-text"
            ></button>
            <button
              pTooltip="Delete category"
              tooltipPosition="top"
              (click)="handleDelete(category)"
              pButton
              pRipple
              type="button"
              icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-text"
            ></button>
          </td>
          <td>{{ category?.name }}</td>
          <td>{{ category?.description }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="p-d-flex p-ai-center p-jc-between">
          Total {{ categories ? categories.length : 0 }} categories.
        </div>
      </ng-template>
    </p-table>
  </div>

  <ng-template pTemplate="footer">
    <div class="p-mt-3"></div>
    <p-button
      pRipple
      icon="pi pi-times"
      (click)="closeModalDialog()"
      label="Close"
      styleClass="p-button-raised p-button-secondary p-button-text"
    ></p-button>
    <!-- <p-button
      [disabled]="isSaving"
      pRipple
      icon="pi pi-check"
      (click)="handleSubmit()"
      label="Save"
      styleClass="p-button-primary"
    >
    </p-button> -->
  </ng-template>
</p-dialog>
