import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-author-content',
  template: `
    <app-sidebar-author-list></app-sidebar-author-list>
  `
})
export class SidebarAuthorContentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
