interface ProjectEditProps {
  projectId?: number;
  name?: string;
  description?: string;
}

export class ProjectEdit {
  public projectId?: number;
  public name?: string;
  public description?: string;

  constructor({ projectId, name, description }: ProjectEditProps) {
    this.projectId = projectId || null;
    this.name = name || null;
    this.description = description || null;
  }
}
