import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-items-skeleton',
  template: `
    <ul class="p-list-group">
      <li *ngFor="let item of ramdomArray">
        <div class="p-d-flex p-jc-between">
          <div class="p-list-picture">
            <p-skeleton
              shape="square"
              size="50px"
              styleClass="mb-4"
            ></p-skeleton>
          </div>
          <div class="p-list-text">
            <p-skeleton
              width="100%"
              height="1rem"
              styleClass="mt-2"
            ></p-skeleton>
            <p-skeleton
              width="100%"
              height="1rem"
              styleClass="mt-2"
            ></p-skeleton>
            <p-skeleton
              width="100%"
              height="1rem"
              styleClass="mt-2"
            ></p-skeleton>
            <p-skeleton
              width="100%"
              height="1rem"
              styleClass="mt-2"
            ></p-skeleton>
            <p-skeleton
              width="100%"
              height="1rem"
              styleClass="mt-2"
            ></p-skeleton>
            <p-skeleton
              width="100%"
              height="1rem"
              styleClass="mt-2"
            ></p-skeleton>
          </div>
          <div></div>
        </div>
      </li>
    </ul>
  `,
  styleUrls: ['./content-items.component.scss']
})
export class ContentItemsSkeletonComponent implements OnInit {
  public ramdomArray: any[];
  public totalTable: any[];
  public text: string;
  constructor() {}

  ngOnInit() {
    this.genLongTex();
    this.totalTable = new Array(4);
    this.totalTable = this.totalTable.fill(0).map(() => Math.random());
    this.ramdomArray = new Array(600);
    this.ramdomArray = this.ramdomArray.fill(0).map(() => Math.random());
  }

  genLongTex() {
    this.text = `${'x'.repeat(100)} ${'q'.repeat(100)}`;
  }
}
