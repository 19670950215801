interface UserProfileProps {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  picture?: string;
  login?: string;
  hasPassword?: boolean;
  createTrial?: boolean;
}

export class UserProfile {
  public firstName?: string;
  public lastName?: string;
  public emailAddress?: string;
  public picture?: string;
  public login?: string;
  public hasPassword?: boolean;
  public createTrial?: boolean;

  constructor({
    firstName,
    lastName,
    emailAddress,
    picture,
    login,
    hasPassword,
    createTrial
  }: UserProfileProps) {
    this.firstName = firstName || null;
    this.lastName = lastName || null;
    this.emailAddress = emailAddress || null;
    this.picture = picture || null;
    this.login = login || 'oauth';
    this.hasPassword = hasPassword || false;
    this.createTrial = createTrial || false;
  }
}
