import { TypeaheadReponseProps } from 'app/interfaces';

export class TypeaheadResponse {
  public id: number;
  public type: string;
  public name: string;
  public influenceScore: number;

  constructor({ id, type, name, influenceScore }: TypeaheadReponseProps) {
    this.id = id || null;
    this.type = type || null;
    this.name = name || null;
    this.influenceScore = influenceScore || 0;
  }
}
