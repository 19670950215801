import { createAction, props } from '@ngrx/store';

// Create Company

export const createCompany = createAction(
  '[Company] Create Company',
  props<{ payload: any }>()
);

export const createCompanySuccess = createAction(
  '[Company] Create Company Success',
  props<{ company: any }>()
);

export const createCompanyFail = createAction(
  '[Company] Create Company Fail',
  props<{ payload: any }>()
);

// Update Company

export const updateCompany = createAction(
  '[Company] Update Company',
  props<{ payload: any }>()
);

export const updateCompanySuccess = createAction(
  '[Company] Update Company Success',
  props<{ company: any }>()
);

export const updateCompanyFail = createAction(
  '[Company] Update Company Fail',
  props<{ payload: any }>()
);

// Delete Company

export const deleteCompany = createAction(
  '[Company] Delete Company',
  props<{ payload: any }>()
);

export const deleteCompanySuccess = createAction(
  '[Company] Delete Company Success',
  props<{ company: any }>()
);

export const deleteCompanyFail = createAction(
  '[Company] Delete Company Fail',
  props<{ payload: any }>()
);

export const resetCompanyState = createAction('[Company] Reset Company State');

// Company Manager Dialog

export const showCompanyDialog = createAction(
  '[UI Company Dialog] showCompanyDialog',
  props<{ company: any }>()
);

export const hideCompanyDialog = createAction(
  '[UI Company Dialog] hideCompanyDialog'
);
