import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { eq } from 'lodash';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { Notification } from 'app/models';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from 'app/services';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styles: [
    `
      :host ::ng-deep .create .p-button {
        background: #465675 !important;
        border-color: transparent !important;
        box-shadow: none;
      }
    `
  ]
})
export class CreateProjectComponent implements OnInit {
  public isSaving: boolean;
  public showCreateSection: boolean;
  public userId: any;
  public accountId: any;
  public;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService
  ) {
    this.isSaving = false;
    this.showCreateSection = false;
    this.userId = null;
    this.accountId = null;
  }

  ngOnInit(): void {
    this.store.select('userData').subscribe(({ data }) => {
      if (data) {
        this.userId = data.userId;
        this.accountId = data.accountId;
      }
    });

    this.store.select('ui').subscribe(({ showProjectCreateSection }) => {
      this.showCreateSection = showProjectCreateSection;
    });

    this.store.select('projects').subscribe(({ newProject, action }) => {
      if (eq(action, 'addProjectSuccess')) {
        this.isSaving = false;
        this.projectService.saveProjec({
          ...newProject
        });

        this.store.dispatch(
          actions.showNotification({
            notify: new Notification(
              'Project has been created',
              'success',
              'tl'
            )
          })
        );
        this.handleCancelProject();
      }

      if (eq(action, 'addProjectError')) {
        this.isSaving = false;
        this.store.dispatch(
          actions.showNotification({
            notify: new Notification('Error creating project', 'error', 'tl')
          })
        );
      }
    });
  }

  reloadPage() {
    window.location.reload();
  }

  handleCancelProject() {
    this.store.dispatch(actions.hideProjectCreateSection());
  }

  public handleSaveProject(value) {
    if (value) {
      this.isSaving = true;
      this.store.dispatch(
        actions.addProject({
          userId: this.userId,
          accountId: this.accountId,
          newProjectName: value
        })
      );
    }
  }
}
