<p-toast position="top-right" key="tr" [preventOpenDuplicates]="true"></p-toast>
<p-toast position="top-left" key="tl" [preventOpenDuplicates]="true"></p-toast>
<p-toast
  position="top-center"
  key="tc"
  [preventOpenDuplicates]="true"
></p-toast>
<p-toast
  position="bottom-center"
  key="bc"
  [preventOpenDuplicates]="true"
></p-toast>
