import { createReducer, on } from '@ngrx/store';
import { Search } from 'app/models';
import {
  setSearch,
  setSearchSuccess,
  setSearchError,
  unSetSearch,
  setSaveAsTopic,
  unSetSaveAsTopic,
  setSearchTopicSelected,
  resetSearch
} from 'redux/actions';

export interface SearchState {
  searchBody: Search;
  loaded: boolean;
  loading: boolean;
  saveAsTopic: boolean;
  onReset: boolean;
  error: any;
}

const initialState: SearchState = {
  searchBody: null,
  loaded: false,
  loading: false,
  saveAsTopic: false,
  onReset: false,
  error: null
};

const _searchReducer = createReducer(
  initialState,
  on(setSearch, (state, { search }) => {
    return {
      ...state,
      searchBody: search,
      loaded: false,
      loading: true
    };
  }),

  on(setSearchTopicSelected, (state, { searchBody }) => ({
    ...state,
    searchBody
  })),
  on(unSetSearch, () => ({ ...initialState })),
  on(resetSearch, (state, { onReset }) => {
    return {
      ...state,
      onReset
    };
  }),

  on(setSearchSuccess, state => ({
    ...state,
    loading: false,
    loaded: true
  })),

  on(setSearchError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  })),
  on(setSaveAsTopic, state => ({ ...state, saveAsTopic: true })),
  on(unSetSaveAsTopic, state => ({ ...state, saveAsTopic: false }))
);

export function searchReducer(state, action) {
  return _searchReducer(state, action);
}
