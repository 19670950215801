import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nav-notification',
  templateUrl: './nav-notification.component.html',
  styleUrls: ['./nav-notification.component.scss']
})
export class NavNoticationComponent {
  @Input() icon: string;
  @Input() navItem: string;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() message: string;
  @Input() imageUrl: string;

  constructor() {}
}
