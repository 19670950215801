import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { tap, mergeMap, map, catchError } from 'rxjs/operators';
import { CategoryService } from 'app/services';
import { of } from 'rxjs';
import { ProjectToggle } from 'app/models';

@Injectable()
export class ToggleEmailEffects {
  constructor(
    private actions$: Actions,
    private categoryService: CategoryService
  ) {}

  loadToggle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.showSelectProjectModal),
      mergeMap(action =>
        this.categoryService.getIncludeInEmailAlerts(action.projectId).pipe(
          map(({ value }) =>
            actions.setEmailAlertToogle({
              toggle: new ProjectToggle(action.projectId, value)
            })
          ),
          catchError(err =>
            of(actions.setEmailAlertToogleError({ payload: err }))
          )
        )
      )
    )
  );
}
