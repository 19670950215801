import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { from, concat } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent
} from 'ngx-perfect-scrollbar';
import { get, eq, isEmpty, includes, orderBy } from 'lodash';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { FacetSearch, Notification } from 'app/models';
import { MAP_URL } from 'app/pages/topics/constants';

@Component({
  selector: 'companies-ranked-list',
  templateUrl: 'companies-ranked-list.component.html',
  styles: [
    `
      .scroll-container {
        position: relative;
        width: 100%;
        height: calc(100vh - 195px);
        max-width: 100%;
        max-height: 100%;
      }

      :host ::ng-deep .p-datatable .p-datatable-tbody > tr {
        cursor: pointer;
      }

      :host ::ng-deep .p-datatable .p-datatable-tbody > tr > td {
        padding: 0rem;
      }

      :host ::ng-deep .p-datatable .p-datatable-thead > tr > th:first-child {
        text-align: left !important;
        padding-left: 1rem !important;
      }

      :host ::ng-deep .p-datatable .p-datatable-tbody > tr > td:first-child {
        text-align: left !important;
        padding-left: 1rem !important;
      }

      :host ::ng-deep .p-datatable .p-datatable-thead > tr > th:last-child {
        padding-left: 4rem !important;
      }

      :host ::ng-deep .p-datatable .p-datatable-tbody > tr > td:last-child {
        padding-left: 4rem !important;
        padding-right: 2rem !important;
      }

      .col-star {
        width: 5%;
      }

      .col-name {
        width: 10%;
      }

      .col-relevance {
        padding: 1rem !important;
        width: 10%;
      }

      .col-numarticle {
        padding-right: 2rem !important;
        text-align: right !important;
        width: 10%;
      }

      .col-shortterm {
        padding: 1rem !important;
        width: 10%;
      }

      .col-longterm {
        width: 20%;
      }

      :host ::ng-deep .p-progressbar .p-progressbar-value {
        background: lightgray;
      }
    `
  ]
})
export class ComapniesRankedListComponent implements OnInit {
  public companies: any[];
  public companiesList: any[];
  public page: number;
  public totalPages: number;
  public pageSize: number;
  public totalItems: number;
  public sort: string;
  public config: PerfectScrollbarConfigInterface = {};
  public topicSelected: any;
  public starIcon: string;
  public starCheckbox: boolean;
  public isClickOnstar: boolean;
  public project: any;

  @ViewChild('rankedListPerfectScroll', { static: false })
  rankedListPerfectScroll: PerfectScrollbarComponent;

  @ViewChildren(
    'sortNameIcon, sortRelevantIcon, sortArticleIcon, sortTrendIcon, sortLongTrendIcon, sortMonthDeltaIcon, sortYearDeltaIcon',
    {
      read: ElementRef
    }
  )
  sortIcons: QueryList<ElementRef>;

  constructor(private store: Store<AppState>) {
    this.page = 1;
    this.totalPages = 0;
    this.pageSize = 50;
    this.totalItems = 0;
    this.sort = 'stats.trend,desc';
    this.isClickOnstar = false;
    this.companies = [];
  }

  ngOnInit() {
    this.store
      .select('companies')
      .subscribe(
        ({
          companies,
          isDeleteCompany,
          companyDeleted,
          companyAdded,
          isAddCompany
        }) => {
          this.companiesList = companies;
          if (isDeleteCompany) {
            const compIndex = this.companies.findIndex(item =>
              eq(item.name, companyDeleted.name)
            );
            this.companies[compIndex].isSaved = false;
          }

          if (isAddCompany) {
            const compIndex = this.companies.findIndex(item =>
              eq(item.name, companyAdded.name)
            );
            this.companies[compIndex].isSaved = true;
          }
        }
      );

    this.store.select('companiesRanked').subscribe(({ list, error }) => {
      this.companiesData(get(list, 'content', []));
      this.totalPages = get(list, 'totalPages', 0);
      this.pageSize = get(list, 'size', 0);
      this.totalItems = get(list, 'totalElements', 0);

      if (error) {
        this.store.dispatch(
          actions.showNotification({
            notify: new Notification('Error fetching companies', 'error', 'tr')
          })
        );
      }
    });

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.project = projectSelected;
      }
    });
  }

  handleStarCheckOnClick(company) {
    this.isClickOnstar = !this.isClickOnstar;
    const companyIndex = this.companies.findIndex(item =>
      eq(item.id, company.id)
    );
    this.companies[companyIndex].isSaved = !this.companies[companyIndex]
      .isSaved;
    if (!this.companies[companyIndex].isSaved) {
      this.store.dispatch(
        actions.deleteCompanyToProject({
          companyId: company.id,
          userProject: this.project
        })
      );
    } else {
      this.store.dispatch(
        actions.addCompanyToProject({
          companyId: company.id,
          userProject: this.project
        })
      );
    }
  }

  public companiesData(companies: any[]) {
    if (!!companies.length) {
      this.companies = companies.map(item => ({
        ...item,
        isSaved: this.companiesList.find(li => eq(li.name, item.name))
          ? true
          : false,
        longTermChart: {
          longTermSeries: [
            {
              name: 'longterm-history',
              data: item.stats.longHistory
            }
          ],
          longTermColor: [item.stats.longTrend > 0 ? 'lightgray' : 'lightgray']
        }
      }));
      // const ob$ = from(companies);
      // const map1$ = ob$.pipe(
      //   map(item => ({
      //     ...item,
      //     isSaved: this.companiesList.find(li => eq(li.name, item.name))
      //       ? true
      //       : false,
      //     longTermChart: null
      //     // shortTermSeries: [
      //     //   {
      //     //     name: 'shortterm-history',
      //     //     data: item.stats.shortHistory
      //     //   }
      //     // ],
      //     // shortTermColor: [item.stats.trend > 0 ? 'lightgray' : 'lightgray'],
      //   }))
      // );

      // const map2$ = map1$.pipe(
      //   map(item => ({
      //     ...item,
      //     longTermChart: {
      //       longTermSeries: [
      //         {
      //           name: 'longterm-history',
      //           data: item.stats.longHistory
      //         }
      //       ],
      //       longTermColor: [
      //         item.stats.longTrend > 0 ? 'lightgray' : 'lightgray'
      //       ]
      //     }
      //   }))
      // );

      // concat(map1$, map2$).subscribe(newCompany => {
      //   const isIdExist = this.companies.find(t => eq(t?.id, newCompany.id));
      //   if (isIdExist) {
      //     // Observable 2: Load charts information
      //     const companiesFiltered = this.companies.filter(
      //       item => item.id !== isIdExist.id
      //     );
      //     this.companies = orderBy(
      //       [{ ...newCompany }, ...companiesFiltered],
      //       [this.sort],
      //       ['asc']
      //     );
      //   } else {
      //     // Observable 1: Load companies information
      //     this.companies = orderBy(
      //       [{ ...newCompany }, ...this.companies],
      //       [this.sort],
      //       ['asc']
      //     );
      //   }
      // });
    }
  }

  showCompanyDialog(t) {
    if (!this.isClickOnstar) {
      this.isClickOnstar = false;
      this.store.dispatch(actions.startLoading());
      const topic = {
        id: t.id,
        name: t.name,
        query: t?.query,
        isSaved: t?.isSaved,
        implicitQuery: t?.implicitQuery,
        wikipediaUrl: t.contact?.wikipediaUrl,
        webSiteUrl: t.contact?.webSiteUrl,
        youTubeUrl: t.contact?.youTubeUrl,
        twitterUrl: t.contact?.twitterUrl,
        linkedInUrl: t.contact?.linkedInUrl,
        financial: t?.organizationDetail
          ? this.getYahooFinanceURL(t.organizationDetail?.stockTicker)
          : null,
        locationUrl: t.contact?.streetAddress
          ? `${MAP_URL}/${t.contact.streetAddress}`
          : '',
        picture: t.contact?.imageUrl,
        project: this.project
      };

      const keywords = topic.query ? topic.query : topic.implicitQuery;
      const facetSearch = new FacetSearch(
        topic.id,
        'topic',
        keywords,
        'recent',
        0,
        50
      );

      this.store.dispatch(
        actions.loadCompanyContent({
          facetSearch,
          company: topic
        })
      );
      // setTimeout(() => {
      //   this.store.dispatch(actions.stopLoading());
      // }, 1000);
    } else {
      this.isClickOnstar = !this.isClickOnstar;
    }
  }

  getPage(e: any) {
    this.page = e.page + 1;
    this.store.dispatch(actions.startLoading());
    setTimeout(() => {
      this.store.dispatch(actions.startLoading());
      this.store.dispatch(
        actions.setCompaniesRanked({ sort: this.sort, page: e.page })
      );
    }, 500);
  }

  sortChange(icon: any) {
    this.sortIcons.forEach(({ nativeElement }) => {
      if (nativeElement === icon) {
        icon.className =
          icon.className === 'pi pi-sort'
            ? 'pi pi-sort-down'
            : icon.className === 'pi pi-sort-up'
            ? 'pi pi-sort-down'
            : 'pi pi-sort-up';
      } else {
        nativeElement.className = 'pi pi-sort';
      }
    });
    switch (icon.id) {
      case 'sortNameIcon':
        this.sort =
          icon.className === 'pi pi-sort-up' ? 'name,asc' : 'name,desc';
        break;

      case 'sortTrendIcon':
        this.sort =
          icon.className === 'pi pi-sort-up'
            ? 'stats.trend,asc'
            : 'stats.trend,desc';
        break;

      case 'sortLongTrendIcon':
        this.sort =
          icon.className === 'pi pi-sort-up'
            ? 'stats.longTrend,asc'
            : 'stats.longTrend,desc';
        break;

      case 'sortArticleIcon':
        this.sort =
          icon.className === 'pi pi-sort-up'
            ? 'stats.newsItems,asc'
            : 'stats.newsItems,desc';
        break;

      case 'sortRelevantIcon':
        this.sort =
          icon.className === 'pi pi-sort-up'
            ? 'stats.totalRelevance,asc'
            : 'stats.totalRelevance,desc';
        break;

      case 'sortMonthDeltaIcon':
        this.sort =
          icon.className === 'pi pi-sort-up'
            ? 'stats.deltaMonth,asc'
            : 'stats.deltaMonth,desc';
        break;

      case 'sortYearDeltaIcon':
        this.sort =
          icon.className === 'pi pi-sort-up'
            ? 'stats.deltaYear,asc'
            : 'stats.deltaYear,desc';
        break;

      default:
        this.sort =
          icon.className === 'pi pi-sort-up'
            ? 'stats.trend,asc'
            : 'stats.trend,desc';
        break;
    }

    this.store.dispatch(actions.startLoading());
    setTimeout(() => {
      this.store.dispatch(
        actions.setCompaniesRanked({ sort: this.sort, page: 0 })
      );
    }, 500);
  }

  getYahooFinanceURL(value: any) {
    if (isEmpty(value)) {
      return null;
    } else if (includes(value, 'http')) {
      const q = value.substring(value.lastIndexOf('/') + 1, value.length);
      return `https://finance.yahoo.com/quote/${q}`;
    } else {
      return `https://finance.yahoo.com/quote/${value}`;
    }
  }
}
