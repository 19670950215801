<div class="card">
  <div class="card-body" style="padding: 0.60rem;">
    <div class="text-muted" *ngIf="facetsLoading">
      <app-dashboard-facets-table-skeleton></app-dashboard-facets-table-skeleton>
    </div>
    <div>
      <div class="facets-container" *ngIf="!facetsLoading">
        <div
          class="d-flex justify-content-center text-muted"
          *ngIf="allContentsAreEmpty && facetsLoaded"
        >
          <app-not-records-found
            [visible]="allContentsAreEmpty"
          ></app-not-records-found>
        </div>

        <!-- <app-coverage-preview></app-coverage-preview> -->
        <div class="d-flex flex-row" *ngIf="!allContentsAreEmpty">
          <div class="p-pr-2" *ngFor="let content of facets">
            <facets-table
              (sortTableEmitter)="handleSortTables($event)"
              [entity]="content"
            ></facets-table>
          </div>
        </div>
      </div>
    </div>
    <div class="p-d-flex p-jc-between">
      <div></div>
      <div>
        <p-paginator
          #p
          [showCurrentPageReport]="false"
          currentPageReportTemplate="Showing {{ page }} of {{
            totalPages
          }} pages"
          [pageLinkSize]="3"
          [showFirstLastIcon]="false"
          (onPageChange)="getPage($event)"
          [rows]="pageSize"
          [totalRecords]="totalItems"
        >
        </p-paginator>
      </div>
      <div>
        <!--          <p-multiSelect-->
        <!--            [options]="selectOptions"-->
        <!--            [(ngModel)]="selectedFacets"-->
        <!--            [displaySelectedLabel]="false"-->
        <!--            [filter]="false"-->
        <!--            defaultLabel="VIEW"-->
        <!--            (onChange)="handleMulipleSelect()"-->
        <!--          ></p-multiSelect>-->
      </div>
    </div>
  </div>
</div>
