import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { tap, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthorService } from 'app/services';
import { FacetSearch } from 'app/models';

@Injectable()
export class AuthorDialogEffects {
  constructor(
    private actions$: Actions,
    private authorService: AuthorService
  ) {}

  loadAuthorDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setAuthorDialog),
      mergeMap(action =>
        this.getAuthor$(action).pipe(
          switchMap(response => [
            actions.setAuthorDialogSuccess({
              author: response
            }),
            actions.loadAuthorContent({
              facetSearch: new FacetSearch(
                action.authorId,
                'author',
                action.keywords,
                action.sort,
                0,
                50
              ),
              author: response
            })
          ]),
          catchError(err => of(actions.setAuthorDialogError({ payload: err })))
        )
      )
    )
  );

  getAuthor$(action): Observable<any> {
    return this.authorService.getAuthorById(action.authorId);
  }
}
