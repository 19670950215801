import { createAction, props } from '@ngrx/store';

export const setCollectionContent = createAction(
  '[CollectionContent] setCollectionContent',
  props<{
    collectionId: number;
    page: number;
  }>()
);

export const setCollectionContentSuccess = createAction(
  '[CollectionContent] setCollectionContentSuccess',
  props<{ contents: any }>()
);

export const setCollectionContentError = createAction(
  '[CollectionContent] setCollectionContentError',
  props<{ payload: any }>()
);

export const unSetCollectionContent = createAction(
  '[CollectionContent] unSetCollectionContent'
);
