import { Component } from '@angular/core';

@Component({
  selector: 'app-public-topics',
  templateUrl: './public-topics.component.html',
  styleUrls: ['./public-topics.component.scss']
})
export class PublicTopicsComponent {
  constructor() {}
}
