<toast-notification></toast-notification>
<get-screen-size></get-screen-size>
<div
  *ngIf="!isLoading"
  class="p-d-flex p-jc-center align-items-center justify-content-center login-container"
>
  <div [style]="bodySize">
    <div class="surface-img text-center w-medium">
      <img
        width="350"
        src="../../../assets/images/scalableVectorGraphics/logo-full.svg"
        alt="Image"
        class="m-4"
      />
    </div>

    <app-subscriber-user
      (handleLogin)="login($event)"
      [screenSize]="screenSize"
    ></app-subscriber-user>
  </div>
</div>

<div *ngIf="isLoading">
  <app-spinner alwaysVisible="true"></app-spinner>
</div>

<!-- [style]="{
  'padding-top': paddingT,
  'padding-bottom': paddingB,
  'padding-right': paddingRL,
  'padding-left': paddingRL
}" -->
