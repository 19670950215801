import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topics-ranked-skeleton',
  template: `
    <div class="card border">
      <p-table
        [value]="ramdomArray"
        styleClass="p-datatable-sm"
        selectionMode="single"
        [scrollable]="true"
        scrollHeight="calc(100vh - 405px)"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="p-p-5">
              <p-skeleton></p-skeleton>
            </th>

            <th class="p-p-5">
              <p-skeleton></p-skeleton>
            </th>
            <th class="p-p-5">
              <p-skeleton></p-skeleton>
            </th>
            <th class="p-p-5">
              <p-skeleton></p-skeleton>
            </th>

            <th class="p-p-5">
              <p-skeleton></p-skeleton>
            </th>
            <th class="p-p-5">
              <p-skeleton></p-skeleton>
            </th>
            <th class="p-p-5">
              <p-skeleton></p-skeleton>
            </th>

            <th class="p-p-5">
              <p-skeleton></p-skeleton>
            </th>
            <th class="p-p-5">
              <p-skeleton></p-skeleton>
            </th>
            <th class="p-p-5">
              <p-skeleton></p-skeleton>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr>
            <td class="p-p-5"><p-skeleton></p-skeleton></td>
            <td class="p-p-5"><p-skeleton></p-skeleton></td>
            <td class="p-p-5"><p-skeleton></p-skeleton></td>
            <td class="p-p-5"><p-skeleton></p-skeleton></td>
            <td class="p-p-5"><p-skeleton></p-skeleton></td>
            <td class="p-p-5"><p-skeleton></p-skeleton></td>
            <td class="p-p-5"><p-skeleton></p-skeleton></td>
            <td class="p-p-5"><p-skeleton></p-skeleton></td>
            <td class="p-p-5"><p-skeleton></p-skeleton></td>
            <td class="p-p-5"><p-skeleton></p-skeleton></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td class="p-p-5"><p-skeleton></p-skeleton></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  styles: [``]
})
export class TopicsRankedSkeletonComponent implements OnInit {
  public ramdomArray: any[];
  constructor() {}

  ngOnInit() {
    this.ramdomArray = new Array(50);
    this.ramdomArray = this.ramdomArray.fill(0).map(() => Math.random());
  }
}
