import { RouterModule, Routes } from '@angular/router';

import { PagesComponent, TopicsComponent } from './index';
import { AuthenticationGuard, LoginGuard } from '../services';
import {
  Auth0Component,
  CallbackComponent,
  LoginComponent,
  LogoutComponent,
  SignupComponent,
  ResetPasswordComponent
} from 'app/authentication';
import { PublicTopicsComponent } from '../public-pages/topics/public-topics.component';

const pagesRoutes: Routes = [
  { path: 'public', component: PublicTopicsComponent },
  { path: 'auth', component: LoginComponent },
  { path: 'auth/signup', component: SignupComponent },
  { path: 'auth/login', canActivate: [LoginGuard], component: Auth0Component },
  {
    path: 'auth/logout',
    component: LogoutComponent
  },
  { path: 'auth/callback', component: CallbackComponent },
  {
    path: 'auth/password-reset',
    component: ResetPasswordComponent
  },
  {
    path: '',
    component: PagesComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        component: TopicsComponent,
        data: {
          title: 'Topics',
          urls: [{ title: 'Topics', url: '' }, { title: 'Topics' }]
        }
      },
      {
        path: 'search/topic/:id/:section',
        component: TopicsComponent,
        data: {
          title: 'Topics',
          urls: [{ title: 'Topics', url: '' }, { title: 'Topics' }]
        }
      },
      {
        path: 'outlet/:id',
        component: TopicsComponent,
        data: {
          title: 'Topics',
          urls: [{ title: 'Topics', url: '' }, { title: 'Topics' }]
        }
      },
      {
        path: 'author/:id',
        component: TopicsComponent,
        data: {
          title: 'Topics',
          urls: [{ title: 'Topics', url: '' }, { title: 'Topics' }]
        }
      },
      {
        path: 'collections/:id/:menuItem',
        component: TopicsComponent,
        data: {
          title: 'Topics',
          urls: [{ title: 'Topics', url: '' }, { title: 'Topics' }]
        }
      },
      {
        path: ':section',
        component: TopicsComponent,
        data: {
          title: 'Topics',
          urls: [{ title: 'Topics', url: '' }, { title: 'Topics' }]
        }
      },
      {
        path: 'payment',
        component: TopicsComponent,
        data: {
          title: 'Topics',
          urls: [{ title: 'Topics', url: '' }, { title: 'Topics' }]
        }
      },
      { path: '', redirectTo: '/', pathMatch: 'full' },
      { path: '**', component: TopicsComponent }
    ]
  }
];

export const PAGES_ROUTE = RouterModule.forChild(pagesRoutes);
