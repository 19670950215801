import { createReducer, on } from '@ngrx/store';
import {
  showOutletManagerDialog,
  hideOutletManagerDialog,
  resetOutletManagerState,
  createOutlet,
  createOutletSuccess,
  createOutletError,
  updateOutlet,
  updateOutletSuccess,
  updateOutletError,
  deleteOutlet,
  deleteOutletSuccess,
  deleteOutletError
} from 'redux/actions';

export interface OutletManagerState {
  outlet: any;
  success: boolean;
  creationMode: boolean;
  editionMode: boolean;
  loading: boolean;
  message: string;
  error: any;
  showOutletManager: boolean;
  successResponse: any;
}

const initialState: OutletManagerState = {
  outlet: null,
  success: false,
  loading: false,
  editionMode: false,
  creationMode: false,
  message: null,
  error: null,
  showOutletManager: false,
  successResponse: null
};

const _outletManagerReducer = createReducer(
  initialState,

  on(showOutletManagerDialog, (state, { outlet }) => ({
    ...state,
    showOutletManager: true,
    editionMode: outlet ? true : false,
    creationMode: !outlet ? true : false,
    outlet
  })),
  on(hideOutletManagerDialog, () => ({
    outlet: null,
    message: null,
    loading: false,
    editionMode: false,
    creationMode: false,
    success: false,
    error: null,
    successResponse: null,
    showOutletManager: false
  })),

  on(resetOutletManagerState, state => ({
    ...state,
    creationMode: false,
    editionMode: false,
    message: null,
    loading: false,
    success: false,
    error: null,
    outlet: null
  })),

  on(createOutlet, (state, { payload }) => ({
    ...state,
    outlet: payload,
    creationMode: false,
    message: null,
    loading: true,
    success: false,
    error: null
  })),

  on(createOutletSuccess, (state, { outlet }) => ({
    ...state,
    loading: false,
    success: true,
    message: `Outlet ${outlet.name} has been added successfully`,
    successResponse: outlet,
    showOutletManager: false
  })),

  on(createOutletError, (state, { payload }) => ({
    ...state,
    loading: false,
    success: false,
    successResponse: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Error adding outlet: ${payload?.message}`
    }
  })),

  on(updateOutlet, (state, { payload }) => ({
    ...state,
    outlet: payload,
    editionMode: false,
    message: null,
    loading: true,
    success: false,
    error: null
  })),

  on(updateOutletSuccess, (state, { outlet }) => ({
    ...state,
    loading: false,
    success: true,
    message: `Outlet ${outlet.name} has been updated successfully`,
    outlet,
    successResponse: outlet,
    showOutletManager: false
  })),

  on(updateOutletError, (state, { payload }) => ({
    ...state,
    loading: false,
    success: false,
    successResponse: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Error updating outlet: ${payload?.message}`
    }
  })),

  on(deleteOutlet, state => ({
    ...state,
    message: null,
    loading: true,
    success: false,
    error: null
  })),

  on(deleteOutletSuccess, (state, { outlet }) => ({
    ...state,
    loading: false,
    success: true,
    message: `Outlet ${outlet.name} has been unfollowed successfully`,
    successResponse: outlet,
    showOutletManager: false,
    outlet: null
  })),

  on(deleteOutletError, (state, { payload }) => ({
    ...state,
    loading: false,
    success: false,
    successResponse: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Error deleting outlet: ${payload?.message}`
    }
  }))
);

export function outletManagerReducer(state, action) {
  return _outletManagerReducer(state, action);
}
