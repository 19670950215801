import { createReducer, on } from '@ngrx/store';
import {
  setUserData,
  setUserDataSuccess,
  setUserDataError,
  unSetUserData,
  setUserProfile,
  setIsUserLogged
} from 'redux/actions';
import { UserData, UserProfile } from 'app/models';

export interface UserDataState {
  data: UserData;
  loaded: boolean;
  loading: boolean;
  loginLoaded: boolean;
  loginLoadedMessage: string;
  error: any;
  userProfile: UserProfile;
}

const initialState: UserDataState = {
  data: null,
  loaded: false,
  loading: false,
  loginLoaded: false,
  loginLoadedMessage: null,
  error: null,
  userProfile: null
};

const _userDataReducer = createReducer(
  initialState,

  on(setUserData, state => ({
    ...state,
    loginLoaded: false,
    loginLoadedMessage: 'Loading user data...',
    loading: true
  })),

  on(setUserDataSuccess, (state, { userData }) => ({
    ...state,
    data: userData,
    loading: false,
    loaded: true
  })),

  on(setUserDataError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: { ...payload }
  })),

  on(setUserProfile, (state, { userProfile }) => ({
    ...state,
    userProfile
  })),

  on(setIsUserLogged, (state, { loginLoading, message }) => ({
    ...state,
    loginLoaded: loginLoading,
    loginLoadedMessage: message
  })),

  on(unSetUserData, () => ({
    data: null,
    loaded: false,
    loading: false,
    loginLoaded: false,
    loginLoadedMessage: null,
    error: null,
    userProfile: null
  }))
);

export function userDataReducer(state, action) {
  return _userDataReducer(state, action);
}
