import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { unSetUserData } from 'app/redux/actions';

@Component({
  selector: 'app-logout',
  template: `
    <span></span>
  `
})
export class LogoutComponent implements OnInit {
  constructor(private router: Router, private store: Store<AppState>) {}

  ngOnInit() {
    this.store.dispatch(unSetUserData());
    setTimeout(() => {
      this.router.navigate(['auth/login']);
    }, 500);
  }
}
