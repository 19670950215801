import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { ToastNotificationService } from 'app/components';
import { UserService } from 'app/services';
import * as actions from 'redux/actions';
import { UserCreate } from 'app/models';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  public visibleUserDialog: boolean;
  public newUserForm: FormGroup;
  public isSaving: boolean;
  public role: string;

  @Input() accountId: number;
  @Input() username: string;

  @ViewChild('firstName') firstName: ElementRef;
  @ViewChild('lastName') lastName: ElementRef;
  @ViewChild('email') email: ElementRef;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private notificationService: ToastNotificationService,
    private store: Store<AppState>
  ) {
    this.visibleUserDialog = false;
    this.isSaving = false;
    this.role = 'ROLE_SUBSCRIBER';
  }

  ngOnInit(): void {
    this.newUserForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      emailAddress: ['', [Validators.required, Validators.email]]
    });

    this.store.select('myAccount').subscribe(({ visibleNewUserDialog }) => {
      this.visibleUserDialog = visibleNewUserDialog;
      if (visibleNewUserDialog) {
        setTimeout(() => {
          this.firstName.nativeElement.focus();
        }, 100);
      }
    });
  }

  resetValidation() {
    this.newUserForm.reset();
  }

  handleInputTabIndex(e) {
    if (e.key === 'Tab') {
      switch (e.target.id) {
        case 'firstName':
          setTimeout(() => {
            this.lastName.nativeElement.focus();
          }, 100);
          break;
        case 'lastName':
          setTimeout(() => {
            this.email.nativeElement.focus();
          }, 100);

          break;
        case 'email':
          setTimeout(() => {
            this.firstName.nativeElement.focus();
          }, 100);
          break;
      }
    }
  }

  invalidField(field: string) {
    return (
      this.newUserForm.controls[field].errors &&
      this.newUserForm.controls[field].touched
    );
  }

  handleSubmit() {
    if (this.newUserForm.invalid) {
      this.newUserForm.markAllAsTouched();
      return;
    }
    this.isSaving = true;
    const newUser = new UserCreate({
      accountId: this.accountId,
      role: this.role,
      ...this.newUserForm.value
    });

    this.userService.createNewUser(this.username, newUser).subscribe(
      response => {
        this.notificationService.showMessage(
          'User has been created successfuly.',
          'Success'
        );
        this.onClose(true);
      },
      error => {
        this.notificationService.showMessage(
          'Error creating new user',
          'Error'
        );
        this.onClose(false);
      }
    );
  }

  onClose(isCreated) {
    this.isSaving = false;
    this.resetValidation();
    this.store.dispatch(actions.hideNewUserDialog({ isCreated }));
  }
}
