<div class="p-mt-4"></div>
<perfect-scrollbar
  class="scroll-container"
  [config]="config"
  #blogContentPerfectScroll
>
  <p-table [value]="posts" styleClass="p-datatable-sm">
    <ng-template pTemplate="header">
      <tr>
        <th style="width:100%"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>
          <div class="p-grid">
            <div class="p-col-fixed media-img-col">
              <a [href]="item.link" target="_blank">
                <img
                  [src]="item?.image ? item.image : 'assets/images/outls.jpg'"
                />
              </a>
            </div>
            <div class="p-col">
              <div class="p-grid">
                <div class="p-col-12">
                  <span class="media-date">{{ item?.pubDate | date }}</span>
                  <div class="media-title">
                    <a [href]="item?.link" target="_blank">
                      {{ item?.title }}
                    </a>
                  </div>
                </div>
<!--                <div class="p-col-12 media-description">-->
<!--                  {{ item?.description }}-->
<!--                </div>-->
                <span class="main-url" *ngIf="item?.link">
                  <a [href]="item?.link" target="_blank">Read More →</a></span
                >
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</perfect-scrollbar>
