import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { switchMap, delay } from 'rxjs/operators';

@Injectable()
export class SearchEffects {
  constructor(private actions$: Actions) {}

  // search$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(actions.setSearch),
  //     switchMap(action => [
  //       actions.setSearchSuccess(),
  //       actions.setFacetsSearch({
  //         searchBody: action.searchBody,
  //         page: 0,
  //         isNewSearch: true
  //       }),
  //       actions.startSearch()
  //     ])
  //   )
  // );

  searchTopicSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setSearchTopicSelected),
      delay(1000),
      switchMap(action => [actions.startSearch()])
    )
  );
}
