export interface SidebarListProps {
  hasPicture?: boolean;
  hasManager?: boolean;
  loadingIcon?: boolean;
  publishDate?: string;
  parentName?: string;
  parentObject?: any;
  title?: string;
  subTitle?: string;
  picture?: string;
}

export class SidebarList {
  public hasPicture: boolean;
  public hasManager: boolean;
  public parentName: string;
  public loadingIcon: boolean;
  public publishDate: string;
  public parentObject: any;
  public title: string;
  public subTitle: string;
  public picture: string;

  constructor({
    hasPicture,
    hasManager,
    parentName,
    loadingIcon,
    publishDate,
    parentObject,
    title,
    subTitle,
    picture
  }: SidebarListProps) {
    this.hasPicture = hasPicture || false;
    this.hasManager = hasManager || false;
    this.loadingIcon = loadingIcon || false;
    this.publishDate = publishDate || null;
    this.parentName = parentName || null;
    this.parentObject = parentObject || null;
    this.title = title || null;
    this.subTitle = subTitle || null;
    this.picture = picture || null;
  }
}
