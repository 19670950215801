import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { tap, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { SearchService } from '../../services';
import { of } from 'rxjs';
import { includes } from 'lodash';

@Injectable()
export class OffCanvasContentsEffects {
  constructor(
    private actions$: Actions,
    private searchService: SearchService
  ) {}

  authorContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadAuthorContent),
      mergeMap(action =>
        this.searchService.searchContent(action.facetSearch).pipe(
          tap(response => console.log('Load content author')),
          map(response =>
            actions.addContent({
              contentType: 'facetAuthor',
              contentData: {
                author: action.author,
                facetSearch: action.facetSearch,
                contentItems: response
              },
              topicId: null
            })
          ),
          catchError(err =>
            of(
              actions.loadContentError({
                payload: { error: err, content: 'Author' }
              })
            )
          )
        )
      )
    )
  );

  outletContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadOutletContent),
      mergeMap(action =>
        this.searchService.searchContent(action.facetSearch).pipe(
          tap(response => console.log('Load outlet content')),
          map(response =>
            actions.addContent({
              contentType: 'facetOutlet',
              contentData: {
                outlet: action.outlet,
                facetSearch: action.facetSearch,
                contentItems: response
              },
              topicId: null
            })
          ),
          catchError(err =>
            of(
              actions.loadContentError({
                payload: { error: err, content: 'Outlet' }
              })
            )
          )
        )
      )
    )
  );

  companyContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadCompanyContent),
      mergeMap(action =>
        this.searchService.searchContent(action.facetSearch).pipe(
          tap(response => console.log('Load company content')),
          map(response =>
            actions.addContent({
              contentType: 'facetCompany',
              contentData: {
                company: action.company,
                facetSearch: action.facetSearch,
                contentItems: response
              },
              topicId: null
            })
          ),
          catchError(err =>
            of(
              actions.loadContentError({
                payload: { error: err, content: 'Company' }
              })
            )
          )
        )
      )
    )
  );

  productContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadProductContent),
      mergeMap(action =>
        this.searchService.searchContent(action.facetSearch).pipe(
          tap(response => console.log('Load product content')),
          map(response =>
            actions.addContent({
              contentType: 'facetProduct',
              contentData: {
                product: action.product,
                facetSearch: action.facetSearch,
                contentItems: response
              },
              topicId: null
            })
          ),
          catchError(err =>
            of(
              actions.loadContentError({
                payload: { error: err, content: 'Product' }
              })
            )
          )
        )
      )
    )
  );

  topicContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadTopicContent),
      mergeMap(action =>
        this.searchService.searchContent(action.facetSearch).pipe(
          tap(response => console.log('Load topic content')),
          map(response =>
            actions.addContent({
              contentType: 'facetTopic',
              contentData: {
                topic: action.topic,
                facetSearch: action.facetSearch,
                contentItems: response
              },
              topicId: null
            })
          ),
          catchError(err =>
            of(
              actions.loadContentError({
                payload: { error: err, content: 'Topic' }
              })
            )
          )
        )
      )
    )
  );

  // startLoading$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(
  //       // actions.loadTopicContent,
  //       // actions.loadAuthorContent,
  //       // actions.loadOutletContent,
  //       actions.loadCompanyContent,
  //       actions.loadProductContent
  //     ),
  //     switchMap(action => of(actions.startLoading()))
  //   )
  // );

  // stopLoading$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(actions.addContent, actions.loadContentError),
  //     switchMap(action => of(actions.stopLoading()))
  //   )
  // );
}
