import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { ToastNotificationService } from 'app/components';
import { AuthenticationService, UserService } from 'app/services';
import { UserProfile, UserUpdate, ValidUser } from 'app/models';
import { eq, isEmpty } from 'lodash';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  public myUserForm: FormGroup;
  public isSaving: boolean;
  public userId: number;
  public username: string;
  public accountId: number;
  public role: string;
  public userImage: File;
  public userProfile: UserProfile;
  public userProfileState: UserProfile;
  public hasPassword: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private notificationService: ToastNotificationService,
    private store: Store<AppState>
  ) {
    this.isSaving = false;
    this.userImage = null;
  }
  ngOnInit(): void {
    this.userProfile = this.authenticationService.getUserProfile();
    this.myUserForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      emailAddress: ['', [Validators.required, Validators.email]],
      currentPassword: ['', [Validators.required]],
      newPassword: [''],
      confirmPassword: ['']
    });

    this.store.select('myAccount').subscribe(({ showMyAccountDialog }) => {
      if (showMyAccountDialog) {
        this.myUserForm.get('currentPassword').setValue('');
        this.myUserForm.get('newPassword').setValue('');
        this.myUserForm.get('confirmPassword').setValue('');
      }
    });

    this.store.select('userData').subscribe(({ data }) => {
      if (data) {
        this.myUserForm.get('firstName').setValue(data.firstName);
        this.myUserForm.get('lastName').setValue(data.lastName);
        this.myUserForm.get('emailAddress').setValue(data.userName);
        this.userId = data.userId;
        this.username = data.userName;
        this.accountId = data.accountId;
        this.role = data.role;
        this.hasPassword = data.hasPassword;
        if (this.hasPassword) {
          this.myUserForm
            .get('currentPassword')
            .setValidators([Validators.required]);
        } else {
          this.myUserForm.get('currentPassword').clearValidators();
        }

        this.userProfileState = new UserProfile({
          firstName: data.firstName,
          lastName: data.lastName,
          picture: data.hasPassword
            ? this.userService.getUserPicture(data.userId)
            : this.userProfile.picture,
          emailAddress: data.userName,
          hasPassword: this.hasPassword
        });
      }
    });
  }

  invalidField(field: string) {
    return (
      this.myUserForm.controls[field].errors &&
      this.myUserForm.controls[field].touched
    );
  }

  onClose() {
    this.isSaving = false;
    // this.myUserForm.reset();
    this.myUserForm.get('firstName').setValue(this.userProfileState.firstName);
    this.myUserForm.get('lastName').setValue(this.userProfileState.lastName);
    this.myUserForm.get('emailAddress').setValue(this.userProfileState.emailAddress);
    this.store.dispatch(actions.hideMyAccountDialog());
  }

  handleSubmit() {
    if (this.myUserForm.invalid) {
      this.myUserForm.markAllAsTouched();
      return;
    }
    this.isSaving = true;
    const {
      currentPassword,
      newPassword,
      confirmPassword
    } = this.myUserForm.value;
    const valUser = new ValidUser({
      userId: this.userId,
      username: this.username,
      password: currentPassword
    });
    if (!this.hasPassword) {
      this.handleUserUpdate();
      return;
    }
    this.userService.validCurrentPassword(valUser).subscribe(({ value }) => {
      if (value) {
        if (!isEmpty(newPassword) && !eq(newPassword, confirmPassword)) {
          this.notificationService.showMessage(
            'Password and confirm password must be the same!',
            'Error'
          );
          this.isSaving = false;
          return;
        }
        this.handleUserUpdate();
      } else {
        this.isSaving = false;
        this.notificationService.showMessage(
          'Current password is incorrect',
          'Error'
        );
      }
    });
  }

  public handleUserUpdate() {
    if (this.userImage && this.hasPassword) {
      this.handleSaveUserImage();
    }
    const userUpdate = new UserUpdate({
      accountId: this.accountId,
      role: this.role,
      ...this.myUserForm.value
    });
    this.userService
      .updateCurrentUserProfile(this.userId, userUpdate)
      .subscribe(
        response => {
          this.notificationService.showMessage(
            'User profile has been updated successfuly.',
            'Success'
          );
          this.dispatchUpdateUserProfile();
          this.onClose();
        },
        error => {
          this.notificationService.showMessage(
            'Error updating user profile',
            'Error'
          );
          this.onClose();
        }
      );
  }

  public setImageFile(e) {
    this.userImage = e;
  }

  handleSaveUserImage() {
    this.userService.updloadUserImage(this.userId, this.userImage).subscribe(
      response => {
        this.dispatchUpdateUserProfile();
      },
      error => {
        this.notificationService.showMessage(
          'Error updating user image',
          'Error'
        );
      }
    );
  }

  dispatchUpdateUserProfile() {
    const { firstName, lastName, emailAddress } = this.myUserForm.value;
    if (emailAddress && firstName && lastName) {
      const newUserProfile = new UserProfile({
        ...this.userProfile,
        firstName,
        lastName,
        picture: this.hasPassword
          ? this.userService.getUserPicture(this.userId)
          : this.userProfile.picture,
        emailAddress: emailAddress,
        hasPassword: this.hasPassword
      });
      this.store.dispatch(
        actions.setUserProfile({ userProfile: newUserProfile })
      );
      this.authenticationService.saveUserProfile(newUserProfile);

      this.userProfileState = newUserProfile;
    }
  }
}
