import { createReducer, on } from '@ngrx/store';
import {
  setTopicCards,
  unSetTopicCards,
  addTopicCard,
  removeTopicCard,
  updateTopicCard,
  resetAllTopicSelected
} from 'redux/actions';
import { TopicResponse } from 'app/models';

export interface TopicCardsState {
  topicCards: TopicResponse[];
}

const initialState: TopicCardsState = {
  topicCards: []
};

const _topicCardsReducer = createReducer(
  initialState,

  on(setTopicCards, (state, { topicCards }) => ({
    ...state,
    topicCards: [...topicCards]
  })),
  on(addTopicCard, (state, { topic }) => ({
    ...state,
    topicCards: [...state.topicCards, topic]
  })),

  on(updateTopicCard, (state, { topic }) => {
    const topics = state.topicCards.filter(item => item.id !== topic.id);
    return {
      ...state,
      topicCards: [...topics, topic]
    };
  }),

  on(removeTopicCard, (state, { id }) => {
    const topicCards = state.topicCards.filter(item => item.id !== id);
    return {
      ...state,
      topicCards
    };
  }),

  on(unSetTopicCards, state => ({
    projectCards: [],
    topicCards: []
  })),

  on(resetAllTopicSelected, state => {
    const topicCards = state.topicCards.map(item => ({
      ...item,
      selected: false
    }));
    return {
      ...state,
      topicCards
    };
  })
);

export function topicCardsReducer(state, action) {
  return _topicCardsReducer(state, action);
}
