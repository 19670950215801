import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ApexDataLabels,
  ApexLegend,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexTheme,
  ApexTitleSubtitle,
} from 'ng-apexcharts';
import { SovChartProps } from './sov-chart.interface';

interface ChartOptions {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  legend: ApexLegend;
  responsive: ApexResponsive[];
  labels: any;
  theme: ApexTheme;
  colors: string[];
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  xaxis: ApexXAxis | ApexXAxis[];
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  states: any;
  plotOptions: any;
}

@Component({
  selector: 'app-sov-chart',
  templateUrl: './sov-chart.component.html',
  styleUrls: ['./sov-chart.component.scss'],
})
export class SovChartComponent implements OnInit, OnChanges {
  @Input() sovChartProps: SovChartProps;
  public sovChartValues: SovChartProps;

  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() {
    window.Apex = {
      chart: {
        events: {
          click: undefined,
          markerClick: undefined,
          selection: undefined,
        },
        selection: {
          enabled: false,
          type: 'none',
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      stroke: {
        width: 2,
      },
      markers: {
        size: 0,
      },
      tooltip: {
        fixed: {
          enabled: false,
        },
        marker: {
          show: false,
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
        },
      },
    };
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sovChartProps.currentValue) {
      this.sovChartValues = changes.sovChartProps.currentValue;
      this.setChartOptions(this.sovChartValues);
    }
  }

  ngOnInit() {}

  setChartOptions(options) {
    this.chartOptions = {
      series: options.chartData,
      chart: {
        width: '50%',
        type: 'pie',
        events: {
          click: undefined,
          markerClick: undefined,
          selection: undefined,
        },
      },
      legend: {
        show: false, // Establecer show como false para desactivar la leyenda
      },
      labels: options.chartLabels,
      dataLabels: {
        enabled: false,
      },
      colors: ['lightgray', '#ff9800'],
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      title: {
        text: options.chartTitle,
      },
      tooltip: {
        enabled: false,
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
      },
      xaxis: {
        tooltip: {
          enabled: false,
        },
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        tooltip: {
          enabled: false,
        },
        show: false,
        showAlways: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 100,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    };
  }
}
