import { Component, OnInit } from '@angular/core';
import { lowerCase, includes } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  public showDialog: boolean;
  public showMyOrganization: boolean;

  constructor(private store: Store<AppState>) {
    this.showMyOrganization = false;
  }

  ngOnInit(): void {
    this.store.select('myAccount').subscribe(({ showMyAccountDialog }) => {
      this.showDialog = showMyAccountDialog;
    });
    this.store.select('userData').subscribe(({ data }) => {
      if (data) {
        const role = lowerCase(data.role);
        this.showMyOrganization =
          includes(role, 'admin') || includes(role, 'developer');
      }
    });
  }

  onClose() {
    this.store.dispatch(actions.hideMyAccountDialog());
  }
}
