import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { eq, head } from 'lodash';
import { Content } from 'app/constants';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import { SidebarList } from 'app/models';

@Component({
  selector: 'app-sidebar-list',
  templateUrl: './sidebar-list.component.html',
  styleUrls: ['./sidebar-list.component.scss']
})
export class SidebarListComponent implements OnInit, OnChanges {
  @Input() sidebarData: SidebarList[];

  @Output() openEditDialogEmiter: EventEmitter<any> = new EventEmitter<any>();
  @Output() openSelectDialogEmiter: EventEmitter<any> = new EventEmitter<any>();
  @Output() unfollowEmiter: EventEmitter<any> = new EventEmitter<any>();
  @Output() openNewDialogEmiter: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('contentItemsPerfectScroll', { static: false })
  contentItemsPerfectScroll: PerfectScrollbarComponent;

  public listName: string;
  public isTopic: boolean;
  public isClip: boolean;
  public perfectScrollbarHeight: any;
  public config: PerfectScrollbarConfigInterface = {};

  constructor() {
    this.listName = null;
    this.isTopic = false;
    this.perfectScrollbarHeight = {
      height: 'calc(100vh - 440px)'
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sidebarData.currentValue) {
      if (changes.sidebarData.currentValue.length > 0) {
        const firstItem: SidebarList = head(changes.sidebarData.currentValue);
        this.isTopic = eq(firstItem.parentName, Content.Topic);
        this.isClip = eq(firstItem.parentName, Content.ContentItem);
      }
      this.scrollTop();
    }
  }

  ngOnInit() {}

  openSelectDialog(item) {
    this.openSelectDialogEmiter.emit(item.parentObject);
  }

  openEditDialog(item) {
    this.openEditDialogEmiter.emit(item);
  }

  handleUnfollow(item) {
    this.unfollowEmiter.emit(item);
  }

  openNewTopic() {
    this.openNewDialogEmiter.emit();
  }

  openNewContentItem() {}

  goToTitleUrl(url) {
    window.open(url, '_blank');
  }

  private scrollTop() {
    if (this.contentItemsPerfectScroll) {
      this.contentItemsPerfectScroll.directiveRef.scrollTo(0, 1);
    }
  }
}
