interface ProjectProps {
  value?: number;
  name?: string;
  description?: string;
  selected?: boolean;
  tooltip?: string;
  label?: string;
}

export class Project {
  public value?: number;
  public name?: string;
  public description?: string;
  public selected?: boolean;
  public tooltip?: string;
  public label?: string;

  constructor({
    value,
    name,
    description,
    selected,
    tooltip,
    label
  }: ProjectProps) {
    this.value = value || null;
    this.name = name || null;
    this.description = description || null;
    this.selected = selected || false;
    this.tooltip = tooltip || null;
    this.label = label || null;
  }
}
