import { createAction, props } from '@ngrx/store';
import { Blog } from 'app/models';

export const setPosts = createAction(
  '[BlogPost] setPosts',
  props<{
    size: number;
  }>()
);

export const setPostsSuccess = createAction(
  '[BlogPost] setPostsSuccess',
  props<{ posts: Blog[] }>()
);

export const setPostsError = createAction(
  '[BlogPost] setPostsError',
  props<{ payload: any }>()
);

export const unSetPosts = createAction('[BlogPost] unSetPostsError');
