import { Component, OnInit, Input } from '@angular/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

@Component({
  selector: 'app-loading',
  template: `
    <ng-template #customLoadingTemplate> </ng-template>
    <ngx-loading
      [show]="isLoading"
      [config]="config"
      [template]="customLoadingTemplate"
    ></ngx-loading>
  `,
  styles: [
    `
      .custom-class {
        top: 0;
        z-index: 2002;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }

      .custom-class p {
        color: white;
        margin-top: 10rem;
      }
    `
  ]
})
export class LoadingComponent implements OnInit {
  @Input() isLoading: boolean;

  public config = {
    animationType: ngxLoadingAnimationTypes.circle,
    backdropBackgroundColour: 'rgba(0,0,0,0.1)',
    backdropBorderRadius: '3px',
    primaryColour: '#ff9800',
    secondaryColour: '#ffffff',
    tertiaryColour: '#ffffff',
    fullScreenBackdrop: false
  };

  constructor() {}

  ngOnInit() {}
}
