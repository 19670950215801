import { createReducer, on } from '@ngrx/store';
import {
  setNewsFeed,
  unSetNewsFeed,
  setNewsFeedSuccess,
  setNewsFeedError
} from 'redux/actions';

export interface NewsFeedState {
  projectId: number;
  data: any;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initialState: NewsFeedState = {
  projectId: null,
  data: null,
  loaded: false,
  loading: false,
  error: null
};

const _newsFeedReducer = createReducer(
  initialState,

  on(setNewsFeed, (state, { projectId }) => ({
    ...state,
    projectId,
    loading: true,
    loaded: false
  })),

  on(setNewsFeedSuccess, (state, { data }) => ({
    ...state,
    data: { ...data },
    loading: false,
    loaded: true
  })),

  on(setNewsFeedError, (state, { payload }) => ({
    ...state,
    projectId: null,
    data: null,
    loading: false,
    loaded: false,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  })),

  on(unSetNewsFeed, state => ({
    ...state,
    projectId: null,
    data: null
  }))
);

export function newsFeedReducer(state, action) {
  return _newsFeedReducer(state, action);
}
