export interface BlogProps {
  title?: string;
  pubDate?: string;
  description?: string;
  image?: string;
  link?: string;
}

export class Blog {
  public title?: string;
  public pubDate?: string;
  public description?: string;
  public image?: string;
  public link?: string;

  constructor({ title, pubDate, description, image, link }: BlogProps) {
    this.title = title || null;
    this.pubDate = pubDate || null;
    this.description = description || null;
    this.image = image || null;
    this.link = link || null;
  }
}
