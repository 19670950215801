import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexPlotOptions,
  ApexLegend,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ApexGrid
} from 'ng-apexcharts';

interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  legend: ApexLegend;
  colors: string[];
  markers: any;
  tooltip: ApexTooltip;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  grid: ApexGrid;
}

@Component({
  selector: 'relevancebar-chart',
  template: `
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [dataLabels]="chartOptions.dataLabels"
      [colors]="chartOptions.colors"
      [plotOptions]="chartOptions.plotOptions"
      [grid]="chartOptions.grid"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [tooltip]="chartOptions.tooltip"
    ></apx-chart>
  `,
  styles: [
    `
      apx-chart {
        padding: 0rem !important;
      }
    `
  ]
})
export class RelevancebarChartComponent implements OnInit {
  @Input() relevace: number;

  public chartOptions: Partial<ChartOptions>;

  constructor() {}

  ngOnInit() {
    this.chartOptions = {
      series: [
        {
          name: 'Actual',
          data: [
            {
              x: '',
              y: this.relevace,
              goals: [
                {
                  name: '',
                  value: 100,
                  strokeWidth: -1,
                  strokeColor: '#FFF'
                }
              ]
            }
          ]
        }
      ],
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      chart: {
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0
        },
        height: 82,
        type: 'bar'
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      colors: ['#00E396'],
      dataLabels: {
        enabled: true,
        formatter: function(val, opts) {
          const r = `${val.toString()}%`; // HERE we can set an progress bar label
          return '';
        }
      },
      legend: {
        show: false,
        showForSingleSeries: false,
        customLegendItems: ['Actual', 'Expected'],
        markers: {
          fillColors: ['#00E396', '#775DD0']
        }
      },
      xaxis: {
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        show: false,
        showAlways: false
      },
      tooltip: {
        enabled: false, // with this doesn't show values on mouse hover
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function(seriesName) {
              return '';
            }
          }
        },
        marker: {
          show: false
        }
      }
    };
  }
}
