import { createAction, props } from '@ngrx/store';
import { Project } from 'app/models';

export const setCompanyDialog = createAction(
  '[Company Dialog] setCompanyDialog',
  props<{
    companyId: number;
    projectSelected: Project;
    keywords: string;
    sort: string;
  }>()
);

export const setCompanyDialogSuccess = createAction(
  '[Company Dialog] setCompanyDialogSuccess',
  props<{ company: any }>()
);

export const setCompanyDialogError = createAction(
  '[Company Dialog] setCompanyDialogError',
  props<{ payload: any }>()
);
export const unSetCompanyDialog = createAction(
  '[Company Dialog] unSetCompanyDialog'
);
