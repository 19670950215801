import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { tap, mergeMap, catchError, switchMap } from 'rxjs/operators';
import { AuthorService } from '../../services';
import { of } from 'rxjs';
import { includes, map } from 'lodash';

@Injectable()
export class AuthorManagerEffects {
  constructor(
    private actions$: Actions,
    private authorService: AuthorService,
    private store: Store<AppState>
  ) {}

  createAuthor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createAuthor),
      mergeMap(({ payload }) =>
        this.authorService
          .createAuthor(payload)
          .pipe(
            tap(() => console.log('Create Author')),
            switchMap(response => [
              actions.createAuthorSuccess({ author: response })
            ]),
            catchError(err => of(actions.createAuthorError({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (includes(response?.type, 'Create Author Success')) {
                this.refreshProjectAuthorList(payload);
              }
            })
          )
      )
    )
  );

  updateAuthor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateAuthor),
      mergeMap(({ payload }) =>
        this.authorService
          .updateAuthorOutletToProject(payload.id, payload.userLabelId)
          .pipe(
            tap(() => console.log('Update Author')),
            switchMap(response => [
              actions.updateAuthorSuccess({ author: payload })
            ]),
            catchError(err => of(actions.updateAuthorError({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (includes(response?.type, 'Update Author Success')) {
                this.refreshProjectAuthorList(payload);
              }
            })
          )
      )
    )
  );

  deleteAuthor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteAuthor),
      mergeMap(({ payload }) =>
        this.authorService
          .deleteAuthorOutletToProject(payload.userProject.value, payload.id)
          .pipe(
            tap(() => console.log('Delete author')),
            switchMap(response => [
              actions.deleteAuthorSuccess({ author: payload })
            ]),
            catchError(err => of(actions.deleteAuthorError({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (includes(response?.type, 'Delete Author Success')) {
                this.refreshProjectAuthorList(payload);
              }
            })
          )
      )
    )
  );

  refreshProjectAuthorList(action) {
    this.store.dispatch(
      actions.loadAuthorsOutlets({ projectId: action.userProject.value })
    );
  }
}
