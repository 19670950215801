export const COMPANY = 'My Company';
export const TOPIC = 'Tech Topics';
export const MY_COMPETITORS = 'My Competitors';
export const ECOSYSTEM = 'My Ecosystem';
export const COMPETITORS = 'Competitors';

export const INIT_PROJECT = 'INIT_PROJECT';
export const CHANGE_PROJECT = 'CHANGE_PROJECT';
export const CHANGE_TOPIC = 'CHANGE_TOPIC';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_TOPIC = 'CREATE_TOPIC';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_TOPIC = 'UPDATE_TOPIC';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_TOPIC = 'DELETE_TOPIC';

export const MAP_URL = 'https://www.google.com/maps/place';
