import { createReducer, on } from '@ngrx/store';
import {
  setCompanyDialog,
  setCompanyDialogSuccess,
  setCompanyDialogError,
  unSetCompanyDialog
} from 'redux/actions';

export interface CompanyDialogState {
  companyId: number;
  company: any;
  loaded: boolean;
  loading: boolean;
  error: any;
  sort: string;
}

const initialState: CompanyDialogState = {
  companyId: null,
  company: null,
  loaded: false,
  loading: false,
  error: null,
  sort: null
};

const _companyDialogReducer = createReducer(
  initialState,

  on(setCompanyDialog, (state, { companyId, sort }) => {
    return {
      ...state,
      company: null,
      loading: true,
      loaded: false,
      companyId,
      sort
    };
  }),

  on(setCompanyDialogSuccess, (state, { company }) => ({
    ...state,
    company,
    loading: false,
    loaded: true
  })),

  on(setCompanyDialogError, (state, { payload }) => ({
    ...state,
    company: null,
    loading: false,
    loaded: false,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  })),

  on(unSetCompanyDialog, () => ({
    ...initialState
  }))
);

export function companyDialogReducer(state, action) {
  return _companyDialogReducer(state, action);
}
