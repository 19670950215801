import { createAction } from '@ngrx/store';

export const showSignUpDialog = createAction(
  '[SignUp Dialog] showSignUpDialog'
);

export const hideSignUpDialog = createAction(
  '[SignUp Dialog] hideSignUpDialog'
);

export const showPaymentSuccessDialog = createAction(
  '[SignUp Dialog] showPaymentSuccessDialog'
);

export const hidePaymentSuccessDialog = createAction(
  '[SignUp Dialog] hidePaymentSuccessDialog'
);
