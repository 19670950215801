import { createReducer, on } from '@ngrx/store';
import {
  unSetUserSession,
  hideWelcomeDialog,
  nextWelcomeDialog,
  doneWelcomeDialog,
  showWelcomeDialog,
  setIsUserFirstTime,
  hideTourByCollectionSelected
} from 'redux/actions';
import { UserSession } from 'app/models';

export interface SessionState {
  session: UserSession;
  visibleWelcomeDialog: boolean;
  visiblePanelCollection: boolean;
  closeTourByCollectionItem: boolean;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initialState: SessionState = {
  session: null,
  visibleWelcomeDialog: false,
  visiblePanelCollection: false,
  closeTourByCollectionItem: false,
  loaded: false,
  loading: false,
  error: null
};

const _sessionReducer = createReducer(
  initialState,

  on(showWelcomeDialog, state => {
    return {
      ...state,
      visibleWelcomeDialog: true
    };
  }),

  on(hideWelcomeDialog, state => ({
    ...state,
    visibleWelcomeDialog: false
  })),

  on(hideTourByCollectionSelected, (state, { value }) => {
    return {
      ...state,
      closeTourByCollectionItem: value
    };
  }),

  on(doneWelcomeDialog, state => {
    return {
      ...state,
      visiblePanelCollection: false
    };
  }),

  on(nextWelcomeDialog, state => ({
    ...state,
    visiblePanelCollection: true
  })),

  on(setIsUserFirstTime, (state, { value }) => {
    return {
      ...state,
      visibleWelcomeDialog: value
    };
  }),

  on(unSetUserSession, () => ({ ...initialState }))
);

export function sessionReducer(state, action) {
  return _sessionReducer(state, action);
}
