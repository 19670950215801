import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AuthenticationService } from 'app/services';

@Component({
  selector: 'app-trial-user',
  templateUrl: './trial-user.component.html',
  styleUrls: ['./trial-user.component.scss']
})
export class TrialUserComponent implements OnInit, OnChanges {
  @Input() public screenSize: string;

  constructor(
    private authService: AuthService,
    private authTechnewService: AuthenticationService,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {}

  goToLogin() {
    this.router.navigateByUrl('/auth/login');
  }

  loginWithGoogle(): void {
    this.authTechnewService.saveUserProfile({
      createTrial: true
    });
    this.authService.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        connection: 'google-oauth2'
      }
    });
  }

  loginWithLinkedIn(): void {
    this.authTechnewService.saveUserProfile({
      createTrial: true
    });
    this.authService.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        connection: 'linkedin'
      }
    });
  }

  loginWithMicrosoft(): void {
    this.authTechnewService.saveUserProfile({
      createTrial: true
    });
    this.authService.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        connection: 'windowslive'
      }
    });
  }
}
