import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {
  public api: string;
  public product: string;
  public iconUrl: string;
  public env: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.env = environment.production ? '/' : '/Development/';
    this.product = environment.product;
    this.api = environment.api_url;
    this.iconUrl = `${environment.icon_url}${this.env}`;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public getCollectionIcon(id): string {
    return `${this.iconUrl}collections/${id}`;
  }
}
