import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { tap, mergeMap, map, catchError, switchMap } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import { SearchService } from 'app/services';

@Injectable()
export class CollectionContentEffects {
  constructor(
    private actions$: Actions,
    private searchService: SearchService
  ) {}

  loadCollectionContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setCollectionContent),
      mergeMap(action =>
        this.getContents$(action).pipe(
          tap(response => console.log('loadCollectionContent$')),
          switchMap(response => [
            actions.setCollectionContentSuccess({
              contents: { ...response }
            })
          ]),
          catchError(err => of(actions.setSearchError({ payload: err })))
        )
      )
    )
  );

  getContents$(action): Observable<any> {
    return this.searchService.collectionContent(
      action.collectionId,
      action.page
    );
  }
}
