<p-dialog
  [(visible)]="showModal"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="onClose()"
>
  <ng-template pTemplate="header">
    <h3>Alert Settings</h3>
  </ng-template>
  <div class="p-mt-5" *ngIf="projects">
    <div class="p-grid">
      <div class="p-col-6">
        <fieldset class="well">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 40%; min-width: 0vw !important">
                    {{ getProjectLabel() }}
                  </th>
                  <th style="width: 40%; min-width: 0vw !important">
                    Include in alerts
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of includeAlerts; index as i">
                  <td style="min-width: 0vw !important">
                    <i class="pi pi-spin pi-spinner" *ngIf="loading"></i>
                    {{ p.name }}
                  </td>
                  <td style="min-width: 0vw !important" class="td-center">
                    <p-selectButton
                      [disabled]="loading"
                      [options]="stateOptions"
                      [(ngModel)]="p.included"
                      optionValue="value"
                      optionLabel="label"
                      (onChange)="
                        changeIncludeEmailAlertsByRow($event.value, p.value, i)
                      "
                    ></p-selectButton>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </fieldset>
      </div>
      <div class="p-col-6">
        <fieldset class="well">
          <div class="form-group">
            <label class="form-control-static text-right m-l-40 m-r-10"
              >Alerts Frequency</label
            >
            <p-dropdown
              (onChange)="changeFrecuency($event)"
              [options]="frecuencies"
              [(ngModel)]="frecuency"
              optionLabel="label"
              optionValue="value"
            ></p-dropdown>
          </div>
          <div class="text-center m-b-30 m-t-30">
            <button
              (click)="onClickDisableAllEmailAlerts()"
              [disabled]="disableAll"
              pButton
              pRipple
              type="button"
              label="Disable all e-mail alerts"
              class="p-button-raised p-button-warning"
            ></button>
          </div>
        </fieldset>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      pRipple
      icon="pi pi-times"
      (click)="onClose()"
      label="Close"
      styleClass="p-button-raised p-button-secondary p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>
