export class TopicCard {
  constructor(
    public id: number,
    public name: string,
    public query: string,
    public implicitQuery: string,
    public categoryId: number,
    public categoryName: string,
    public coverage: number,
    public authors: any[],
    public outlets: any[],
    public selected: boolean,
    public custom: boolean,
    public partyId: number,
    public wikipediaUrl?: string,
    public webSiteUrl?: string,
    public youTubeUrl?: string,
    public twitterUrl?: string,
    public linkedInUrl?: string,
    public picture?: string,
    public financial?: string,
    public project?: any,
    public isSaved?: boolean
  ) {}
}
