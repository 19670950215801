import { createAction, props } from '@ngrx/store';
import { TopicCard, TopicResponse } from 'app/models';

export const setTopics = createAction(
  '[Topics] Load Topics',
  props<{ projectId: number }>()
);

export const setTopicsSuccess = createAction(
  '[Topics] Load Topics Success',
  props<{ topics: TopicResponse[] }>()
);

export const setTopicsError = createAction(
  '[Topics] Load Topics Error',
  props<{ payload: any }>()
);

export const addTopicToProject = createAction(
  '[Topics] Add Topic to Project',
  props<{ topic: any; userProject: any }>()
);

export const deleteTopicToProject = createAction(
  '[Topics] Delete Topic to Project',
  props<{ topic: any; userProject: any }>()
);

export const addTopicToProjectSuccess = createAction(
  '[Topics] Add Topic to Project Success',
  props<{ topic: any }>()
);

export const deleteTopicToProjectSuccess = createAction(
  '[Topics] Delete Topic to Project Success',
  props<{ topic: any }>()
);

export const addTopicFromDialog = createAction(
  '[Topics] Add Topic from Topic Dialog',
  props<{ topic: TopicCard }>()
);

export const deleteTopicFromManager = createAction(
  '[Topics] Delete Topic from Manager Topics',
  props<{ topic: TopicCard }>()
);

export const setAddTopicToProjectError = createAction(
  '[Topics] Add Topic to Project Error',
  props<{ payload: any; topicId: number }>()
);

export const setDeleteTopicToProjectError = createAction(
  '[Topics] Delete Topic to Project Error',
  props<{ payload: any; topicId: number }>()
);

export const loadTopicsMenu = createAction('[Topics] Load Topics Menu');
export const loadTopicsMenuSuccess = createAction(
  '[Topics] Load Topics Menu Success',
  props<{ payload: any }>()
);
export const loadTopicsMenuError = createAction(
  '[Topics] Load Topics Menu Error',
  props<{ payload: any }>()
);

export const setTopicMenuItemSelected = createAction(
  '[Topics] Topic Menu Item Selected',
  props<{ item: any }>()
);

export const unSetTopicMenuItemSelected = createAction(
  '[Topics] Topic Menu Item Unselected'
);

export const unSetTopics = createAction('[Topics] unSetTopics');
