import { NgModule } from '@angular/core';
import {
  CommonModule,
  LocationStrategy,
  PathLocationStrategy
} from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from '../components/components.module';
import {
  SidebarTopicsContentComponent,
  SidebarCompanyContentComponent,
  SidebarAuthorContentComponent,
  SidebarAuthorListComponent,
  SidebarOutletListComponent,
  SidebarOutletContentComponent,
  SidebarTopicsListComponent,
  SidebarCompaniesListComponent,
  SidebarContentItemsComponent,
  SidebarContentItemsListComponent
} from 'app/pages/topics/sidebar-accordion-contents';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    SidebarTopicsContentComponent,
    SidebarCompanyContentComponent,
    SidebarOutletListComponent,
    SidebarAuthorContentComponent,
    SidebarAuthorListComponent,
    SidebarOutletContentComponent,
    SidebarTopicsListComponent,
    SidebarCompaniesListComponent,
    SidebarContentItemsComponent,
    SidebarContentItemsListComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PerfectScrollbarModule,
    ComponentsModule,
    SharedModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ],
  exports: [LayoutComponent]
})
export class LayoutModule {}
