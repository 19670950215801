import { createAction, props } from '@ngrx/store';
import { Project } from 'app/models';

export const setOutletDialog = createAction(
  '[Outlet Dialog] setOutletDialog',
  props<{
    outletId: number;
    projectSelected: Project;
    keywords: string;
    sort: string;
  }>()
);

export const setOutletDialogSuccess = createAction(
  '[Outlet Dialog] setOutletDialogSuccess',
  props<{ outlet: any }>()
);

export const setOutletDialogError = createAction(
  '[Outlet Dialog] setOutletDialogError',
  props<{ payload: any }>()
);
export const unSetOutletDialog = createAction(
  '[Outlet Dialog] unSetOutletDialog'
);
