import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Session } from 'models/index';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private api: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.api = environment.api_url;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public saveUserSession(session: Session): Observable<any> {
    return this.http.post(
      `${this.api}/session/save`,
      session,
      this.httpOptions
    );
  }

  public getUserSession(userId, accountId): Observable<any> {
    return this.http.get(
      `${this.api}/sessions/search/findUserSession?userId=${userId}&accountId=${accountId}`,
      this.httpOptions
    );
  }
}
