import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { eq } from 'lodash';
import * as actions from '../actions';
import { switchMap, tap, catchError, mergeMap } from 'rxjs/operators';
import { TopicService } from 'app/services';
import { of } from 'rxjs';
import { TopicList, TopicsRankedResponse } from 'app/models';
import { STAR_ICON, STAR_ICON_FILL } from 'app/constants';

@Injectable()
export class TopicsRankedEffects {
  constructor(private actions$: Actions, private topicService: TopicService) {}

  createTopicsRankedList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setTopicsRanked),
      mergeMap(action =>
        this.topicService
          .rankedTopicsSearch(action.searchBody, action.page)
          .pipe(
            tap(response => console.log('Load Topics ranked Search')),
            switchMap(response => [
              actions.setTopicsRankedSuccess({
                topicsRankedList: this.createTopicsRankedList(response)
              }),
              action.searchBody.keywords
                ? actions.setTopicCoveragePreview({
                    searchBody: action.searchBody
                  })
                : actions.unSetTopicCoveragePreview()
              // actions.stopLoading()
            ]),
            catchError(err =>
              of(actions.setTopicsRankedError({ payload: err }))
            )
          )
      )
    )
  );

  topicsRankedPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setTopicsRanked),
      mergeMap(action =>
        this.topicService.topicsSearchPreview(action.searchBody).pipe(
          tap(response => console.log('Load Topics Search Preview')),
          switchMap(({ content }) => [
            actions.setTopicSearchPreviewSuccess({ previews: content })
          ]),
          catchError(err =>
            of(actions.setTopicSearchPreviewError({ payload: err }))
          )
        )
      )
    )
  );

  loadTopicCoveragePreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setTopicCoveragePreview),
      tap(response => console.log('Get Topic Coverage Preview data')),
      mergeMap(action =>
        this.topicService.coveragePreviewSearch(action.searchBody).pipe(
          switchMap(response => [
            actions.setTopicCoveragePreviewSuccess({
              coveragePreview: response
            })
            // actions.stopLoading()
          ]),
          catchError(err =>
            of(actions.setTopicCoveragePreviewError({ payload: err }))
          )
        )
      )
    )
  );

  createTopicsRankedList(response): TopicsRankedResponse {
    const rankedList: TopicList[] = response.content.map(item => {
      return new TopicList({
        ...item,
        starIcon: STAR_ICON,
        longTermChart: {
          longTermSeries: [
            {
              name: 'longterm-history',
              data: item.longHistory
            }
          ],
          longTermColor: [item.longTrend > 0 ? 'lightgray' : 'lightgray']
        }
      });
    });

    return new TopicsRankedResponse({
      ...response,
      list: rankedList
    });
  }
}
