import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { tap, mergeMap, map, catchError, switchMap } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import { TopicService } from 'app/services';
import { FacetSearch, TopicResponse } from 'app/models';
import { isEmpty, includes } from 'lodash';
import { MAP_URL } from 'app/pages/topics/constants';

@Injectable()
export class TopicDialogEffects {
  constructor(private actions$: Actions, private topicService: TopicService) {}

  loadTopicDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setTopicDialog),
      mergeMap(({ keywords, projectSelected, topicSelected, sort }) =>
        this.getContents$(topicSelected).pipe(
          switchMap(response => [
            actions.setTopicDialogSuccess({
              topic: this.getTopic(response, projectSelected, topicSelected)
            }),
            actions.loadTopicContent({
              facetSearch: new FacetSearch(
                response.id,
                'topic',
                this.getKeywords(response, keywords),
                sort,
                0,
                50
              ),
              topic: this.getTopic(response, projectSelected, topicSelected)
            })
          ]),
          catchError(err => of(actions.setTopicDialogError({ payload: err })))
        )
      )
    )
  );

  getKeywords(response, keywords) {
    const query = response.query ? response.query : response.implicitQuery;
    if (keywords) {
      return `(${query}) AND (${keywords})`;
    } else {
      return query;
    }
  }

  getContents$(topic): Observable<any> {
    if (topic.custom) {
      return this.topicService.getUserTopic(topic.userTopicId);
    } else {
      return this.topicService.getTopicRelated(topic.id);
    }
  }

  getTopic(topic, projectSelected, topicSelected): TopicResponse {
    return new TopicResponse({
      id: topic?.id,
      name: topic?.name,
      query: topic?.query,
      implicitQuery: topic?.implicitQuery,
      influenceScore: topic?.influenceScore,
      categoryId: topicSelected?.categoryId,
      categoryName: topicSelected?.categoryName,
      coverage: topicSelected?.coverage,
      authors: topicSelected?.authors,
      outlets: topicSelected?.outlets,
      selected: topicSelected?.selected,
      custom: topicSelected?.custom,
      userTopicId: topicSelected?.userTopicId,
      wikipediaUrl: topic?.contact?.wikipediaUrl,
      webSiteUrl: topic?.contact?.webSiteUrl,
      youTubeUrl: topic?.contact?.youTubeUrl,
      twitterUrl: topic?.contact?.twitterUrl,
      linkedInUrl: topic?.contact?.linkedInUrl,
      picture: topic?.contact?.imageUrl,
      financial: topic?.organizationDetail
        ? this.getYahooFinanceURL(topic.organizationDetail?.stockTicker)
        : null,
      related: topic?.related,
      stats: topic?.stats,
      project: projectSelected,
      isSaved: topicSelected?.isSaved
    });
  }

  getYahooFinanceURL(value: any) {
    if (isEmpty(value)) {
      return null;
    } else if (includes(value, 'http')) {
      const q = value.substring(value.lastIndexOf('/') + 1, value.length);
      return `https://finance.yahoo.com/quote/${q}`;
    } else {
      return `https://finance.yahoo.com/quote/${value}`;
    }
  }
}
