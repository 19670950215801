import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { tap, mergeMap, catchError, switchMap } from 'rxjs/operators';
import { OutletService } from '../../services';
import { of } from 'rxjs';
import { includes, map } from 'lodash';

@Injectable()
export class OutletManagerEffects {
  constructor(
    private actions$: Actions,
    private outletService: OutletService,
    private store: Store<AppState>
  ) {}

  createOutlet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createOutlet),
      mergeMap(({ payload }) =>
        this.outletService
          .createOutlet(payload)
          .pipe(
            tap(() => console.log('Create Outlet')),
            switchMap(response => [
              actions.createOutletSuccess({ outlet: response })
            ]),
            catchError(err => of(actions.createOutletError({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (includes(response?.type, 'Create Outlet Success')) {
                this.refreshProjectOutletList(payload);
              }
            })
          )
      )
    )
  );

  updateOutlet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateOutlet),
      mergeMap(({ payload }) =>
        this.outletService
          .updateAuthorOutletToProject(payload.id, payload.userLabelId)
          .pipe(
            tap(() => console.log('Update Outlet')),
            switchMap(response => [
              actions.updateOutletSuccess({ outlet: response })
            ]),
            catchError(err => of(actions.updateOutletError({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (includes(response?.type, 'Update Outlet Success')) {
                this.refreshProjectOutletList(payload);
              }
            })
          )
      )
    )
  );

  deleteOutlet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteOutlet),
      mergeMap(({ payload }) =>
        this.outletService
          .unfollowOutletToProject(payload.userProject.value, payload.id)
          .pipe(
            tap(() => console.log('Delete outlet')),
            switchMap(response => [
              actions.deleteOutletSuccess({ outlet: payload })
            ]),
            catchError(err => of(actions.deleteOutletError({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (includes(response?.type, 'Delete Outlet Success')) {
                this.refreshProjectOutletList(payload);
              }
            })
          )
      )
    )
  );

  refreshProjectOutletList(action) {
    this.store.dispatch(
      actions.loadAuthorsOutlets({ projectId: action.userProject.value })
    );
  }
}
