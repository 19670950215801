import {
  Component,
  Input,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent
} from 'ngx-perfect-scrollbar';
import { truncate, eq } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { FacetSearch, Project, ContentItems } from 'app/models';
import { AuthorService } from 'app/services';
import { Content, STAR_ICON, STAR_ICON_FILL } from 'app/constants';

@Component({
  selector: 'app-content-items',
  templateUrl: './content-items.component.html',
  styleUrls: ['./content-items.component.scss']
})
export class ContentItemsComponent implements OnInit, OnChanges {
  @Input() parentName: string;
  @Input() items: any[];
  @Input() loading: boolean;
  @Input() pageSize: number;
  @Input() page: number;
  @Input() total: number;
  @Input() keywords: string;
  public contentItems: ContentItems[];
  public perfectScrollbarHeight: any;
  public section: string;
  public sliceControl: number;
  public screenSize: number;
  public starUnSelected: string;
  public starSelected: string;
  public project: Project;
  public contentItemsSaved: ContentItems[];

  public config: PerfectScrollbarConfigInterface = {};

  @ViewChild('contentItemsPerfectScroll', { static: false })
  contentItemsPerfectScroll: PerfectScrollbarComponent;
  constructor(
    private store: Store<AppState>,
    private authorService: AuthorService
  ) {
    this.starUnSelected = STAR_ICON;
    this.starSelected = STAR_ICON_FILL;
    this.sliceControl = 80;
    this.contentItems = [];
    this.contentItemsSaved = [];
    this.perfectScrollbarHeight = {
      height: 'calc(100vh - 205px)'
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items?.currentValue) {
      this.contentItems = changes.items.currentValue.map(item => (new ContentItems(
        {
          ...item,
          truncateTextTitleExcerpt: this.textTrucate(item.titleExcerpt),
          truncateTextTitle: this.textTrucate(item.title),
          isSaved: this.checkIsSavedContentItemToProject(item.id)
        }
      )));
      this.scrollTop();
    }
    if (changes.parentName?.currentValue) {
      this.section = changes.parentName?.currentValue;
      switch (changes.parentName.currentValue) {
        case Content.NewsFeed:
          this.perfectScrollbarHeight.height = 'calc(100vh - 230px)';
          break;
        case Content.Coverage:
            this.perfectScrollbarHeight.height = 'calc(100vh - 285px)';
          break;
        case Content.Author:
          this.perfectScrollbarHeight.height = 'calc(100vh - 300px)';
          break;
        case Content.Outlet:
          this.perfectScrollbarHeight.height = 'calc(100vh - 300px)';
          break;
        case Content.Topic:
          this.perfectScrollbarHeight.height = 'calc(100vh - 500px)';
          break;
        case Content.Company:
          this.perfectScrollbarHeight.height = 'calc(100vh - 450px)';
          break;
        case Content.TopicSidebar:
          this.perfectScrollbarHeight.height = 'calc(100vh - 340px)';
          break;
          case Content.Collection:
          this.perfectScrollbarHeight.height = 'calc(100vh - 285px)';
          break;
      }
    }
  }

  ngOnInit() {
    this.store.select('ui').subscribe(({ screenSizeValues }) => {
      if (eq(this.section, Content.Coverage)) {
        this.screenSize = screenSizeValues.scrWidth;
        if (this.screenSize <= 1299 && this.screenSize >= 500) {
          this.sliceControl = 40;
        }
        if (this.screenSize <= 1699 && this.screenSize >= 1300) {
          this.sliceControl = 50;
        }
        if (this.screenSize <= 1921 && this.screenSize >= 1700) {
          this.sliceControl = 70;
        }
        if (this.screenSize >= 1922) {
          this.sliceControl = 80;
        }
        this.updateTitleLength();
      }
    });

    this.store.select('projects').subscribe(({ projects, action }) => {
      if (!!projects.length) {
        this.project = projects.find(item => item.selected);
      }
    });

    this.store.select('contentItemsList').subscribe(({ contentItems }) => {
      this.contentItemsSaved = contentItems;
      if (this.contentItems.length > 0) {
        this.contentItems = this.contentItems.map(item => (new ContentItems(
          {
            ...item,
            isSaved: this.checkIsSavedContentItemToProject(item.id)
          }
        )));
      }
    });
  }

  checkIsSavedContentItemToProject(contentItemId): boolean {

    if (this.contentItemsSaved.length === 0) {
      return false;
    }
    return this.contentItemsSaved.find(({id}) => eq(id, contentItemId)) ? true : false;
  }

  textTrucate(text) {
    if (text) {
      if (eq(this.section, Content.Coverage)) {
        if (text.length >= this.sliceControl) {
          return truncate(text, {
            length: this.sliceControl,
            omission: '...'
          });
        }
      }
    }
    return text;
  }

  updateTitleLength() {
    const items = this.contentItems.map(item => ({
      ...item,
      truncateTextTitleExcerpt: this.textTrucate(item.titleExcerpt),
      truncateTextTitle: this.textTrucate(item.title),
    }));
    this.contentItems = items;
  }

  goToTitleUrl(url) {
    window.open(url, '_blank');
  }

  getAuthorContent(authorItem) {
    const facetSearch = new FacetSearch(
      authorItem.authorId,
      'author',
      this.keywords,
      'recent',
      0,
      50
    );

    this.store.dispatch(
      actions.loadAuthorContent({
        facetSearch,
        author: {
          ...authorItem,
          id: authorItem.authorId,
          name: authorItem.author,
          outletNames: authorItem.outlet
        }
      })
    );
  }

  getOutletContent(outlet) {
    const facetSearch = new FacetSearch(
      outlet.outletId,
      'outlet',
      this.keywords,
      'recent',
      0,
      50
    );

    this.store.dispatch(
      actions.loadOutletContent({
        facetSearch,
        outlet: {
          ...outlet,
          id: outlet.outletId,
          name: outlet.outlet
        }
      })
    );
  }

  private scrollTop() {
    if (this.contentItemsPerfectScroll) {
      this.contentItemsPerfectScroll.directiveRef.scrollTo(0, 1);
    }
  }

  public handleSaveContenItem(item) {
    if (item.isSaved) {
      this.store.dispatch(
        actions.deleteContentItemToProject({
          projectId: this.project.value,
          contentItemId: item.id
        })
      );
    } else {
       this.store.dispatch(
         actions.saveContentItemToProject({
           projectId: this.project.value,
           contentItemId: item.id
          })
       );
    }
  }
}
