<p-dialog
  header="My Account"
  [style]="{ width: '50vw' }"
  [(visible)]="showDialog"
  [closable]="true"
  [dismissableMask]="true"
  [modal]="true"
  (onHide)="onClose()"
>
  <p-tabView>
    <p-tabPanel leftIcon="pi pi-user" header="My User">
      <app-user-form></app-user-form>
    </p-tabPanel>
    <p-tabPanel
      leftIcon="pi pi-sitemap"
      header="My Organization"
      *ngIf="showMyOrganization"
    >
      <app-my-organization></app-my-organization>
    </p-tabPanel>
  </p-tabView>
</p-dialog>
