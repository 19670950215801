import { createAction, props } from '@ngrx/store';

export const loadAuthorsOutlets = createAction(
  '[AuthorsOutlets] Load Authors Outlets',
  props<{ projectId: number }>()
);

export const loadAuthorsSuccess = createAction(
  '[AuthorsOutlets] Load Authors Success',
  props<{ authors: any[] }>()
);

export const loadOutletsSuccess = createAction(
  '[AuthorsOutlets] Load Outlets Success',
  props<{ outlets: any[] }>()
);

export const loadAuthorsOutletsError = createAction(
  '[AuthorsOutlets] Load Authors Outlets Error',
  props<{ payload: any }>()
);

export const addAuthorToProject = createAction(
  '[AuthorsOutlets] Add Author to Project',
  props<{ partyId: number; userProject: any; author: any }>()
);

export const addOutletToProject = createAction(
  '[AuthorsOutlets] Add Outlet to Project',
  props<{ partyId: number; userProject: any; outlet: any }>()
);

export const addAuthorToProjectSuccess = createAction(
  '[AuthorsOutlets] Add Author to Project Success',
  props<{ author: any }>()
);

export const addOutletToProjectSuccess = createAction(
  '[AuthorsOutlets] Add Outlet to Project Success',
  props<{ outlet: any }>()
);

export const deleteAuthorToProject = createAction(
  '[AuthorsOutlets] Delete Author to Project',
  props<{ partyId: number; userProject: any; author: any }>()
);

export const deleteOutletToProject = createAction(
  '[AuthorsOutlets] Delete Outlet to Project',
  props<{ partyId: number; userProject: any; outlet: any }>()
);

export const deleteAuthorToProjectSuccess = createAction(
  '[AuthorsOutlets] Delete Author to Project Success',
  props<{ author: any }>()
);

export const deleteOutletToProjectSuccess = createAction(
  '[AuthorsOutlets] Delete Outlet to Project Success',
  props<{ outlet: any }>()
);

export const addAuthorOutletToProjectError = createAction(
  '[AuthorsOutlets] Add Author Outlet to Project Error',
  props<{ payload: any }>()
);

export const deleteAuthorOutletToProjectError = createAction(
  '[AuthorsOutlets] Delete Author Outlet to Project Error',
  props<{ payload: any }>()
);

export const initAuthorOutletToProjectState = createAction(
  '[AuthorsOutlets] Delete Author Outlet to Project Error'
);
