<aside class="customizer" [ngClass]="{ 'show-service-panel': showOffCanvas }">
  <a
    pTooltip="Open blog"
    tooltipPosition="top"
    *ngIf="showBlogButton"
    href="javascript:void(0)"
    class="service-panel-toggle"
    (click)="showBlog()"
  >
    <img width="30" alt="Image" src="assets/images/rss.png" />
  </a>
  <div class="body-overlay" (click)="onClose()"></div>
  <div class="customizer-body">
    <app-loading [isLoading]="!showOffCanvas"></app-loading>
    <ul class="nav customizer-tab" role="tablist"></ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div class="p-15">
          <div class="float-right">
            <button
              id="closeDialogBtn"
              type="button"
              class="close"
              aria-label="Close"
              (click)="onClose()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div *ngIf="showAuthorFacet; then authorFacetContent"></div>
          <div *ngIf="showOutletFacet; then outletFacetContent"></div>
          <div *ngIf="showCompanyFacet; then companyFacetContent"></div>
          <div *ngIf="showTopicFacet; then topicFacetContent"></div>
          <div *ngIf="showProductFacet; then productFacetContent"></div>
          <div *ngIf="showBlogContent; then blogContent"></div>
          <ng-template #authorFacetContent>
            <facet-author-content
              [contentData]="authorFacetsDetails"
            ></facet-author-content>
          </ng-template>
          <ng-template #outletFacetContent>
            <facet-outlet-content
              [contentData]="outletFacetsDetails"
            ></facet-outlet-content>
          </ng-template>
          <ng-template #companyFacetContent>
            <facet-company-content
              [contentData]="companyFacetsDetails"
            ></facet-company-content>
          </ng-template>
          <ng-template #topicFacetContent>
            <facet-topic-content
              [contentData]="topicFacetsDetails"
            ></facet-topic-content>
          </ng-template>
          <ng-template #productFacetContent>
            <facet-product-content
              [contentData]="productFacetsDetails"
            ></facet-product-content>
          </ng-template>
          <ng-template #blogContent>
            <app-blog></app-blog>
          </ng-template>
          <br />
        </div>
      </div>
    </div>
  </div>
</aside>
