import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { AppState } from 'app/app.reducer';
import { Store } from '@ngrx/store';
import { SORT_OPTIONS, SORT_OPTIONS_ELIP } from 'app/constants';

@Component({
  selector: 'app-sort-options',
  template: `
    <p-selectButton
      [options]="sortOptions"
      [(ngModel)]="sortSelected"
      optionLabel="label"
      optionValue="value"
      (onOptionClick)="sortChange($event)"
    ></p-selectButton>
  `,
  styleUrls: ['./sort-options.component.scss']
})
export class SortOptionsComponent implements OnInit, OnChanges {
  public sortOptions: any[];
  public sortSelected: string;
  public screenSize: number;

  @Output() sortChangesEmiter: EventEmitter<string> = new EventEmitter();
  @Input() defaultSort: string;

  constructor(private store: Store<AppState>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultSort.currentValue) {
      this.sortSelected = changes.defaultSort.currentValue;
    }
  }

  ngOnInit() {
    this.store.select('ui').subscribe(({ screenSizeValues }) => {
      this.screenSize = screenSizeValues.scrWidth;

      if (this.screenSize >= 1500) {
        this.sortOptions = SORT_OPTIONS;
      } else {
        this.sortOptions = SORT_OPTIONS_ELIP;
      }
    });
  }

  public sortChange(e) {
    this.sortChangesEmiter.emit(e.option.value);
  }
}
