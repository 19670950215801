import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { tap, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { SearchService } from '../../services';
import { of } from 'rxjs';

@Injectable()
export class FacetsSearchEffects {
  constructor(
    private actions$: Actions,
    private searchService: SearchService
  ) {}

  facetsSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setFacetsSearch),
      mergeMap(action =>
        this.searchService.facetsSearch(action.searchBody, action.page).pipe(
          tap(response => console.log('facetsSearch$')),
          switchMap(response => [
            actions.setFacetsSearchSuccess({ contents: response })
          ]),
          catchError(err => of(actions.setFacetsSearchError({ payload: err })))
        )
      )
    )
  );

  // startLoading$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(actions.setFacetsSearch),
  //     switchMap(action => of(actions.startLoading()))
  //   )
  // );

  // stopLoading$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(actions.setFacetsSearchSuccess, actions.setFacetsSearchError),
  //     switchMap(action => of(actions.stopLoading()))
  //   )
  // );
}
