import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { tap, mergeMap, map, catchError, switchMap } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import { CompanyService } from 'app/services';
import { FacetSearch } from 'app/models';
import { isEmpty, includes } from 'lodash';
import { MAP_URL } from 'app/pages/topics/constants';

@Injectable()
export class CompanyDialogEffects {
  constructor(
    private actions$: Actions,
    private companyService: CompanyService
  ) {}

  loadcompanyDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setCompanyDialog),
      mergeMap(action =>
        this.getContents$(action).pipe(
          switchMap(response => [
            actions.setCompanyDialogSuccess({
              company: this.getCompany(response, action)
            }),
            actions.loadCompanyContent({
              facetSearch: new FacetSearch(
                response.id,
                'company',
                this.getKeywords(response, action),
                action.sort,
                0,
                50
              ),
              company: this.getCompany(response, action)
            })
          ]),
          catchError(err => of(actions.setCompanyDialogError({ payload: err })))
        )
      )
    )
  );

  getKeywords(response, action) {
    let keywords = '';
    const { name, query, implicitQuery } = response;
    if (action.keywords) {
      keywords = action.keywords;
    } else if (query) {
      keywords = query;
    } else if (implicitQuery) {
      keywords = implicitQuery;
    } else {
      keywords = `\"${name}\"`;
    }
    return keywords;
  }

  getContents$(action): Observable<any> {
    return this.companyService.getCompanyById(action.companyId);
  }

  getCompany(company, action) {
    return {
      ...company,
      id: company.id,
      name: company.name,
      query: company?.query,
      implicitQuery: company?.implicitQuery,
      isMyCompany: false,
      wikipediaUrl: company?.contact.wikipediaUrl,
      webSiteUrl: company?.contact.webSiteUrl,
      youTubeUrl: company?.contact.youTubeUrl,
      twitterUrl: company?.contact.twitterUrl,
      linkedInUrl: company?.contact.linkedInUrl,
      financial: company?.organizationDetail
        ? this.getYahooFinanceURL(company.organizationDetail?.stockTicker)
        : null,
      picture: company?.contact.imageUrl,
      locationUrl: company.contact?.streetAddress
        ? `${MAP_URL}/${company.contact.streetAddress}`
        : '',
      project: action.projectSelected
    };
  }

  getYahooFinanceURL(value: any) {
    if (isEmpty(value)) {
      return null;
    } else if (includes(value, 'http')) {
      const q = value.substring(value.lastIndexOf('/') + 1, value.length);
      return `https://finance.yahoo.com/quote/${q}`;
    } else {
      return `https://finance.yahoo.com/quote/${value}`;
    }
  }
}
