export interface ContentItemsProps {
  id: number;
  publishDate: string;
  title: string;
  url: string;
  score: number;
  titleExcerpt: string;
  bodyExcerpt: string;
  authorId: number;
  author: string;
  outletId: number;
  outlet: string;
  analyst: boolean;
  truncateTextTitleExcerpt: string;
  truncateTextTitle: string;
  imageUrl: string;
  outletImageUrl: string;
  isSaved: boolean;
}

export class ContentItems {
  public id: number;
  public publishDate: string;
  public title: string;
  public url: string;
  public score: number;
  public titleExcerpt: string;
  public bodyExcerpt: string;
  public authorId: number;
  public author: string;
  public outletId: number;
  public outlet: string;
  public analyst: boolean;
  public truncateTextTitleExcerpt: string;
  public truncateTextTitle: string;
  public imageUrl: string;
  public outletImageUrl: string;
  public isSaved: boolean;

  constructor({
    id,
    publishDate,
    title,
    url,
    score,
    titleExcerpt,
    bodyExcerpt,
    authorId,
    author,
    outletId,
    outlet,
    analyst,
    truncateTextTitleExcerpt,
    truncateTextTitle,
    imageUrl,
    outletImageUrl,
    isSaved
  }: ContentItemsProps) {
    this.id = id || null;
    this.publishDate = publishDate || null;
    this.title = title || null;
    this.url = url || null;
    this.score = score || null;
    this.titleExcerpt = titleExcerpt || null;
    this.bodyExcerpt = bodyExcerpt || null;
    this.authorId = authorId || null;
    this.author = author || null;
    this.outletId = outletId || null;
    this.outlet = outlet || null;
    this.analyst = analyst || null;
    this.truncateTextTitleExcerpt = truncateTextTitleExcerpt || null;
    this.truncateTextTitle = truncateTextTitle || null;
    this.imageUrl = imageUrl || null;
    this.outletImageUrl = outletImageUrl || null;
    this.isSaved = isSaved || false;
  }
}
