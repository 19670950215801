<p-overlayPanel
  #opCollections
  [style]="{ width: '50%' }"
  [showCloseIcon]="false"
  [dismissable]="true"
  [appendTo]="'body'"
  (onHide)="hideOverlayPanel()"
  [baseZIndex]="9999998"
>
  <div class="p-grid">
    <div *ngFor="let item of collections" class="p-col-4">
      <app-collection-item
        [collectionName]="item.label"
        [collectionImage]="item.image"
        [collectionItems]="item.items"
      ></app-collection-item>
    </div>
  </div>
</p-overlayPanel>
<button
  #btnCollections
  pButton
  type="button"
  label="Collections"
  icon="pi pi-angle-down"
  iconPos="right"
  class="p-button-text btn-collection"
  (click)="toggleOpCollections($event)"
  *ngIf="collections"
></button>
