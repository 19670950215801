import { Component, OnInit } from '@angular/core';
import { map, head } from 'lodash/fp';
import { eq, orderBy, flatMap, isEmpty } from 'lodash';

import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { Search, SelectedMenuItem } from 'app/models';
import { CollectionService } from 'app/services';

@Component({
  selector: 'app-collection-carousel',
  templateUrl: './collection-carousel.component.html',
  styleUrls: ['./collection-carousel.component.scss']
})
export class CollectionCarouselComponent implements OnInit {
  public collectionMenuItems: SelectedMenuItem[];
  public numberCollection: number;
  public selectColor: string;
  public regularColor: string;
  constructor(
    private store: Store<AppState>,
    private collectionService: CollectionService
  ) {
    this.collectionMenuItems = [];
    this.numberCollection = 10;
    this.regularColor = '#f8f9fa';
    this.selectColor = '#ff9800';
  }

  ngOnInit(): void {
    this.store
      .select('topics')
      .subscribe(({ topicMenuItemSelected, topicsMenu }) => {
        if (topicsMenu) {
          const collections =
            topicsMenu.filter(menu => eq(menu.label, 'Collections')) || [];

          if (collections.length > 0) {
            const collectionItems = map('items', collections);
            const collectionsSubItems = map('items', head(collectionItems));
            const flatCollectionMenuItems = flatMap(
              collectionsSubItems,
              item => item
            );
            this.collectionMenuItems = orderBy(
              flatCollectionMenuItems.map(item => {
                return {
                  icon: eq(item.label, 'AWS')
                    ? '/assets/images/aws.svg'
                    : this.getCollectionImage(item.topicsCollection),
                  selectBgColor: { background: this.regularColor },
                  ...item
                };
              }),
              ['label'],
              ['asc']
            );
          }
          if (topicMenuItemSelected) {
            this.changeColorSelection(topicMenuItemSelected);
          }
        }
      });

    this.store.select('ui').subscribe(({ screenSizeValues }) => {
      const { scrWidth } = screenSizeValues;
      if (scrWidth > 1600) {
        this.numberCollection = 10;
      }
      if (scrWidth < 1600) {
        this.numberCollection = 8;
      }
      if (scrWidth < 1500) {
        this.numberCollection = 7;
      }
      if (scrWidth < 1400) {
        this.numberCollection = 6;
      }
      if (scrWidth < 1200) {
        this.numberCollection = 5;
      }
    });
  }

  public changeColorSelection(item) {
    const index = this.collectionMenuItems.findIndex(({ topicsCollection }) =>
      eq(topicsCollection, item.topicsCollection)
    );

    if (index >= 0) {
      this.collectionMenuItems[index].selectBgColor = {
        background: this.selectColor
      };
    }
  }

  public handleSelectItem(item) {
    this.handleSelectMenuItemCollection(item);
  }

  handleSelectMenuItemCollection(itemSelected: any) {
    setTimeout(() => {
      const collectionSelected = this.getCollectionItem(
        itemSelected.topicsCollection
      );

      const item = {
        label: collectionSelected.label,
        header: collectionSelected.label,
        description: collectionSelected.description || '',
        topicsCollection: itemSelected.topicsCollection
      };

      this.store.dispatch(actions.startLoading());
      const newSearch = new Search({
        facets: { TOPICS: 'newsAuthorsWeek desc' },
        topicsFilter: null,
        topicsCollection: item.topicsCollection
      });

      this.store.dispatch(
        actions.setSearch({
          search: newSearch
        })
      );
      this.store.dispatch(
        actions.setTopicsRanked({
          searchBody: newSearch,
          page: 0,
          isNewSearch: true
        })
      );
      this.store.dispatch(
        actions.setCollectionContent({
          page: 0,
          collectionId: itemSelected.topicsCollection
        })
      );
      this.store.dispatch(actions.setTopicMenuItemSelected({ item }));
      this.store.dispatch(actions.tabControl({ index: 3 }));
    }, 1000);
  }

  getCollectionItem(id: number): SelectedMenuItem {
    const collection = this.collectionMenuItems.find(item =>
      eq(item.topicsCollection, id)
    );
    return new SelectedMenuItem({ ...collection });
  }

  public getCollectionImage(id): string {
    const icon = `${this.collectionService.getCollectionIcon(id)}`;
    return icon;
  }
}
