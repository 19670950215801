<div class="p-d-flex p-jc-center p-ai-center">
  <div class="p-mr-2">
    <!-- Circular Image -->
    <div class="avatar-container">
      <img
        [src]="filePath"
        src="assets/images/profile.png"
        onerror="this.src='assets/images/profile.png'"
        class="avatar-image"
        alt="Avatar"
      />
      <span *ngIf="hasPassword">
        <button
        *ngIf="!isAttached"
        pButton
        icon="pi pi-camera"
        class="p-button-secondary p-button-rounded avatar-upload-button"
        (click)="fileInput.click()"
        >
        <input
        #fileInput
        accept="image/*"
        type="file"
        (change)="chooseImage($event)"
        style="display: none"
        />
      </button>
      <button
      *ngIf="isAttached"
      pButton
      icon="pi pi-times"
      class="p-button-danger p-button-rounded avatar-upload-button"
      (click)="resetImageSelected()"
      ></button>
    </span>
    </div>
  </div>
</div>
<div class="p-d-flex p-jc-center p-ai-center">
  <div class="p-mt-2 p-mb-2">
    <h3>Hi, {{ name }}!</h3>
  </div>
</div>
