import { createReducer, on } from '@ngrx/store';
import {
  showCompanyDialog,
  hideCompanyDialog,
  resetCompanyState,
  createCompany,
  createCompanySuccess,
  updateCompanyFail,
  updateCompany,
  updateCompanySuccess,
  deleteCompany,
  deleteCompanySuccess,
  deleteCompanyFail
} from 'redux/actions';

export interface CompanyState {
  company: any;
  success: boolean;
  loading: boolean;
  message: string;
  error: any;
  showCompanyManager: boolean;
  successResponse: any;
}

const initialState: CompanyState = {
  company: null,
  success: false,
  loading: false,
  message: null,
  error: null,
  showCompanyManager: false,
  successResponse: null
};

const _companyReducer = createReducer(
  initialState,

  on(showCompanyDialog, (state, { company }) => ({
    ...state,
    showCompanyManager: true,
    company
  })),
  on(hideCompanyDialog, () => ({
    company: null,
    message: null,
    loading: false,
    success: false,
    error: null,
    successResponse: null,
    showCompanyManager: false
  })),

  on(resetCompanyState, state => ({
    ...state,
    message: null,
    loading: false,
    success: false,
    error: null,
    company: null,
    showCompanyManager: false
  })),

  on(createCompany, (state, { payload }) => ({
    ...state,
    company: {
      name: payload.name,
      query: payload.query,
      categoryId: payload.categoryId
    },
    message: null,
    loading: true,
    success: false,
    error: null
  })),

  on(createCompanySuccess, (state, { company }) => ({
    ...state,
    loading: false,
    success: true,
    message: `Company ${company.name} has been added successfully`,
    successResponse: company,
    showTopicManager: false
  })),

  on(updateCompanyFail, (state, { payload }) => ({
    ...state,
    loading: false,
    success: false,
    successResponse: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Add company failed: ${payload?.message}`
    }
  })),

  on(updateCompany, (state, { payload }) => ({
    ...state,
    company: {
      name: payload.name,
      query: payload.query,
      categoryId: payload.categoryId
    },
    message: null,
    loading: true,
    success: false,
    error: null
  })),

  on(updateCompanySuccess, (state, { company }) => ({
    ...state,
    loading: false,
    success: true,
    message: `Company ${company.name} has been updated successfully`,
    company,
    successResponse: company,
    showTopicManager: false
  })),

  on(updateCompanyFail, (state, { payload }) => ({
    ...state,
    loading: false,
    success: false,
    successResponse: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Update Company failed: ${payload?.message}`
    }
  })),

  on(deleteCompany, state => ({
    ...state,
    message: null,
    loading: true,
    success: false,
    error: null
  })),

  on(deleteCompanySuccess, (state, { company }) => ({
    ...state,
    loading: false,
    success: true,
    message: `Company ${state.company.name} has been deleted successfully`,
    successResponse: company,
    showTopicManager: false,
    company: null
  })),

  on(deleteCompanyFail, (state, { payload }) => ({
    ...state,
    loading: false,
    success: false,
    successResponse: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: `Delete company failed: ${payload?.message}`
    }
  }))
);

export function companyReducer(state, action) {
  return _companyReducer(state, action);
}
