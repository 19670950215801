import { Component, OnInit } from '@angular/core';
import * as actions from 'redux/actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { eq, head } from 'lodash';
import { Search } from 'app/models';
import { CollectionService } from 'app/services';

@Component({
  selector: 'app-description-box',
  template: `
    <div id="toolbar-text" *ngIf="label">
      <span *ngIf="topicCollection">
        <img
          alt="Error"
          onerror="this.src='assets/images/collections/computer.svg'"
          [src]="image"
          class="coll-icon"
        />
      </span>
      <!-- <span class="title">{{ label }}</span> -->
      <!-- <p class="text">{{ description }}</p> -->
    </div>
  `,
  styles: [
    `
      #toolbar-text {
        height: 2rem;
      }

      .title {
        font-weight: bold;
        font-size: 18px;
        color: #465675;
      }

      .text {
        color: #465675;
        font-size: 14px;
      }

      .coll-icon {
        margin-right: 0.5rem;
        width: 1.6rem;
      }
    `
  ]
})
export class DescriptionBoxComponent implements OnInit {
  public menuItemSelected: any;
  public topicsMenu: any[];
  public search: Search;
  public label: string;
  public description: string;
  public topicCollection: number;
  public image: string;
  public isTopicsFilterSelected: boolean;

  constructor(
    private store: Store<AppState>,
    private collectionService: CollectionService
  ) {
    this.label = null;
    this.description = null;
    this.topicCollection = null;
    this.image = null;
  }

  ngOnInit() {
    this.store
      .select('topics')
      .subscribe(({ topicMenuItemSelected, topicsMenu }) => {
        this.isTopicsFilterSelected = !!topicMenuItemSelected;
        this.topicsMenu = topicsMenu;
        if (this.isTopicsFilterSelected) {
          this.menuItemSelected = topicMenuItemSelected;
          this.label = topicMenuItemSelected.label;
          this.description = topicMenuItemSelected.description;
          this.topicCollection = topicMenuItemSelected.topicsCollection || null;
          this.image = this.getCollectionImage(this.topicCollection);
        } else {
          this.label = 'All Topics';
          this.description = 'Showing the top trending topics.';
          this.store.select('search').subscribe(({ searchBody }) => {
            if (searchBody) {
              this.search = searchBody;
              if (eq(searchBody.topicsFilter, 'NEW_PAST_WEEK')) {
                this.label = 'Topics new this week';
                this.description =
                  'Showing topics whose first coverage was less that a week ago.';
              }
              if (searchBody.keywords) {
                if (!topicMenuItemSelected || eq(this.label, 'All Topics')) {
                  // If there is no filter selected, use the search's description
                  this.description = this.createTopicDescription(
                    searchBody.keywords
                  );
                }
                // Overwrite the title with the search title
                this.label = this.createTopicTitle(searchBody.keywords);
              }
            }
          });
        }
      });
  }

  public createTopicDescription(keywords) {
    return `These are topics which frequently appear together with ${keywords}.`;
  }

  public createTopicTitle(keywords) {
    return `Topics related to ${keywords}`;
  }

  public getCollectionImage(id): string {
    const icon = `${this.collectionService.getCollectionIcon(id)}`;
    return icon;
  }
}
