import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { Content } from 'app/constants';
import { SidebarList } from 'app/models';
import {
  deleteOutlet,
  setOutletDialog,
  showOutletManagerDialog,
  startLoading
} from 'app/redux/actions';
import { OutletService } from 'app/services';
import { eq } from 'lodash';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-sidebar-outlet-list',
  template: `
    <div *ngIf="!!outlets.length">
      <app-sidebar-list
        [sidebarData]="outlets"
        (openSelectDialogEmiter)="selectedItem($event)"
        (openEditDialogEmiter)="openEditOutletManagerDialog($event)"
        (unfollowEmiter)="unfollow($event)"
      ></app-sidebar-list>
    </div>
  `
})
export class SidebarOutletListComponent implements OnInit {
  public outlets: SidebarList[];
  public selectItem: any;
  public sort: any;
  public showScrollbar: boolean;
  public projectId: number;
  public loading: boolean;
  public loaded: boolean;
  public currentProject: any;
  public keywords: string;
  public isEdit: boolean;
  public isUnfollow: boolean;
  public parentName: string;

  @ViewChildren('sortNameIcon, sortCategoryIcon', {
    read: ElementRef
  })
  sortIcons: QueryList<ElementRef>;

  constructor(
    private store: Store<AppState>,
    private confirmationService: ConfirmationService,
    private outletService: OutletService
  ) {
    this.isEdit = false;
    this.isUnfollow = false;
    this.keywords = null;
    this.showScrollbar = false;
    this.loading = false;
    this.loaded = false;
    this.outlets = [];
    this.parentName = Content.Outlet;
  }

  ngOnInit() {
    this.store.select('search').subscribe(({ searchBody }) => {
      if (searchBody) {
        const { keywords } = searchBody;
        this.keywords = keywords;
      }
    });

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.currentProject = projectSelected;
      }
    });

    this.store
      .select('authorsOutletsList')
      .subscribe(({ outlets, loading, loaded, errors }) => {
        this.loading = loading;
        this.loaded = loaded;
        if (outlets && outlets.length > 0 && loaded) {
          this.outlets = outlets.map(
            item =>
              new SidebarList({
                title: item.name,
                subTitle: item.label.name,
                picture: this.loadPicture(item.partyId),
                hasPicture: true,
                parentObject: {
                  categoryName: item.label.name,
                  categoryId: item.label.id,
                  name: item.name,
                  id: item.id,
                  partyId: item.partyId
                },
                parentName: this.parentName
              })
          );
        } else {
          this.outlets = [];
        }
      });

    this.store
      .select('outletDialog')
      .subscribe(({ outletId, loading, loaded }) => {
        if (outletId) {
          this.loadingOutletDialog(outletId, loading, loaded);
        }
      });
  }

  public selectedItem(item) {
    this.store.dispatch(
      setOutletDialog({
        outletId: item.partyId,
        projectSelected: this.currentProject,
        keywords: this.keywords,
        sort: 'recent'
      })
    );
  }

  loadingOutletDialog(outletId, loading, loaded) {
    const index = this.outlets.findIndex(({ parentObject }) =>
      eq(parentObject.partyId, outletId)
    );
    if (loading) {
      this.outlets[index].loadingIcon = true;
    }
    if (loaded) {
      this.outlets[index].loadingIcon = false;
    }
  }

  openCreateOutletManagerDialog() {
    this.store.dispatch(showOutletManagerDialog({ outlet: null }));
  }

  openEditOutletManagerDialog(outlet) {
    this.store.dispatch(showOutletManagerDialog({ outlet }));
  }

  handleUnfollow(value) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to unfollow this outlet?',
      header: 'Unfollow Confirmation',
      icon: 'pi pi-exclamation-triangle',
      key: 'unfollowConfirmation',
      accept: () => {
        this.unfollow(value);
      },
      reject: type => {
        // handle rejected option ConfirmEventType.CANCEL
      }
    });
  }

  public unfollow(value) {
    const payload = {
      userProject: this.currentProject,
      id: value.partyId,
      name: value.name
    };
    this.store.dispatch(
      deleteOutlet({
        payload
      })
    );
  }

  public loadPicture(id) {
    return this.outletService.getOutletPicture(id);
  }
}
