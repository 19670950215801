<p-overlayPanel
  (onHide)="hideOverlayPanel()"
  [appendTo]="'body'"
  #opItems
  [baseZIndex]="9999999"
>
  <ng-template pTemplate>
    <div *ngFor="let item of collectionItems" class="link">
      <div (click)="handleSelectItem(item)">
        <img
          alt="Error"
          onerror="this.src='assets/images/file-error.svg'"
          [src]="getCollectionImage(item.topicsCollection)"
          class="coll-icon"
        />
        <span class="p-ml-2 link-item">{{ item.label }}</span>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
<button
  [id]="collectionName"
  #btnCollectionItem
  pButton
  class="p-button-text btn-collection-item"
  (click)="toggleOpCollectionItem($event, collectionName)"
>
  <!-- <img alt="Image collection" [src]="collectionImage" class="coll-icon" /> -->
  <span class="p-ml-2">{{ collectionName }}</span>
  <i
    [class]="
      labelSelected === collectionName
        ? 'pi pi-angle-down'
        : 'pi pi-angle-right'
    "
    style="width: 1.5rem"
  ></i>
</button>
