import { createAction, props } from '@ngrx/store';

// Create Outlet

export const createOutlet = createAction(
  '[Outlet Manager] Create Outlet',
  props<{ payload: any }>()
);

export const createOutletSuccess = createAction(
  '[Outlet Manager] Create Outlet Success',
  props<{ outlet: any }>()
);

export const createOutletError = createAction(
  '[Outlet Manager] Create Outlet Error',
  props<{ payload: any }>()
);

// Update Outlet

export const updateOutlet = createAction(
  '[Outlet Manager] Update Outlet',
  props<{ payload: any }>()
);

export const updateOutletSuccess = createAction(
  '[Outlet Manager] Update Outlet Success',
  props<{ outlet: any }>()
);

export const updateOutletError = createAction(
  '[Outlet Manager] Update Outlet Error',
  props<{ payload: any }>()
);

// Delete Outlet

export const deleteOutlet = createAction(
  '[Outlet Manager] Delete Outlet',
  props<{ payload: any }>()
);

export const deleteOutletSuccess = createAction(
  '[Outlet Manager] Delete Outlet Success',
  props<{ outlet: any }>()
);

export const deleteOutletError = createAction(
  '[Outlet Manager] Delete Outlet Error',
  props<{ payload: any }>()
);

export const resetOutletManagerState = createAction(
  '[Outlet Manager] Reset Outlet State'
);

// Outlet Manager Dialog

export const showOutletManagerDialog = createAction(
  '[Outlet Manager Dialog] showOutletManagerDialog',
  props<{ outlet: any }>()
);

export const hideOutletManagerDialog = createAction(
  '[Outlet Manager Dialog] hideOutletManagerDialog'
);
