import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent
} from 'ngx-perfect-scrollbar';

import {
  head,
  filter,
  includes,
  isUndefined,
  isNull,
  isEmpty,
  eq
} from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { CategoryService, AuthenticationService } from 'app/services';
import { UserSession, Project } from 'app/models';

@Component({
  selector: 'app-sidebar-content-items',
  template: `
    <app-sidebar-content-items-list
      (createNewContentItemOnclick)="createNewContentItem()"
      (editContentItemOnclick)="openContentItemManagerModal($event)"
      (selectedContentItemOnClick)="onSelectContentItem($event)"
    ></app-sidebar-content-items-list>
  `
})
export class SidebarContentItemsComponent implements OnInit, OnDestroy {
  public accountId: any;
  public projectId: any;
  public userId: any;
  public currentTopic: number;
  public projects: Project[] = [];
  public projectSelectedItem: Project;
  public loadingProjects: boolean;
  public formProject: FormGroup;
  public currentProject: any;
  public sortTopicBy: string;
  public avoidSelectCard: boolean;
  public projectLabel: string;
  public userSession: UserSession;
  public numberOfProjects: number;
  public maxProjects: any;
  public saveSearchAsTopicData: any;
  public config: PerfectScrollbarConfigInterface = {};
  public keywords: string;
  public prevIconAfterLoading: string;

  @ViewChild('topicsSidebarPerfectScroll', { static: false })
  topicsSidebarPerfectScroll: PerfectScrollbarComponent;

  constructor(
    private authService: AuthenticationService,
    private categoryService: CategoryService,
    private store: Store<AppState>,
    private formBuilder: FormBuilder
  ) {
    this.saveSearchAsTopicData = null;
    this.avoidSelectCard = false;
    this.sortTopicBy = 'category';
    this.projectLabel = this.authService.getProjectLabel();
    this.maxProjects = this.authService.getMaxProjects();
    this.userId = this.authService.getUserId();
    this.accountId = this.authService.getAccountId();
    this.keywords = null;
    this.keywords = '';
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  isMaxProjects(): boolean {
    if (!isUndefined(this.maxProjects) && !isNull(this.maxProjects)) {
      if (this.numberOfProjects >= parseInt(this.maxProjects, 0)) {
        return true;
      }
    }
    return false;
  }

  changeEmailAlerts(e) {
    if (e) {
      this.categoryService.activeIncludeInEmailAlerts(this.projectId).subscribe(
        (response: any) => {
          console.log(response);
        },
        ({ error }) => {
          console.log(error);
        }
      );
    } else {
      this.categoryService.deleteIncludeInEmailAlerts(this.projectId).subscribe(
        (response: any) => {
          console.log(response);
        },
        ({ error }) => {
          console.log(error);
        }
      );
    }
  }

  public onSelectContentItem(item) {
    // This selection used to show the topic dialog
    // const sort = /* this.keywords ? RELEVANT_SORT : */ RECENT_SORT;
    // this.store.dispatch(
    //   actions.setTopicDialog({
    //     topicSelected: topic,
    //     projectSelected: this.currentProject,
    //     keywords: null,
    //     sort,
    //     isSidebarTopic: true
    //   })
    // );
  }

  public handleCreateProject() {
    this.store.dispatch(actions.showProjectCreateSection());
  }

  changeProject(project: any) {
    this.currentProject = project;
    this.projectId = this.currentProject.id;
  }

  openContentItemManagerModal(e) {
    // this dialog is used to edit the content item
    // this.store.dispatch(actions.showTopicDialog({ topic: e }));
  }

  public cancelCreateProject() {
    window.location.href = this.authService.getSearchUrl(null);
  }

  public createNewContentItem() {
    // Create new content item new content item dialog workflow
    // this.store.dispatch(actions.showTopicDialog({ topic: null }));
  }
}
