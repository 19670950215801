import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { includes, eq } from 'lodash';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Subscription';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { UserService, AuthenticationService } from '../../services';
import { Project, Search } from 'app/models';
import { distinctUntilKeyChanged } from 'rxjs/operators';

@Component({
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent implements OnInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  public projects: Project[] = [];
  public contentUserTopics: any[];
  public projectSelectedItem: any;
  public accountId: any;
  public projectId: any;
  public userId: any;
  public topicId: any;
  public myCompanyId: any;
  public topicSelectdName: string;
  public myCompanyName: string;
  public topicSelectedItems: any[] = [];
  public sort: string;
  public alertType: string;
  public showProjects: boolean;
  public showTopics: boolean;
  public offCanvasContent: any;
  public content: any;
  private uiStoreSub: Subscription;
  private projectStoreSub: Subscription;
  public contentSelector: string;
  public pathParam: string;
  public projectLabel: string;
  public loadingProjects: boolean;
  public changeEvent: string;
  private userSessionSub: Subscription;
  public keywords: string;
  public showDashboard: boolean;
  public classDashboard: string;
  public classContent: string;
  public showTopicsContent: boolean;
  public showCompaniesContent: boolean;
  public showModalNewCompany: boolean;
  public tabControl: number;
  public search: Search;
  public facets: any;
  public topics: any;
  public showMyNewsFeed: boolean;
  public showMiniTopics: boolean;
  public cPanelSizes: number[];
  public collectionPanelSizes: number[];
  public topicsSavedList: any[];

  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private store: Store<AppState>
  ) {
    this.tabControl = 1;
    this.loadingProjects = false;
    this.showModalNewCompany = false;
    this.userId = this.authService.getUserId();
    this.accountId = this.authService.getAccountId();
    this.projectLabel = this.authService.getProjectLabel();
    this.showDashboard = false;
    this.showTopicsContent = false;
    this.showCompaniesContent = false;
    // this.setResultSectionClass(4);
    this.search = null;
    this.facets = null;
    this.topics = null;
    this.cPanelSizes = [27, 40, 37];
    this.collectionPanelSizes = [65, 35];
    this.showMiniTopics = true;
  }

  ngOnInit() {
    this.store.dispatch(actions.startLoading());
    this.store.dispatch(actions.loadTopicsMenu());
    this.store.dispatch(actions.setPosts({ size: 10 }));

    this.store.select('routerReducer').subscribe(({ state }) => {
      const { url } = state;
      let showBlogDialogSection = this.authService.showBlogAfterLogin();
      if (includes(url, 'search') && includes(url, 'coverage')) {
        showBlogDialogSection = false;
      }

      if (includes(url, 'collections')) {
        showBlogDialogSection = false;
      }

      if (includes(url, 'author')) {
        showBlogDialogSection = false;
      }

      if (includes(url, 'outlet')) {
        showBlogDialogSection = false;
      }

      this.store.select('blog').subscribe(({ loaded }) => {
        if (loaded) {
          if (!this.authService.getFirstLogin() && showBlogDialogSection) {
            this.store.dispatch(
              actions.addContent({
                contentType: 'blogContent',
                contentData: {},
                topicId: null
              })
            );
            this.authService.saveBlogAfterLogin(false);
          }
        }
      });
    });

    this.openSignUpModal();

    this.uiStoreSub = this.store
      .select('ui')
      .subscribe(
        ({
          indexTab,
          showNewsFeedSection,
          showTopicSection,
          showCoverageSection,
          showCollectionSection
        }) => {
          this.showMyNewsFeed = showNewsFeedSection;
          this.showTopicsContent = showTopicSection;
          if (showCoverageSection) {
            this.tabControl = 1;
          } else if (showCollectionSection) {
            this.tabControl = 3;
          } else {
            this.tabControl = indexTab;
          }
        }
      );

    this.store
      .select('advancedSearch')
      .pipe(distinctUntilKeyChanged('filterText'))
      .subscribe(({ filterText }) => {
        if (filterText) {
          this.cPanelSizes = [30, 70];
          this.showMiniTopics = false;
        } else {
          this.cPanelSizes = [27, 47, 30];
          this.showMiniTopics = true;
        }
      });

    this.store.select('topics').subscribe(({ topics }) => {
      this.topicsSavedList = topics || [];
    });

    this.store
      .select('topicsRanked')
      .subscribe(
        ({
          topicsRankedList,
          searchBody,
          isNewSearch,
          loaded,
          loading,
          error
        }) => {
          this.topics = {
            topicsRankedList,
            searchBody,
            isNewSearch,
            loaded,
            loading,
            error
          };
        }
      );

    // this.openSettingsModal();
  }

  changeResizeSplitterPanel({ sizes }) {
    const facetPanelSize = sizes[0];
    const contemItemPanelSize = sizes[1];
    this.store.dispatch(
      actions.resizeSpitter({ facetPanelSize, contemItemPanelSize })
    );
  }

  ngOnDestroy(): void {
    if (this.uiStoreSub) {
      this.uiStoreSub.unsubscribe();
    }
    if (this.projectStoreSub) {
      this.projectStoreSub.unsubscribe();
    }
    if (this.userSessionSub) {
      this.userSessionSub.unsubscribe();
    }
  }

  getKeywords(value: string) {
    this.keywords = value;
  }

  // Check if is settings route
  openSettingsModal() {
    this.route.params.forEach((param: Params) => {
      this.pathParam = param['section'];
    });
    if (eq(this.pathParam, 'settings')) {
      // TODO Check if already is necessary open modal settings from this route
      // this.openCategoryManagerModal(this.projectSelectedItem);
    }
    if (eq(this.pathParam, 'payment')) {
      this.store.dispatch(actions.showPaymentSuccessDialog());
    }
  }

  openSignUpModal() {
    this.route.params.forEach((param: Params) => {
      const section = param['section'];
      if (eq(section, 'signup')) {
        this.store.dispatch(actions.showSignUpDialog());
      }
    });
  }
}
