import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { eq, flatMap } from 'lodash';
import { map, head } from 'lodash/fp';
import * as actions from 'redux/actions';
import { Search, SelectedMenuItem } from 'app/models';
import { environment } from 'environments/environment';
import { distinctUntilKeyChanged } from 'rxjs/operators';
import { AuthenticationService, CollectionService } from 'app/services';

@Component({
  selector: 'app-collection-item',
  templateUrl: './collection-item.component.html',
  styleUrls: ['./collection-item.component.scss']
})
export class CollectionItemComponent implements OnInit {
  @Input() collectionName: string;
  @Input() collectionImage: string;
  @Input() collectionItems: any[];

  @ViewChildren('btnCollectionItem') btnCollectionItem: QueryList<ElementRef>;
  @ViewChild('opItems') op: OverlayPanel;

  private cdn: string;
  public imageItem: string;
  public isPanelShowed: boolean;
  public itemSelected: any;
  public labelSelected: string;
  public chevronIcon: string;
  public collectionMenuItems: SelectedMenuItem[];
  public isTourOpenPanel: boolean;
  public tourItemSelection: string;

  constructor(
    private store: Store<AppState>,
    private collectionService: CollectionService
  ) {
    this.imageItem = 'assets/images/collections/cloud-data.svg';
    this.isPanelShowed = false;
    this.itemSelected = null;
    this.labelSelected = null;
    this.chevronIcon = 'pi pi-angle-right';
    this.collectionMenuItems = [];
    this.cdn = environment.cdn_url;
    this.isTourOpenPanel = false;
    this.tourItemSelection = 'Vendor Ecosystems';
  }

  ngOnInit(): void {
    this.store
      .select('session')
      .pipe(distinctUntilKeyChanged('visiblePanelCollection'))
      .subscribe(({ visiblePanelCollection }) => {
        this.isTourOpenPanel = visiblePanelCollection;

        if (visiblePanelCollection) {
          this.btnCollectionItem.forEach(({ nativeElement }) => {
            if (eq(nativeElement.id, this.tourItemSelection)) {
              this.op.show(new MouseEvent('click'), nativeElement);
              return;
            }
          });
        }
      });

    this.store.select('topics').subscribe(({ topicsMenu }) => {
      if (topicsMenu) {
        const collections =
          topicsMenu.filter(menu => eq(menu.label, 'Collections')) || [];
        if (collections.length > 0) {
          const collectionItems = map('items', collections);
          const collectionsSubItema = map('items', head(collectionItems));
          this.collectionMenuItems = flatMap(collectionsSubItema, item => item);
        }
      }
    });
  }

  public showOverlayPanel(e) {
    this.op.show(e);
    this.isPanelShowed = true;
  }

  public hideOverlayPanel() {
    this.op.hide();
    this.isPanelShowed = false;
    this.labelSelected = null;
  }

  public toggleOpCollectionItem(event, label) {
    if (this.isPanelShowed) {
      this.hideOverlayPanel();
    } else {
      this.labelSelected = label;
      this.showOverlayPanel(event);
    }
  }

  public handleSelectItem(item) {
    this.handleSelectMenuItemCollection(item);
    this.hideOverlayPanel();
    if (this.isTourOpenPanel) {
      this.store.dispatch(actions.hideWelcomeDialog());
      this.store.dispatch(
        actions.hideTourByCollectionSelected({ value: true })
      );
    }
  }

  handleSelectMenuItemCollection(itemSelected: any) {
    setTimeout(() => {
      const collectionSelected = this.getCollectionItem(
        itemSelected.topicsCollection
      );

      const item = {
        label: collectionSelected.label,
        header: collectionSelected.label,
        description: collectionSelected.description || '',
        topicsCollection: itemSelected.topicsCollection
      };

      this.store.dispatch(actions.startLoading());
      const newSearch = new Search({
        facets: { TOPICS: 'newsAuthorsWeek desc' },
        topicsFilter: null,
        topicsCollection: item.topicsCollection
      });

      this.store.dispatch(
        actions.setSearch({
          search: newSearch
        })
      );
      this.store.dispatch(
        actions.setTopicsRanked({
          searchBody: newSearch,
          page: 0,
          isNewSearch: true
        })
      );
      this.store.dispatch(
        actions.setCollectionContent({
          page: 0,
          collectionId: itemSelected.topicsCollection
        })
      );
      this.store.dispatch(actions.setTopicMenuItemSelected({ item }));
      this.store.dispatch(actions.tabControl({ index: 3 }));
    }, 1000);
  }

  getCollectionItem(id: number): SelectedMenuItem {
    const collection = this.collectionMenuItems.find(item =>
      eq(item.topicsCollection, id)
    );
    return new SelectedMenuItem({ ...collection });
  }

  public getCollectionImage(id): string {
    const icon = `${this.collectionService.getCollectionIcon(id)}`;
    return icon;
  }
}
