import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { Content } from 'app/constants';
import { get } from 'lodash';
import { Paginator } from 'primeng/paginator';
import * as actions from 'redux/actions';

@Component({
  selector: 'app-collection-content',
  templateUrl: './collection-content.component.html',
  styleUrls: ['./collection-content.component.scss']
})
export class CollectionContentComponent implements OnInit {
  public loading: boolean;
  public contents: any[];
  public currentPage: number;
  public page: number;
  public totalPages: number;
  public total: number;
  public parentName: string;
  public pageSize: number;
  public keywords: string;
  public topicsCollection: number;

  @ViewChild('pp', { static: false }) paginator: Paginator;

  constructor(private store: Store<AppState>) {
    this.parentName = Content.Collection;
    this.pageSize = 50;
    this.keywords = '';
    this.page = 0;
  }

  ngOnInit(): void {
    this.store
      .select('collectionContent')
      .subscribe(({ contents, loaded, loading, collectionId }) => {
        this.topicsCollection = collectionId;
        this.loading = loaded;
        this.contents = [];
        const contentItems = get(contents, 'content', []);
        if (contentItems.length > 0) {
          this.contents = contentItems;
          this.currentPage = contents.number;
          this.page = contents.number + 1;
          this.totalPages = contents.totalPages;
          this.total = contents.totalElements;
        } else {
          this.contents = [];
          this.currentPage = 0;
          this.page = 0;
          this.totalPages = 0;
          this.total = 0;
        }
        // this.scrollTop();
      });
  }

  private getContentItems() {
    this.store.dispatch(
      actions.setCollectionContent({
        page: this.currentPage,
        collectionId: this.topicsCollection
      })
    );
  }

  getPage({ page }) {
    this.currentPage = page;
    this.page = page + 1;
    this.getContentItems();
  }

  resetPaginator() {
    if (this.paginator) {
      this.paginator.changePage(0);
    }
  }
}
