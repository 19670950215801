import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { AuthenticationService } from 'app/services';
import * as actions from 'redux/actions';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: []
})
export class PagesComponent implements OnInit {
  constructor(
    private store: Store<AppState>,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    if (!this.authService.isLoginRender()) {
      this.store.dispatch(actions.setUserData());
    }
    this.authService.setLoginRender(false);
  }
}
