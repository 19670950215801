import { createAction, props } from '@ngrx/store';

export const setCompaniesRanked = createAction(
  '[CompaniesRanked] setCompaniesRanked',
  props<{
    sort: string;
    page: number;
  }>()
);

export const setCompaniesRankedSuccess = createAction(
  '[CompaniesRanked] setCompaniesRankedSuccess',
  props<{ list: any[] }>()
);

export const setCompaniesRankedError = createAction(
  '[CompaniesRanked] setCompaniesRankedError',
  props<{ payload: any }>()
);

export const unSetCompaniesRanked = createAction(
  '[CompaniesRanked] unSetCompaniesRanked'
);
