import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { ContentItemsService } from 'app/services';
import { tap, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ContentItems } from 'app/models';

@Injectable()
export class ContentItemsListEffects {
  constructor(
    private actions$: Actions,
    private contentItemsService: ContentItemsService
  ) {}

  loadContentItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadProjectContentItems),
      mergeMap(({ projectId }) =>
        this.contentItemsService.getContentItems(projectId).pipe(
          switchMap(response => [
            actions.loadProjectContentItemsSuccess({
              contentItems: response.map(item => new ContentItems({ ...item }))
            })
          ]),
          catchError(err =>
            of(actions.loadProjectContentItemsError({ payload: err }))
          )
        )
      )
    )
  );

  saveContentItemToProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.saveContentItemToProject),
      mergeMap(({ projectId, contentItemId }) =>
        this.contentItemsService
          .saveContentItemToProject(projectId, contentItemId)
          .pipe(
            switchMap(response => [
              actions.saveContentItemToProjectSuccess({
                contentItem: response
              }),
              actions.loadProjectContentItems({
                projectId: projectId
              })
            ]),
            catchError(err =>
              of(actions.saveContentItemToProjectError({ payload: err }))
            )
          )
      )
    )
  );

  deleteContentItemToProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteContentItemToProject),
      mergeMap(({ projectId, contentItemId }) =>
        this.contentItemsService
          .deleteContentItemToProject(projectId, contentItemId)
          .pipe(
            switchMap(response => [
              actions.deleteContentItemToProjectSuccess({
                contentItemId: contentItemId
              }),
              actions.loadProjectContentItems({
                projectId: projectId
              })
            ]),
            catchError(err =>
              of(
                actions.deleteContentItemsToProjectError({
                  payload: err
                })
              )
            )
          )
      )
    )
  );
}
