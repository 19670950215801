import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { isEmpty, includes, eq, join } from 'lodash';
import { DatePipe } from '@angular/common';
import { FILTERS } from 'app/constants';
import {
  resetAdvancedSearchFilters,
  resetSearch,
  setAdvancedFilterText,
  unSetAdvancedFilterText
} from 'app/redux/actions';

@Component({
  selector: 'app-filter-text',
  template: `
    <div class="p-d-flex p-jc-between filter-text" *ngIf="keywords">
      <div></div>
      <div *ngIf="filterText">
        {{ filterText }}.&nbsp;
        <a
          href="javascript:;"
          role="button"
          (click)="clearFilters()"
          class="a-button-link"
          ><i class="pi pi-filter-slash filter-text"></i> Clear Filters</a
        >
      </div>
    </div>
  `,
  styles: [
    `
      .filter-text {
        font-size: small;
        font-style: italic;
        color: #7b7b7b;
      }
    `
  ],
  providers: [DatePipe]
})
export class FilterTextComponent implements OnInit {
  public filterText: string;
  public datePipe: any;
  public keywords: string;
  constructor(private store: Store<AppState>) {
    this.filterText = null;
    this.datePipe = new DatePipe('en-US');
    this.keywords = null;
  }

  ngOnInit() {
    this.store.select('search').subscribe(({ searchBody }) => {
      if (searchBody?.keywords) {
        this.keywords = searchBody?.keywords;
      } else {
        this.keywords = null;
      }
      this.setFilterText(searchBody);
    });
  }

  public clearFilters() {
    this.store.dispatch(resetAdvancedSearchFilters({ onResetFilters: true }));
    this.setFilterText(null);
  }

  public setFilterText(filterFields) {
    const filterArr = [];
    if (filterFields) {
      const {
        analysts,
        countries,
        timeframe,
        topAuthorsOnly,
        topOutletsOnly,
        authors,
        outlets,
        excludeAuthors,
        excludeOutlets
      } = filterFields;
      FILTERS.map((f: any) => {
        switch (f.key) {
          case 'analysts':
            if (analysts) {
              const { label } = f.filters.find(item =>
                eq(item.value, analysts)
              );
              filterArr.push(label);
            }
            break;

          case 'countries':
            if (countries) {
              const { label } = f.filters.find(item =>
                eq(item.value, countries)
              );
              filterArr.push(label);
            }
            break;

          case 'timeframe':
            if (timeframe) {
              if (eq(timeframe, 'CUSTOM')) {
                const from = this.datePipe.transform(
                  filterFields.from,
                  'MM/dd/yyyy'
                );
                const to = this.datePipe.transform(
                  filterFields.to,
                  'MM/dd/yyyy'
                );
                const label = `${from}-${to}`;
                filterArr.push(label);
              } else {
                const { label } = f.filters.find(item =>
                  eq(item.value, timeframe)
                );
                if (!eq(timeframe, 'ONE_YEAR')) {
                  filterArr.push(label);
                }
              }
            }

            break;

          case 'topAuthorsOnly':
            if (topAuthorsOnly) {
              const { label } = f.filters.find(item =>
                eq(item.value, topAuthorsOnly)
              );
              filterArr.push(label);
            }
            break;

          case 'topOutletsOnly':
            if (topOutletsOnly) {
              const { label } = f.filters.find(item =>
                eq(item.value, topOutletsOnly)
              );
              filterArr.push(label);
            }
            break;

          case 'authors':
            if (!isEmpty(authors)) {
              const label = `Include ${authors.length} author(s)`;
              filterArr.push(label);
            }
            break;

          case 'outlets':
            if (!isEmpty(outlets)) {
              const label = `Include ${outlets.length} outlet(s)`;
              filterArr.push(label);
            }
            break;

          case 'excludeAuthors':
            if (!isEmpty(excludeAuthors)) {
              const label = `Exclude ${excludeAuthors.length} author(s)`;
              filterArr.push(label);
            }
            break;

          case 'excludeOutlets':
            if (!isEmpty(excludeOutlets)) {
              const label = `Exclude ${excludeOutlets.length} outlet(s)`;
              filterArr.push(label);
            }
            break;
        }
        this.filterText = join(filterArr, ', ');
        if (this.filterText) {
          this.store.dispatch(
            setAdvancedFilterText({ filterText: this.filterText })
          );
        }
      });
    } else {
      this.filterText = null;
      this.store.dispatch(unSetAdvancedFilterText());
    }
  }
}
