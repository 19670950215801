import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'app/services';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { FacetSearch, Notification } from 'app/models';
import { MAP_URL } from 'app/pages/topics/constants';
import { includes, isEmpty } from 'lodash';
@Component({
  selector: 'app-company-content',
  template: `
    <app-companies-list
      [companies]="companies"
      (createNewCompanyOnclick)="createNewCompany()"
      (editCompanyOnclick)="openCompanyManagerModal($event)"
      (selectedCompanyOnClick)="onSelectCompany($event)"
      (sortCompanyOnClick)="sortChange($event)"
    ></app-companies-list>
  `
})
export class SidebarCompanyContentComponent implements OnInit {
  public companies: any[];
  public currentProject: any;
  constructor(
    private companyService: CompanyService,
    private store: Store<AppState>
  ) {
    this.companies = [];
  }

  ngOnInit() {
    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.currentProject = projectSelected;
        this.getComapanies(projectSelected.value);
      }
    });

    this.store.select('companies').subscribe(({ companies }) => {
      if (companies) {
        this.companies = companies.map(company => ({
          category: !company?.label ? null : company.label?.name,
          ...company
        }));
      }
    });
  }
  createNewCompany() {
    this.store.dispatch(actions.showCompanyDialog({ company: null }));
  }
  openCompanyManagerModal(e) {
    this.store.dispatch(actions.showCompanyDialog({ company: e }));
  }

  onSelectCompany(company) {
    if (company?.topicId) {
      this.companyService
        .getCompanyById(company.topicId)
        .subscribe(response => {
          const topic = {
            id: company?.topicId,
            name: company.name,
            query: company?.query,
            implicitQuery: company?.implicitQuery,
            isSaved: true,
            isMyCompany: false,
            wikipediaUrl: response.contact?.wikipediaUrl,
            webSiteUrl: response.contact?.webSiteUrl,
            youTubeUrl: response.contact?.youTubeUrl,
            twitterUrl: response.contact?.twitterUrl,
            linkedInUrl: response.contact?.linkedInUrl,
            financial: response?.organizationDetail
              ? this.getYahooFinanceURL(
                  response.organizationDetail?.stockTicker
                )
              : null,
            picture: response.contact?.imageUrl,
            locationUrl: response.contact?.streetAddress
              ? `${MAP_URL}/${response.contact.streetAddress}`
              : '',
            project: this.currentProject
          };

          const keywords = topic.query ? topic.query : topic.implicitQuery;
          const facetSearch = new FacetSearch(
            company?.topicId,
            'topic',
            keywords,
            'recent',
            0,
            50
          );

          this.store.dispatch(
            actions.loadCompanyContent({
              facetSearch,
              company: topic
            })
          );
        });
    } else {
      const myCompany = {
        id: company.id,
        name: company.name,
        query: company?.query,
        implicitQuery: company?.implicitQuery,
        isSaved: true,
        isMyCompany: true
      };
      const keywords = myCompany.query
        ? myCompany.query
        : myCompany.implicitQuery;
      const facetSearch = new FacetSearch(
        myCompany.id,
        'topic',
        keywords,
        'recent',
        0,
        50
      );

      this.store.dispatch(
        actions.loadCompanyContent({
          facetSearch,
          company: myCompany
        })
      );
    }
  }
  sortChange(e) {}

  public getComapanies(id) {
    this.store.dispatch(actions.setCompanies({ projectId: id }));
  }

  getYahooFinanceURL(value: any) {
    if (isEmpty(value)) {
      return null;
    } else if (includes(value, 'http')) {
      const q = value.substring(value.lastIndexOf('/') + 1, value.length);
      return `https://finance.yahoo.com/quote/${q}`;
    } else {
      return `https://finance.yahoo.com/quote/${value}`;
    }
  }
}
