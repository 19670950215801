import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { formatPercent } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService, SearchService, TopicService } from 'services/index';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { get, eq, isEmpty, includes, find } from 'lodash';
import * as actions from 'redux/actions';
import { Notification, FacetSearch } from 'app/models';
import { Paginator } from 'primeng/paginator';
import { MAP_URL } from 'app/pages/topics/constants';
import { LongTermChart } from 'app/interfaces';
import {
  Content,
  RECENT_SORT,
  RELEVANT_SORT,
  STAR_ICON,
  STAR_ICON_FILL
} from 'app/constants';

@Component({
  selector: 'facet-company-content',
  templateUrl: './facet-company-content.component.html',
  styleUrls: ['./facet-company-content.component.scss']
})
export class FacetCompanyContentComponent implements OnInit, OnChanges {
  @Input() contentData: any;
  public search: FacetSearch;
  public companyContentPage: number;
  public companyContentTotalPages: number;
  public companyContentPageSize: number;
  public companyContentTotal: number;
  public currentPage: number;
  public topicId: number;
  public isTopicSaved: boolean;
  public keywords: string;
  public starToggle: string;
  public sortSelected: string;
  public loading: boolean;

  public company: any;
  public contentItems: any[] = [];
  public relatedTopics: any[];
  public relatedCompanies: any[];
  public currentProject: any;
  public relatedProducts: any[];
  public companiesList: any[];
  public topicsList: any[];
  public longTermChart: LongTermChart;
  public coveragePastYearText: string;

  @ViewChild('pp', { static: false }) paginator: Paginator;
  public parentName: Content;

  constructor(
    private companyService: CompanyService,
    private searchService: SearchService,
    private topicService: TopicService,
    private store: Store<AppState>,

    private router: Router,
    private route: ActivatedRoute
  ) {
    this.parentName = Content.Company;
    this.isTopicSaved = false;
    this.loading = false;
    this.keywords = null;
    this.relatedCompanies = null;
    this.relatedTopics = null;
    this.relatedProducts = null;
    this.coveragePastYearText = null;
    this.longTermChart = null;
    this.starToggle = STAR_ICON;
  }

  ngOnInit() {
    this.companyContentPage = 1;
    this.currentPage = 0;
    this.store.select('topic').subscribe(({ topic }) => {
      this.topicId = get(topic, 'id', null);
    });
    this.store.select('search').subscribe(({ searchBody }) => {
      if (searchBody?.keywords) {
        this.keywords = searchBody.keywords;
      }
    });
    this.store.select('companyDialog').subscribe(({ sort, error }) => {
      this.sortSelected = sort;
      if (error) {
        this.store.dispatch(
          actions.showNotification({
            notify: new Notification('Company not found', 'error', 'tl')
          })
        );
      }
    });
    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.currentProject = projectSelected;
      }
    });
    this.store.select('topics').subscribe(({ topics }) => {
      this.topicsList = topics;
    });

    this.store.select('companies').subscribe(({ companies }) => {
      this.companiesList = companies;
    });

    this.store.select('offCanvas').subscribe(({ isOpen }) => {
      if (!isOpen) {
        if (this.currentPage > 0) {
          this.paginator.changePage(0);
        }
      }
    });
  }

  ngOnChanges(changes: any) {
    const {
      company,
      contentItems,
      facetSearch
    } = changes.contentData?.currentValue;
    this.company = {
      ...company,
      contact: {},
      picture: this.companyService.getCompanyPicture(company.id)
    };
    // this.keywords = this.keywords || company.name;
    this.getRelatedInformation(company.id);
    this.search = facetSearch;
    this.companyContentTotalPages = contentItems.totalPages;
    this.companyContentTotal = contentItems.totalElements;
    this.contentItems = contentItems.content;
    this.companyContentPageSize = this.search.getSize;
    this.currentPage = this.search.getPage;
    this.getIsTopicSaved();
    // this.resetPaginator();
  }

  private setCoverageSection(stats) {
    if (stats) {
      this.coveragePastYearText = `${stats?.newsAuthors.toLocaleString()} authors, ${stats?.newsItems.toLocaleString()} articles (${
        eq(stats?.deltaYearPercent, 0.000001)
          ? '&infin;'
          : formatPercent(Math.abs(stats?.deltaYearPercent), 'en-US')
      } ${
        stats?.deltaYearPercent > 0.000001 ? ' more ' : ' less '
      } than previous year)`;
      this.longTermChart = {
        longTermSeries: [
          {
            name: 'longterm-history',
            data: stats?.longHistory
          }
        ],
        longTermColor: [stats?.longTrend > 0 ? 'lightgray' : 'lightgray']
      };
    } else {
      this.longTermChart = null;
      this.coveragePastYearText = null;
    }
  }

  private getRelatedInformation(id) {
    this.topicService.getTopicRelated(id).subscribe(
      response => {
        const { stats } = response;
        const { related } = response;
        if (related) {
          this.relatedCompanies = related?.companies
            ? related.companies.content
            : null;
          this.relatedTopics = related?.topics ? related.topics.content : null;
          this.relatedProducts = related?.products
            ? related.products.content
            : null;
        } else {
          this.relatedCompanies = null;
          this.relatedTopics = null;
          this.relatedProducts = null;
        }
        this.setCoverageSection(stats);
      },
      error => {
        this.relatedCompanies = null;
        this.relatedTopics = null;
        this.relatedProducts = null;
      }
    );
  }

  public sortChange(e) {
    this.sortSelected = e;
    this.companyContentPage = 1;
    this.currentPage = 0;
    this.resetPaginator();
    // this.getCompanyContent();
  }

  getPage(e: any) {
    this.companyContentPage = e.page + 1;
    this.currentPage = e.page;
    this.getCompanyContent();
  }

  getCompanyContent() {
    const facetSearch = new FacetSearch(
      this.search.getId,
      this.search.getSearchBy,
      this.search.getKeywords,
      this.sortSelected,
      this.currentPage,
      this.search.getSize
    );

    this.searchService.searchContent(facetSearch).subscribe(response => {
      this.companyContentTotalPages = response.totalPages;
      this.companyContentTotal = response.totalElements;
      this.contentItems = response.content;
      // this.scrollTop();
    });
  }

  handleSaveCompany(company) {
    if (this.isTopicSaved) {
      this.store.dispatch(
        actions.deleteCompanyToProject({
          companyId: company.id,
          userProject: company.project
        })
      );
      this.isTopicSaved = false;
      this.starToggle = this.isTopicSaved ? STAR_ICON_FILL : STAR_ICON;
      this.store.dispatch(actions.deleteCompanyFromManager({ company }));
    } else {
      this.store.dispatch(
        actions.addCompanyToProject({
          companyId: company.id,
          userProject: company.project
        })
      );
      this.isTopicSaved = true;
      this.starToggle = this.isTopicSaved ? STAR_ICON_FILL : STAR_ICON;
      this.store.dispatch(actions.addCompanyFromDialog({ company }));
    }
  }

  resetPaginator() {
    if (this.paginator) {
      this.paginator.changePage(0);
    }
  }

  onDisplayRelatedTopicContent(topic) {
    const sort = /* this.keywords ? RELEVANT_SORT : */ RECENT_SORT;
    this.store.dispatch(actions.startLoading());
    this.store.dispatch(
      actions.setTopicDialog({
        topicSelected: topic,
        projectSelected: this.currentProject,
        keywords: null,
        sort,
        isSidebarTopic: true
      })
    );
  }

  onDisplayRelatedCompanyContent(company) {
    const sort = /* this.keywords ? RELEVANT_SORT : */ RECENT_SORT;
    this.store.dispatch(
      actions.setCompanyDialog({
        companyId: company.id,
        projectSelected: this.currentProject,
        keywords: null,
        sort
      })
    );
  }

  onDisplayRelatedProductContent(product) {
    this.topicService.getTopicWithContact(product.id).subscribe(response => {
      const newProduct = {
        id: product.id,
        name: product.name,
        query: product?.query,
        implicitQuery: product?.implicitQuery,
        wikipediaUrl: response.contact?.wikipediaUrl,
        webSiteUrl: response.contact?.webSiteUrl,
        youTubeUrl: response.contact?.youTubeUrl,
        twitterUrl: response.contact?.twitterUrl,
        linkedInUrl: response.contact?.linkedInUrl,
        financial: response?.organizationDetail
          ? this.getYahooFinanceURL(response.organizationDetail?.stockTicker)
          : null,
        picture: response.contact?.imageUrl,
        locationUrl: response.contact?.streetAddress
          ? `${MAP_URL}/${response.contact.streetAddress}`
          : '',
        project: this.currentProject
      };

      const { name, query, implicitQuery } = product;
      const q = query ? query : implicitQuery ? implicitQuery : name;
      const keywords = `(\"${q}\")`;

      const facetSearch = new FacetSearch(
        product.id,
        'topic',
        keywords,
        'recent',
        0,
        50
      );

      this.store.dispatch(
        actions.loadProductContent({
          facetSearch,
          product: newProduct
        })
      );
    });
  }

  public goToExploreRelatedCoverage(topic) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/search/topic/${topic.id}/coverage`])
    );

    window.open(url, '_blank');
  }

  // public goToExploreRelatedTopics(topic) {
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree([`/search/topic/${topic}/topics`])
  //   );

  //   window.open(url, '_blank');
  // }

  private getIsTopicSaved() {
    setTimeout(() => {
      this.isTopicSaved = !!find(this.topicsList, i =>
        eq(i.name, this.company.name)
      );
      this.starToggle = this.isTopicSaved ? STAR_ICON_FILL : STAR_ICON;
    }, 500);
  }

  getYahooFinanceURL(value: any) {
    if (isEmpty(value)) {
      return null;
    } else if (includes(value, 'http')) {
      const q = value.substring(value.lastIndexOf('/') + 1, value.length);
      return `https://finance.yahoo.com/quote/${q}`;
    } else {
      return `https://finance.yahoo.com/quote/${value}`;
    }
  }
}
