import { createAction, props } from '@ngrx/store';
import { Project, ProjectEdit } from 'app/models';

export const setProjects = createAction(
  '[Projects] setProjects',
  props<{ userId: number }>()
);

export const setProjectsSuccess = createAction(
  '[Projects] setProjectsSuccess',
  props<{ projectId: number; projects: Project[] }>()
);

export const setProjectsError = createAction(
  '[Projects] setProjectsError',
  props<{ payload: any }>()
);

export const addProject = createAction(
  '[Projects] addProject',
  props<{ userId: number; accountId: number; newProjectName: string }>()
);

export const addProjectSuccess = createAction(
  '[Projects] addProjectSuccess',
  props<{ project: Project }>()
);

export const addProjectError = createAction(
  '[Projects] addProjectError',
  props<{ payload: any }>()
);

export const editProject = createAction(
  '[Projects] editProject',
  props<{ projectEdit: ProjectEdit }>()
);

export const editProjectSuccess = createAction(
  '[Projects] editProjectSuccess',
  props<{ project: Project }>()
);

export const editProjectError = createAction(
  '[Projects] editProjectError',
  props<{ payload: any }>()
);

export const deleteProject = createAction(
  '[Projects] deleteProject',
  props<{ project: Project; nextProject: Project }>()
);

export const deleteProjectSuccess = createAction(
  '[Projects] deleteProjectSuccess',
  props<{ project: Project; nextProject: Project }>()
);

export const deleteProjectError = createAction(
  '[Projects] deleteProjectError',
  props<{ payload: any }>()
);

export const unSetProjects = createAction('[Projects] unSetProjects');
