import { createReducer, on } from '@ngrx/store';
import { Blog } from 'app/models';
import {
  setPosts,
  unSetPosts,
  setPostsSuccess,
  setPostsError
} from 'redux/actions';

export interface BlogState {
  posts: Blog[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initialState: BlogState = {
  posts: [],
  loaded: false,
  loading: false,
  error: null
};

const _blogReducer = createReducer(
  initialState,

  on(setPosts, state => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(setPostsSuccess, (state, { posts }) => ({
    ...state,
    posts,
    loading: false,
    loaded: true
  })),

  on(setPostsError, (state, { payload }) => {
    const { url, name, message } = payload;
    return {
      ...state,
      loading: false,
      loaded: false,
      error: {
        url: url || null,
        name: name || null,
        message: message || 'An error occurred while loading the blog posts.'
      }
    };
  }),

  on(unSetPosts, state => ({
    ...state,
    posts: []
  }))
);

export function blogReducer(state, action) {
  return _blogReducer(state, action);
}
