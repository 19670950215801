import { createAction, props } from '@ngrx/store';
import { Search } from 'app/models';

export const setAuthors = createAction(
  '[Authors] setAuthors',
  props<{
    searchBody: Search;
    topicId: number;
    sort: string;
    page: number;
    size: number;
    isSearch: boolean;
  }>()
);

export const setAuthorsSuccess = createAction(
  '[Authors] setAuthorsSuccess',
  props<{ contents: any }>()
);

export const setAuthorsError = createAction(
  '[Authors] setAuthorsError',
  props<{ payload: any }>()
);

export const unSetAuthors = createAction('[Authors] unSetAuthors');
