<div *ngIf="!!collectionMenuItems.length" class="mt-2 mb-2">
  <p-carousel
    [value]="collectionMenuItems"
    [numVisible]="numberCollection"
    [showIndicators]="false"
    [circular]="true"
  >
    <ng-template let-collection pTemplate="item">
      <div
        [style]="collection.selectBgColor"
        class="bg-card-coll"
        (click)="handleSelectItem(collection)"
      >
        <img
          alt="Error"
          onerror="this.src='assets/images/collections/computer.svg'"
          [src]="collection.icon"
          class="coll-icon"
        />
        <span class="text-label">{{ collection.label }}</span>
      </div>
    </ng-template>
  </p-carousel>
</div>
