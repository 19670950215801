import { createAction, props } from '@ngrx/store';
import { UserData, UserProfile } from 'app/models';

export const setUserData = createAction('[UserData] setUserData');

export const setUserDataSuccess = createAction(
  '[UserData] setUserDataSuccess',
  props<{ userData: UserData }>()
);

export const setUserDataError = createAction(
  '[UserData] setUserDataError',
  props<{ payload: any }>()
);
export const unSetUserData = createAction('[UserData] unSetUserData');

export const setUserProfile = createAction(
  '[UserProfile] setUserProfile',
  props<{ userProfile: UserProfile }>()
);

export const setIsUserLogged = createAction(
  '[UserData] setIsUserLogged',
  props<{ loginLoading: boolean; message: string }>()
);
