import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styles: [
    `
      :host {
        .p-button:focus {
          box-shadow: none;
        }

        .p-inputtext:focus {
          box-shadow: none;
        }

        .p-button {
          background: #465675;
          border-color: transparent;
        }

        .p-button:hover {
          background: #4e5f80;
          border-color: #4e5f80;
        }

        .text-orange-600 {
          color: #ff9800 !important;
        }
      }
    `
  ]
})
export class PagenotfoundComponent implements OnInit {
  public url: string;
  constructor(private router: Router) {}

  ngOnInit() {
    this.url = this.router.url;
  }

  goBack() {
    this.router.navigateByUrl('/', { replaceUrl: true });
  }
}
