import { createReducer, on } from '@ngrx/store';
import { TypeaheadResponse } from 'app/models';
import {
  loadTypeaheadSearch,
  loadTypeaheadSearchSuccess,
  loadTypeaheadSearchError,
  typeaheadItemSelected
} from 'redux/actions';

export interface TypeaheadSearchState {
  content: TypeaheadResponse[];
  loaded: boolean;
  loading: boolean;
  error: any;
  itemSelected: string;
  facet: string;
}

const initialState: TypeaheadSearchState = {
  content: [],
  loaded: false,
  loading: false,
  error: null,
  itemSelected: null,
  facet: null
};

const _searchReducer = createReducer(
  initialState,
  on(loadTypeaheadSearch, state => {
    return {
      ...state,
      content: [],
      itemSelected: null,
      loaded: false,
      loading: true,
      type: null
    };
  }),

  on(loadTypeaheadSearchSuccess, (state, { content }) => ({
    ...state,
    content,
    loading: false,
    loaded: true
  })),

  on(loadTypeaheadSearchError, (state, { error }) => ({
    ...state,
    error,
    content: [],
    loading: false,
    loaded: true
  })),

  on(typeaheadItemSelected, (state, { itemSelected, facet }) => ({
    ...state,
    facet,
    itemSelected
  }))
);

export function typeaheadSearchReducer(state, action) {
  return _searchReducer(state, action);
}
