<p-dialog
  [(visible)]="showDialog"
  [closable]="true"
  [dismissableMask]="true"
  [style]="isStepTwo ? { width: '30vw' } : { width: '40vw' }"
  [modal]="true"
  (onHide)="closeDialog()"
  [baseZIndex]="2003"
  [position]="position"
>
  <div *ngIf="isStepOne; then welcome"></div>
  <div *ngIf="isStepTwo; then panel"></div>
  <ng-template #welcome>
    <app-step-one></app-step-one>
  </ng-template>
  <ng-template #panel>
    <app-step-two></app-step-two>
  </ng-template>

  <div class="box-button-right">
    <button
      pButton
      [icon]="isStepOne ? 'pi pi-angle-right' : 'pi pi-check'"
      [iconPos]="isStepOne ? 'right' : 'left'"
      [label]="isStepTwo ? 'Done' : 'Next'"
      class="p-button-raised p-button-secondary p-button-text p-mr-2"
      (click)="next()"
    ></button>
  </div>
</p-dialog>
