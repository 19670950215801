<p-dialog
  [(visible)]="showModal"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="onClose()"
>
  <p-confirmDialog
    key="deleteProjectConfirmation"
    [style]="{ width: '40vw' }"
    [baseZIndex]="10000"
  ></p-confirmDialog>
  <ng-template pTemplate="header">
    <h3>{{ title }}</h3>
  </ng-template>

  <div class="p-grid">
    <div class="p-col-12">
      <app-edit-project></app-edit-project>
    </div>
    <div class="p-col-12">
      <app-create-project></app-create-project>
    </div>
    <div
      class="p-col-12"
      *ngIf="showModal && !showCreateProject && !showEditProject"
    >
      <div [class]="isOpenProjectDw ? 'openDw' : 'closeDw'">
        <h4>Select project:</h4>
        <div class="p-fluid p-mb-4 p-mt-4">
          <div class="p-field">
            <p-dropdown
              [filter]="true"
              filterBy="name"
              (onShow)="showDwOptions()"
              (onHide)="closeDwOptions()"
              (onFocus)="closeDwOptions()"
              (onChange)="selectOneItem($event)"
              placeholder="Select a project"
              [options]="projects"
              optionLabel="name"
              [(ngModel)]="projectSelected"
            ></p-dropdown>
          </div>
        </div>
      </div>
      <div class="p-mt-4">
        <div class="box-button-left">
          <p-button
            icon="pi pi-plus"
            type="button"
            label="New Project"
            (click)="handleCreateProject()"
            class="create p-mr-2"
          ></p-button>
          <button
            icon="pi pi-envelope"
            pButton
            pRipple
            type="button"
            label="Manage Alerts"
            class="p-mr-2"
            (click)="openAlertSettings()"
            pTooltip="Alert Settings"
            tooltipPosition="top"
          ></button>
          <button
            icon="pi pi-pencil"
            pButton
            pRipple
            type="button"
            label="Edit Project"
            class="p-mr-2"
            (click)="openProjectSettings()"
            pTooltip="Edit"
            tooltipPosition="top"
          ></button>
          <button
            icon="pi pi-trash"
            pButton
            pRipple
            type="button"
            label="Delete"
            class="p-mr-2"
            (click)="deleteProject()"
            pTooltip="Delete Project"
            tooltipPosition="top"
          ></button>
        </div>
      </div>
    </div>
    <div
      class="p-col-12 p-mb-lg-auto"
      *ngIf="showModal && !showCreateProject && !showEditProject"
    >
      <div class="p-fluid p-mb-4">
        <div class="p-field"></div>
      </div>
    </div>
  </div>
</p-dialog>
