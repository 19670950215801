import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { CategoryResponse, CategorySubmit } from 'app/models';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { eq, reverse, orderBy } from 'lodash';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit {
  public sort: any;
  public showModal: boolean;
  public loading: boolean;
  public loaded: boolean;
  public title: string;
  public showScrollbar: boolean;
  public categories: CategoryResponse[];
  public userProject: any;

  @ViewChildren('sortNameIcon', {
    read: ElementRef
  })
  sortIcons: QueryList<ElementRef>;

  constructor(
    private store: Store<AppState>,
    private confirmationService: ConfirmationService
  ) {
    this.title = 'Categories';
    this.categories = [];
    this.showScrollbar = false;
    this.loading = false;
    this.loaded = false;
    this.sort = {
      orientation: 'ASC',
      order: 'name'
    };
  }

  ngOnInit() {
    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.userProject = projectSelected;
      }
    });

    this.store
      .select('category')
      .subscribe(({ categories, showCategoriesList, loading, loaded }) => {
        this.loading = loading;
        this.loaded = loaded;
        // this.showScrollbar = categories.length > 5;
        this.categories = categories;
        this.showModal = showCategoriesList;
      });
  }

  public sortChange(icon: any) {
    this.sortIcons.forEach(({ nativeElement }) => {
      if (nativeElement === icon) {
        icon.className =
          icon.className === 'pi pi-sort'
            ? 'pi pi-sort-up'
            : icon.className === 'pi pi-sort-down'
            ? 'pi pi-sort-up'
            : 'pi pi-sort-down';
      } else {
        nativeElement.className = 'pi pi-sort';
      }
    });
    switch (icon.id) {
      case 'sortNameIcon':
        this.sort = {
          orientation: eq(icon.className, 'pi pi-sort-up') ? 'ASC' : 'DESC',
          order: 'name'
        };
        break;
    }
    if (eq(this.sort.orientation, 'ASC')) {
      this.categories = orderBy(this.categories, [this.sort.order], ['ASC']);
    } else {
      this.categories = reverse(
        orderBy(this.categories, [this.sort.order], ['ASC'])
      );
    }
  }

  showCategoryManager(value) {
    this.store.dispatch(actions.showCategoryManagerDialog({ category: value }));
  }

  handleDelete(value) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this category?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      key: 'unfollowConfirmation',
      accept: () => {
        this.deleteCategory(value);
      },
      reject: type => {
        // handle rejected option ConfirmEventType.CANCEL
      }
    });
  }

  deleteCategory(value) {
    const payload = new CategorySubmit({
      id: value.id,
      name: value.name,
      description: value.description,
      projectId: this.userProject.value
    });
    this.store.dispatch(actions.deleteCategory({ payload }));
  }

  closeModalDialog() {
    this.store.dispatch(actions.hideCategoriesListDialog());
  }
}
