import { createReducer, on } from '@ngrx/store';
import {
  setCollectionContent,
  setCollectionContentSuccess,
  setCollectionContentError,
  unSetCollectionContent
} from 'redux/actions';

export interface CollectionContentState {
  collectionId: number;
  contents: any;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initialState: CollectionContentState = {
  collectionId: null,
  contents: null,
  loaded: false,
  loading: false,
  error: null
};

const _collectionContentReducer = createReducer(
  initialState,
  on(setCollectionContent, (state, { collectionId }) => ({
    ...state,
    collectionId,
    contents: null,
    loading: true,
    loaded: false
  })),

  on(setCollectionContentSuccess, (state, { contents }) => ({
    ...state,
    contents,
    loading: false,
    loaded: true
  })),

  on(setCollectionContentError, (state, { payload }) => {
    const { url, status, message } = payload;
    return {
      ...state,
      contents: null,
      loading: false,
      loaded: false,
      error: {
        url: url || null,
        status: status || null,
        message: message || 'An error occurred'
      }
    };
  }),

  on(unSetCollectionContent, state => ({
    ...state,
    contents: null,
    collectionId: null
  }))
);

export function collectionContentReducer(state, action) {
  return _collectionContentReducer(state, action);
}
