import { createReducer, on } from '@ngrx/store';
import {
  setCompanies,
  setCompaniesSuccess,
  setCompaniesError,
  unSetCompanies,
  deleteCompanyFromManager,
  addCompanyFromDialog
} from 'redux/actions';

export interface CompaniesState {
  companies: any[];
  loaded: boolean;
  loading: boolean;
  error: any;
  isDeleteCompany: boolean;
  companyDeleted: any;
  isAddCompany: boolean;
  companyAdded: any;
}

const initialState: CompaniesState = {
  companies: [],
  loaded: false,
  loading: false,
  error: null,
  isDeleteCompany: false,
  companyDeleted: null,
  isAddCompany: false,
  companyAdded: null
};

const _companiesReducer = createReducer(
  initialState,

  on(setCompanies, state => ({ ...state, companies: [] })),

  on(setCompaniesSuccess, (state, { companies }) => ({
    ...state,
    companies,
    isDeleteCompany: false,
    companyDeleted: null,
    loading: false,
    loaded: true,
    isAddCompany: false,
    companyAdded: null
  })),

  on(setCompaniesError, (state, { payload }) => ({
    ...state,
    isDeleteCompany: false,
    companyDeleted: null,
    isAddCompany: false,
    companyAdded: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  })),

  on(deleteCompanyFromManager, (state, { company }) => ({
    ...state,
    isDeleteCompany: true,
    companyDeleted: company,
    isAddCompany: false,
    companyAdded: null,
    companies: state.companies.filter(item => item?.name !== company?.name)
  })),

  on(unSetCompanies, state => ({
    ...state,
    companies: [],
    isDeleteCompany: false,
    companyDeleted: null
  })),

  on(addCompanyFromDialog, (state, { company }) => ({
    ...state,
    isDeleteCompany: false,
    companyDeleted: null,
    isAddCompany: true,
    companyAdded: company,
    topics: [...state.companies, company]
  }))
);

export function companiesReducer(state, action) {
  return _companiesReducer(state, action);
}
