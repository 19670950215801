import { createReducer, on } from '@ngrx/store';
import { filter, eq } from 'lodash';
import {
  loadAuthorsOutlets,
  loadAuthorsSuccess,
  loadOutletsSuccess,
  loadAuthorsOutletsError,
  addAuthorToProject,
  addOutletToProject,
  addAuthorToProjectSuccess,
  addOutletToProjectSuccess,
  addAuthorOutletToProjectError,
  deleteAuthorToProject,
  deleteOutletToProject,
  deleteAuthorToProjectSuccess,
  deleteOutletToProjectSuccess,
  deleteAuthorOutletToProjectError,
  initAuthorOutletToProjectState
} from 'redux/actions';

export interface AuthorsOutletsState {
  authors: any[];
  outlets: any[];
  loaded: boolean;
  loading: boolean;
  hasAuthorOutletAdded: boolean;
  hasAuthorOutletDeleted: boolean;
  isAddingAuthorOutlet: boolean;
  isDeletingAuthorOutlet: boolean;
  errors: any;
}

const initialState: AuthorsOutletsState = {
  authors: [],
  outlets: [],
  loaded: false,
  loading: false,
  isAddingAuthorOutlet: false,
  hasAuthorOutletAdded: false,
  isDeletingAuthorOutlet: false,
  hasAuthorOutletDeleted: false,
  errors: null
};

const _authorsOutletsListReducer = createReducer(
  initialState,

  on(initAuthorOutletToProjectState, state => ({
    ...state,
    isAddingAuthorOutlet: false,
    isDeletingAuthorOutlet: false,
    hasAuthorOutletDeleted: false,
    hasAuthorOutletAdded: false,
    errors: null
  })),

  on(loadAuthorsOutlets, state => ({
    ...state,
    loaded: false,
    loading: true,
    errors: null,
    hasAuthorOutletDeleted: false,
    hasAuthorOutletAdded: false,
    isAddingAuthorOutlet: false,
    isDeletingAuthorOutlet: false
  })),

  on(loadAuthorsSuccess, (state, { authors }) => ({
    ...state,
    authors,
    loading: false,
    loaded: true
  })),

  on(loadOutletsSuccess, (state, { outlets }) => ({
    ...state,
    outlets,
    loading: false,
    loaded: true
  })),

  on(loadAuthorsOutletsError, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    errors: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  })),

  on(addAuthorToProject, state => ({
    ...state,
    isAddingAuthorOutlet: true,
    hasAuthorOutletAdded: false,
    errors: null
  })),

  on(addOutletToProject, state => ({
    ...state,
    isAddingAuthorOutlet: true,
    hasAuthorOutletAdded: false,
    errors: null
  })),

  on(addAuthorToProjectSuccess, (state, { author }) => {
    return {
      ...state,
      authors: [...state.authors, author],
      hasAuthorOutletAdded: true,
      isAddingAuthorOutlet: false
    };
  }),

  on(addOutletToProjectSuccess, (state, { outlet }) => {
    return {
      ...state,
      outlets: [...state.outlets, outlet],
      hasAuthorOutletAdded: true,
      isAddingAuthorOutlet: false
    };
  }),

  on(addAuthorOutletToProjectError, (state, { payload }) => ({
    ...state,
    hasAuthorOutletAdded: false,
    isAddingAuthorOutlet: false,
    errors: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  })),

  on(deleteAuthorToProject, state => ({
    ...state,
    isDeletingAuthorOutlet: true,
    hasAuthorOutletDeleted: false,
    errors: null
  })),

  on(deleteOutletToProject, state => ({
    ...state,
    isDeletingAuthorOutlet: true,
    hasAuthorOutletDeleted: false,
    errors: null
  })),

  on(deleteAuthorToProjectSuccess, (state, { author }) => ({
    ...state,
    authorsOutlets: filter(state.authors, item => !eq(item.id, author.id)),
    hasAuthorOutletDeleted: true,
    isDeletingAuthorOutlet: false
  })),

  on(deleteOutletToProjectSuccess, (state, { outlet }) => ({
    ...state,
    authorsOutlets: filter(state.outlets, item => !eq(item.id, outlet.id)),
    hasAuthorOutletDeleted: true,
    isDeletingAuthorOutlet: false
  })),

  on(deleteAuthorOutletToProjectError, (state, { payload }) => ({
    ...state,
    hasAuthorOutletDeleted: false,
    isDeletingAuthorOutlet: false,
    errors: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  }))
);

export function authorsOutletsListReducer(state, action) {
  return _authorsOutletsListReducer(state, action);
}
