import { createAction, props } from '@ngrx/store';
import { Search } from 'app/models';

export const setSearch = createAction(
  '[Search] setSearch',
  props<{
    search: Search;
  }>()
);

export const unSetSearch = createAction('[Search] unSetSearch');
export const resetSearch = createAction(
  '[Search] resetSearch',
  props<{ onReset: boolean }>()
);

export const setSearchTopicSelected = createAction(
  '[Search] setSearchTopicSelected',
  props<{ searchBody: Search }>()
);

export const setSearchSuccess = createAction('[Search] setSearchSuccess');

export const setSearchError = createAction(
  '[Search] setSearchError',
  props<{ payload: any }>()
);

export const setSaveAsTopic = createAction('[Search] setSaveAsTopic');

export const unSetSaveAsTopic = createAction('[Search] unSetSaveAsTopic');
