import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot
} from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { UserProfile } from 'app/models';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userProfile: UserProfile = this.authenticationService.getUserProfile();
    if (!!userProfile) {
      const isAuthenticated = this.authenticationService.isAuthenticated();
      if (isAuthenticated) {
        return true;
      }
    } else {
      this.authenticationService.goAuth0Login(state, 'AuthenticationGuard');
    }
    return false;
  }
}
