import { Component, OnInit } from '@angular/core';
import { ROUTES } from './menu-items';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthenticationService } from 'services/index';
import { Store } from '@ngrx/store';
import { eq } from 'lodash';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { distinctUntilKeyChanged } from 'rxjs/operators';
import { Search, Notification } from 'app/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  public username: string;
  public picture: string;
  public name: string;
  public accountId: any;
  public showMenu: string;
  public showSubMenu: string;
  public headerHome: string;
  public headerNewsFeed: string;
  public headerTopic: string;
  public headerContentItems: string;
  public headerAuthor: string;
  public headerOutlet: string;
  public showTopicContent: boolean;
  public showAuthorContent: boolean;
  public showOutletContent: boolean;
  public showContentItemContent: boolean;
  public showRankedList: boolean;
  public showCompaniesRankedList: boolean;
  public showSpinnerProjects: boolean;
  public sidebarnavItems: any[];
  public arrowIcon: string;
  public visibleSidebar: boolean;
  public newSearch: Search;
  public currentProject: any;
  public activeState: boolean[];
  public animationOptions: string;
  public cPanelSizes: number[];
  public topicTotalItems: number;
  public contentItemsTotalItems: number;
  public authorTotalItems: number;
  public outletTotalItems: number;
  public currentIndexTab: number;
  public prevIndexTab: number;

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private authenticationService: AuthenticationService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.showMenu = '';
    this.showSubMenu = '';
    this.animationOptions = '800ms cubic-bezier(0.86, 0, 0.07, 1)';
    this.showTopicContent = false;
    this.showAuthorContent = false;
    this.showOutletContent = false;
    this.showContentItemContent = false;
    this.activeState = [false, false, false];
    this.cPanelSizes = [30, 70];
    this.contentItemsTotalItems = 0;
    this.topicTotalItems = 0;
    this.authorTotalItems = 0;
    this.outletTotalItems = 0;
  }

  // End open close
  ngOnInit() {
    this.showSpinnerProjects = true;
    this.store.select('ui').subscribe(({ visible, indexTab }) => {
      this.visibleSidebar = visible;
      this.arrowIcon = visible
        ? 'pi pi-angle-double-left'
        : 'pi pi-angle-double-right';
      this.headerNewsFeed = visible ? 'My News Feed' : '';
      this.headerTopic = visible ? 'Topics' : '';
      this.headerContentItems = visible ? 'Clips' : '';
      this.headerAuthor = visible ? 'Authors' : '';
      this.headerOutlet = visible ? 'Outlets' : '';
      this.headerHome = visible ? 'Home' : '';
      this.currentIndexTab = indexTab || 0;
    });

    this.store
      .select('projects')
      .subscribe(({ action, loaded, loading, error }) => {
        this.showSpinnerProjects = loading;
        if (loaded) {
          this.showSpinnerProjects = false;
        }
        if (error) {
          this.store.dispatch(
            actions.showNotification({
              notify: new Notification(`${error.message}`, 'error', 'tl')
            })
          );
        }
      });

    this.store
      .select('project')
      .pipe(distinctUntilKeyChanged('projectSelected'))
      .subscribe(({ projectSelected }) => {
        if (projectSelected) {
          this.currentProject = projectSelected;
          // this.handleShowTopicSection({ index: 0 });
          this.showTopicContent = false;
          this.showAuthorContent = false;
          this.showOutletContent = false;
          this.showContentItemContent = false;
          this.accordinToggle();
        }
      });

    this.store
      .select('authorsOutletsList')
      .pipe(distinctUntilKeyChanged('authors'))
      .subscribe(({ authors }) => {
        this.authorTotalItems = authors.length;
      });

    this.store
      .select('authorsOutletsList')
      .pipe(distinctUntilKeyChanged('outlets'))
      .subscribe(({ outlets }) => {
        this.outletTotalItems = outlets.length;
      });

    this.store
      .select('topics')
      .pipe(distinctUntilKeyChanged('topics'))
      .subscribe(({ topics }) => {
        this.topicTotalItems = topics.length;
      });

    this.store
      .select('contentItemsList')
      .pipe(distinctUntilKeyChanged('contentItems'))
      .subscribe(({ contentItems }) => {
        this.contentItemsTotalItems = contentItems.length;
      });

    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
  }

  handleShowTopicSection({ index }) {
    if (eq(index, 0)) {
      this.store.dispatch(actions.showSidebar());
      // setTimeout(() => {
      this.showTopicContent = false;
      this.showAuthorContent = true;
      this.showOutletContent = false;
      this.showContentItemContent = false;
      this.store.dispatch(
        actions.showAppSections({
          showNewsFeedSection: true,
          showTopicSection: false,
          showCoverageSection: false,
          showCollectionSection: false
        })
      );
      // this.accordinToggle();
      // }, 1000);
    } else if (eq(index, 1)) {
      this.store.dispatch(actions.showSidebar());
      // setTimeout(() => {
      this.showTopicContent = false;
      this.showAuthorContent = false;
      this.showContentItemContent = false;
      this.showOutletContent = true;
      this.store.dispatch(
        actions.showAppSections({
          showNewsFeedSection: false,
          showTopicSection: true,
          showCoverageSection: false,
          showCollectionSection: false
        })
      );
      // this.accordinToggle();
      // }, 1000);
    } else if (eq(index, 2)) {
      this.store.dispatch(actions.showSidebar());
      // setTimeout(() => {
      this.showOutletContent = false;
      this.showTopicContent = true;
      this.showAuthorContent = false;
      this.showContentItemContent = false;
      this.store.dispatch(
        actions.showAppSections({
          showNewsFeedSection: false,
          showTopicSection: true,
          showCoverageSection: false,
          showCollectionSection: false
        })
      );
      // this.accordinToggle();
      // }, 1000);
    } else if (eq(index, 3)) {
      this.store.dispatch(actions.showSidebar());
      // setTimeout(() => {
      this.showOutletContent = false;
      this.showTopicContent = false;
      this.showAuthorContent = false;
      this.showContentItemContent = true;
      this.store.dispatch(
        actions.showAppSections({
          showNewsFeedSection: false,
          showTopicSection: true,
          showCoverageSection: false,
          showCollectionSection: false
        })
      );
      // this.accordinToggle();
      // }, 1000);
    } else if (eq(index, 4)) {
      this.store.dispatch(actions.showSidebar());
      // setTimeout(() => {
      this.showOutletContent = false;
      this.showTopicContent = false;
      this.showAuthorContent = false;
      this.showContentItemContent = true;
      this.store.dispatch(
        actions.showAppSections({
          showNewsFeedSection: false,
          showTopicSection: true,
          showCoverageSection: false,
          showCollectionSection: false
        })
      );
    }
    this.prevIndexTab = this.currentIndexTab;
  }

  accordinToggle() {
    this.activeState = [
      this.showAuthorContent,
      this.showOutletContent,
      this.showTopicContent,
      this.showContentItemContent
    ];
  }

  logoutUrl() {
    return this.authenticationService.getLogoutUrl();
  }

  removePermission() {
    this.authenticationService.logOut();
  }

  handleSidebarOpen() {
    this.store.dispatch(actions.toggleShowSidebar());
    if (eq(this.visibleSidebar, false)) {
      // Close Sidebar
      this.store.dispatch(actions.tabControl({ index: this.prevIndexTab }));
      this.showOutletContent = false;
      this.showTopicContent = false;
      this.showAuthorContent = false;
      this.showContentItemContent = false;
      this.store.dispatch(
        actions.showAppSections({
          showNewsFeedSection: false,
          showTopicSection: eq(this.prevIndexTab, 0),
          showCoverageSection: eq(this.prevIndexTab, 1),
          showCollectionSection: eq(this.prevIndexTab, 3)
        })
      );
      this.accordinToggle();
    } else {
      // Open Sidebar
      this.prevIndexTab = this.currentIndexTab;
      this.store.dispatch(actions.tabControl({ index: 0 }));
      this.showOutletContent = false;
      this.showTopicContent = false;
      this.showAuthorContent = false;
      this.showContentItemContent = false;
      this.store.dispatch(
        actions.showAppSections({
          showNewsFeedSection: false,
          showTopicSection: true,
          showCoverageSection: false,
          showCollectionSection: false
        })
      );
      // this.accordinToggle();
    }
  }

  openNewsFeed() {
    // this.prevIndexTab = this.currentIndexTab;
    // this.store.dispatch(actions.tabControl({ index: 0 }));
    this.showOutletContent = false;
    this.showTopicContent = false;
    this.showAuthorContent = false;
    this.showContentItemContent = false;
    this.store.dispatch(
      actions.showAppSections({
        showNewsFeedSection: true,
        showTopicSection: false,
        showCoverageSection: false,
        showCollectionSection: false
      })
    );
  }

  changeResizeSplitterPanel(e) {}
}
