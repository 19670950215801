<p-dialog
  header="Edit User"
  [style]="{ width: '50vw' }"
  [(visible)]="visibleEditUserDialog"
  [closable]="true"
  [dismissableMask]="true"
  [modal]="true"
  (onHide)="onClose(false)"
  [baseZIndex]="2004"
>
  <form [formGroup]="editUserForm">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6">
        <label htmlFor="firstName">First Name*</label>
        <input
          id="firstName"
          formControlName="firstName"
          [class]="invalidField('firstName') ? 'ng-invalid ng-dirty' : ''"
          pInputText
          maxlength="250"
        />
        <small
          class="block"
          *ngIf="invalidField('firstName')"
          id="name-help"
          class="p-error"
          >First Name is a required field.</small
        >
      </div>
      <div class="p-field p-col-12 p-md-6">
        <label htmlFor="lastName">Last Name*</label>
        <input
          id="lastName"
          formControlName="lastName"
          [class]="invalidField('lastName') ? 'ng-invalid ng-dirty' : ''"
          pInputText
          maxlength="250"
        />
        <small
          class="block"
          *ngIf="invalidField('lastName')"
          id="name-help"
          class="p-error"
          >Last Name is a required field.</small
        >
      </div>

      <div class="p-field p-col-12 p-md-6">
        <label htmlFor="email">Email Address*</label>
        <input
          id="email"
          formControlName="userName"
          [class]="invalidField('userName') ? 'ng-invalid ng-dirty' : ''"
          pInputText
          maxlength="250"
          (keydown.enter)="handleSubmit()"
        />

        <small
          class="block"
          *ngIf="invalidField('userName')"
          id="name-help"
          class="p-error"
          >Enter a valid email address.</small
        >
      </div>
    </div>
  </form>
  <div class="box-button-right">
    <button
      pButton
      label="Cancel"
      class="p-button-raised p-button-secondary p-button-text p-mr-2"
      (click)="onClose(false)"
    ></button>
    <button
      [disabled]="isSaving"
      icon="pi pi-check"
      pButton
      [label]="isSaving ? 'Saving' : 'Save'"
      class="p-save"
      (click)="handleSubmit()"
    ></button>
  </div>
</p-dialog>
