import { createAction, props } from '@ngrx/store';
import { Project } from 'app/models';

export const setAuthorDialog = createAction(
  '[Author Dialog] setAuthorDialog',
  props<{
    authorId: number;
    projectSelected: Project;
    keywords: string;
    sort: string;
  }>()
);

export const setAuthorDialogSuccess = createAction(
  '[Author Dialog] setAuthorDialogSuccess',
  props<{ author: any }>()
);

export const setAuthorDialogError = createAction(
  '[Author Dialog] setAuthorDialogError',
  props<{ payload: any }>()
);
export const unSetAuthorDialog = createAction(
  '[Author Dialog] unSetAuthorDialog'
);
