import { createAction, props } from '@ngrx/store';
import { ContentItems } from 'app/models';

export const loadProjectContentItems = createAction(
  '[ProjectContentItems] Load Content Items',
  props<{ projectId: number }>()
);

export const loadProjectContentItemsSuccess = createAction(
  '[ProjectContentItems] Load Content Items Success',
  props<{ contentItems: ContentItems[] }>()
);

export const loadProjectContentItemsError = createAction(
  '[ProjectContentItems] Load Content Items Error',
  props<{ payload: any }>()
);

export const saveContentItemToProject = createAction(
  '[ProjectContentItems] Save Content Items',
  props<{ projectId: number; contentItemId: number }>()
);

export const saveContentItemToProjectSuccess = createAction(
  '[ProjectContentItems] Save Content Items Success',
  props<{ contentItem: ContentItems }>()
);

export const saveContentItemToProjectError = createAction(
  '[ProjectContentItems] Save Content Items Error',
  props<{ payload: any }>()
);

export const deleteContentItemToProject = createAction(
  '[ProjectContentItems] Delete Content Items',
  props<{ projectId: number; contentItemId: number }>()
);

export const deleteContentItemToProjectSuccess = createAction(
  '[ProjectContentItems] Delete Content Items Success',
  props<{ contentItemId: number }>()
);

export const deleteContentItemsToProjectError = createAction(
  '[ProjectContentItems] Delete Content Items Error',
  props<{ payload: any }>()
);
