import { createAction, props } from '@ngrx/store';
import { Search, TopicsRankedResponse } from 'app/models';

export const setTopicsRanked = createAction(
  '[TopicsRanked] setTopicsRanked',
  props<{
    searchBody: Search;
    page: number;
    isNewSearch: boolean;
  }>()
);

export const setTopicsRankedSuccess = createAction(
  '[TopicsRanked] setTopicsRankedSuccess',
  props<{ topicsRankedList: TopicsRankedResponse }>()
);

export const setTopicsRankedError = createAction(
  '[TopicsRanked] setTopicsRankedError',
  props<{ payload: any }>()
);

export const unSetTopicsRanked = createAction(
  '[TopicsRanked] unSetTopicsRanked'
);

export const setTopicCoveragePreview = createAction(
  '[Topic Coverage Preview] setTopicCoveragePreview',
  props<{
    searchBody: Search;
  }>()
);

export const setTopicCoveragePreviewSuccess = createAction(
  '[Topic Coverage Preview] setTopicCoveragePreviewSuccess',
  props<{ coveragePreview: any }>()
);

export const setTopicCoveragePreviewError = createAction(
  '[Topic Coverage Preview] setTopicCoveragePreviewError',
  props<{ payload: any }>()
);

export const unSetTopicCoveragePreview = createAction(
  '[Topic Coverage Preview] unSetTopicCoveragePreview'
);

export const setTopicSearchPreviewSuccess = createAction(
  '[Topic Search Preview] setTopicSearchPreviewSuccess',
  props<{ previews: any }>()
);

export const setTopicSearchPreviewError = createAction(
  '[Topic Search Preview] setTopicSearchPreviewError',
  props<{ payload: any }>()
);
