<p-dialog
  [dismissableMask]="true"
  [(visible)]="showModal"
  [style]="{ width: '60vw' }"
  [modal]="true"
  position="center"
  (onHide)="closeModalDialog()"
  [baseZIndex]="9999998"
>
  <ng-template pTemplate="header">
    <h4>Report Missing/Incorrect Data</h4>
  </ng-template>
  <div class="p-mt-3">
    <div class="p-grid">
      <div class="p-col-12">
        <p>
          We strive to make TechNews the highest quality dataset for promoting
          tech companies, and to reduce our margin of error. If you think we are
          missing something that conforms to the following criteria please
          inform us so that our data team can evaluate it.
        </p>
      </div>
      <div class="p-col-6">
        We <b>do</b> track:
        <ul>
          <li>Authors writing about technology in the English language</li>
          <li>
            Content with attribution (must be connected to an actual author)
          </li>
          <li>The original story (not syndication)</li>
        </ul>
      </div>
      <div class="p-col-6">
        We <b>do not</b> track:
        <ul>
          <li>One-off contributed articles by company execs</li>
          <li>Content that does not have attribution to specific author</li>
          <li>Press releases</li>
          <li>Web crawl gibberish</li>
        </ul>
      </div>
    </div>
    <form [formGroup]="form">
      <div class="p-fluid">
        <div class="p-field p-grid">
          <label for="firstname4" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"
            >What's wrong?</label
          >
          <div class="p-col-10">
            <p-dropdown
              placeholder="Select one issue"
              [options]="issues"
              optionLabel="name"
              optionValue="value"
              formControlName="problem"
              [class]="invalidField('problem') ? 'ng-invalid ng-dirty' : ''"
            ></p-dropdown>
            <small
              class="block"
              *ngIf="invalidField('problem')"
              id="problem-help"
              class="p-error"
              >Select one issue</small
            >
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"
            >Name (if applicable)</label
          >
          <div class="p-col-10">
            <input id="name" formControlName="name" type="text" pInputText />
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"
            >URL (if applicable)</label
          >
          <div class="p-col-10">
            <input id="url" formControlName="url" type="text" pInputText />
          </div>
        </div>

        <div class="p-field p-grid">
          <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"
            >Notes</label
          >
          <div class="p-col-10">
            <textarea
              [style]="{ width: '100%' }"
              autoResize="autoResize"
              rows="2"
              pInputTextarea
              formControlName="notes"
            ></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <div class="p-mt-3"></div>
    <p-button
      pRipple
      icon="pi pi-times"
      (click)="closeModalDialog()"
      label="Cancel"
      styleClass="p-button-raised p-button-secondary p-button-text"
    ></p-button>
    <p-button
      [disabled]="isSaving"
      pRipple
      icon="pi pi-check"
      (click)="handleSubmit()"
      label="Save"
      styleClass="p-button-primary"
    >
    </p-button>
  </ng-template>
</p-dialog>
