import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { eq, includes } from 'lodash';
import * as actions from '../actions';
import { tap, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { ProjectService } from '../../services';
import { of } from 'rxjs';
import { Project, ProjectEdit } from 'app/models';

@Injectable()
export class ProjectsEffects {
  public projectId: number;

  constructor(
    private actions$: Actions,
    private projectService: ProjectService
  ) {
    const projectStorage = this.projectService.getProject();
    this.projectId = projectStorage ? projectStorage.value : 0;
  }

  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setProjects),
      mergeMap(action =>
        this.projectService.getProjects(action.userId, 'TOPICS').pipe(
          // tap(response => console.log('Load projects list', response)),
          switchMap(response => [
            actions.setProjectsSuccess({
              projectId: this.projectId,
              projects: response.map(
                ({ id, name, description }: any) =>
                  new Project({
                    value: id,
                    name: name,
                    description: description || null,
                    selected: false
                  })
              )
            })
          ]),
          catchError(err => of(actions.setProjectsError({ payload: err })))
        )
      )
    )
  );

  createProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addProject),
      mergeMap(action =>
        this.projectService
          .save(action.newProjectName, action.accountId, 'TOPICS')
          .pipe(
            switchMap(response => [
              actions.addProjectSuccess({
                project: new Project({
                  value: response.id,
                  name: response.name,
                  description: null,
                  selected: true
                })
              }),
              actions.projectChange({
                projectSelected: new Project({
                  value: response.id,
                  name: response.name,
                  description: null,
                  selected: true
                })
              })
            ]),
            catchError(err => of(actions.addProjectError({ payload: err })))
          )
      )
    )
  );

  editProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.editProject),
      mergeMap(({ projectEdit }) =>
        this.projectService
          .update(projectEdit)
          .pipe(
            switchMap(response => [
              actions.editProjectSuccess({
                project: new Project({
                  value: projectEdit.projectId,
                  name: projectEdit.name,
                  description: projectEdit.description,
                  selected: true
                })
              }),
              actions.projectChange({
                projectSelected: new Project({
                  value: projectEdit.projectId,
                  name: projectEdit.name,
                  description: projectEdit.description,
                  selected: true
                })
              })
            ]),
            catchError(err => of(actions.addProjectError({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (response) {
                if (includes(response.type, 'editProjectSuccess')) {
                  this.projectService.saveProjec({
                    value: projectEdit.projectId,
                    name: projectEdit.name,
                    description: projectEdit.description,
                    selected: true
                  });
                }
              }
            })
          )
      )
    )
  );

  deleteProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteProject),
      mergeMap(({ project, nextProject }) =>
        this.projectService.delete(project.value).pipe(
          switchMap(response => [
            actions.deleteProjectSuccess({ project, nextProject })
          ]),
          catchError(err => of(actions.deleteProjectError({ payload: err })))
        )
      )
    )
  );
}
