import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/services';
import { ToastNotificationService } from 'app/components';
import { SocialUser } from 'app/models';
import { eq } from 'lodash';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public isAuth0Logged: boolean;
  public resetPasswordForm: FormGroup;
  public isUpdating: boolean;
  public userProfile: SocialUser;
  public showforgotPasswordDialog: boolean;
  public uParam: string;
  public pParam: string;

  constructor(
    private router: Router,
    private authTechnewService: AuthenticationService,
    private formBuilder: FormBuilder,
    private notificationService: ToastNotificationService,
    private route: ActivatedRoute
  ) {
    this.isUpdating = false;
    this.userProfile = null;
    this.showforgotPasswordDialog = false;
  }

  ngOnInit() {
    const { u, h } = this.route.snapshot.queryParams;
    this.uParam = u;
    this.pParam = h;
    this.resetPasswordForm = this.formBuilder.group({
      newPassword: [null, Validators.required],
      confirmPassword: [null, Validators.required]
    });
  }

  invalidField(field: string) {
    return (
      this.resetPasswordForm.controls[field].errors &&
      this.resetPasswordForm.controls[field].touched
    );
  }

  updatePassword(): void {
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }
    const newPassword = this.resetPasswordForm.get('newPassword').value;
    const confirmPassword = this.resetPasswordForm.get('confirmPassword').value;

    if (!eq(newPassword, confirmPassword)) {
      this.notificationService.showMessage(
        'Password and confirm password must be the same!',
        'Error'
      );
      return;
    }
    this.isUpdating = true;
    this.authTechnewService
      .resetPassword(this.uParam, this.pParam, newPassword)
      .subscribe(
        (response: any) => {
          this.isUpdating = false;
          this.notificationService.showMessage(
            'Password has been changed successfully',
            'Success'
          );
          setTimeout(() => {
            this.cancel();
          }, 2000);
        },
        (error: any) => {
          this.isUpdating = false;
          this.notificationService.showMessage(
            'Failed to reset password',
            'Error'
          );
        }
      );
  }

  cancel(): void {
    this.router.navigate(['auth/login']);
  }
}
