import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-not-records-found',
  template: `
    <div *ngIf="visible" class="empty-container">
      <!--      <div class="p-d-flex p-jc-center">-->
      <!--        <img-->
      <!--          width="80"-->
      <!--          alt="Not-record-found"-->
      <!--          src="assets/images/empty_item.svg"-->
      <!--        />-->
      <!--      </div>-->
      <div class="p-d-flex p-jc-center">
        <div class="empty-msg">No&nbsp;results</div>
      </div>
    </div>
  `,
  styles: [
    `
      .empty-msg {
        padding: 10px;
        color: #cbcdd1;
        font-weight: 700;
      }

      .empty-container {
        position: relative;
        height: calc(100vh - 410px);
        max-width: 99%;
        max-height: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `
  ]
})
export class NotRecordsFoundComponent implements OnChanges {
  @Input() visible: boolean;

  constructor() {}

  ngOnChanges(changes): void {
    this.visible = changes.visible?.currentValue;
  }
}
