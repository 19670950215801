import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { ConfirmationService } from 'primeng/api';
import { findIndex } from 'lodash';
import { update } from 'lodash/fp';
import { deleteContentItemToProject, deleteTopic } from 'app/redux/actions';
import { ContentItems, SidebarList } from 'app/models';
import { Content } from 'app/constants';

@Component({
  selector: 'app-sidebar-content-items-list',
  template: `
    <app-sidebar-list
      [sidebarData]="contentItems"
      (openNewDialogEmiter)="handleCreateNewContentItem()"
      (openSelectDialogEmiter)="selectedContentItem($event)"
      (openEditDialogEmiter)="handleEditContentItem($event)"
      (unfollowEmiter)="unfollow($event)"
    ></app-sidebar-list>
  `
})
export class SidebarContentItemsListComponent implements OnInit {
  @Output() createNewContentItemOnclick: EventEmitter<any> = new EventEmitter();
  @Output() editContentItemOnclick: EventEmitter<any> = new EventEmitter();
  @Output() selectedContentItemOnClick: EventEmitter<any> = new EventEmitter();
  public selectTopic: any;
  public contentItems: SidebarList[];
  public sort: any;
  public showScrollbar: boolean;
  public isEdit: boolean;
  public isUnfollow: boolean;
  public currentProject: any;
  public userId: any;
  public parentName: string;

  @ViewChildren('sortNameIcon, sortCategoryIcon', {
    read: ElementRef
  })
  sortIcons: QueryList<ElementRef>;

  constructor(
    private store: Store<AppState>,
    private confirmationService: ConfirmationService
  ) {
    this.contentItems = [];
    this.showScrollbar = false;
    this.isEdit = false;
    this.isUnfollow = false;
    this.userId = null;
    this.parentName = Content.ContentItem;
  }

  ngOnInit() {
    this.store.select('userData').subscribe(({ data }) => {
      if (data) {
        this.userId = data.userId;
      }
    });

    this.store
      .select('contentItemsList')
      .subscribe(({ contentItems, loaded }) => {
        if (contentItems && contentItems.length > 0 && loaded) {
          this.contentItems = contentItems.map(
            item =>
              new SidebarList({
                title: item.title,
                publishDate: item.date,
                subTitle: `${item.outlet}`,
                picture: null,
                hasPicture: true,
                parentObject: item,
                parentName: this.parentName
              })
          );
        } else {
          this.contentItems = [];
        }
        this.showScrollbar = this.contentItems.length > 10;
      });

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.currentProject = projectSelected;
      }
    });
  }

  public handleCreateNewContentItem() {
    this.createNewContentItemOnclick.emit(null);
  }

  public handleEditContentItem(index) {
    this.editContentItemOnclick.emit(index);
  }

  public selectedContentItem(item) {
    this.selectedContentItemOnClick.emit(item);
  }

  handleUnfollow(value) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to unfollow this content item?',
      header: 'Unfollow Confirmation',
      icon: 'pi pi-exclamation-triangle',
      key: 'unfollowConfirmation',
      accept: () => {
        this.unfollow(value);
      },
      reject: type => {
        // handle rejected option ConfirmEventType.CANCEL
      }
    });
  }

  public unfollow(value) {
    this.store.dispatch(
      deleteContentItemToProject({
        projectId: this.currentProject.value,
        contentItemId: value.id
      })
    );
  }
}
