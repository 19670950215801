import { createAction, props } from '@ngrx/store';
import { Search } from 'app/models';

export const setOutlets = createAction(
  '[Outlets] setOutlets',
  props<{
    searchBody: Search;
    topicId: number;
    sort: string;
    page: number;
    size: number;
    isSearch: boolean;
  }>()
);

export const setOutletsSuccess = createAction(
  '[Outlets] setOutletsSuccess',
  props<{ contents: any }>()
);

export const setOutletsError = createAction(
  '[Outlets] setOutletsError',
  props<{ payload: any }>()
);

export const unSetOutlets = createAction('[Outlets] unSetOutlets');
