import { createReducer, on } from '@ngrx/store';
import { Notification } from 'app/models';
import {
  startLoading,
  stopLoading,
  toggleShowSidebar,
  hideSidebar,
  showSidebar,
  showNotification,
  showAppSections,
  startSearch,
  stopSearch,
  tabControl,
  resizeSpitter,
  hideSelectProjectModal,
  showSelectProjectModal,
  hideAlertSettingstModal,
  showAlertSettingsModal,
  showProjectSettingsSection,
  hideProjectSettingsSection,
  hideProjectCreateSection,
  showProjectCreateSection,
  subscriptionExpire,
  screenSize
} from 'redux/actions';

export interface UIState {
  isLoading: boolean;
  isSearch: boolean;
  visible: boolean;
  notification: Notification;
  showCoverage: boolean;
  indexTab: number;
  facetPanelSize: number;
  contemItemPanelSize: number;
  showSelectProjectModal: boolean;
  showAlertSettingstModal: boolean;
  showProjectSettingstSection: boolean;
  showProjectCreateSection: boolean;
  isSubscriptionExpired: boolean;
  expireMessage: string;
  screenSizeValues: any;
  showNewsFeedSection: boolean;
  showTopicSection: boolean;
  showCoverageSection: boolean;
  showCollectionSection: boolean;
}

const initialState: UIState = {
  isLoading: false,
  visible: false,
  isSearch: false,
  notification: null,
  showCoverage: false,
  indexTab: 0,
  facetPanelSize: 50,
  contemItemPanelSize: 50,
  showSelectProjectModal: false,
  showAlertSettingstModal: false,
  showProjectSettingstSection: false,
  showProjectCreateSection: false,
  isSubscriptionExpired: false,
  expireMessage: null,
  screenSizeValues: null,
  showTopicSection: true,
  showCoverageSection: false,
  showNewsFeedSection: false,
  showCollectionSection: false
};

const _uiReducer = createReducer(
  initialState,

  on(startLoading, state => ({ ...state, isLoading: true })),
  on(stopLoading, state => ({ ...state, isLoading: false })),
  on(toggleShowSidebar, state => ({ ...state, visible: !state.visible })),
  on(hideSidebar, state => ({ ...state, visible: false })),
  on(showSidebar, state => ({ ...state, visible: true })),
  on(
    showAppSections,
    (
      state,
      {
        showTopicSection,
        showCoverageSection,
        showNewsFeedSection,
        showCollectionSection
      }
    ) => ({
      ...state,
      showTopicSection,
      showCoverageSection,
      showNewsFeedSection,
      showCollectionSection
    })
  ),

  on(startSearch, state => ({
    ...state,
    isSearch: true,
    showRankedList: true,
    showCoverage: true,
    showRankedCompanyList: false
  })),
  on(stopSearch, state => ({ ...state, isSearch: false })),
  on(showNotification, (state, { notify }) => ({
    ...state,
    notification: notify
  })),
  on(tabControl, (state, { index }) => ({
    ...state,
    indexTab: index
  })),
  on(resizeSpitter, (state, { facetPanelSize, contemItemPanelSize }) => ({
    ...state,
    facetPanelSize,
    contemItemPanelSize
  })),
  on(hideSelectProjectModal, state => ({
    ...state,
    showSelectProjectModal: false
  })),
  on(showSelectProjectModal, state => {
    return {
      ...state,
      showSelectProjectModal: true
    };
  }),
  on(hideAlertSettingstModal, state => ({
    ...state,
    showAlertSettingstModal: false
  })),
  on(showAlertSettingsModal, state => {
    return {
      ...state,
      showAlertSettingstModal: true
    };
  }),
  on(hideProjectSettingsSection, state => ({
    ...state,
    showProjectSettingstSection: false
  })),
  on(showProjectSettingsSection, state => {
    return {
      ...state,
      showProjectSettingstSection: true
    };
  }),

  on(hideProjectCreateSection, state => ({
    ...state,
    showProjectCreateSection: false
  })),
  on(showProjectCreateSection, state => {
    return {
      ...state,
      showProjectCreateSection: true
    };
  }),
  on(subscriptionExpire, (state, { isSubscriptionExpired, expireMessage }) => ({
    ...state,
    isSubscriptionExpired,
    expireMessage
  })),
  on(screenSize, (state, { screenSizeValues }) => ({
    ...state,
    screenSizeValues
  }))
);

export function uiReducer(state, action) {
  return _uiReducer(state, action);
}
