export interface SelectedMenuItemProps {
  label?: string;
  description?: string;
  items?: SelectedMenuItemProps[];
  topicsFilter?: string;
  topicsCollection?: number;
  icon?: string;
  selectBgColor?: any;
}

export class SelectedMenuItem {
  public label?: string;
  public description?: string;
  public items?: SelectedMenuItemProps[];
  public topicsFilter?: string;
  public topicsCollection?: number;
  public icon?: string;
  public selectBgColor?: any;

  constructor({
    label,
    description,
    items,
    topicsFilter,
    topicsCollection,
    icon,
    selectBgColor
  }: SelectedMenuItemProps) {
    this.label = label || null;
    this.description = description || null;
    this.items = items || null;
    this.topicsFilter = topicsFilter || null;
    this.topicsCollection = topicsCollection || null;
    this.icon = icon || null;
    this.selectBgColor = selectBgColor || {};
  }
}
