import { Injectable } from '@angular/core';
import { find } from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthenticationService } from 'services/authentication/authentication.service';
import { IssueReport, ReportMissingData } from 'models/index';

@Injectable({
  providedIn: 'root'
})
export class ReportMissingDataService {
  private api: string;
  private httpOptions: any;
  issuesAvalibles: IssueReport[] = [];
  private userEmail: string;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
    this.api = environment.api_url;
    this.userEmail = this.authService.getUsername();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this.issuesAvalibles.push(
      new IssueReport(
        'An article/blog post is missing',
        'An article/blog post is missing'
      )
    );
    this.issuesAvalibles.push(
      new IssueReport('An author is missing', 'An author is missing')
    );
    this.issuesAvalibles.push(
      new IssueReport('An outlet is missing', 'An outlet is missing')
    );
    this.issuesAvalibles.push(
      new IssueReport('A topic is missing', 'A topic is missing')
    );
    this.issuesAvalibles.push(
      new IssueReport('A company is missing', 'A company is missing')
    );
    this.issuesAvalibles.push(
      new IssueReport(
        'Some information is incorrect',
        'Some information is incorrect'
      )
    );
    this.issuesAvalibles.push(
      new IssueReport('Something else', 'Something else')
    );
  }

  public getIssues(): Promise<IssueReport[]> {
    return Promise.resolve(this.issuesAvalibles);
  }

  public getIssueByName(name: string): IssueReport {
    return find(this.issuesAvalibles, { name: name });
  }

  public getIssueByValue(value: string): IssueReport {
    return find(this.issuesAvalibles, { value: value });
  }

  public sendReport(report: ReportMissingData): Observable<any> {
    const body = {
      problem: report.problem,
      name: report.name,
      url: report.url,
      notes: report.notes,
      user: this.userEmail
    };
    return this.http.post(
      `${this.api}/forms/missing-data`,
      body,
      this.httpOptions
    );
  }
}
