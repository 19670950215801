import { createReducer, on } from '@ngrx/store';
import { setEmailAlertToogle, setEmailAlertToogleError } from 'redux/actions';
import { ProjectToggle } from 'models/index';

export interface ToggleState {
  toggle: ProjectToggle;
  error: any;
}

const initialState: ToggleState = {
  toggle: null,
  error: null
};

const _projectAlertToggleReducer = createReducer(
  initialState,

  on(setEmailAlertToogle, (state, { toggle }) => ({
    ...state,
    toggle: { ...toggle }
  })),

  on(setEmailAlertToogleError, (state, { payload }) => ({
    ...state,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  }))
);

export function projectAlertToggleReducer(state, action) {
  return _projectAlertToggleReducer(state, action);
}
