import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { Search } from 'app/models';

@Component({
  selector: 'app-toolbar-content',
  template: `
    <div id="toolbar" *ngIf="tabControl === 0 || tabControl === 3">
      <!-- <button
        *ngIf="search?.keywords && !isTopicsFilterSelected"
        pButton
        type="button"
        icon="pi pi-arrow-right"
        iconPos="left"
        class="p-button-secondary p-mr-4"
        (click)="goCoverage()"
      ></button> -->
      <!-- <app-description-box></app-description-box> -->
      <app-collection-carousel></app-collection-carousel>
    </div>
  `,
  styles: [
    `
      :host ::ng-deep .p-button.p-button-icon-only {
        background-color: transparent;
        color: #465675;
        border-color: transparent;
      }

      :host ::ng-deep .p-button.p-button-icon-only:focus {
        box-shadow: none;
      }

      :host ::ng-deep .p-button.p-button-icon-only:hover {
        background: transparent;
        border-color: transparent;
        color: #465675;
      }

      #toolbar {
        padding-top: 0.2em;
        border-top: 1px solid #dee2e6;
        height: 5rem;
      }
    `
  ]
})
export class ToolbarContentComponent implements OnInit {
  public search: Search;
  public tabControl: number;
  public isTopicsFilterSelected: boolean;
  constructor(private store: Store<AppState>) {
    this.isTopicsFilterSelected = false;
  }

  ngOnInit() {
    this.store.select('search').subscribe(({ searchBody }) => {
      this.search = searchBody;
    });
    this.store.select('ui').subscribe(({ indexTab }) => {
      this.tabControl = indexTab;
    });
    this.store.select('topics').subscribe(({ topicMenuItemSelected }) => {
      this.isTopicsFilterSelected = !!topicMenuItemSelected;
    });
  }

  goCoverage() {
    this.store.dispatch(actions.tabControl({ index: 1 }));
  }
}
