import { createReducer, on } from '@ngrx/store';
import {
  setOutletDialog,
  setOutletDialogSuccess,
  setOutletDialogError,
  unSetOutletDialog
} from 'redux/actions';

export interface OutletDialogState {
  outletId: number;
  outlet: any;
  loaded: boolean;
  loading: boolean;
  error: any;
  sort: string;
}

const initialState: OutletDialogState = {
  outletId: null,
  outlet: null,
  loaded: true,
  loading: false,
  error: null,
  sort: null
};

const _outletDialogReducer = createReducer(
  initialState,

  on(setOutletDialog, (state, { outletId, sort }) => {
    return {
      ...state,
      outlet: null,
      loading: true,
      loaded: false,
      outletId,
      sort
    };
  }),

  on(setOutletDialogSuccess, (state, { outlet }) => {
    return {
      ...state,
      outlet,
      loading: false,
      loaded: true
    };
  }),

  on(setOutletDialogError, (state, { payload }) => ({
    ...state,
    outlet: null,
    loading: false,
    loaded: true,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  })),

  on(unSetOutletDialog, () => ({
    ...initialState
  }))
);

export function outletDialogReducer(state, action) {
  return _outletDialogReducer(state, action);
}
