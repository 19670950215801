import { createReducer, on } from '@ngrx/store';
import {
  setCoverages,
  setCoveragesSuccess,
  setCoveragesError,
  unSetCoverages
} from 'redux/actions';

export interface CoveragesState {
  contents: any;
  isSearch: boolean;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initialState: CoveragesState = {
  contents: null,
  isSearch: false,
  loaded: false,
  loading: false,
  error: null
};

const _coveragesReducer = createReducer(
  initialState,
  on(setCoverages, (state, { isSearch }) => ({
    ...state,
    contents: null,
    loading: true,
    loaded: false,
    isSearch
  })),

  on(setCoveragesSuccess, (state, { contents }) => ({
    ...state,
    contents: { ...contents },
    loading: false,
    loaded: true
  })),

  on(setCoveragesError, (state, { payload }) => ({
    ...state,
    contents: null,
    loading: false,
    loaded: false,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  })),

  on(unSetCoverages, state => ({
    ...state,
    contents: null
  }))
);

export function coveragesReducer(state, action) {
  return _coveragesReducer(state, action);
}
