import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { Router } from '@angular/router';
import { eq, head, find } from 'lodash';
import { Paginator } from 'primeng/paginator';
import {
  AuthenticationService,
  AuthorService,
  OutletService,
  SearchService
} from 'app/services';
import * as actions from 'redux/actions';
import { MAP_URL } from 'app/pages/topics/constants';
import { FacetSearch, Notification } from 'app/models';
import {
  Content,
  SPINNER_ICON,
  STAR_ICON,
  STAR_ICON_FILL
} from 'app/constants';

@Component({
  selector: 'facet-outlet-content',
  templateUrl: './facet-outlet-content.component.html',
  styleUrls: ['./facet-outlet-content.component.scss']
})
export class FacetOutletContentComponent implements OnInit, OnChanges {
  @Input() contentData: any;
  public search: FacetSearch;
  public sortSelected: string;
  public outletContentPage: number;
  public outletContentTotalPages: number;
  public outletContentPageSize: number;
  public outletContentTotal: number;
  public currentPage: number;
  public hideFollowing: boolean;
  public authorsOutletsList: any[];
  public project: any;
  public isOutletSaved: boolean;
  public starToggle: string;
  public loading: boolean;

  public outlet: any;
  public contentItems: any[] = [];
  public keywords: string;
  public actionType: string;

  @ViewChild('pp', { static: false }) paginator: Paginator;
  public parentName: Content;

  constructor(
    private outletService: OutletService,
    private searchService: SearchService,
    private authorService: AuthorService,
    private router: Router,
    private store: Store<AppState>,
    private auth: AuthenticationService
  ) {
    this.parentName = Content.Outlet;
    this.sortSelected = 'recent';
    this.loading = false;
    this.keywords = null;
    this.starToggle = STAR_ICON;
    this.isOutletSaved = false;
    this.authorsOutletsList = [];
    this.project = null;
    this.actionType = null;
  }

  ngOnInit() {
    this.hideFollowing = false;
    this.outletContentPage = 1;
    this.currentPage = 0;

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.project = projectSelected;
      }
    });

    this.store.select('search').subscribe(({ searchBody }) => {
      if (searchBody?.keywords) {
        this.keywords = searchBody.keywords;
      }
    });

    this.store
      .select('authorsOutletsList')
      .subscribe(
        ({
          outlets,
          isAddingAuthorOutlet,
          isDeletingAuthorOutlet,
          hasAuthorOutletDeleted,
          hasAuthorOutletAdded,
          errors
        }) => {
          this.authorsOutletsList = outlets;

          if (isAddingAuthorOutlet || isDeletingAuthorOutlet) {
            this.starToggle = SPINNER_ICON;
          }

          if (hasAuthorOutletAdded) {
            this.isOutletSaved = true;
            this.starToggle = STAR_ICON_FILL;
          }

          if (hasAuthorOutletDeleted) {
            this.isOutletSaved = false;
            this.starToggle = STAR_ICON;
          }

          if (errors?.message) {
            this.store.dispatch(
              actions.showNotification({
                notify: new Notification(
                  `Error ${this.actionType} outlet to project`,
                  'error',
                  'tl'
                )
              })
            );
            this.setOutletSaved();
            setTimeout(() => {
              this.store.dispatch(actions.initAuthorOutletToProjectState());
            }, 500);
          }
        }
      );
  }

  ngOnChanges(changes: any) {
    const {
      outlet,
      contentItems,
      facetSearch
    } = changes.contentData?.currentValue;
    const author = head(contentItems.content)?.author;
    const authorId = head(contentItems.content)?.authorId;
    this.outlet = {
      ...outlet,
      author,
      authorId,
      picture: this.outletService.getOutletPicture(outlet.id)
    };
    this.getOutletContactDetails(outlet.id);
    this.search = facetSearch;
    this.outletContentTotalPages = contentItems.totalPages;
    this.outletContentTotal = contentItems.totalElements;
    this.contentItems = contentItems.content;
    this.outletContentPageSize = this.search.getSize;
    this.currentPage = this.search.getPage;
    this.setOutletSaved();
    this.resetPaginator();
  }

  public goToExploreRelatedCoverage(outlet) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/outlet/${outlet.id}`])
    );

    window.open(url, '_blank');
  }

  public sortChange(e) {
    this.sortSelected = e;
    this.outletContentPage = 1;
    this.currentPage = 0;
    this.resetPaginator();
    this.getOutletContent();
  }

  getPage(e: any) {
    this.outletContentPage = e.page + 1;
    this.currentPage = e.page;
    this.getOutletContent();
  }

  getOutletContent() {
    const facetSearch = new FacetSearch(
      this.search.getId,
      this.search.getSearchBy,
      this.search.getKeywords,
      this.sortSelected,
      this.currentPage,
      this.search.getSize
    );

    this.searchService.searchContent(facetSearch).subscribe(response => {
      this.outletContentTotalPages = response.totalPages;
      this.outletContentTotal = response.totalElements;
      this.contentItems = response.content;
      // this.scrollTop();
    });
  }

  getOutletContactDetails(id) {
    this.outletService.getOutletById(id).subscribe((response: any) => {
      const { contact } = response;
      this.outlet = {
        ...this.outlet,
        location: `${contact?.city}, ${contact?.state}`,
        locationUrl: contact?.city
          ? `${MAP_URL}/${
              contact.streetAddress ? contact.streetAddress : ''
            }, ${contact.city}, ${contact.state}`
          : '',
        contact
      };
    });
  }

  resetPaginator() {
    if (this.paginator) {
      this.paginator.changePage(0);
    }
  }

  private setOutletSaved() {
    setTimeout(() => {
      this.isOutletSaved = !!find(this.authorsOutletsList, i =>
        eq(i.partyId, this.outlet.id)
      );
      this.starToggle = this.isOutletSaved ? STAR_ICON_FILL : STAR_ICON;
    }, 500);
  }

  handleSaveOutlet(outlet) {
    if (this.isOutletSaved) {
      this.actionType = 'deleting';
      this.store.dispatch(
        actions.deleteOutletToProject({
          partyId: outlet.id,
          userProject: this.project,
          outlet
        })
      );
    } else {
      this.actionType = 'adding';
      this.store.dispatch(
        actions.addOutletToProject({
          partyId: outlet.id,
          userProject: this.project,
          outlet
        })
      );
    }
  }

  openReportMissingDataModal() {
    this.store.dispatch(actions.showReportDialog());
  }
}
