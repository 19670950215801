<perfect-scrollbar
  class="scroll-container"
  [style]="perfectScrollbarHeight"
  [config]="config"
  #contentItemsPerfectScroll
>
  <ul class="p-list-group">
    <li *ngIf="isTopic" class="p-d-flex p-jc-end p-pt-2">
      <button
        pButton
        type="button"
        icon="pi pi-plus"
        class="p-button-text p-button-sm"
        label="New Topic"
        (click)="openNewTopic()"
      ></button>
    </li>
    <li *ngIf="isClip" class="p-d-flex p-jc-end p-pt-2">
      <button
        pButton
        type="button"
        icon="pi pi-plus"
        class="p-button-text p-button-sm"
        label="New Content Item"
        (click)="openNewContentItem()"
      ></button>
    </li>
    <li *ngFor="let item of sidebarData">
      <div class="p-list-picture" *ngIf="item.hasPicture">
        <img
          [src]="item?.picture"
          onerror="this.src='assets/images/profile-author.png'"
        />
      </div>
      <div class="p-list-picture" *ngIf="!item.hasPicture">
        <img [src]="'assets/images/blank.jpg'" />
      </div>
      <div class="p-d-flex p-jc-between">
        <div>
          <span
            class="p-list-title"
            href="javascript:;"
            role="button"
            (click)="
              !isClip
                ? openSelectDialog(item)
                : goToTitleUrl(item.parentObject.url)
            "
          >
            <h6>{{ item.title }}</h6>
          </span>
          <div class="p-list-text">
            {{ item.publishDate | date }} {{ item.subTitle }}
          </div>
        </div>
        <div class="p-d-flex">
          <button
            pTooltip="Edit item"
            tooltipPosition="top"
            (click)="openEditDialog(item.parentObject)"
            pButton
            pRipple
            type="button"
            icon="pi pi-pencil"
            class="p-button-secondary"
          ></button>
          <button
            *ngIf="!item?.loadingIcon"
            pTooltip="Unfollow item"
            tooltipPosition="top"
            (click)="handleUnfollow(item.parentObject)"
            pButton
            pRipple
            type="button"
            icon="pi pi-star-fill"
            class="p-button-secondary"
          ></button>
          <button
            *ngIf="item.loadingIcon"
            pTooltip="loading"
            tooltipPosition="top"
            pButton
            pRipple
            type="button"
            icon="pi pi-spin pi-spinner"
            class="p-button-secondary"
          ></button>
        </div>
      </div>
    </li>
  </ul>
</perfect-scrollbar>
