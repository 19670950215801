import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { tap, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NewsFeedService } from 'app/services';

@Injectable()
export class NewsFeedEffects {
  constructor(
    private actions$: Actions,
    private newFeedService: NewsFeedService
  ) {}

  loadNewsFeed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setNewsFeed),
      mergeMap(action =>
        this.getContents$(action).pipe(
          switchMap(response => [
            actions.setNewsFeedSuccess({
              data: response
            })
          ]),
          catchError(err => of(actions.setNewsFeedError({ payload: err })))
        )
      )
    )
  );

  getContents$(action): Observable<any> {
    return this.newFeedService.myNewsFeed(
      action.projectId,
      action.sort,
      action.page,
      action.size
    );
  }
}
