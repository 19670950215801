<p-dialog
  header="Payment Successful"
  [(visible)]="showDialog"
  [closable]="true"
  [dismissableMask]="true"
  [modal]="true"
  (onHide)="hideSuccessDialog()"
  [style]="{ width: '45rem' }"
  [baseZIndex]="2003"
>
  <div [style]="{ 'margin-top': '1rem' }"></div>
  <div class="p-d-flex p-jc-center">
    <div class="p-fluid">
      <i class="pi pi-check-circle icon"></i>
    </div>
  </div>
  <div [style]="{ 'margin-top': '2rem' }"></div>
  <div class="p-d-flex p-jc-center">
    <div class="p-title">Thank you for subscribing to TechNews!</div>
  </div>
  <div [style]="{ 'margin-top': '2rem' }"></div>

  <!--
  <div class="p-d-flex p-jc-center">
    <div class="p-subtitle">
      You will receive login credentials for your new account via e-mail.
    </div>
  </div>
  -->
  <!-- <div class="p-spacing"></div> -->
  <ng-template pTemplate="footer">
    <div class="p-d-flex p-jc-center">
      <button
        pButton
        class="p-button-secondary"
        label="Close"
        (click)="hideSuccessDialog()"
      ></button>
    </div>
  </ng-template>
</p-dialog>
