import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { ToastNotificationService } from 'app/components';
import { UserService } from 'app/services';
import * as actions from 'redux/actions';
import { UserEdit } from 'app/models';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  public visibleEditUserDialog: boolean;
  public editUserForm: FormGroup;
  public isSaving: boolean;
  public role: string;
  public userId: number;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private notificationService: ToastNotificationService,
    private store: Store<AppState>
  ) {
    this.visibleEditUserDialog = false;
    this.isSaving = false;
    this.role = null;
    this.userId = null;
  }

  ngOnInit(): void {
    this.editUserForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      userName: ['', [Validators.required, Validators.email]]
    });

    this.store
      .select('myAccount')
      .subscribe(({ visibleEditUserDialog, userEdit }) => {
        this.visibleEditUserDialog = visibleEditUserDialog;
        if (visibleEditUserDialog) {
          this.userId = userEdit.userId;
          this.role = userEdit.role;
          this.editUserForm.get('firstName').setValue(userEdit.firstName);
          this.editUserForm.get('lastName').setValue(userEdit.lastName);
          this.editUserForm.get('userName').setValue(userEdit.userName);
        }
      });
  }

  invalidField(field: string) {
    return (
      this.editUserForm.controls[field].errors &&
      this.editUserForm.controls[field].touched
    );
  }

  handleSubmit() {
    if (this.editUserForm.invalid) {
      this.editUserForm.markAllAsTouched();
      return;
    }
    this.isSaving = true;
    const editUser = new UserEdit({
      userId: this.userId,
      role: this.role,
      ...this.editUserForm.value
    });

    this.userService.editUser(editUser).subscribe(
      response => {
        this.notificationService.showMessage(
          'User has been edited successfuly.',
          'Success'
        );
        this.onClose(true);
      },
      error => {
        this.notificationService.showMessage('Error editing user', 'Error');
        this.onClose(false);
      }
    );
  }

  onClose(isEdited) {
    this.isSaving = false;
    this.store.dispatch(actions.hideEditUserDialog({ isEdited }));
  }
}
