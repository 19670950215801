import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { mergeMap, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { BlogService } from 'app/services';
import { Blog } from 'app/models';

@Injectable()
export class BlogEffects {
  constructor(private actions$: Actions, private blogService: BlogService) {}

  loadPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setPosts),
      mergeMap(action =>
        this.getPosts$(action).pipe(
          switchMap(({ posts }) => [
            actions.setPostsSuccess({
              posts: posts.map(post => {
                const { title, date, description, imageUrl, url } = post;
                return new Blog({
                  title,
                  pubDate: date,
                  description,
                  image: imageUrl,
                  link: url
                });
              })
            })
          ]),
          catchError(err => of(actions.setPostsError({ payload: err })))
        )
      )
    )
  );

  getPosts$({ size }): Observable<any> {
    return this.blogService.getPosts(size);
  }
}
