<p>All users in my organization</p>
<div class="p-mt-3">
  <p-table
    #dt
    [value]="userList"
    [globalFilterFields]="['name']"
    [rowHover]="true"
    selectionMode="single"
    styleClass="p-datatable-sm"
    scrollHeight="460px"
    dataKey="name"
    [rows]="5"
    [paginator]="true"
    [showCurrentPageReport]="false"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-ai-center p-jc-between">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            #sdt
            pInputText
            type="text"
            (input)="dt.filterGlobal(sdt.value, 'contains')"
            placeholder="Filter by name"
          />
        </span>

        <div class="p-m-0">
          <button
            pButton
            pRipple
            label="New User"
            icon="pi pi-user-plus"
            class="p-mr-2"
            (click)="newUser()"
          ></button>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <span *ngIf="userList.length == 0">No results</span>
    </ng-template>
    <p-confirmPopup></p-confirmPopup>
    <ng-template pTemplate="body" let-user let-rowIndex="rowIndex">
      <tr *ngIf="userList.length > 0">
        <td class="p-column-picture">
          <img
            [src]="user.avatar"
            onerror="this.src='assets/images/profile-author.png'"
            alt="Avatar"
          />
        </td>
        <td>{{ user.name }}</td>
        <td class="p-d-flex p-jc-end">
          <div>
            <p-button
              pTooltip="Edit user"
              tooltipPosition="top"
              icon="pi pi-pencil"
              styleClass="p-button-link"
              (click)="editUser(user)"
            ></p-button>
            <p-button
              pTooltip="Delete user"
              tooltipPosition="top"
              icon="pi pi-times"
              styleClass="p-button-link"
              (click)="deleteConfirmation($event, user)"
            ></p-button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between">
        Total {{ userList ? userList.length : 0 }} users
      </div>
    </ng-template>
  </p-table>
</div>
<app-new-user [accountId]="accountId" [username]="username"></app-new-user>
<app-edit-user></app-edit-user>
