import { createReducer, on } from '@ngrx/store';
import { UserEdit } from 'app/models';
import {
  showMyAccountDialog,
  hideMyAccountDialog,
  showNewUserDialog,
  hideNewUserDialog,
  showEditUserDialog,
  hideEditUserDialog
} from 'redux/actions';

export interface MyAccountState {
  showMyAccountDialog: boolean;
  visibleNewUserDialog: boolean;
  visibleEditUserDialog: boolean;
  isCreated: boolean;
  isEdited: boolean;
  userEdit: UserEdit;
}

export const initialState: MyAccountState = {
  showMyAccountDialog: false,
  visibleNewUserDialog: false,
  visibleEditUserDialog: false,
  isCreated: false,
  isEdited: false,
  userEdit: null
};

export const _myAccountReducer = createReducer(
  initialState,
  on(showMyAccountDialog, state => {
    return {
      ...state,
      showMyAccountDialog: true
    };
  }),
  on(hideMyAccountDialog, state => {
    return {
      ...state,
      showMyAccountDialog: false
    };
  }),
  on(showNewUserDialog, state => {
    return {
      ...state,
      visibleNewUserDialog: true,
      isCreated: false
    };
  }),
  on(hideNewUserDialog, (state, { isCreated }) => {
    return {
      ...state,
      visibleNewUserDialog: false,
      isCreated
    };
  }),
  on(showEditUserDialog, (state, { user }) => {
    return {
      ...state,
      userEdit: user,
      visibleEditUserDialog: true,
      isEdited: false
    };
  }),
  on(hideEditUserDialog, (state, { isEdited }) => {
    return {
      ...state,
      visibleEditUserDialog: false,
      isEdited
    };
  })
);

export function myAccountReducer(state, action) {
  return _myAccountReducer(state, action);
}
