import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { OutletService, AuthorService } from 'app/services';
import { tap, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class AuthorsOutletsListEffects {
  constructor(
    private actions$: Actions,
    private authorService: AuthorService,
    private outletService: OutletService
  ) {}

  loadAuthorsSaved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadAuthorsOutlets),
      mergeMap(action =>
        this.authorService.getAuthorsSaved(action.projectId).pipe(
          switchMap(response => [
            actions.loadAuthorsSuccess({
              authors: response
            })
          ]),
          catchError(err =>
            of(actions.loadAuthorsOutletsError({ payload: err }))
          )
        )
      )
    )
  );

  loadOutletsSaved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadAuthorsOutlets),
      mergeMap(action =>
        this.outletService.getOutletsSaved(action.projectId).pipe(
          switchMap(response => [
            actions.loadOutletsSuccess({
              outlets: response
            })
          ]),
          catchError(err =>
            of(actions.loadAuthorsOutletsError({ payload: err }))
          )
        )
      )
    )
  );

  addAuthorToProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addAuthorToProject),
      mergeMap(action =>
        this.authorService
          .addAuthorOutletToProject(action.partyId, action.userProject.value)
          .pipe(
            switchMap(response => [
              actions.addAuthorToProjectSuccess({
                author: action.author
              }),
              actions.loadAuthorsOutlets({
                projectId: action.userProject.value
              })
            ]),
            catchError(err =>
              of(
                actions.addAuthorOutletToProjectError({
                  payload: err
                })
              )
            )
          )
      )
    )
  );

  addOutletToProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addOutletToProject),
      mergeMap(action =>
        this.authorService
          .addAuthorOutletToProject(action.partyId, action.userProject.value)
          .pipe(
            switchMap(response => [
              actions.addOutletToProjectSuccess({
                outlet: action.outlet
              }),
              actions.loadAuthorsOutlets({
                projectId: action.userProject.value
              })
            ]),
            catchError(err =>
              of(
                actions.addAuthorOutletToProjectError({
                  payload: err
                })
              )
            )
          )
      )
    )
  );

  deleteAuthorToProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteAuthorToProject),
      mergeMap(action =>
        this.authorService
          .deleteAuthorOutletToProject(action.userProject.value, action.partyId)
          .pipe(
            tap(),
            switchMap(response => [
              actions.deleteAuthorToProjectSuccess({
                author: action.author
              }),
              actions.loadAuthorsOutlets({
                projectId: action.userProject.value
              })
            ]),
            catchError(err =>
              of(
                actions.deleteAuthorOutletToProjectError({
                  payload: err
                })
              )
            )
          )
      )
    )
  );

  deleteOutletToProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteOutletToProject),
      mergeMap(action =>
        this.authorService
          .deleteAuthorOutletToProject(action.userProject.value, action.partyId)
          .pipe(
            tap(),
            switchMap(response => [
              actions.deleteOutletToProjectSuccess({
                outlet: action.outlet
              }),
              actions.loadAuthorsOutlets({
                projectId: action.userProject.value
              })
            ]),
            catchError(err =>
              of(
                actions.deleteAuthorOutletToProjectError({
                  payload: err
                })
              )
            )
          )
      )
    )
  );
}
