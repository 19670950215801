import { createReducer, on } from '@ngrx/store';
import {
  resetAdvancedSearchFilters,
  setAdvancedFilterText,
  unSetAdvancedFilterText
} from 'redux/actions';

export interface AdvancedSearchState {
  onResetFilters: boolean;
  filterText: string;
}

const initialState: AdvancedSearchState = {
  onResetFilters: false,
  filterText: null
};

const _advancedSearchReducer = createReducer(
  initialState,

  on(resetAdvancedSearchFilters, (state, { onResetFilters }) => {
    return {
      ...state,
      onResetFilters
    };
  }),

  on(setAdvancedFilterText, (state, { filterText }) => {
    return {
      ...state,
      filterText
    };
  }),
  on(unSetAdvancedFilterText, state => {
    return {
      ...state,
      filterText: null
    };
  })
);

export function advancedSearchReducer(state, action) {
  return _advancedSearchReducer(state, action);
}
