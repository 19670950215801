import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentItemsService {
  private api: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.api = `${environment.api_url}`;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public getContentItems(projectId: number): Observable<any> {
    return this.http.get(
      `${this.api}/project/${projectId}/contentItems?detail=false`,
      this.httpOptions
    );
  }

  public saveContentItemToProject(
    projectId: number,
    contentItemId: number
  ): Observable<any> {
    const path = `${this.api}/userContentItems/addContent?userProjectId=${projectId}&itemId=${contentItemId}`;
    return this.http.post(path, null, this.httpOptions);
  }

  public deleteContentItemToProject(
    projectId: number,
    contentItemId: number
  ): Observable<any> {
    const path = `${this.api}/userContentItems/deleteFromProject?userProjectId=${projectId}&itemId=${contentItemId}`;
    return this.http.delete(path, this.httpOptions);
  }
}
