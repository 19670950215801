import { TopicReponseProps } from 'app/interfaces';

export class TopicResponse {
  public id?: number;
  public name?: string;
  public query?: string;
  public implicitQuery?: string;
  public influenceScore?: number;
  public categoryId?: number;
  public categoryName?: string;
  public coverage?: number;
  public authors?: any[];
  public outlets?: any[];
  public selected?: boolean;
  public custom?: boolean;
  public userTopicId?: number;
  public wikipediaUrl?: string;
  public webSiteUrl?: string;
  public youTubeUrl?: string;
  public twitterUrl?: string;
  public linkedInUrl?: string;
  public picture?: string;
  public financial?: string;
  public related?: any;
  public stats?: any;
  public project?: any;
  public isSaved?: boolean;
  public loadingIcon?: boolean;

  constructor({
    id,
    name,
    query,
    implicitQuery,
    influenceScore,
    categoryId,
    categoryName,
    coverage,
    authors,
    outlets,
    selected,
    custom,
    userTopicId,
    wikipediaUrl,
    webSiteUrl,
    youTubeUrl,
    twitterUrl,
    linkedInUrl,
    picture,
    financial,
    related,
    stats,
    project,
    isSaved,
    loadingIcon
  }: TopicReponseProps) {
    this.id = id || null;
    this.name = name || null;
    this.query = query || null;
    this.implicitQuery = implicitQuery || null;
    this.influenceScore = influenceScore || null;
    this.categoryId = categoryId || null;
    this.categoryName = categoryName || null;
    this.coverage = coverage || null;
    this.coverage = coverage || null;
    this.authors = authors || null;
    this.outlets = outlets || null;
    this.selected = selected || false;
    this.custom = custom || false;
    this.userTopicId = userTopicId || null;
    this.wikipediaUrl = wikipediaUrl || null;
    this.webSiteUrl = webSiteUrl || null;
    this.youTubeUrl = youTubeUrl || null;
    this.twitterUrl = twitterUrl || null;
    this.linkedInUrl = linkedInUrl || null;
    this.picture = picture || null;
    this.financial = financial || null;
    this.related = related || null;
    this.stats = stats || null;
    this.project = project || null;
    this.isSaved = isSaved || false;
    this.loadingIcon = loadingIcon || false;
  }
}
