import { Component, Input, OnInit } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexTooltip,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexPlotOptions,
  ApexLegend,
  ApexXAxis,
  ApexYAxis
} from 'ng-apexcharts';

interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  markers: any;
  stroke: any;
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels;
  colors: string[];
  labels: string[] | number[];
  title: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
}

declare global {
  interface Window {
    Apex: any;
  }
}

@Component({
  selector: 'short-term-chart',
  template: `
    <apx-chart
      [series]="topic.shortTermSeries"
      [colors]="topic.shortTermColor"
      [chart]="sparklineChartOptions.chart"
      [tooltip]="sparklineChartOptions.tooltip"
    ></apx-chart>
  `,
  styles: [``]
})
export class ShortTermChartComponent implements OnInit {
  public series: any[];

  @Input() topic: any;

  public chartOptions: Partial<ChartOptions>;
  public chartLineSparkline1Options: Partial<ChartOptions>;
  public sparklineChartOptions: Partial<ChartOptions> = {
    chart: {
      type: 'line',
      width: '100%',
      height: 35,
      sparkline: {
        enabled: true
      }
    },
    tooltip: {
      enabled: false, // with this doesn't show values on mouse hover
      fixed: {
        enabled: false
      },
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function(seriesName) {
            return '';
          }
        }
      },
      marker: {
        show: false
      }
    }
  };

  constructor() {
    window.Apex = {
      stroke: {
        width: 3
      },
      markers: {
        size: 0
      },
      tooltip: {
        fixed: {
          enabled: false
        }
      }
    };
  }

  ngOnInit() {}
}
