<div class="error-box">
  <div class="error-body text-center">
    <h1 class="error-title text-orange-600">{{ url }}</h1>
    <h3 class="text-uppercase error-subtitle">Route Not Found</h3>
    <p class="text-muted m-t-30 m-b-30">
      Sorry, This route is unknown to the application
    </p>
    <button
      pButton
      icon="pi pi-home"
      class="p-button-lg m-b-40"
      label="Go Home"
      (click)="goBack()"
    ></button>
  </div>
</div>
