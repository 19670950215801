import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthorService {
  private api: string;
  private cdn: string;
  private version: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.api = environment.api_url;
    this.cdn = environment.cdn_url;
    this.version = environment.images_version;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public getAuthorsSaved(projectId): Observable<any> {
    return this.http.get(
      `${this.api}/project/${projectId}/authors/v2`,
      this.httpOptions
    );
  }

  public addAuthorOutletToProject(
    partyId: number,
    projectId: number
  ): Observable<any> {
    const path = `${this.api}/userParties/addPartyToProject?partyId=${partyId}&projectId=${projectId}`;
    return this.http.post(path, null, this.httpOptions);
  }

  public updateAuthorOutletToProject(
    partyId: number,
    userLabelId: number
  ): Observable<any> {
    const path = `${this.api}/userParties/updateCustom?userPartyId=${partyId}&userLabelId=${userLabelId}`;
    return this.http.put(path, null, this.httpOptions);
  }

  public deleteAuthorOutletToProject(
    projectId: number,
    partyId: number
  ): Observable<any> {
    const path = `${this.api}/userParties/deleteFromProject?userProjectId=${projectId}&partyId=${partyId}`;
    return this.http.delete(path, this.httpOptions);
  }

  public getAuthorsForTopic(topicId, sort, p, s): Observable<any> {
    return this.http.post(
      `${this.api}/content/userTopic/${topicId}/authors/${sort}?page=${p}&size=${s}`,
      '{}',
      this.httpOptions
    );
  }

  // Async function to fetch author in order to Wait for asynchronous content indexing of new topic #1055
  public async asyncAuthorsForTopic(topicId, sort, p, s) {
    const authors = await this.http
      .post(
        `${this.api}/content/userTopic/${topicId}/authors/${sort}?page=${p}&size=${s}`,
        '{}',
        this.httpOptions
      )
      .toPromise();
    return authors;
  }

  public getAuthorPicture(authorId): string {
    return `${this.cdn}/images/people/${this.version}/${authorId}.png`;
  }

  public getContentItems(topicId, authorId, sort, p, s = 10): Observable<any> {
    return this.http.get(
      `${this.api}/content/userTopic/${topicId}/author/${authorId}/${sort}?page=${p}&size=${s}`,
      this.httpOptions
    );
  }

  public getAuthorById(authorId): Observable<any> {
    return this.http.get(`${this.api}/authors/${authorId}`, this.httpOptions);
  }

  public searchAuthorsByName(name): Observable<any> {
    return this.http.get(
      `${this.api}/search/authors/${name}`,
      this.httpOptions
    );
  }

  public getAuthorWithOutlets(authorId, topicId): Observable<any> {
    return this.http.get(
      `${this.api}/content/userTopic/${topicId}/author/${authorId}`,
      this.httpOptions
    );
  }

  public createAuthor(author: any): Observable<any> {
    const path = `${this.api}/authors/create`;
    return this.http.post(path, author, this.httpOptions);
  }

  public updateAuthor(author: any): Observable<any> {
    const path = `${this.api}/authors/${author.id}`;
    return this.http.post(path, author, this.httpOptions);
  }

  public removeAuthor(author: any): Observable<any> {
    const path = `${this.api}/authors/${author.id}`;
    return this.http.delete(path, this.httpOptions);
  }
}
