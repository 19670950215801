import { createAction, props } from '@ngrx/store';
import { UserEdit } from 'app/models';

export const showMyAccountDialog = createAction(
  '[My Account] showMyAccountDialog'
);

export const hideMyAccountDialog = createAction(
  '[My Account] hideMyAccountDialog'
);

export const showNewUserDialog = createAction('[My Account] showNewUserDialog');

export const hideNewUserDialog = createAction(
  '[My Account] hideNewUserDialog',
  props<{ isCreated: boolean }>()
);

export const showEditUserDialog = createAction(
  '[My Account] showEditUserDialog',
  props<{ user: UserEdit }>()
);

export const hideEditUserDialog = createAction(
  '[My Account] hideEditUserDialog',
  props<{ isEdited: boolean }>()
);
