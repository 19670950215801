import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { eq, isEmpty, includes } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import {
  loadAuthorContent,
  loadOutletContent,
  addAuthorToProject,
  deleteAuthorToProject,
  addOutletToProject,
  deleteOutletToProject,
  showNotification,
  initAuthorOutletToProjectState
} from 'redux/actions';
import { FacetSearch, Notification } from 'app/models';
import { STAR_ICON, STAR_ICON_FILL } from 'app/constants';
import { FacetContent } from '../dashboard-facets/facet-content.interface';

@Component({
  selector: 'app-content-table',
  template: `
    <div class="card border" *ngIf="entity">
      <p-table [value]="tableItems" styleClass="p-datatable-sm">
        <ng-template pTemplate="header">
          <tr>
            <th>
              {{ entity.title }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-facet>
          <tr>
            <td class="p-d-flex p-jc-between">
              <div>
                <span
                  href="javascript:;"
                  role="button"
                  *ngIf="facet?.starIcon"
                  (click)="handleSaveFacetItemToProject(facet, entity.title)"
                >
                  <i [class]="facet.starIcon" style="font-size: 13px"></i>
                </span>
                <span
                  href="javascript:;"
                  role="button"
                  (click)="showModalContent(entity.title, facet)"
                >
                  <span>
                    {{ facet.name }}
                  </span>
                  <!-- <span *ngIf="!visibleEllipses">
                    {{ facet.name | slice: 0:sliceControl }}...
                  </span> -->
                </span>
              </div>

              <div class="total-inline" *ngIf="showNumberItem">
                {{ facet.numItems | number }}
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  styleUrls: ['./facets-table.scss']
})
export class ContentTableComponent implements OnInit, OnChanges {
  @Input() entity: FacetContent;
  @Input() authorsSavedList: any[];
  @Input() outletsSavedList: any[];
  public keywords: string;
  public currentProject: any;
  public outletsList: any[];
  public authorsList: any[];
  public starSelected: string;
  public starUnSelected: string;
  public tableItems: any[];
  public project: any;
  public authorOutletOperation: any;
  public sort: any;
  public screenSize: number;
  public sliceControl: number;
  public visibleEllipses: boolean;
  public isInitList: boolean;
  public showNumberItem: boolean;

  constructor(private store: Store<AppState>) {
    this.keywords = '';
    this.starUnSelected = STAR_ICON;
    this.starSelected = STAR_ICON_FILL;
    this.tableItems = [];
    this.authorsList = [];
    this.outletsList = [];
    this.project = null;
    this.authorOutletOperation = {
      typeOperation: null,
      entity: null,
      itemIndex: null
    };
    this.visibleEllipses = true;
    this.sliceControl = 9;
    this.isInitList = true;
    this.showNumberItem = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity) {
      this.tableItems = changes.entity.currentValue.contents;
    }

    if (this.isInitList) {
      if (changes.authorsSavedList.currentValue) {
        this.authorsList = changes.authorsSavedList.currentValue;
        if (eq(this.entity.title, 'Authors')) {
          this.tableItems = this.entity.contents.map(item => ({
            ...item,
            starIcon: this.authorsList.find(li => eq(li.partyId, item.id))
              ? this.starSelected
              : this.starUnSelected
          }));
        }
      }

      if (changes.outletsSavedList.currentValue) {
        this.outletsList = changes.outletsSavedList.currentValue;
        if (eq(this.entity.title, 'Outlets')) {
          this.tableItems = this.entity.contents.map(item => ({
            ...item,
            starIcon: this.outletsList.find(li => eq(li.partyId, item.id))
              ? this.starSelected
              : this.starUnSelected
          }));
        }
      }
      this.isInitList = false;
    }
  }

  ngOnInit() {
    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.project = projectSelected;
      }
    });

    this.store.select('authorsOutletsList').subscribe(({ errors }) => {
      const { typeOperation, entity } = this.authorOutletOperation;
      if (errors && typeOperation) {
        this.store.dispatch(
          showNotification({
            notify: new Notification(
              `Error ${typeOperation} ${entity} to project`,
              'error',
              'tl'
            )
          })
        );
        this.authorOutletOperation = {
          typeOperation: null,
          entity: null,
          tableItem: null
        };
        setTimeout(() => {
          this.store.dispatch(initAuthorOutletToProjectState());
        }, 500);
      }
    });

    this.store
      .select('search')
      .subscribe(({ searchBody }) => (this.keywords = searchBody.keywords));

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.currentProject = projectSelected;
      }
    });

    this.store.select('ui').subscribe(({ screenSizeValues }) => {
      this.screenSize = screenSizeValues.scrWidth;
      if (this.screenSize <= 1700) {
        this.sliceControl = 18;
        this.visibleEllipses = false;
        if (this.screenSize < 1500) {
          this.sliceControl = 9;
        }
      } else {
        this.visibleEllipses = true;
      }
      if (this.screenSize >= 1500) {
        this.showNumberItem = true;
      } else {
        this.showNumberItem = false;
      }
    });
  }

  showModalContent(entity, item) {
    if (eq(entity, 'Authors')) {
      this.getAuthorContent(item);
    }

    if (eq(entity, 'Outlets')) {
      this.getOutletContent(item);
    }
  }

  /**
   * Handle star icon action
   */
  handleSaveFacetItemToProject(valueItem, entity) {
    // Author update
    if (eq(entity, 'Authors')) {
      const itemIndex = this.tableItems.findIndex(item =>
        eq(item.id, valueItem.id)
      );
      const isSaved = eq(
        this.tableItems[itemIndex].starIcon,
        this.starSelected
      );

      const author = {
        ...this.tableItems[itemIndex],
        starIcon: isSaved ? STAR_ICON : STAR_ICON_FILL
      };
      this.tableItems[itemIndex] = author; // final item update

      if (isSaved) {
        this.authorOutletOperation = {
          typeOperation: 'deleting',
          entity: 'author',
          itemIndex: itemIndex
        };
        this.store.dispatch(
          deleteAuthorToProject({
            partyId: valueItem.id,
            userProject: this.project,
            author: valueItem
          })
        );
      } else {
        this.authorOutletOperation = {
          typeOperation: 'adding',
          entity: 'author',
          itemIndex: itemIndex
        };
        this.store.dispatch(
          addAuthorToProject({
            partyId: valueItem.id,
            userProject: this.project,
            author: valueItem
          })
        );
      }
    }

    // Outlet update
    if (eq(entity, 'Outlets')) {
      const itemIndex = this.tableItems.findIndex(item =>
        eq(item.id, valueItem.id)
      );
      const isSaved = eq(
        this.tableItems[itemIndex].starIcon,
        this.starSelected
      );

      const outlet = {
        ...this.tableItems[itemIndex],
        starIcon: isSaved ? STAR_ICON : STAR_ICON_FILL
      };
      this.tableItems[itemIndex] = outlet; // final item update

      if (isSaved) {
        this.authorOutletOperation = {
          typeOperation: 'deleting',
          entity: 'outlet',
          itemIndex: itemIndex
        };
        this.store.dispatch(
          deleteOutletToProject({
            partyId: valueItem.id,
            userProject: this.project,
            outlet: valueItem
          })
        );
      } else {
        this.authorOutletOperation = {
          typeOperation: 'adding',
          entity: 'outlet',
          itemIndex: itemIndex
        };
        this.store.dispatch(
          addOutletToProject({
            partyId: valueItem.id,
            userProject: this.project,
            outlet: valueItem
          })
        );
      }
    }
  }

  getAuthorContent(author) {
    const facetSearch = new FacetSearch(
      author.id,
      'author',
      this.keywords,
      'recent',
      0,
      50
    );

    this.store.dispatch(
      loadAuthorContent({
        facetSearch,
        author
      })
    );
  }

  getOutletContent(outlet) {
    const facetSearch = new FacetSearch(
      outlet.id,
      'outlet',
      this.keywords,
      'recent',
      0,
      50
    );

    this.store.dispatch(
      loadOutletContent({
        facetSearch,
        outlet
      })
    );
  }

  getYahooFinanceURL(value: any) {
    if (isEmpty(value)) {
      return null;
    } else if (includes(value, 'http')) {
      const q = value.substring(value.lastIndexOf('/') + 1, value.length);
      return `https://finance.yahoo.com/quote/${q}`;
    } else {
      return `https://finance.yahoo.com/quote/${value}`;
    }
  }
}
