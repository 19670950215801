import { createAction, props } from '@ngrx/store';

export const resetAdvancedSearchFilters = createAction(
  '[Advanced Search] resetSearch',
  props<{ onResetFilters: boolean }>()
);

export const setAdvancedFilterText = createAction(
  '[Advanced Search] setAdvancedFilterText',
  props<{ filterText: string }>()
);

export const unSetAdvancedFilterText = createAction(
  '[Advanced Search] unSetAdvancedFilterText'
);
