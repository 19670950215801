<div class="surface-card py-2">
  <form>
    <div class="p-mb-4">
      <div class="text-center font-title">
        Create Account
      </div>
<!--      <div class="text-center font-title">-->
<!--        Begin a free trial now-->
<!--      </div>-->
      <div class="text-center">
        Already have an account?
        <a
          (click)="goToLogin()"
          class="font-medium no-underline text-grey-500 cursor-pointer"
        >Sign in</a
        >
      </div>
    </div>

    <div class="flex w-full justify-content-center mb-2">
      <button
        icon="pi pi-google"
        pButton
        pRipple
        label="Sign up with Google"
        class="p-button-lg px-4 w-medium"
        (click)="loginWithGoogle()"
      ></button>
    </div>
    <!--    <div class="flex w-full justify-content-center mb-2">-->
    <!--      <button-->
    <!--        icon="pi pi-microsoft"-->
    <!--        pButton-->
    <!--        pRipple-->
    <!--        label="Sign up with Microsoft"-->
    <!--        class="p-button-lg px-4 w-medium"-->
    <!--        (click)="loginWithMicrosoft()"-->
    <!--      ></button>-->
    <!--    </div>-->
    <div class="flex w-full justify-content-center mb-2">
      <button
        icon="pi pi-linkedin"
        pButton
        pRipple
        label="Sign up with LinkedIn"
        class="p-button-lg px-4 w-medium"
        (click)="loginWithLinkedIn()"
      ></button>
    </div>

    <div class="text-center">
      <iframe src="https://player.vimeo.com/video/803604938" width="640" height="400" frameborder="0" title="TechNews" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </div>
  </form>
</div>
