import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AuthenticationService,
  ProjectService,
  TopicService,
  AuthorService,
  UserService,
  CategoryService,
  SessionService,
  OutletService,
  ReportMissingDataService,
  SearchService,
  NewsFeedService,
  BlogService,
  CompanyService,
  CollectionService
} from './index';
import {
  AuthenticationInterceptor,
  AuthenticationGuard,
  LoginGuard
} from './authentication';
import { ConfirmationService } from 'primeng/api';

@NgModule({
  imports: [HttpClientModule, CommonModule],
  providers: [
    AuthenticationService,
    AuthenticationGuard,
    LoginGuard,
    ProjectService,
    TopicService,
    AuthorService,
    UserService,
    CategoryService,
    SessionService,
    OutletService,
    ReportMissingDataService,
    SearchService,
    NewsFeedService,
    BlogService,
    CompanyService,
    ConfirmationService,
    CollectionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    }
  ],
  declarations: []
})
export class ServicesModule {}
