<p-dialog
  header="Pricing / Sign Up"
  [(visible)]="showDialog"
  [closable]="true"
  [dismissableMask]="true"
  [modal]="true"
  (onHide)="hideSignUpDialog()"
  [baseZIndex]="2003"
>
  <div [style]="{ 'margin-top': '2rem' }"></div>
  <div class="p-d-flex p-jc-between">
    <div>
      <p-card [style]="{ width: '450px' }" styleClass="p-card-shadow">
        <ng-template pTemplate="header">
          <div class="p-d-flex p-jc-center">
            <div class="p-title">Monthly Subscription</div>
          </div>
          <!--          <div class="p-d-flex p-jc-center">-->
          <!--            <div class="p-subtitle">One User</div>-->
          <!--          </div>-->
        </ng-template>
        <!-- <div class="p-spacing"></div> -->
        <div class="p-grid">
          <div class="p-col-12 box">
            <!-- <p class="p-title-box">PRICES</p> -->
            <div class="p-sub-title-box"></div>
            <p>
              <span class="p-content-box-price">1-2 users</span>:&nbsp;&nbsp;
              <span class="p-content-box-price">$149 / user</span>
            </p>
            <p>
              <span class="p-content-box-price">3-9 users</span>:&nbsp;&nbsp;
              <span class="p-content-box-price">$75 / user</span>
            </p>
            <p>
              <span class="p-content-box-price">10+ users</span>:&nbsp;&nbsp;
              <span class="p-content-box-price">$49 / user</span>
            </p>
            <div class="p-sub-title-box"></div>
          </div>
        </div>

        <div class="p-grid p-mt-4">
          <div class="p-field p-col-12 p-d-flex p-jc-center">
            <label class="p-mr-2 p-mt-2" for="minmax">Number of Users:</label>
            <p-inputNumber
              [(ngModel)]="numUsers"
              [showButtons]="true"
              mode="decimal"
              [min]="minUser"
              [max]="maxUser"
            >
            </p-inputNumber>
          </div>

          <div class="p-col-12 p-d-flex p-jc-center">
            <button
              pButton
              class="p-button-warning"
              label="Sign Up"
              (click)="handleSubscription(false)"
            ></button>
          </div>
        </div>
      </p-card>
    </div>
    <div></div>
    <!--    <div>-->
    <!--      <p-card [style]="{ width: '310px' }" styleClass="p-card-shadow">-->
    <!--        <ng-template pTemplate="header">-->
    <!--          <div class="p-d-flex p-jc-center">-->
    <!--            <div class="p-title">Annual Subscription</div>-->
    <!--          </div>-->
    <!--          <div class="p-d-flex p-jc-center">-->
    <!--            <div class="p-subtitle">One User</div>-->
    <!--          </div>-->
    <!--        </ng-template>-->
    <!--        &lt;!&ndash; <div class="p-spacing"></div> &ndash;&gt;-->
    <!--        <div class="p-d-flex p-jc-between">-->
    <!--          <div></div>-->
    <!--          <div class="box">-->
    <!--            <span class="p-title-box">PRICE</span>-->
    <!--            <p class="p-content-box-price">${{ price?.yearly }} / year</p>-->
    <!--            <div class="p-sub-title-box">(a 44% savings!)</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <ng-template pTemplate="footer">-->
    <!--          <div class="p-d-flex p-jc-center">-->
    <!--            <button-->
    <!--              pButton-->
    <!--              class="p-button-warning"-->
    <!--              label="Annual Subscription"-->
    <!--              (click)="handleSubscription(true)"-->
    <!--            ></button>-->
    <!--          </div>-->
    <!--        </ng-template>-->
    <!--      </p-card>-->
    <!--    </div>-->
  </div>
  <div [style]="{ 'margin-top': '2rem' }"></div>
  <div class="p-d-flex p-jc-center">
    <div>
      Questions? Contact
      <a href="mailto:support@technews.io">support@technews.io</a>
    </div>
  </div>
</p-dialog>
