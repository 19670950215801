import { createAction, props } from '@ngrx/store';
import { ProjectToggle } from 'app/models';

export const setEmailAlertToogle = createAction(
  '[Project Email Alert Toggle] setEmailAlertToogle',
  props<{ toggle: ProjectToggle }>()
);

export const setEmailAlertToogleError = createAction(
  '[Project Email Alert Toggle] setEmailAlertToogleError',
  props<{ payload: any }>()
);
